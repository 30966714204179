const FILTER_MESSAGES = {
  filterMessage: `$t('components.emptySelectionMessage.dropdown')`,
  filterResultMessage: `$t('components.emptySelectionMessage.dropdown')`,
}

const EMPTY_MESSAGES = {
  emptyFilterMessage: `$t('components.emptyFilterMessage.dropdown')`,
  emptyMessage: `$t('components.emptyMessage.dropdown')`,
  emptySelectionMessage: `$t('components.emptySelectionMessage.dropdown')`,
}

export { FILTER_MESSAGES, EMPTY_MESSAGES };