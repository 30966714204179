import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'community-support',
    name: 'community-support',
    redirect: '/shelter/community-support/active-support',
    meta: {
      featureFlag: FEATURE_FLAGS.COMMUNITY_SUPPORT,
      permissions: [ROLES.COMMUNITY_SUPPORT],
    },
    children: [
      {
        path: 'active-support',
        name: 'community-support-active-support',
        component: () => import('./views/ActiveSupport.view.vue'),
      },
      {
        path: 'map',
        name: 'community-support-map',
        component: () => import('./views/SupportMap.view.vue'),
      },
      {
        path: 'report',
        name: 'community-support-report',
        component: () => import('./views/SupportReport.view.vue'),
      },
      {
        path: 'stats',
        name: 'community-support-stats',
        component: () => import('./views/SupportStats.view.vue'),
      },
      {
        path: 'request/:id',
        name: 'community-support-report-request',
        component: () => import('./views/SupportRequest.view.vue'),
      },
      {
        path: 'add-case',
        name: 'community-support-add-case',
        component: () => import('./views/SupportAddCase.view.vue'),
      },
    ],
  },
];
