import type { ShallowRef } from 'vue';
import type { AfDate, OId, UtcDateTime } from '@Types';
import type { ProductAtCart } from '@ProductsAndServicesModule';
import type { RawField } from '@FormModule';

enum CART_STEPS {
  ADD_PEOPLE = 'step-add-people',
  CHECKOUT_METHOD = 'step-checkout-method',
  MOBILE_CHECKOUT = 'step-mobile-checkout',
  MOBILE_PROCESSING = 'step-mobile-processing',
  OUTCOME_INFO = 'step-outcome-info',
  PAYMENT_METHOD = 'step-payment-method',
  PRODUCTS_AND_SERVICES = 'products-and-services',
  PRODUCTS_INFO = 'step-products-info',
  SUMMARY = 'step-summary',
}

enum OUTCOMES {
  CITATION = 'citation',
  FOUND = 'found',
  LOST = 'lost',
  LOST_AND_FOUND = 'lost_found',
  OUTCOME = 'outcome',
  PRODUCT = 'product',
  REHOME = 'rehome',
}

// NOTE: Do not sort this enum
enum OUTCOMES_TYPES {
  // IN CARE
  ADOPTED = 'adopted',
  DIED_IN_CARE = 'died_in_care',
  DOA = 'doa',
  LOST = 'lost',
  OWNER_REQUESTED_EUTHANASIA = 'owner_requested_euthanasia',
  RETURN_TO_FIELD = 'return_to_field',
  RETURN_TO_OWNER = 'return_to_owner',
  SERVICE_OUT = 'service_out',
  SHELTER_EUTHANASIA = 'shelter_euthanasia',
  TRANSFER_OUT = 'transfer_out',

  // LOST & FOUND
  FINDER_KEPT = 'finder_kept',
  REHOMED = 'rehomed',
  REUNITED_WITH_OWNER = 'reunited_with_owner',
  SHELTER_INTAKE = 'shelter_intake',
  UNKNOWN = 'unknown',

  // REHOME
  REHOME = 'rehome',
  REHOME_SHELTER_INTAKE = 'rehome_shelter_intake',
  REHOME_UNKNOWN = 'rehome_unknown',
  REMAINED_WITH_OWNER = 'remained_with_owner',

  // CITATIONS
  CITATION = 'citation',

  // PRODUCTS
  PRODUCTS = 'product',

  // INTAKE
  INTAKE = 'intake',
}

enum PAYMENT_METHODS {
  CARD_READER = 'card_reader',
  CASH = 'cash',
  CHECK = 'check',
  CREDIT = 'credit_card',
  OTHER = 'other',
}

enum PAYMENT_STATUS {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  DELETED = 'deleted',
  PAID = 'paid',
  PENDING = 'pending',
}

type AfSource = 'manual' | 'mobile_checkout' | 'adopt_app';
type CartType = 'outcome' | 'product' | 'intake' | 'citation' | 'boarding' | 'donation';
type DiscountType = 'percent' | 'amount';
type IntakeType = 'stray' | 'owner_surrender';
type OutcomeType = 'adopted' | 'return_to_owner';
type PaymentStatus =
  | PAYMENT_STATUS.ACTIVE
  | PAYMENT_STATUS.COMPLETED
  | PAYMENT_STATUS.DELETED
  | PAYMENT_STATUS.PAID
  | PAYMENT_STATUS.PENDING;
type PaymentType =
  | PAYMENT_METHODS.CARD_READER
  | PAYMENT_METHODS.CASH
  | PAYMENT_METHODS.CHECK
  | PAYMENT_METHODS.CREDIT
  | PAYMENT_METHODS.OTHER;
type ProductSource = 'Stray' | 'Return to Owner';
type RtoFeeType = 'daily' | 'flat';
type CartCategory =
  | 'Adoptions'
  | 'Boardings'
  | 'Citations'
  | 'Donations'
  | 'Owner Surrenders'
  | 'Products & Services'
  | 'Returns to Owner'
  | 'Strays';

interface AdditionalFee {
  _id: string;
  amount: string | number;
  description: string;
  label?: string;
  outcome_type: string; // TODO : AF-533 This should be an ENUM
  species: string; // TODO : AF-533 This should be an ENUM
}

interface RtoFee extends Omit<AdditionalFee, '_id'> {
  _id: OId;
  shelter_id?: OId;
  status: string;
  type: RtoFeeType;
}

interface StepHeader {
  icon: string;
  title: string;
  subtitle: string;
}

interface Step {
  active: boolean;
  component: ShallowRef<any>;
  header: StepHeader;
  name: CART_STEPS;
  tooltip: string;
}

// TODO AF-532 :: Improve this interface
interface CartItem {
  [key: string]: any;
  _id: string;
  animal_name: string;
  photo: string;
}

interface PaymentMethod {
  disabled?: boolean;
  disabledMessage?: string;
  icon: string;
  key: PaymentType;
  label: string;
  value: PaymentType;
}

// TODO : AF-531 - Update API to accept this payload
interface PendingMobileOutcomePayload {
  cart_id: string;
  last_four_digits?: string | number; // NOTE: Not implement in the API yet
  outcome_date?: string; // NOTE: utc eg. 2024-11-24T19:24:01.520Z
  payment_method?: PaymentType; // NOTE: Not implement in the API yet
  pre_adopt_date?: UtcDateTime; // NOTE: utc eg. 2024-11-24T19:24:01.520Z
}

interface PendingMobileIntakePayload {
  cart_id: string;
}

interface OutcomeForm {
  [key: string]: any;
  _id: OId;
  disabled?: boolean;
  disabledMessage?: string;
  fields: RawField[];
  form_name: string;
  form_title: string;
  form_type: string;
  is_default: boolean;
  shelter_id: OId;
  status: string;
  updated_at: AfDate;
}

// TODO AF-532 :: Improve this interface
interface TempCart {
  [key: string]: any;
  _id: OId;
  cart_type: CartType;
  expires_at: AfDate;
  is_expired: boolean;
  paid?: boolean;
  status: PaymentStatus;
}

type CartItemType = 'animal' | 'citation' | 'product' | 'service';

interface AddProductToCartRequestPayload {
  cart_type: 'product';
  products: ProductAtCart[];
}

interface AddProductToOutcomeCartRequestPayload {
  products: ProductAtCart[];
}

export type {
  AddProductToCartRequestPayload,
  AddProductToOutcomeCartRequestPayload,
  AdditionalFee,
  CartItem,
  CartItemType,
  OutcomeForm,
  PaymentMethod,
  PendingMobileIntakePayload,
  PendingMobileOutcomePayload,
  RtoFee,
  Step,
  TempCart,
};

export { CART_STEPS, OUTCOMES, OUTCOMES_TYPES, PAYMENT_METHODS, PAYMENT_STATUS };

export type {
  AfSource,
  CartCategory,
  CartType,
  DiscountType,
  IntakeType,
  OutcomeType,
  PaymentStatus,
  PaymentType,
  ProductSource,
  RtoFeeType,
};
