<script setup lang="ts">
import { computed } from 'vue';
import type { Field } from '@FormModule';

const props = withDefaults(
  defineProps<{
    email?: string;
    field?: Field;
    href?: string;
    name?: string;
    phone?: string;
    profileIcon?: string;
  }>(),
  {
    profileIcon: 'pi-truck',
  }
);

const profile = computed(() => ({
  email: props?.email || props?.field?.settings?.email || '',
  href: props?.href || props?.field?.settings?.href || '',
  name: props?.name || props?.field?.settings?.name || '',
  phone: props?.phone || props?.field?.settings?.phone || '',
  profileIcon: props?.field?.settings?.profileIcon || props?.profileIcon,
}));
</script>

<template>
  <span
    v-if="!profile.name"
    class="flex items-center text-sm text-gray-400"
  >
    {{ $t('components.forms.empty.profileInfo') }}
  </span>

  <div
    v-else
    class="my-2 flex w-full flex-col gap-1"
  >
    <RouterLink
      class="flex items-center gap-0.5 text-sm text-sky-600 transition-colors hover:text-indigo-900 hover:underline"
      :to="profile.href"
    >
      <span :class="['pi text-xs text-brand-500', profileIcon]"></span>

      <span>{{ profile.name }}</span>
    </RouterLink>

    <a
      class="flex w-full items-center gap-1 text-sm hover:text-indigo-900 hover:underline"
      target="_blank"
      :href="`mail:${profile.email}`"
    >
      <span class="pi pi-at text-xs text-brand-500"></span>

      <span class="overflow-hidden text-ellipsis">
        {{ profile.email }}
      </span>
    </a>

    <a
      class="flex items-center gap-1 text-sm hover:text-indigo-900 hover:underline"
      :href="`tel:${profile.phone}`"
      target="_blank"
    >
      <span class="pi pi-phone text-xs text-brand-500"></span>

      <span>{{ profile.phone }} </span>
    </a>
  </div>
</template>
