<script setup lang="ts">
import { ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    error?: string;
    field?: FieldSchema;
    max?: number;
    maxFractionDigits?: number;
    min?: number;
    minFractionDigits?: number;
    modelValue?: number | string;
    placeholder?: string;
    required?: boolean;
    useGrouping?: boolean;
  }>(),
  {
    maxFractionDigits: 2,
    placeholder: 'components.forms.placeholder.common',
    useGrouping: true,
  }
);

const emits = defineEmits<{
  'update:modelValue': [number | undefined];
}>();

const _model = ref<number | undefined>(parseValue(props.modelValue));

const input = useDebounce(_model, 50);

watch(input, () => emits('update:modelValue', _model.value));

watch(
  () => props.modelValue,
  (newVal) => {
    _model.value = parseValue(newVal);
  }
);

function parseValue(value: number | string | undefined) {
  const notAString = typeof value !== 'string';
  if (notAString) return value;

  const parsed = parseInt(value);
  return isNaN(parsed) ? undefined : parsed;
}
</script>

<template>
  <InputNumber
    v-model="_model"
    fluid
    :class="['h-9 w-full', { 'af-field-error': !!error }]"
    :disabled="field?.state?.disabled || disabled"
    :invalid="!!error"
    :max="field?.settings.max || max"
    :maxFractionDigits="field?.settings.maxFractionDigits || maxFractionDigits"
    :min="field?.settings.min || min"
    :minFractionDigits="field?.settings.minFractionDigits || minFractionDigits"
    :placeholder="$t(field?.settings.placeholder || placeholder)"
    :pt:input:root="['text-sm', { 'af-field-error': !!error }]"
    :required="field?.settings.required || required"
    :useGrouping="field?.settings.useGrouping || useGrouping"
    @input="(event) => (_model = Number(event.value))"
  />
</template>
