import type { MenuItem } from 'primevue/menuitem';
import type { GenericArray } from '@Types';
import type { PaymentMethod } from './types';
import { PAYMENT_METHODS, OUTCOMES } from './types';

const { CARD_READER, CASH, CHECK, CREDIT, OTHER } = PAYMENT_METHODS;
const { CITATION, FOUND, LOST, OUTCOME, PRODUCT, REHOME } = OUTCOMES;

const SANITIZED_OUTCOME_TYPES: GenericArray = {
  adopted: 'cart.outcomeOptions.adopted',
  died_in_care: 'cart.outcomeOptions.died_in_care',
  doa: 'cart.outcomeOptions.doa',
  finder_kept: 'cart.outcomeOptions.finderKept',
  lost: 'cart.outcomeOptions.lost',
  owner_requested_euthanasia: 'cart.outcomeOptions.owner_requested_euthanasia',
  rehome: 'cart.outcomeOptions.rehome',
  rehome_shelter_intake: 'cart.outcomeOptions.rehome_shelter_intake',
  rehome_unknown: 'cart.outcomeOptions.rehome_unknown',
  rehomed: 'cart.outcomeOptions.rehomed',
  remained_with_owner: 'cart.outcomeOptions.remained_with_owner',
  return_to_field: 'cart.outcomeOptions.return_to_field',
  return_to_owner: 'cart.outcomeOptions.return_to_owner',
  remained_with_finder: 'cart.outcomeOptions.remained_with_finder',
  reunited_with_owner: 'cart.outcomeOptions.reunited_with_owner',
  service_in: 'cart.outcomeOptions.service_in',
  shelter_euthanasia: 'cart.outcomeOptions.shelter_euthanasia',
  shelter_intake: 'cart.outcomeOptions.shelter_intake',
  transfer_out: 'cart.outcomeOptions.transfer_out',
  unknown: 'cart.outcomeOptions.unknown',
};

const OUTCOME_ROUTES = {
  completed: '/shelter/animal/outcome-completed/',
  pending: '/shelter/animal/outcome-pending/',
};

const INTAKE_ROUTES = {
  completed: '/shelter/animal/intake-payment-completed/',
  pending: '/shelter/animal/intake-payment-pending/',
};

const OUTCOME_SOURCES = <MenuItem[]>[
  {
    category: 'animal',
    label: 'cart.addNewItemMenu.inCare',
    type: [OUTCOME],
    route: '/shelter/animals/in-care',
  },
  {
    category: 'animal',
    label: 'cart.addNewItemMenu.lost',
    type: [LOST],
    route: '/shelter/lost-and-found/lost-animals',
  },
  {
    category: 'animal',
    label: 'cart.addNewItemMenu.found',
    type: [FOUND],
    route: '/shelter/lost-and-found/found-animals',
  },
  {
    category: 'animal',
    label: 'cart.addNewItemMenu.rehome',
    type: [REHOME],
    route: '/shelter/rehoming/animals',
  },
  {
    category: 'other',
    label: 'cart.addNewItemMenu.citations',
    type: [CITATION],
    route: '/shelter/field-services/citation-report',
  },
  {
    category: 'other',
    label: 'cart.addNewItemMenu.product',
    type: [PRODUCT],
    route: '/shelter/products-and-services',
  },
];

const INTAKES_WITH_PAYMENT = ['stray', 'owner_surrender'];
const OUTCOMES_WITH_CART = ['adopted', 'return_to_owner'];

const PAYMENT_OPTIONS = <PaymentMethod[]>[
  {
    icon: 'faCreditCard',
    key: 'credit_card',
    label: 'cart.paymentOptions.creditCard',
    value: CREDIT,
  },
  {
    icon: 'faDollarSign',
    key: 'cash',
    label: 'cart.paymentOptions.cash',
    value: CASH,
  },
  {
    icon: 'faCheck',
    key: 'check',
    label: 'cart.paymentOptions.check',
    value: CHECK,
  },
  {
    icon: 'faSdCard',
    key: 'card_reader',
    label: 'cart.paymentOptions.cardReader',
    value: CARD_READER,
  },
  {
    icon: 'faCashRegister',
    key: 'other',
    label: 'cart.paymentOptions.other',
    value: OTHER,
  },
];

export {
  INTAKE_ROUTES,
  INTAKES_WITH_PAYMENT,
  OUTCOME_ROUTES,
  OUTCOME_SOURCES,
  OUTCOMES_WITH_CART,
  PAYMENT_OPTIONS,
  SANITIZED_OUTCOME_TYPES,
};
