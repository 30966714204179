<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useAToast } from '@Atoms';
import { GetAllSitesInfoService } from '../../../../../Entities/Shelter/modules/KennelManager/services';
import type { FieldSchema } from '../../../types';
import type {
  Building,
  Kennel,
  Location,
  Site,
} from '../../../../../Entities/Shelter/modules/KennelManager/types/kennel.types';

export interface Model {
  kennel: boolean;
  kennel_building_id: string;
  kennel_location_id: string;
  kennel_site_id: string;
}

defineProps<{
  error?: string;
  field?: FieldSchema;
  placeholder?: string;
  required?: boolean;
}>();

const { notify, TOAST_TYPES } = useAToast();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const model = defineModel<Model | null | string>();

const buildingModel = ref();
const buildingOptions = ref<Building[]>([]);
const kennelModel = ref();
const kennelOptions = ref<Kennel[]>([]);
const loading = ref<boolean>(false);
const locationLoading = ref<boolean>(false);
const locationModel = ref();
const locationOptions = ref<Location[]>([]);
const siteModel = ref();
const siteOptions = ref<Site[]>([]);

watch(
  () => model.value,
  () => onFormClearModel()
);

onMounted(() => getAnimalKennels());

function getAnimalKennels() {
  loading.value = true;

  GetAllSitesInfoService()
    .then((response: any) => {
      if (response.data === null) return;

      siteOptions.value = response.data;

      if (siteOptions.value.length === 1) {
        siteModel.value = siteOptions.value[0];
        buildingOptions.value = siteModel.value.buildings;

        if (buildingOptions.value.length === 1) {
          buildingModel.value = buildingOptions.value[0];
          locationOptions.value = buildingModel.value.locations;

          if (locationOptions.value.length === 1) {
            locationModel.value = locationOptions.value[0];
            kennelOptions.value = locationModel.value.kennels;
          }
        }
      }
    })
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onChangeSite() {
  buildingOptions.value = siteModel.value.buildings;
  buildingModel.value = '';
  locationModel.value = '';
  kennelModel.value = '';
}

async function onChangeBuilding() {
  locationModel.value = '';
  kennelModel.value = '';
  locationOptions.value = buildingModel.value.locations;
}

function onChangeLocation() {
  kennelOptions.value = locationModel.value.kennels;
  kennelModel.value = '';
}

// NOTE :: This is necessary because if a use selects a kennel on intake an animal and submit and duplicate
// we need to reset the values on the model fields to catch the real availables kennels in the system
function onFormClearModel() {
  const modelResetedByForm = model.value === '';
  if (!modelResetedByForm) return;

  siteModel.value = siteOptions.value[0];
  buildingModel.value = '';
  locationModel.value = '';
  kennelModel.value = '';

  getAnimalKennels();
}

function updateModelValue() {
  model.value = {
    kennel_building_id: buildingModel.value._id,
    kennel: kennelModel.value,
    kennel_location_id: locationModel.value._id,
    kennel_site_id: siteModel.value._id,
  };
}
</script>

<template>
  <div
    v-if="siteOptions.length"
    class="flex w-full flex-col gap-3"
  >
    <div
      v-if="siteOptions.length > 1"
      class="mb-2 flex w-full flex-col"
    >
      <AFieldLabel
        :error
        :field
        :label="$t('animals.individualPage.kennel.labels.site')"
        :required="field?.settings.required || required"
      />

      <AFieldDropdown
        v-if="!loading"
        v-model="siteModel"
        optionLabel="label"
        :error
        :options="siteOptions"
        :required="field?.settings.required || required"
        @change="onChangeSite"
      />

      <Skeleton
        v-else
        class="w-full rounded-md"
        height="2.25rem"
      />
    </div>

    <div
      v-if="siteOptions.length > 1 || buildingOptions.length > 1"
      class="mb-2 flex w-full flex-col"
    >
      <AFieldLabel
        :error
        :field
        :label="$t('animals.individualPage.kennel.labels.building')"
        :required="field?.settings.required || required"
      />

      <AFieldDropdown
        v-if="!loading"
        v-model="buildingModel"
        optionLabel="label"
        :error
        :disabled="!siteModel"
        :options="buildingOptions"
        :required="field?.settings.required || required"
        @change="onChangeBuilding"
      />

      <Skeleton
        v-else
        class="w-full rounded-md"
        height="2.25rem"
      />
    </div>

    <div
      v-if="siteOptions.length > 1 || buildingOptions.length > 1 || locationOptions.length > 1"
      class="mb-2 flex w-full flex-col"
    >
      <AFieldLabel
        :error
        :field
        :label="$t('animals.individualPage.kennel.labels.location')"
        :required="field?.settings.required || required"
      />

      <AFieldDropdown
        v-if="!loading && !locationLoading"
        v-model="locationModel"
        optionLabel="label"
        :error
        :disabled="!locationOptions.length || !buildingModel"
        :options="locationOptions"
        :required="field?.settings.required || required"
        @change="onChangeLocation"
      />

      <Skeleton
        v-else
        class="w-full rounded-md"
        height="2.25rem"
      />
    </div>

    <div class="mb-2 flex w-full flex-col">
      <AFieldLabel
        :error
        :field
        :label="$t('animals.individualPage.kennel.labels.kennel')"
        :required="field?.settings.required || required"
      />

      <AFieldDropdown
        v-if="!loading && !locationLoading"
        v-model="kennelModel"
        filter
        optionLabel="label"
        :error
        :disabled="!locationModel"
        :options="kennelOptions"
        :required="field?.settings.required || required"
        @change="updateModelValue"
      />

      <Skeleton
        v-else
        class="w-full rounded-md"
        height="2.25rem"
      />
    </div>
  </div>

  <div
    v-else-if="!siteOptions.length && !loading"
    class="flex flex-col items-center justify-center gap-3 text-center text-slate-400"
  >
    <i class="pi pi-map mb-1 text-center text-2xl"></i>

    <p>{{ $t('components.kennelManager.changeKennel.emptyState') }}</p>

    <RouterLink to="/shelter/kennel-manager">
      <Button
        class="af-button af-button-success mb-2"
        icon="pi pi-arrow-right"
        :label="$t('components.kennelManager.changeKennel.goToKennelManager')"
      />
    </RouterLink>
  </div>
</template>
