import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { IntakeFee } from '@IntakeModule';
import { useSessionStore } from '@Stores';
import { useForm, useMFieldContact, setEmptyModelForFieldsWithDateType } from '@FormModule';

const animalId = ref('');
const animalStatuses = ref();
const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formData = ref();
const formError = ref();
const formId = ref('');
const formList = ref();
const formName = ref('');
const formTemplateId = ref('');
const formTemplateName = ref('');
const formTitle = ref('');
const formType = ref('');
const intakeFormTitle = ref('');
const intakeFormType = ref('');
const intakeFees = ref<IntakeFee[]>([]);
const isDefault = ref();
const kennels = ref();
const rawFields = ref<RawField[]>([]);

export default function () {
  const { contactData, showCard } = useMFieldContact();
  const { getDetailsSchema } = useForm();
  const { shelterProfile } = storeToRefs(useSessionStore());

  const INITIAL_FORM_TEMPLATE = 'dog';

  const hasVisualKennel = computed(() => {
    if (!kennels.value) return;

    return kennels?.value?.find((kennel: any) => kennel?.name);
  });

  const fieldsSettings = ref<FieldInitialSettings>({
    animal_name: {
      settings: {
        type: 'animal_name',
      },
    },
    intake_fee: {
      edit: {
        label: 'label',
      },
      settings: {
        type: 'select',
      },
    },
    kennel: {
      edit: {
        label: hasVisualKennel.value ? 'name' : '',
        options: kennels.value,
      },
      settings: {
        show: !shelterProfile.value?.use_kennel_manager,
      },
    },
    kennel_manager: {
      settings: {
        show: false,
      },
    },
    animal_status: {
      edit: {
        label: 'label',
      },
    },
    secondary_color_map: {
      edit: {
        label: 'value',
      },
    },
    tertiary_color_map: {
      edit: {
        label: 'value',
      },
    },
    animal_template: {
      model: INITIAL_FORM_TEMPLATE,
    },
    breed: {
      edit: {
        options: [],
      },
      settings: {
        type: 'breeds',
      },
    },
    intake_date: {
      model: new Date(),
    },
    'google-address': {
      settings: {
        type: 'location',
      },
    },
    partner_name: {
      edit: {
        label: 'name',
        options: [],
      },
    },
    photo: {
      settings: {
        type: 'file',
        multiple: true,
        fileLimit: 5,
      },
    },
    file: {
      settings: {
        imageOnly: false,
        multiple: true,
        fileLimit: 5,
      },
    },
    secondary_breed: {
      edit: {
        options: [],
      },
      settings: {
        type: 'select',
      },
    },
    species: {
      model: [],
      edit: {
        options: [],
      },
      settings: {
        show: true,
      },
    },
    email: {
      settings: {
        type: 'contact',
      },
    },
    phone: {
      settings: {
        type: 'contact',
      },
    },
  });

  const selectedTemplate = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'animal_template')
  );
  const formTemplate = computed(() => selectedTemplate.value?.model);

  watch(contactData, () => updateSchemaModels(contactData.value));

  function setFormAnimal() {
    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);
  }

  function setFormPeople() {
    setEmptyModelForFieldsWithDateType(rawFields, fieldsSettings);

    if (fieldsSchema.value.length) return;
    showCard.value = true;

    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);
  }

  function updateSchemaModels(sourceData: any) {
    setFormPeople();

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];

      switch (field.name) {
        case 'country': {
          field.model = {
            country: sourceData.addresses[0].country,
            state: sourceData.addresses[0].state,
          };
          field.value = {
            country: sourceData.addresses[0].country,
            state: sourceData.addresses[0].state,
          };
          break;
        }
        case 'address1': {
          field.model = sourceData.addresses[0].address1;
          field.value = sourceData.addresses[0].address1;
          break;
        }
        case 'address2': {
          field.model = sourceData.addresses[0].address2;
          field.value = sourceData.addresses[0].address2;
          break;
        }
        case 'city': {
          field.model = sourceData.addresses[0].city;
          field.value = sourceData.addresses[0].city;
          break;
        }
        case 'zip': {
          field.model = sourceData.addresses[0].zip;
          field.value = sourceData.addresses[0].zip;
          break;
        }
        case 'phone': {
          field.model = sourceData.phones[0].phone;
          field.value = sourceData.phones[0].phone;
          break;
        }
        case 'email': {
          field.model = sourceData.emails[0].email;
          field.value = sourceData.emails[0].email;
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }

      showCard.value = false;
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formData.value = null;
    formError.value = null;
    formId.value = '';
    formName.value = '';
    formTitle.value = '';
    rawFields.value = [];
  }

  return {
    animalId,
    animalStatuses,
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formData,
    formError,
    formId,
    formList,
    formName,
    formTemplate,
    formTemplateId,
    formTemplateName,
    formTitle,
    formType,
    intakeFormTitle,
    intakeFormType,
    intakeFees,
    isDefault,
    kennels,
    rawFields,
    resetFieldSchema,
    setFormAnimal,
    setFormPeople,
    INITIAL_FORM_TEMPLATE,
  };
}
