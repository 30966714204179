import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'foster',
    name: 'foster',
    redirect: '/shelter/foster/animals-in-foster',
    children: [
      {
        path: 'animals-in-foster',
        name: 'animals-in-foster',
        component: () => import('./views/AnimalsInFoster.view.vue'),
        meta: {
          permissions: [ROLES.FOSTERS],
        },
      },
      {
        path: 'foster-parents',
        name: 'foster-parents',
        component: () => import('./views/FosterParents.view.vue'),
        meta: {
          permissions: [ROLES.FOSTERS],
        },
      },
      {
        path: 'add-foster-parent',
        name: 'foster-parents-form',
        component: () => import('./views/FosterParentsForm.view.vue'),
      },
    ],
  },
];
