import type { RouteRecordRaw } from 'vue-router';
import { AdminReportsRoutes } from './modules/Report';
import { AdminShelterSettingsRoutes } from './modules/ShelterSettings';
import { AdminSheltersRoutes } from './modules/Shelters';

export default <RouteRecordRaw[]>[
  {
    path: '/admin',
    component: () => import('../../../layouts/TheMainLayout/TheMainLayout.vue'),
    redirect: '/admin/shelters',
    children: [...AdminReportsRoutes, ...AdminShelterSettingsRoutes, ...AdminSheltersRoutes],
    meta: {
      isAdmin: true,
    },
  },
];
