<script setup lang="ts">
import { ref, onMounted } from 'vue';
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { useAToast } from '@Atoms';
import { useForm } from '@FormModule';
import type { Product } from '@ProductsAndServicesModule';
import { useCartSteps } from '../../../../composables';
import { AddOutcomeToCart } from '../../../../services';
import useCartStore from '../../../../store';
import { CART_STEPS } from '../../../../types';
import useFields from './useFields';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const { cartData, loading, outcomeInfoActiveStep, products, services, stepState } =
  storeToRefs(useCartStore());

const { currentSchemaModels, fieldsSchema, formError, setForm } = useFields();
const { currentStep, nextStep } = useCartSteps();
const { fieldsValidation } = useForm();
const { notify, TOAST_TYPES } = useAToast();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const showErrorMessage = ref(false);

onMounted(() => onInitPage());

// EVENTS
function onInitPage() {
  outcomeInfoActiveStep.value = 0;
  currentStep.value = CART_STEPS.PRODUCTS_INFO;
  setForm(new Date().getUTCMilliseconds());
}

function onNextStep() {
  const hasErrors = fieldsValidation(fieldsSchema.value, currentSchemaModels.value);
  if (hasErrors) return (formError.value = true);

  loading.value = true;
  formError.value = false;

  const [checkoutDate] = currentSchemaModels.value;
  // NOTE: this is the format being used at adopt.animalsfirst
  const adoptAppCheckoutDate = moment(checkoutDate.value).format('YYYY-MM-DD');

  const PAYLOAD = {
    outcome_type: 'product',
    products: [...products.value, ...services.value].map((product: Product) => {
      return {
        ...product,
        outcome_date: adoptAppCheckoutDate,
        outcome_type: 'product',
        outcome: {
          ...product,
          animal_id: null,
          animal_name: null,
          form_id: null,
          form_title: null,
          outcome_type: 'product',
          outcome_date: adoptAppCheckoutDate,
          partner_name: null,
        },
      };
    }),
  };

  AddOutcomeToCart(PAYLOAD)
    .then((data) => {
      cartData.value = data;
      emits('onStepUpdate', nextStep.value.step!.name);
      stepState.value![CART_STEPS.OUTCOME_INFO] = PAYLOAD;
    })
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex h-full max-w-[700px] flex-col gap-4">
    <FormTemplate
      class="w-full"
      formError=""
      :schema="fieldsSchema"
    />

    <footer class="flex-shrink">
      <Message
        v-if="showErrorMessage"
        severity="error"
        pt:text="text-sm"
      >
        {{ $t('cart.checkoutSelector.error') }}
      </Message>

      <div class="justfiy-between flex">
        <Button
          class="af-button af-button-success ml-auto"
          iconPos="right"
          :disabled="showErrorMessage"
          :label="$t('common.nextStep')"
          @click="onNextStep"
        >
          <template #icon>
            <FaIcon
              class="mr-2"
              :icon="$icons['faBox']"
            />
          </template>
        </Button>
      </div>
    </footer>
  </div>
</template>
