import { computed, ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { GenericField, OId } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { capitalizeStr } from '@Helpers';
import { ROUTES } from '@AnimalModule';
import { useForm } from '@FormModule';
import { useAnimalProfileStore } from '@Stores';

interface MedicalStaff {
  [key: string]: any;
  first_name: string;
  last_name: string;
  _id: OId;
}

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { t } = useI18n();
  const { getDetailsSchema, wasFormSubmitted } = useForm();
  const { medicationTemplates, medicalStaff } = storeToRefs(useAnimalProfileStore());

  const rawFields: RawField[] = [
    {
      label: 'Medication / Treatment',
      name: 'medication',
      required: true,
      type: 'select',
      value: '',
    },
    {
      label: 'Reason for Treatment',
      name: 'reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Start Date',
      name: 'start_date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Start Time',
      name: 'time',
      required: false,
      type: 'date',
      value: '',
    },
    {
      label: 'Route',
      name: 'route',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Amount',
      name: 'amount',
      required: false,
      type: 'amount_unit',
      value: '',
    },
    {
      label: '',
      name: 'multi_dose',
      required: false,
      type: 'toggle',
      value: false,
    },
    {
      label: '',
      name: 'separator_frequency',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Frequency',
      name: 'frequency',
      required: false,
      type: 'frequency',
      value: '',
    },
    {
      label: '',
      name: 'separator_frequency',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: '',
      name: 'schedule_followup',
      required: false,
      type: 'toggle',
      value: '',
    },
    {
      label: '',
      name: 'separator_followup',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Time Until Follow-Up',
      name: 'time_until_followup',
      required: false,
      type: 'timeframe',
      value: '',
    },
    {
      label: 'Follow-Up Reason',
      name: 'followup_reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Follow-Up Notes',
      name: 'followup_notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: '',
      name: 'separator_followup',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Attending Vet',
      name: 'attending_vet',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
  ];

  const attendingVetOptions = computed(() =>
    medicalStaff.value.map((staff: MedicalStaff) => {
      const { first_name, last_name } = staff;
      return {
        ...staff,
        by: `${first_name} ${last_name}`,
      };
    })
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      attending_vet: {
        edit: {
          label: 'by',
          options: [...attendingVetOptions.value, { by: t('common.other') }],
        },
      },
      medication: {
        edit: {
          label: 'template_name',
          options: medicationTemplates.value.sort((a, b) =>
            a.template_name.localeCompare(b.template_name)
          ),
        },
      },
      route: {
        edit: {
          options: Object.values(ROUTES),
        },
      },
      frequency: {
        settings: {
          required: false,
          show: false,
        },
      },
      separator_frequency: {
        settings: {
          show: false,
        },
      },
      followup_notes: {
        settings: {
          required: false,
          show: false,
        },
      },
      time: {
        settings: {
          timeOnly: true,
        },
      },
      separator_followup: {
        settings: {
          show: false,
        },
      },
      time_until_followup: {
        settings: {
          hasCalendar: true,
          required: false,
          show: false,
        },
      },
      multi_dose: {
        model: false,
        settings: {
          label: 'Multiple Doses / Treatments',
        },
      },
      schedule_followup: {
        settings: {
          label: 'Schedule a Follow-Up Check',
        },
      },
    })
  );

  const medicationField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'medication')
  );
  const multiDoseField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'multi_dose')
  );
  const followUpField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'schedule_followup')
  );
  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'attending_vet'));
  const byModel = computed(() => byField.value?.model);
  const medicationModel = computed(() => medicationField.value?.model);
  const isMultiDose = computed(() => multiDoseField.value?.model);
  const isFollowUp = computed(() => followUpField.value?.model);

  watch(isMultiDose, () => showFrequencyFields());
  watch(isFollowUp, () => showFollowUpFields());
  watch(medicationModel, (newValue, oldValue) => fillFormWithTemplate(newValue, oldValue));
  watch(byModel, () => showOtherVetField());

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.by === 'Other';

        field.settings = {
          ...field.settings,
          show: showField,
        };

        if (byModel?.value?.by !== 'Other') return (field.model = '');
      }
    });

    return true;
  }

  function fillFormWithTemplate(newValue: any, oldValue: any) {
    if (!newValue || oldValue === newValue) return;

    updateSchemaModels(medicationModel.value);
  }

  function showFrequencyFields() {
    fieldsSchema.value.forEach((field) => {
      const frequencyFields = ['frequency', 'separator_frequency', 'time'];

      if (frequencyFields.includes(field.name)) {
        const showField = isMultiDose.value;

        field.settings = {
          ...field.settings,
          required: field.name === 'frequency' ? showField : false,
          show: field.name === 'time' ? !showField : showField,
        };
      }
    });

    return true;
  }

  function showFollowUpFields() {
    fieldsSchema.value.forEach((field) => {
      const followUpFields = [
        'followup_notes',
        'followup_reason',
        'separator_followup',
        'time_until_followup',
      ];

      const nonRequiredFields = ['followup_notes', 'followup_reason', 'separator_followup'];

      if (followUpFields.includes(field.name)) {
        const showField = isFollowUp.value === true;

        field.settings = {
          ...field.settings,
          required: nonRequiredFields.includes(field.name) ? false : showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings.value);

    if (!medicationModel.value) {
      fieldsSchema.value.forEach((field: any) => {
        if (field.name === 'medication') return;

        field.state.disabled = true;
        field.settings.disabled = true;
      });
    }
  }

  function updateSchemaModels(sourceData: any) {
    formError.value = null;
    wasFormSubmitted.value = false;

    const toggleableFields = [
      'frequency',
      'separator_frequency',
      'followup_notes',
      'followup_reason',
      'time_until_followup',
    ];

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];
      if (toggleableFields.includes(field.name)) return;

      switch (field.name) {
        case 'reason': {
          setMatchField(field, match);
          break;
        }
        case 'attending_vet': {
          setByField(field, sourceData);
          break;
        }
        case 'route': {
          setMatchField(field, match);
          break;
        }
        case 'notes': {
          setMatchField(field, match);
          break;
        }
        case 'start_date': {
          setDateField(field, match);
          break;
        }
        case 'amount': {
          setAmountField(field, sourceData);
          break;
        }
        case 'multi_dose': {
          setMatchField(field, match);
          showFrequencyFields();
          nextTick(() => {
            fillFrequencyFields(sourceData);
          });
          break;
        }
        case 'schedule_followup': {
          setMatchField(field, match);
          showFollowUpFields();
          nextTick(() => {
            fillFollowUpFields(sourceData);
          });
          break;
        }
      }
    });
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
    field.state.errors.clear();
  }

  function setDateField(field: any, match: any) {
    field.model = match || new Date();
    field.value = match || new Date();
  }

  function setAmountField(field: any, sourceData: any) {
    field.model = { amount: Number(sourceData.amount), amount_unit: sourceData.amount_unit };
    field.value = { amount: Number(sourceData.amount), amount_unit: sourceData.amount_unit };
  }

  function setByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    const [first_name, last_name] = sourceData.by.split(' ');
    const by = { _id: sourceData.by_id, first_name, last_name, by: sourceData.by };

    field.model = by;
    field.value = by;
  }

  function setFrequencyField(field: any, sourceData: any) {
    field.model = {
      frequency: sourceData.frequency,
      duration: sourceData.duration,
      duration_unit: sourceData.duration_unit,
      time1: sourceData.time1 || new Date(),
      time2: sourceData.time2 || null,
      time3: sourceData.time3 || null,
      time4: sourceData.time4 || null,
    };
    field.value = {
      frequency: sourceData.frequency,
      duration: sourceData.duration,
      duration_unit: capitalizeStr(sourceData.duration_unit),
      time1: sourceData.time1 || new Date(),
      time2: sourceData.time2 || null,
      time3: sourceData.time3 || null,
      time4: sourceData.time4 || null,
    };
  }

  function setTimeUntilFollowUpField(field: any, sourceData: any) {
    if (!sourceData.time_until_followup || !sourceData.followup_unit) return;

    field.model = {
      duration: sourceData.time_until_followup,
      duration_unit: sourceData.followup_unit,
    };
    field.value = {
      duration: sourceData.time_until_followup,
      duration_unit: sourceData.followup_unit,
    };
  }

  function fillFrequencyFields(sourceData: any) {
    fieldsSchema.value.forEach((field) => {
      if (field.name !== 'frequency') return;

      field.state.errors.clear();
      setFrequencyField(field, sourceData);
    });
  }

  function fillFollowUpFields(sourceData: any) {
    fieldsSchema.value.forEach((field) => {
      const followUpFields = ['followup_notes', 'followup_reason', 'time_until_followup'];
      const match = sourceData[field.name];

      field.state.disabled = false;
      field.settings.disabled = false;

      if (!followUpFields.includes(field.name)) return;
      field.state.errors.clear();
      // prettier-ignore
      switch (field.name) {
        case 'followup_notes': return setMatchField(field, match);
        case 'followup_reason': return setMatchField(field, match);
        case 'time_until_followup': return setTimeUntilFollowUpField(field, sourceData);
      }
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
    wasFormSubmitted.value = false;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    resetFieldSchema,
    setForm,
  };
}
