import type { People } from '../types';

export class Profile implements People {
  address1: string;
  address2: string;
  city: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  state: string;
  updated_at: string;
  zip: string;
  [key: string]: any;

  constructor(
    address1: string = '',
    address2: string = '',
    city: string = '',
    country: string = '',
    email: string = '',
    first_name: string = '',
    last_name: string = '',
    phone: string = '',
    state: string = '',
    updated_at: string = new Date().toISOString(),
    zip: string = '',
    additionalProperties?: { [key: string]: any }
  ) {
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.country = country;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone = phone;
    this.state = state;
    this.updated_at = updated_at;
    this.zip = zip;

    if (additionalProperties) Object.assign(this, additionalProperties);
  }
}
