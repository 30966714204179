enum SYSTEM_MODALS {
  ADD_DONATION = 'ADD_DONATION',
  ADD_PARTNER_TO_ANIMAL = 'ADD_PARTNER_TO_ANIMAL',
  ADD_PEOPLE_TO_ANIMAL = 'ADD_PEOPLE_TO_ANIMAL',
  ADOPTION_APPLICATIONS = 'ADOPTION_APPLICATIONS',
  ADOPTION_ADD_ANIMAL_TO_APPLICATION = 'ADOPTION_ADD_ANIMAL_TO_APPLICATION',
  ADOPTION_APPROVAL = 'ADOPTION_APPROVAL',
  ADOPTION_COMPLETE = 'ADOPTION_COMPLETE',
  ANIMAL_ADD_BEHAVIOR_FOLLOWUP = 'ANIMAL_ADD_BEHAVIOR_FOLLOWUP',
  ANIMAL_ADD_DIAGNOSTIC = 'ANIMAL_ADD_DIAGNOSTIC',
  ANIMAL_ADD_LOCATION = 'ANIMAL_ADD_LOCATION',
  ANIMAL_ADD_MEDICATION = 'ANIMAL_ADD_MEDICATION',
  ANIMAL_ADD_PROCEDURE = 'ANIMAL_ADD_PROCEDURE',
  ANIMAL_ADD_SURGERY = 'ANIMAL_ADD_SURGERY',
  ANIMAL_ADD_VACCINATIONS = 'ANIMAL_ADD_VACCINATIONS',
  ANIMAL_ADD_VIDEOS = 'ANIMAL_ADD_VIDEOS',
  ANIMAL_ADD_WEIGHT = 'ANIMAL_ADD_WEIGHT',
  ANIMAL_CANCEL_MEDICATION = 'ANIMAL_CANCEL_MEDICATION',
  ANIMAL_CHANGE_KENNEL_MODAL = 'ANIMAL_CHANGE_KENNEL_MODAL',
  ANIMAL_CLOSE_VOUCHER = 'ANIMAL_CLOSE_VOUCHER',
  ANIMAL_DELETE_DIAGNOSTIC = 'ANIMAL_DELETE_DIAGNOSTIC',
  ANIMAL_DELETE_EXAM = 'ANIMAL_DELETE_EXAM',
  ANIMAL_DELETE_EXAM_FOLLOWUP = 'ANIMAL_DELETE_EXAM_FOLLOWUP',
  ANIMAL_DELETE_FOSTER_HISTORY_RECORD = 'ANIMAL_DELETE_FOSTER_HISTORY_RECORD',
  ANIMAL_DELETE_MEDICATION = 'ANIMAL_DELETE_MEDICATION',
  ANIMAL_DELETE_MEDICATION_FOLLOWUP = 'ANIMAL_DELETE_MEDICATION_FOLLOWUP',
  ANIMAL_DELETE_PROCEDURE = 'ANIMAL_DELETE_PROCEDURE',
  ANIMAL_DELETE_PROCEDURE_FOLLOWUP = 'ANIMAL_DELETE_PROCEDURE_FOLLOWUP',
  ANIMAL_DELETE_SURGERY = 'ANIMAL_DELETE_SURGERY',
  ANIMAL_DELETE_SURGERY_FOLLOWUP = 'ANIMAL_DELETE_SURGERY_FOLLOWUP',
  ANIMAL_DELETE_VACCINATION = 'ANIMAL_DELETE_VACCINATION',
  ANIMAL_DELETE_VIDEOS = 'ANIMAL_DELETE_VIDEOS',
  ANIMAL_EDIT_BEHAVIOR = 'ANIMAL_EDIT_BEHAVIOR',
  ANIMAL_EDIT_BEHAVIOR_FOLLOWUP = 'ANIMAL_EDIT_BEHAVIOR_FOLLOWUP',
  ANIMAL_EDIT_DIAGNOSTICS_MODAL = 'ANIMAL_EDIT_DIAGNOSTICS_MODAL',
  ANIMAL_EDIT_DIAGNOSTIC_TEST_RESULT = 'ANIMAL_EDIT_DIAGNOSTIC_TEST_RESULT',
  ANIMAL_EDIT_EXAM_FOLLOWUP = 'ANIMAL_EDIT_EXAM_FOLLOWUP',
  ANIMAL_EDIT_EXAM_MODAL = 'ANIMAL_EDIT_EXAM_MODAL',
  ANIMAL_EDIT_FOSTER_HISTORY_RECORD = 'ANIMAL_EDIT_FOSTER_HISTORY_RECORD',
  ANIMAL_EDIT_MEDICATION = 'ANIMAL_EDIT_MEDICATION',
  ANIMAL_EDIT_MEDICATION_FOLLOWUP = 'ANIMAL_EDIT_MEDICATION_FOLLOWUP',
  ANIMAL_EDIT_PROCEDURE = 'ANIMAL_EDIT_PROCEDURE',
  ANIMAL_EDIT_PROCEDURE_FOLLOWUP = 'ANIMAL_EDIT_PROCEDURE_FOLLOWUP',
  ANIMAL_EDIT_SURGERY_FOLLOWUP = 'ANIMAL_EDIT_SURGERY_FOLLOWUP',
  ANIMAL_EDIT_SURGERY_MODAL = 'ANIMAL_EDIT_SURGERY_MODAL',
  ANIMAL_EDIT_SURGERY_PROCEDURE = 'ANIMAL_EDIT_SURGERY_PROCEDURE',
  ANIMAL_EDIT_VACCINATIONS_MODAL = 'ANIMAL_EDIT_VACCINATIONS_MODAL',
  ANIMAL_EDIT_VACCINATION_DOSES = 'ANIMAL_EDIT_VACCINATION_DOSES',
  ANIMAL_EMAIL_VOUCHER = 'ANIMAL_EMAIL_VOUCHER',
  ANIMAL_END_FOSTER = 'ANIMAL_END_FOSTER',
  ANIMAL_EXAM_NEW_FOLLOWUP = 'ANIMAL_EXAM_NEW_FOLLOWUP',
  ANIMAL_HOLD_STATUS_MODAL = 'ANIMAL_HOLD_STATUS_MODAL',
  ANIMAL_MULTIPLE_MEDICATION_DOSES = 'ANIMAL_MULTIPLE_MEDICATION_DOSES',
  ANIMAL_NEW_BEHAVIOR = 'ANIMAL_NEW_BEHAVIOR',
  ANIMAL_NEW_EXAM = 'ANIMAL_NEW_EXAM',
  ANIMAL_REMOVE_LITTER = 'ANIMAL_REMOVE_LITTER',
  ANIMAL_SCHEDULE_FOLLOWUP = 'ANIMAL_SCHEDULE_FOLLOWUP',
  ANIMAL_SCHEDULE_PROCEDURE_FOLLOWUP = 'ANIMAL_SCHEDULE_PROCEDURE_FOLLOWUP',
  ANIMAL_SURGERY_NEW_FOLLOWUP = 'ANIMAL_SURGERY_NEW_FOLLOWUP',
  ANIMAL_TOP_ACTION_ADOPTION_LINK = 'ANIMAL_TOP_ACTION_ADOPTION_LINK',
  ANIMAL_TOP_ACTION_BANNER = 'ANIMAL_TOP_ACTION_BANNER',
  ANIMAL_TOP_ACTION_CS = 'ANIMAL_TOP_ACTION_CS',
  ANIMAL_TOP_ACTION_FS = 'ANIMAL_TOP_ACTION_FS',
  ANIMAL_TOP_ACTION_INTAKE = 'ANIMAL_TOP_ACTION_INTAKE',
  ANIMAL_TOP_ACTION_LF = 'ANIMAL_TOP_ACTION_LF',
  ANIMAL_TOP_ACTION_REHOME = 'ANIMAL_TOP_ACTION_REHOME',
  ANIMAL_TOP_ACTION_REVERSE_OUTCOME = 'ANIMAL_TOP_ACTION_REVERSE_OUTCOME',
  ANIMAL_TOP_ACTION_RFC = 'ANIMAL_TOP_ACTION_RFC',
  ANIMAL_TOP_ACTION_START_FOSTER = 'ANIMAL_TOP_ACTION_START_FOSTER',
  ANIMAL_TOP_ACTION_TNVR = 'ANIMAL_TOP_ACTION_TNVR',
  ANIMAL_TOP_ACTION_VOUCHER = 'ANIMAL_TOP_ACTION_VOUCHER',
  ANIMAL_UPDATE_MEDICATION_DOSE = 'ANIMAL_UPDATE_MEDICATION_DOSE',
  ANIMAL_UPDATE_PROCEDURE = 'ANIMAL_UPDATE_PROCEDURE',
  APPLICATION_DOCUMENTS = 'APPLICATION_DOCUMENTS',
  BASIC_MODAL = 'BASIC_MODAL',
  CART_PRE_PAYMENT = 'CART_PRE_PAYMENT',
  CART_RESEND_LINK = 'CART_RESEND_LINK',
  COMPLETE_DIAGNOSTIC_RESULT = 'COMPLETE_DIAGNOSTIC_RESULT',
  COMPLETE_MEDICATION_DOSE = 'COMPLETE_MEDICATION_DOSE',
  COMPLETE_MULTIPLE_DOSES = 'COMPLETE_MULTIPLE_DOSES',
  COMPLETE_REQUEST_FOR_CARE = 'COMPLETE_REQUEST_FOR_CARE',
  COMPLETE_PROCEDURE = 'COMPLETE_PROCEDURE',
  COMPLETE_SURGERY = 'COMPLETE_SURGERY',
  COMPLETE_VACCINATION_DOSE = 'COMPLETE_VACCINATION_DOSE',
  COMPLETE_VOUCHER = 'COMPLETE_VOUCHER',
  CS_ANIMAL_ADD_MODAL = 'CS_ANIMAL_ADD_MODAL',
  CS_ANIMAL_ADD_VOUCHER = 'CS_ANIMAL_ADD_VOUCHER',
  CS_ANIMAL_INTAKE = 'CS_ANIMAL_INTAKE',
  CS_CONTACT_PARTY = 'CS_CONTACT_PARTY',
  CS_LOCATION_MODAL = 'CS_LOCATION_MODAL',
  DATA_TABLE_DEFAULT_COLUMNS = 'DATA_TABLE_DEFAULT_COLUMNS',
  DATA_TABLE_WEIGHT_MODAL = 'DATA_TABLE_WEIGHT_MODAL',
  DELETE_ANIMAL_STATUS_REPORT = 'DELETE_ANIMAL_STATUS_REPORT',
  DELETE_BITE_MODAL = 'DELETE_BITE_MODAL',
  DELETE_CITATION_MODAL = 'DELETE_CITATION_MODAL',
  DELETE_CONTACT_MODAL = 'DELETE_CONTACT_MODAL',
  DELETE_CONTACT_PARTY_MODAL = 'DELETE_CONTACT_PARTY_MODAL',
  DELETE_MODAL = 'DELETE_MODAL',
  DELETE_TNVR_CALL = 'DELETE_TNVR_CALL',
  EDIT_ANIMAL_STATUS_REPORT = 'EDIT_ANIMAL_STATUS_REPORT',
  EDIT_DONATION = 'EDIT_DONATION',
  EDIT_PERSON_DETAILS = 'EDIT_PERSON_DETAILS',
  EMAIL_RECEIPT = 'EMAIL_RECEIPT',
  EMAIL_SENDER = 'EMAIL_SENDER',
  FILES_ACTION_FILE = 'FILES_ACTION_FILE',
  FOSTER_SETTINGS = 'FOSTER_SETTINGS',
  FS_ANIMAL_ADD_MODAL = 'FS_ANIMAL_ADD_MODAL',
  FS_BITE_MODAL = 'FS_BITE_MODAL',
  FS_CITATION_MODAL = 'FS_CITATION_MODAL',
  FS_CONTACT_ADD_MODAL = 'FS_CONTACT_ADD_MODAL',
  FS_CONTACT_EDIT_MODAL = 'FS_CONTACT_EDIT_MODAL',
  FS_LOCATION_MODAL = 'FS_LOCATION_MODAL',
  FS_REPORTING_PARTY_CREATE_MODAL = 'FS_REPORTING_PARTY_CREATE_MODAL',
  FS_REPORTING_PARTY_EDIT_MODAL = 'FS_REPORTING_PARTY_EDIT_MODAL',
  FS_STATEMENT_MODAL = 'FS_STATEMENT_MODAL',
  INDIVIDUAL_ADOPTION_APPLICATIONS = 'INDIVIDUAL_ADOPTION_APPLICATIONS',
  INVITE_FOSTER_PORTAL = 'INVITE_FOSTER_PORTAL',
  KENNEL_AVAILABLE = 'KENNEL_AVAILABLE',
  KENNEL_CARD_MODAL = 'KENNEL_CARD_MODAL',
  KENNEL_DELETE = 'KENNEL_DELETE',
  KENNEL_DELETE_LOCATION_MODAL = 'KENNEL_DELETE_LOCATION_MODAL',
  KENNEL_ADD_SITE_MODAL = 'KENNEL_ADD_SITE_MODAL',
  KENNEL_ADD_BUILDING_MODAL = 'KENNEL_ADD_BUILDING_MODAL',
  KENNEL_ADD_LOCATION_MODAL = 'KENNEL_ADD_LOCATION_MODAL',
  KENNEL_ADD_KENNEL_MODAL = 'KENNEL_ADD_KENNEL_MODAL',
  KENNEL_DELETE_MODAL = 'KENNEL_DELETE_MODAL',
  KENNEL_EDIT_SITE_MODAL = 'KENNEL_EDIT_SITE_MODAL',
  KENNEL_EDIT_BUILDING_MODAL = 'KENNEL_EDIT_BUILDING_MODAL',
  KENNEL_EDIT_LOCATION_MODAL = 'KENNEL_EDIT_LOCATION_MODAL',
  KENNEL_HISTORY_MODAL = 'KENNEL_HISTORY_MODAL',
  KENNEL_LOCATION_MODAL = 'KENNEL_LOCATION_MODAL',
  KENNEL_MANAGER_SELECT_ANIMAL = 'KENNEL_MANAGER_SELECT_ANIMAL',
  KENNEL_MANAGER_STATE = 'KENNEL_MANAGER_STATE',
  KENNEL_MODAL = 'KENNEL_MODAL',
  KENNEL_POSITION_MODAL = 'KENNEL_POSITION_MODAL',
  KENNEL_QUARANTINE = 'KENNEL_QUARANTINE',
  KENNEL_REMOVE_QUARANTINE = 'KENNEL_REMOVE_QUARANTINE',
  KENNEL_UNAVAILABLE = 'KENNEL_UNAVAILABLE',
  MEDICAL_FILES = 'MEDICAL_FILES',
  MERGE_PEOPLE = 'MERGE_PEOPLE',
  NOTES_DIALOG = 'NOTES_DIALOG',
  PARTNER_ACTIVE_USER = 'PARTNER_ACTIVE_USER',
  PARTNER_CONTACT_EDIT = 'PARTNER_CONTACT_EDIT',
  PARTNER_CONTACT_NEW = 'PARTNER_CONTACT_NEW',
  PARTNER_DELETE_CONTACT = 'PARTNER_DELETE_CONTACT',
  PARTNER_DELETE_PROFILE_IMAGE = 'PARTNER_DELETE_PROFILE_IMAGE',
  PARTNER_DELETE_USER = 'PARTNER_DELETE_USER',
  PARTNER_EDIT_PROFILE_INFO = 'PARTNER_EDIT_PROFILE_INFO',
  PARTNER_EDIT_PROFILE_CUSTOM_FIELDS = 'PARTNER_EDIT_PROFILE_CUSTOM_FIELDS',
  PARTNER_EDIT_TRANSFER_ID = 'PARTNER_EDIT_TRANSFER_ID',
  PARTNER_LOCATION_DELETE = 'PARTNER_LOCATION_DELETE',
  PARTNER_LOCATION_EDIT = 'PARTNER_LOCATION_EDIT',
  PARTNER_LOCATION_NEW = 'PARTNER_LOCATION_NEW',
  PEOPLE_ADD_ADDRESS = 'PEOPLE_ADD_ADDRESS',
  PEOPLE_DELETE_ADDRESS = 'PEOPLE_DELETE_ADDRESS',
  PEOPLE_DELETE_DONATIONS = 'PEOPLE_DELETE_DONATIONS',
  PEOPLE_DELETE_HOUSEHOLD_MEMBER = 'PEOPLE_DELETE_HOUSEHOLD_MEMBER',
  PEOPLE_DELETE_PROFILE = 'PEOPLE_DELETE_PROFILE',
  PEOPLE_DELETE_PROFILE_PHOTO = 'PEOPLE_DELETE_PROFILE_PHOTO',
  PEOPLE_DONATION = 'PEOPLE_DONATION',
  PEOPLE_EDIT_PROFILE_CUSTOM_FIELDS = 'PEOPLE_EDIT_PROFILE_CUSTOM_FIELDS',
  PEOPLE_HOUSEHOLD_MEMBER = 'PEOPLE_HOUSEHOLD_MEMBER',
  PEOPLE_MERGE = 'PEOPLE_MERGE',
  PEOPLE_REACTIVE_PROFILE = 'PEOPLE_REACTIVE_PROFILE',
  PEOPLE_REMOVE_ANIMAL = 'PEOPLE_REMOVE_ANIMAL',
  PEOPLE_REMOVE_DROPPED_ANIMAL = 'PEOPLE_REMOVE_DROPPED_ANIMAL',
  PROCESS_PAYMENT = 'PROCESS_PAYMENT',
  SEND_SAC = 'SEND_SAC',
  SHELTER_INFO_CHANGE_MODULE_STATUS = 'SHELTER_INFO_CHANGE_MODULE_STATUS',
  SHOW_HIDE_COLUMNS = 'SHOW_HIDE_COLUMNS',
  STAFF_USER_ACTIVE_MODAL = 'STAFF_USER_ACTIVE_MODAL',
  STAFF_USER_ADD_CONTACT = 'STAFF_USER_ADD_CONTACT',
  STAFF_USER_ADD_TASK = 'STAFF_USER_ADD_TASK',
  STAFF_USER_COMPLETE_TASK = 'STAFF_USER_COMPLETE_TASK',
  STAFF_USER_DELETE_CONTACT = 'STAFF_USER_DELETE_CONTACT',
  STAFF_USER_DELETE_MODAL = 'STAFF_USER_DELETE_MODAL',
  STAFF_USER_DELTE_IMAGE_MODAL = 'STAFF_USER_DELTE_IMAGE_MODAL',
  STAFF_USER_EDIT_CONTACT = 'STAFF_USER_EDIT_CONTACT',
  STAFF_USER_EDIT_INFO = 'STAFF_USER_EDIT_INFO',
  STAFF_USER_EDIT_ROLE = 'STAFF_USER_EDIT_ROLE',
  STAFF_USER_REPORTS_ALERTS = 'STAFF_USER_REPORTS_ALERTS',
  STAFF_USER_RESET_PASSWORD = 'STAFF_USER_RESET_PASSWORD',
  TNVR_ANIMAL_ADD_MODAL = 'TNVR_ANIMAL_ADD_MODAL',
  TNVR_REPORTING_PARTY_MODAL = 'TNVR_REPORTING_PARTY_MODAL',
  TNVR_CONTACT_ADD_MODAL = 'TNVR_CONTACT_ADD_MODAL',
  TNVR_CONTACT_EDIT_MODAL = 'TNVR_CONTACT_EDIT_MODAL',
  TNVR_DELETE_REPORTING_PARTY = 'TNVR_DELETE_REPORTING_PARTY',
  TNVR_DELETE_CONTACT = 'TNVR_DELETE_CONTACT',
  TNVR_LOCATION_MODAL = 'TNVR_LOCATION_MODAL',
  TRANSPORT_ADD_ANIMAL = 'TRANSPORT_ADD_ANIMAL',
  TRANSPORT_INTAKE_ANIMAL = 'TRANSPORT_INTAKE_ANIMAL',
  TRANSPORT_LOCATION = 'TRANSPORT_LOCATION',
  TRANSPORT_OUTCOME = 'TRANSPORT_OUTCOME',
  VOUCHER_EMAIL = 'VOUCHER_EMAIL',
}

export { SYSTEM_MODALS };
