<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const targetRef = computed(() => {
  const { href, targetKey, secondaryKey } = props.settings.render;

  if (secondaryKey)
    return `${href}${props.rowData[targetKey[0]] + '/' + props.rowData[secondaryKey[0]]}`;

  return targetKey.length > 1
    ? `${href}${props.rowData[targetKey[0]][targetKey[1]]}`
    : `${href}${props.rowData[targetKey]}`;
});
</script>

<template>
  <RouterLink
    v-if="!field && settings?.canBeUnknown"
    class="af-link overflow-hidden hover:underline"
    :to="targetRef"
  >
    {{ $t('common.unknown') }}
  </RouterLink>

  <span
    v-else-if="!field"
    class="flex flex-col items-start gap-0.5"
  >
    {{ settings?.composedEmptyState ? $t(settings.composedEmptyState) : '-' }}
  </span>

  <a
    v-else-if="settings.render.type === 'mail'"
    class="af-link overflow-hidden hover:underline"
    target="_blank"
    :href="'mailto:' + field"
  >
    {{ field }}
  </a>

  <RouterLink
    v-else
    class="af-link overflow-hidden text-ellipsis hover:underline"
    :to="targetRef"
  >
    {{ field }}
  </RouterLink>
</template>
