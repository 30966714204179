import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: 'shelters',
    name: 'shelters',
    component: () => import('./views/AdminShelters.view.vue'),
  },
  {
    path: 'shelters-options',
    name: 'shelters-options',
    component: () => import('./views/AdminSheltersOptions.view.vue'),
  },
];
