import { computed, ref } from 'vue';
import moment from 'moment';

const timerInterval = ref<number | undefined>();
const currentTime = ref<moment.Moment | null>(moment.utc());
const outcomeSessionExpiresAt = ref<moment.Moment | null>(null);

const timeRemaining = computed(() => {
  if (!outcomeSessionExpiresAt.value || !currentTime.value) return null;

  const timeDiff = outcomeSessionExpiresAt.value.diff(currentTime.value);

  if (timeDiff <= 0) return 0;

  const duration = moment.duration(timeDiff);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const daysLabel = days > 0 ? `${days}d ` : '';

  return `${daysLabel}${hours}h ${minutes}m ${seconds}s`;
});

export default function () {
  function onNewExpiredAtInterval(expiresAt: string | number) {
    outcomeSessionExpiresAt.value =
      typeof expiresAt === 'number' ? moment.utc(expiresAt * 1000) : moment.utc(expiresAt);

    if (timerInterval.value) clearInterval(timerInterval.value);

    timerInterval.value = window.setInterval(() => {
      currentTime.value = moment.utc();
    }, 1000) as unknown as number;
  }

  function resetUseCartExpiresAtTimer() {
    clearInterval(timerInterval.value);
    currentTime.value = null;
    outcomeSessionExpiresAt.value = null;
  }

  return {
    outcomeSessionExpiresAt,
    timeRemaining,

    onNewExpiredAtInterval,
    resetUseCartExpiresAtTimer,
  };
}
