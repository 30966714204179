import { ref } from 'vue';

const tableHeaders = ref<any>([]);

export default function () {
  function reset() {
    tableHeaders.value = [];
  }

  return {
    tableHeaders,
    reset,
  };
}
