import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField, Field } from '@FormModule';
import { useAnimalProfileStore, useSessionStore } from '@Stores';
import { useADialog } from '@Atoms';
import { ROLES } from '@AuthModule';
import { useForm, FIELDS_COMPONENTS } from '@FormModule';

const { SYSTEM_MODALS } = useADialog();

const fieldsSchema = ref<FieldSchema[]>([]);
const currentSchemaModels = ref<Set<GenericField>>(new Set());

export default function () {
  const { can } = useSessionStore();
  const { getPersonLabel } = useAnimalProfileStore();
  const { pageData } = storeToRefs(useAnimalProfileStore());

  const { getDetailsSchema } = useForm();

  const intake = computed(() => pageData.value?.admissions[0]?.intake);
  const hasLocation = computed(() => {
    const location = intake.value?.expanded_intake_form?.fields.find(
      (field: Field) => field?.name === 'google-address'
    );

    return location && location?.name == 'google-address' ? true : false;
  });
  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      intake_type: {
        settings: {
          editable: false,
        },
      },
      intake_source_partner: {
        render: {
          component: FIELDS_COMPONENTS.LINK,
        },
        settings: {
          editable: false,
          href: `/shelter/partner/profile/${intake.value?.intake_sourceId?.$oid}`,
          show: intake.value?.intake_source === 'partner' && can(ROLES.PARTNERS),
        },
      },
      intake_source_people: {
        render: {
          component: FIELDS_COMPONENTS.LINK,
        },
        settings: {
          editable: false,
          href: `/shelter/people/profile/${intake.value?.intake_sourceId?.$oid}`,
          show:
            intake.value?.intake_source === 'people' &&
            (can(ROLES.PEOPLE) || can(ROLES.ADOPTER) || can(ROLES.OWNER)),
        },
      },
      intake_source_partner_transfer_in: {
        render: {
          component: FIELDS_COMPONENTS.OPEN_MODAL,
        },
        settings: {
          activeModal: SYSTEM_MODALS.ADD_PARTNER_TO_ANIMAL,
          editable: false,
          modalProps: {
            associateType: 'intake',
          },
          show:
            can(ROLES.PARTNERS) &&
            can(ROLES.INTAKE_ANIMAL) &&
            !intake.value?.intake_source &&
            intake.value?.intake_type === 'Transfer In',
        },
      },
      select_person: {
        render: {
          component: FIELDS_COMPONENTS.OPEN_MODAL,
        },
        settings: {
          activeModal: SYSTEM_MODALS.ADD_PEOPLE_TO_ANIMAL,
          editable: false,
          modalProps: {
            associateType: 'intake',
          },
          show:
            (can(ROLES.PEOPLE) || can(ROLES.ADOPTER) || can(ROLES.OWNER)) &&
            can(ROLES.INTAKE_ANIMAL) &&
            !intake.value?.intake_source &&
            intake.value?.intake_type !== 'Transfer In',
        },
      },
      location: {
        render: {
          component: FIELDS_COMPONENTS.GMAP,
        },
        settings: {
          activeModal: SYSTEM_MODALS.ANIMAL_ADD_LOCATION,
          modalProps: {
            intakeId: intake.value._id.$oid,
          },
          address: intake.value['google-address']?.formatted_address,
          editable: false,
          editLocation: true,
          coordinates: intake.value?.location?.coordinates,
          show: hasLocation.value,
        },
      },
    })
  );

  const rawFields: RawField[] = [
    {
      label: 'Admission Date',
      name: 'intake_date',
      required: false,
      type: 'date',
      value: '',
    },
    {
      label: 'Admission Type',
      name: 'intake_type',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Transfer Partner',
      name: 'intake_source_partner',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: getPersonLabel(pageData.value?.admissions[0]),
      name: 'intake_source_people',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Transfer Partner',
      name: 'intake_source_partner_transfer_in',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: getPersonLabel(pageData.value?.admissions[0]),
      name: 'select_person',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: locationLabel(),
      name: 'location',
      required: false,
      type: 'location',
      value: '',
    },
  ];

  function locationLabel() {
    if (intake.value?.intake_type === 'Lost') return 'Lost Location';
    if (intake.value?.type === 'rehome') return 'Current Location';

    return 'Found Location';
  }

  function positionStaticFields(fields: any) {
    const locationFieldIndex = fields.findIndex((field: Field) => field.name === 'location');
    if (!locationFieldIndex || locationFieldIndex === -1) return;

    const [locationField] = fields.splice(locationFieldIndex, 1);
    fields.push(locationField);

    return fields;
  }

  function setForm() {
    fieldsSchema.value = getSchemaModels(intake.value);
  }

  function getSchemaModels(sourceData: any) {
    const excludedNames = [
      'id',
      'header',
      'paragraph',
      'seperator',
      'birthday',
      'breed',
      'google_address',
      'google-address',
      'partner_name',
      'photo',
      'file',
      'kennel',
      'name',
      'microchip',
      'status',
      'intake_type',
      'species',
    ];
    const dynamicFields = intake.value?.form?.fields.filter(
      (field: Field) => !excludedNames.some((excluded) => field.name.includes(excluded))
    );

    const fields = dynamicFields ? [...rawFields, ...dynamicFields] : rawFields;

    const reorderedField = positionStaticFields(fields);

    const schema = getDetailsSchema(reorderedField, fieldsSettings.value);

    schema.forEach((field) => {
      const match = sourceData[field.name];

      switch (field.name) {
        case 'intake_source_partner': {
          field.model = sourceData.intake_partner;
          field.value = sourceData.intake_partner;
          break;
        }
        case 'intake_source_people': {
          field.model = sourceData.intake_person;
          field.value = sourceData.intake_person;
          break;
        }
        case 'intake_source_partner_transfer_in': {
          field.model = 'Select or add a partner';
          field.value = 'Select or add a partner';
          break;
        }
        case 'select_person': {
          field.model = 'Select or add a person';
          field.value = 'Select or add a person';
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });

    return schema;
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    rawFields,

    setForm,
    resetForm,
  };
}
