import { computed, ref } from 'vue';

export default function () {
  const draggableData = ref<any>([]);
  const draggingItemId = ref<number | null>(null);
  const isDragging = ref(false);

  const dragOptions = computed(() => ({
    animation: 0,
    group: 'description',
    ghostClass: 'ghost',
  }));

  function onDragStart(event: { oldIndex: number }, model: any = draggableData.value) {
    if (!model || !model.length || !model[event.oldIndex]) {
      console.error('Model is undefined or not in the correct structure');
      return;
    }
    isDragging.value = true;
    draggingItemId.value = model[event.oldIndex]?.id;
  }

  function onDragEnd() {
    isDragging.value = false;
    draggingItemId.value = null;
  }

  function getGrabbingClass(id: number) {
    const elementIsBeingDragged = isDragging.value && id === draggingItemId.value;
    return elementIsBeingDragged ? 'cursor-grabbing bg-blue-200 opacity-50' : '';
  }

  return {
    draggableData,
    dragOptions,
    onDragStart,
    onDragEnd,
    getGrabbingClass,
  };
}
