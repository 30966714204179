import { defineStore } from 'pinia';
import type { Stat } from '@Atoms';

interface State {
  kennels: any[];
  kennelId: string;
  loading: boolean;
  locations: any;
  stats: Stat | null;
}

export default defineStore('kennel', {
  state: (): State => ({
    kennels: [],
    kennelId: '',
    loading: false,
    locations: {},
    stats: null,
  }),
});
