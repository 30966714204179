enum FEATURE_FLAGS {
  ADOPT_APP = 'adopt_app',
  COMMUNITY_SUPPORT = 'community_support',
  FIELD_SERVICES = 'field_services',
  FORMS_AND_APPLICATIONS = 'unlimited_forms',
  HILLS_REPORT = 'hills_report',
  KENNEL_MANAGER = 'use_kennel_manager',
  LOST_AND_FOUND = 'lost_found',
  PETCADEMY = 'petcademy',
  REHOME = 'rehome',
  TNVR = 'tnvr',
  TRANSPORTS = 'transports',
  VISUAL_KENNEL = 'visual_kennel',
}

export { FEATURE_FLAGS };
