<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { formatPhoneNumber } from '@Helpers';
import useCartStore from '../../../store';

const props = defineProps<{
  cartData: any;
  headless?: boolean;
}>();

const cartStore = storeToRefs(useCartStore());

const cartType = computed(() =>
  props.cartData ? props.cartData.cart_type : cartStore.cartData.value.cart_type
);
const outcomeType = computed(() =>
  props.cartData ? props.cartData.outcome_type : cartStore.outcomeType.value
);
const people = computed(() => (props.cartData ? props.cartData.people : cartStore.people.value));
const peopleId = computed(() =>
  props.cartData ? props.cartData.peopleId : cartStore.cartData?.value.people_id
);

// TODO - AF-537 - Review i18n here. Values come from backend
const title = computed(() => {
  const isProduct = cartType.value === 'product';
  const isCitation = cartType.value === 'citation';
  const isAdopted = outcomeType.value === 'adopted';
  const isRto = outcomeType.value === 'return_to_owner';

  // prettier-ignore
  return isProduct ? 'cart.title.buyer' :
         isCitation ? 'cart.label.payor' :
         isAdopted ? 'cart.title.adopter' :
         isRto ? 'cart.title.owner' :
         props.cartData?.source_text || '';
});
</script>

<template>
  <div class="flex flex-col gap-1">
    <div
      v-if="!headless"
      class="flex items-center gap-4"
    >
      <h3 class="block font-semibold">{{ $t(title) }}</h3>

      <Divider
        class="before:border-t-2"
        type="dashed"
      />
    </div>

    <div class="flex items-center gap-2">
      <i class="pi pi-user font-semibold text-brand-500" />

      <RouterLink
        class="af-link"
        :to="`/shelter/people/profile/${props.cartData.people.person_id || peopleId}`"
      >
        <p class="text-sm">{{ people.first_name }} {{ people.last_name }}</p>
      </RouterLink>
    </div>

    <div
      v-if="people.address1"
      class="flex items-start gap-2"
    >
      <i class="pi pi-map mt-1 font-semibold text-brand-500" />

      <div class="flex flex-col gap-1">
        <p class="text-sm">{{ people.address1 }} {{ people.address2 }}</p>

        <p class="text-sm">{{ people.city }} {{ people.state }} {{ people.zip }}</p>
      </div>
    </div>

    <div
      v-if="people.phone"
      class="flex items-center gap-2"
    >
      <i class="pi pi-phone font-semibold text-brand-500" />

      <p class="text-sm">{{ formatPhoneNumber(people.phone) }}</p>
    </div>

    <div
      v-if="people.email"
      class="flex items-center gap-2"
    >
      <i class="pi pi-at font-semibold text-brand-500" />

      <p class="text-sm">{{ people.email }}</p>
    </div>
  </div>
</template>
