<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { FieldSchema } from '../../../types';

export interface Model {
  amount: string;
  amount_unit: string;
}

defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
  placeholder?: string;
  required?: boolean;
}>();

const { t } = useI18n();

const model = defineModel<Model | undefined>();
const modelNumber = ref();
const modelUnity = ref();

const options = computed(() => [
  `${t('units.application')}`,
  `${t('units.capsule')}`,
  `${t('units.cup')}`,
  `${t('units.dose')}`,
  `${t('units.drop(s)')}`,
  `${t('units.g')}`,
  `${t('units.mcg')}`,
  `${t('units.mg')}`,
  `${t('units.mg/kg')}`,
  `${t('units.mg/lb')}`,
  `${t('units.ml')}`,
  `${t('units.ml/hr')}`,
  `${t('units.oz')}`,
  `${t('units.package')}`,
  `${t('units.pinch')}`,
  `${t('units.tablet')}`,
  `${t('units.tblsp')}`,
  `${t('units.tsp')}`,
  `${t('units.unit')}`,
  `${t('units.scoop')}`,
  `${t('units.cm')}`,
  `${t('units.mcgs')}`,
  `${t('units.mcgs/kg')}`,
  `${t('units.g/kg')}`,
  `${t('units.gr/kg')}`,
  `${t('units.ml/kg')}`,
  `${t('units.l/kg')}`,
  `${t('units.units/kg')}`,
  `${t('units.pipette')}`,
  `${t('units.spray')}`,
]);

const input = useDebounce(modelNumber, 400);

onMounted(() => setModelOnStart());

watch(input, updateModel);
watch(model, setModelOnStart);

function setModelOnStart() {
  if (!model.value) return;

  modelNumber.value = model.value.amount || 0;
  modelUnity.value = model.value?.amount_unit?.toLowerCase();
}

function updateModel() {
  if (!modelNumber.value || !modelUnity.value) return;

  model.value = { amount: modelNumber.value, amount_unit: modelUnity.value };
}
</script>

<template>
  <div class="flex flex-col gap-3 sm:flex-row">
    <div class="w-full sm:!w-1/2">
      <AFieldNumber
        v-model="modelNumber"
        :error
        :required
        :disabled="field?.state?.disabled || disabled"
      />
    </div>

    <AFieldDropdown
      v-model="modelUnity"
      class="w-full sm:!w-1/2"
      :error
      :options
      :placeholder
      :required
      :disabled="field?.state?.disabled || disabled"
      @change="updateModel"
    />
  </div>
</template>
