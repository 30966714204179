<script setup lang="ts">
import { computed } from 'vue';
import { capitalizeStr, formatNumberToDate } from '@Helpers';
import { AF_FILE_COLLECTIONS } from '../../molecules/MFiles/types';

const props = defineProps<{
  collection: AF_FILE_COLLECTIONS;
  created_at: any;
  destinationPath: string;
  filename: string;
  filesProperties: any;
  fullFileName?: boolean;
  hasPermissionsRules?: boolean;
  hugeFileName?: boolean;
  icon: string;
  index: number;
  largeFileName?: boolean;
  mediumFileName?: boolean;
  path: string;
  permissionsRules?: boolean;
  showTypeCol: boolean;
  showVisibilityCol: boolean;
  type?: string;
  visibility: string;
}>();

const emit = defineEmits(['setFileVisibility', 'deleteFile']);

const applyPermissionRules = computed(() =>
  props.hasPermissionsRules ? props.permissionsRules : true
);

function onSetFileVisibility(file: any) {
  if (!applyPermissionRules.value) return;

  emit('setFileVisibility', file);
}

function onDeleteFile(event: any, file: any) {
  emit('deleteFile', event, file);
}

function showColumn(destinationPath?: string, collection?: AF_FILE_COLLECTIONS) {
  return props.showVisibilityCol && hasDynamicPayload(destinationPath, collection);
}

function hasDynamicPayload(destinationPath?: string, collection?: AF_FILE_COLLECTIONS) {
  return destinationPath && collection;
}
</script>

<template>
  <div
    :class="[
      'relative flex items-center gap-2',
      fullFileName ? 'w-[57%]' : 'w-32 sm:max-w-40 lg:max-w-72',
    ]"
  >
    <span :class="`${icon} text-sm`"></span>

    <a
      v-tooltip.top="filename"
      :class="[
        'af-link overflow-hidden text-ellipsis whitespace-nowrap text-sm hover:underline',
        hugeFileName ? 'absolute left-6 w-[450px]' : '',
        largeFileName ? 'absolute left-6 w-[350px]' : '',
        mediumFileName ? 'absolute left-6 w-[250px]' : '',
      ]"
      target="_blank"
      :href="path"
    >
      {{ filename }}
    </a>
  </div>

  <p
    v-if="showTypeCol"
    class="hidden w-32 items-center gap-2 text-sm sm:flex sm:max-w-40 lg:max-w-72"
  >
    {{ type ? capitalizeStr(type) : '' }}
  </p>

  <Button
    v-if="showColumn(destinationPath, collection)"
    outlined
    :class="[
      'hidden w-28 flex-row-reverse items-center border-none bg-transparent px-0 py-1 pr-4 text-left text-sm text-gray-700  sm:flex',
      applyPermissionRules && visibility ? 'hover:text-brand-500' : 'cursor-default',
    ]"
    :icon="`pi ${visibility === 'public' || !visibility ? 'pi-eye' : 'pi-eye-slash'}`"
    :label="visibility ? capitalizeStr(visibility) : $t('common.public')"
    @click="applyPermissionRules && visibility ? onSetFileVisibility(filesProperties[index]) : null"
  />

  <p
    v-if="created_at?.$date"
    class="w-32 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
  >
    {{ created_at?.$date?.$numberLong ? formatNumberToDate(created_at.$date.$numberLong) : '-' }}
  </p>

  <p
    v-else
    class="w-32 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
  >
    {{ created_at || '-' }}
  </p>

  <Button
    v-if="destinationPath && applyPermissionRules"
    class="absolute right-0 w-5 border-none bg-transparent p-0 text-red-500 hover:bg-slate-200"
    icon="pi pi-trash"
    @click="(event) => onDeleteFile(event, filesProperties[index])"
  />
</template>
