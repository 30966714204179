import { computed, ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { ROUTES, SITES } from '@AnimalModule';
import { useForm } from '@FormModule';
import { useAnimalProfileStore } from '@Stores';
import moment from 'moment';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { t } = useI18n();
  const { vaccinationTemplates, medicalStaff } = storeToRefs(useAnimalProfileStore());
  const { getDetailsSchema, wasFormSubmitted } = useForm();

  const rawFields: RawField[] = [
    {
      label: 'Vaccination',
      name: 'vaccination',
      required: true,
      type: 'select',
      value: '',
    },
    {
      label: 'Status',
      name: 'action',
      required: true,
      type: 'radio',
      value: '',
    },
    {
      label: 'Vaccination Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Product',
      name: 'product',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Dosage',
      name: 'dosage',
      required: false,
      type: 'amount_unit',
      value: '',
    },
    {
      label: 'Lot #',
      name: 'lot',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Expiration Date',
      name: 'expiration_date',
      required: false,
      type: 'date',
      value: '',
    },
    {
      label: 'Vaccine Type',
      name: 'vaccine_type',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Route',
      name: 'route',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Site',
      name: 'site',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Administered By',
      name: 'by',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: '',
      name: 'schedule_booster',
      required: false,
      type: 'toggle',
      value: '',
    },
    {
      label: 'Boosters',
      name: 'booster',
      required: false,
      type: 'booster',
      value: '',
    },
    {
      label: '',
      name: 'schedule_yearly_booster',
      required: false,
      type: 'toggle',
      value: '',
    },
    {
      label: 'Next Yearly Booster',
      name: 'yearly_booster_date',
      required: false,
      type: 'date',
      value: '',
    },
    {
      label: 'Rabies',
      name: 'rabies',
      required: false,
      type: 'toggle',
      value: '',
    },
  ];

  const byOptions = computed(() =>
    medicalStaff.value.map((staff: any) => {
      const { first_name, last_name } = staff;
      return {
        ...staff,
        by: `${first_name} ${last_name}`,
      };
    })
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      rabies: {
        settings: {
          show: false,
        },
      },
      by: {
        edit: {
          label: 'by',
          options: [...byOptions.value, { by: 'Other' }],
        },
        settings: {
          required: false,
          show: false,
        },
      },
      other_by: {
        settings: {
          show: false,
        },
      },
      action: {
        edit: {
          options: [
            { name: t('common.schedule'), value: 'scheduled' },
            { name: t('common.complete'), value: 'completed' },
          ],
        },
      },
      vaccination: {
        edit: {
          label: 'template_name',
          options: vaccinationTemplates.value.sort((a, b) =>
            a.template_name.localeCompare(b.template_name)
          ),
        },
      },
      route: {
        edit: {
          options: Object.values(ROUTES),
        },
      },
      site: {
        edit: {
          options: Object.values(SITES),
        },
      },
      booster: {
        settings: {
          boosterValue: {},
          required: false,
          show: false,
        },
      },
      yearly_booster_date: {
        settings: {
          required: false,
          show: false,
        },
        model: moment().add(1, 'year').toDate(),
      },
      schedule_booster: {
        settings: {
          label: 'Boosters Required',
        },
      },
      schedule_yearly_booster: {
        settings: {
          label: 'Schedule Yearly Booster',
        },
      },
    })
  );

  const vaccinationField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'vaccination')
  );
  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'by'));
  const actionField = computed(() => fieldsSchema.value.find(({ name }) => name === 'action'));
  const scheduleBoosterField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'schedule_booster')
  );
  const scheduleYearlyBoosterField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'schedule_yearly_booster')
  );
  const byModel = computed(() => byField.value?.model);
  const vaccinationModel = computed(() => vaccinationField.value?.model);
  const isAction = computed(() => actionField.value?.model);
  const isBooster = computed(() => scheduleBoosterField.value?.model);
  const isYearlyBooster = computed(() => scheduleYearlyBoosterField.value?.model);

  watch(isAction, () => showActionFields());
  watch(isBooster, () => showBoosterFields());
  watch(isYearlyBooster, () => showYearlyBoosterFields());
  watch(byModel, () => showOtherVetField());
  watch(vaccinationModel, (newValue, oldValue) => fillFormWithTemplate(newValue, oldValue));

  function fillFormWithTemplate(newValue: any, oldValue: any) {
    if (!newValue || oldValue === newValue) return;

    updateSchemaModels(vaccinationModel.value);
  }

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.by === 'Other';

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function showActionFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'by') {
        const showField = isAction.value === 'completed';

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function showBoosterFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'booster') {
        const showField = isBooster.value;

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function showYearlyBoosterFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'yearly_booster_date') {
        const showField = isYearlyBooster.value === true;

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings.value);

    if (!vaccinationModel.value) {
      fieldsSchema.value.forEach((field: any) => {
        if (field.name === 'vaccination') return;

        field.state.disabled = true;
        field.settings.disabled = true;
      });
    }
  }

  function updateSchemaModels(sourceData: any) {
    formError.value = null;
    wasFormSubmitted.value = false;

    const toggleableFields = ['booster', 'yearly_booster_date', 'vaccination'];

    fieldsSchema.value.forEach((field) => {
      field.state.disabled = false;
      field.settings.disabled = false;

      const match = sourceData[field.name];
      if (toggleableFields.includes(field.name)) return;

      switch (field.name) {
        case 'action': {
          setMatchStatusField(field, sourceData);
          break;
        }
        case 'expiration_date':
        case 'date': {
          setDateField(field, sourceData);
          break;
        }
        case 'dosage': {
          setDosageField(field, sourceData);
          break;
        }
        case 'schedule_booster': {
          setMatchScheduleBoosterField(field, sourceData);
          showBoosterFields();
          nextTick(() => {
            fillBoosterFields(sourceData);
          });
          break;
        }
        case 'schedule_yearly_booster': {
          setMatchScheduleYearlyBoosterField(field, sourceData);
          showYearlyBoosterFields();
          nextTick(() => {
            fillYearlyBoosterFields();
          });
          break;
        }
        default:
          setMatchField(field, match);
      }
    });
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
    field.state.errors.clear();
  }

  function setMatchScheduleBoosterField(field: any, sourceData: any) {
    field.model = sourceData.multi_dose;
    field.value = sourceData.multi_dose;
    field.state.errors.clear();
  }

  function setMatchScheduleYearlyBoosterField(field: any, sourceData: any) {
    field.model = sourceData.schedule_booster === 'true' || sourceData.schedule_booster;
    field.value = sourceData.schedule_booster === 'true' || sourceData.schedule_booster;
    field.state.errors.clear();
  }

  function setMatchStatusField(field: any, sourceData: any) {
    const sourceStatus = (field.value =
      sourceData.status === 'active' || sourceData.status === 'Schedule'
        ? 'scheduled'
        : 'completed');

    field.model = field.value = sourceStatus;
    field.value = field.value = sourceStatus;
    field.state.errors.clear();
  }

  function setDateField(field: any, sourceData: any) {
    if (field.name !== 'expiration_date') {
      field.model = new Date();
      field.value = new Date();
      field.state.errors.clear();

      return;
    }

    const numberDate = Number(sourceData?.expiration_date?.$date?.$numberLong);
    const validDate = isNaN(numberDate) ? new Date() : new Date(numberDate);

    field.model = validDate;
    field.value = validDate;
    field.state.errors.clear();
  }

  function setDosageField(field: any, sourceData: any) {
    field.model = { amount: Number(sourceData.dosage), amount_unit: sourceData.dosage_unit };
    field.value = { amount: Number(sourceData.dosage), amount_unit: sourceData.dosage_unit };
    field.state.errors.clear();
  }

  function fillBoosterFields(sourceData: any) {
    fieldsSchema.value.forEach((field) => {
      if (field.name !== 'booster') return;

      field.state.errors.clear();

      field.settings = {
        ...field.settings,
        boosterValue: {
          booster: sourceData.frequency,
          booster_unit: sourceData.frequency_unit,
          booster_frequency: sourceData.doses,
        },
      };
    });
  }

  function fillYearlyBoosterFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name !== 'yearly_booster_date') return;

      field.state.errors.clear();

      field.model = moment().add(1, 'year').toDate();
      field.value = moment().add(1, 'year').toDate();
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
    wasFormSubmitted.value = false;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    resetFieldSchema,
    setForm,
  };
}
