import { ref } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useSessionStore } from '@Stores';
import { formatNumberToDate } from '@Helpers';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { shelterProfile } = useSessionStore();

  const { getDetailsSchema } = useForm();

  const fieldsSettings: FieldInitialSettings = {
    unit: {
      edit: {
        options: ['lbs', 'oz', 'kg', 'g'],
      },
      model: shelterProfile.weight_unit,
    },
    date: {
      settings: {
        maxDate: new Date(),
      },
    },
    weight: {
      settings: {
        maxFractionDigits: 3,
      },
    },
  };
  const rawFields: RawField[] = [
    {
      label: 'Weight Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Weight',
      name: 'weight',
      required: true,
      type: 'number',
      value: '',
    },
    {
      label: 'Unit of measurement',
      name: 'unit',
      required: true,
      type: 'select',
      value: '',
    },
  ];

  function setForm() {
    if (fieldsSchema.value.length) return;

    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings);
  }

  function updateSchemaModels(sourceData: any) {
    setForm();

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];
      if (!match) return;

      switch (field.name) {
        case 'date': {
          field.model = formatNumberToDate(sourceData.date.$date.$numberLong, false, false, true);
          field.value = formatNumberToDate(sourceData.date.$date.$numberLong, false, false, true);
          break;
        }
        case 'weight': {
          field.model = Number(sourceData.weight);
          field.value = Number(sourceData.weight);
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    setForm,
    resetFieldSchema,
    updateSchemaModels,
  };
}
