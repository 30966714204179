import { defineStore } from 'pinia';
import { useStorage, useWindowSize } from '@vueuse/core';
import type { RemovableRef } from '@vueuse/core';
import { getStorageKey, STORES } from '@NavigationModule';

const { height, width } = useWindowSize();

interface State {
  isNavigating: boolean;
  isSideNavExpanded: RemovableRef<boolean>;
}

export default defineStore('ui', {
  state: (): State => ({
    isNavigating: false,
    isSideNavExpanded: useStorage(getStorageKey(STORES.UI, 'isSideNavExpanded'), width.value > 768),
  }),

  actions: {
    toggleSideNav() {
      if (height.value < 779 && width.value > 768) return (this.isSideNavExpanded = true);
      this.isSideNavExpanded = !this.isSideNavExpanded;
    },
  },
});
