import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import '@Styles/main.css';
import Directives from '@Directives';
import Libraries from '@Libraries';
import Plugins from '@Plugins';
import router from './libraries/router/index';
import { SENTRY_DNS, SENTRY_PROPAGATION_TARGETS } from '@Constants';
import App from './App.vue';

const app = createApp(App);

// TODO - AF-693
if (SENTRY_DNS && SENTRY_PROPAGATION_TARGETS) {
  Sentry.init({
    app,
    dsn: SENTRY_DNS,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: SENTRY_PROPAGATION_TARGETS,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Directives.forEach(({ name, directive }) => app.directive(name, directive));
Libraries.forEach((lib) => app.use(lib));
Plugins.forEach((plugin) => app.use(plugin));

app // TODO - AF-693
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBPW9Kfik0b542RvFMJA8NIHUQ1l3Iirtk',
      libraries: 'places',
      loading: 'async',
    },
  })
  .mount('#app');
