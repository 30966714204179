<script setup lang="ts">
import { ref } from 'vue';

defineEmits<{
  onFileUpload: [File | File[]];
}>();

defineProps<{
  isPhoto?: boolean;
  imageOnly?: boolean;
  loading?: boolean;
}>();

const overloadFile = ref<boolean>(false);

function checkFileSize(event: any) {
  if (event.files.length) return (overloadFile.value = false);

  overloadFile.value = true;
  setTimeout(() => (overloadFile.value = false), 5000);
}
</script>

<template>
  <section class="flex w-full flex-col justify-between gap-6 md:w-auto">
    <div :class="[`flex gap-3 md:text-nowrap`, isPhoto ? 'flex-wrap' : 'flex-col']">
      <slot name="header" />

      <slot name="content" />
    </div>

    <FileUpload
      auto
      customUpload
      :accept="
        imageOnly
          ? 'image/*'
          : '.gif,.jpg,.jpeg,.png,.webp,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.heic'
      "
      name="files[]"
      class="bottom-0 w-full"
      url="/api/shelter/files/upload-file"
      pt:content=" items-center flex justify-center"
      pt:choosebutton="text-sm af-button text-gray-500 hover:bg-gray-100 active:bg-gray-200 outline-none border-slate-300 bg-transparent w-full"
      :disabled="loading"
      :multiple="true"
      :maxFileSize="10000000"
      :showCancelButton="false"
      :showUploadButton="false"
      @select="(event) => checkFileSize(event)"
      @uploader="(event) => $emit('onFileUpload', event.files)"
    >
      <template #content>
        <div
          v-if="loading"
          class="hidden items-center gap-3 text-center text-sm md:flex"
        >
          <i class="pi pi-spin pi-spinner text-xl text-brand-500"></i>

          <p>{{ $t('components.fileUploader.load') }}</p>
        </div>

        <Message
          v-else-if="overloadFile"
          class="text-wrap"
          icon="pi pi-times-circle"
          pt:text="text-sm"
          severity="error"
          :closable="false"
        >
          {{ $t('components.fileUploader.toast.message.error') }}
        </Message>

        <p
          v-else
          class="hidden items-center gap-3 text-center text-sm md:flex"
        >
          <i class="pi pi-file-import text-xl text-brand-500"></i>

          {{ $t('components.fileUploader.drop') }}
        </p>
      </template>
    </FileUpload>
  </section>
</template>
