import { API } from '@Providers';

interface RtoFeePayload {
  animals: {
    base_rto_fee: string,
    id: string;
    newLos: number;
    newRtoFee: string | number;
    rto_fee_type: 'flat' | 'daily';
  }
}

export default async function (payload: RtoFeePayload) {
  const endpoint = '/api/shelter/animal/update-animal-rto-fee';

  return API({ endpoint, method: 'post', payload });
}
