import { ref } from 'vue';
import type { FieldPayload, FieldInitialSettings, FieldSchema, RawField } from '../types';
import { FIELDS_COMPONENTS } from '../constants';
import { Field } from '../types';
import {
  AFieldCheckboxButton,
  AFieldColorSelector,
  AFieldDropdown,
  AFieldEditor,
  AFieldEmail,
  AFieldHeader,
  AFieldInputText,
  AFieldMicrochipIssuer,
  AFieldMoney,
  AFieldMultiSelect,
  AFieldNumber,
  AFieldParagraph,
  AFieldPassword,
  AFieldPhone,
  AFieldRadio,
  AFieldSeparator,
  AFieldSpecies,
  AFieldPrintButton,
  AFieldWeight,
  MFieldStaff,
  AFieldTextArea,
  AFieldToggle,
  MFieldAmountUnity,
  MFieldAnimalName,
  MFieldBooster,
  MFieldCheckbox,
  MFieldContact,
  MFieldContactType,
  MFieldCountry,
  MFieldDatePicker,
  MFieldFileUpload,
  MFieldGMapAutocomplete,
  MFieldMicrochip,
  MFieldResults,
  MFieldTimeFrame,
  OFieldAnimalBirthday,
  OFieldFrequency,
  OFieldHoldStatus,
  OFieldKennelManager,
} from '../components';
import useFormValidations from './useFormValidations';

const wasFormSubmitted = ref(false);

export default function () {
  const { validateField } = useFormValidations();

  // VALIDATION
  function fieldsValidation(schema: FieldSchema[], currentSchemaModels: Set<FieldPayload>) {
    wasFormSubmitted.value = true;
    let hasErrors = false;

    currentSchemaModels.clear();

    schema.forEach((field: FieldSchema) => {
      validateField(field, wasFormSubmitted.value);

      if (field.state.errors.size) hasErrors = true;

      currentSchemaModels.add({
        name: field.name,
        value: field.model,
        type: field.settings.type,
      });
    });

    return hasErrors;
  }

  function granularFieldValidation(field: FieldSchema) {
    validateField(field, wasFormSubmitted.value);
  }

  // GETTERS
  function getComponent(type?: FIELDS_COMPONENTS | string) {
    // prettier-ignore
    switch (type) {
      case 'AMOUNT_UNIT': return MFieldAmountUnity;
      case 'ANIMAL_BIRTHDAY': return OFieldAnimalBirthday;
      case 'ANIMAL_NAME': return MFieldAnimalName;
      case 'ANIMAL_TEMPLATE': return AFieldSpecies;
      case 'BOOSTER': return MFieldBooster;
      case 'BUTTON_CHECKBOX': return AFieldCheckboxButton;
      case 'CHECKBOX': return MFieldCheckbox;
      case 'COLOR_SELECTOR': return AFieldColorSelector;
      case 'CONTACT': return MFieldContact;
      case 'CONTACT_TYPE': return MFieldContactType;
      case 'COUNTRY': return MFieldCountry;
      case 'DATE': return MFieldDatePicker;
      case 'DROPDOWN': return AFieldDropdown;
      case 'EDITOR': return AFieldEditor;
      case 'EMAIL': return AFieldEmail;
      case 'FILE': return MFieldFileUpload;
      case 'FREQUENCY': return OFieldFrequency;
      case 'HEADER': return AFieldHeader;
      case 'HOLD_STATUS': return OFieldHoldStatus;
      case 'KENNEL_MANAGER': return OFieldKennelManager;
      case 'LOCATION': return MFieldGMapAutocomplete;
      case 'MICROCHIP': return MFieldMicrochip;
      case 'MICROCHIP_ISSUER': return AFieldMicrochipIssuer;
      case 'MONEY': return AFieldMoney;
      case 'MULTISELECT': return AFieldMultiSelect;
      case 'NUMBER': return AFieldNumber;
      case 'PARAGRAPH': return AFieldParagraph;
      case 'PASSWORD': return AFieldPassword;
      case 'PRINT': return AFieldPrintButton;
      case 'RADIO': return AFieldRadio;
      case 'RESULT': return MFieldResults;
      case 'SEPARATOR': return AFieldSeparator;
      case 'SPECIES': return AFieldDropdown;
      case 'STAFF': return MFieldStaff;
      case 'TEL': case 'PHONE': return AFieldPhone;
      case 'TEXT': return AFieldInputText;
      case 'TEXTAREA': return AFieldTextArea;
      case 'TIMEFRAME': return MFieldTimeFrame;
      case 'TOGGLE': return AFieldToggle;
      case 'WEIGHT': return AFieldWeight;
      default: return AFieldInputText;
    }
  }

  function getDetailsSchema(
    rawFields: RawField[],
    fieldsSettings: FieldInitialSettings,
    data?: any
  ) {
    /*
     * NOTE
     * If we have two active forms in a single view we may have a problem
     * with the wasFormSubmitted state. Who will be in charge of reset
     * it? We may need to use two independent useFields composable
     * */
    wasFormSubmitted.value = false;

    return rawFields.map((field) => {
      const targetData = data ? data[field.name] : undefined;

      return new Field(field, fieldsSettings[field.name], targetData);
    });
  }

  // HELPERS [SETTERS]
  function setEditOptions(fieldsSettings: FieldInitialSettings, dataSource: any) {
    if (!fieldsSettings) return null;

    // Object.keys(fieldsSettings).forEach((schemaKey: string) => {
    //   const target = fieldsSettings[schemaKey];
    //   if (!target.edit) return;

    //   const optionKey = target.edit.key;
    //   const dataHasField = result.call[schemaKey];
    //   const initialModelValue = dataHasField ? result.call[schemaKey] : '';

    //   target.edit.options = optionKey ? result[optionKey] : [];
    //   target.edit.value = dataHasField ? result.call[schemaKey] : '';
    //   target.model = initialModelValue;
    // });
  }

  return {
    fieldsValidation,
    getComponent,
    getDetailsSchema,
    granularFieldValidation,
    setEditOptions,
    wasFormSubmitted,
  };
}
