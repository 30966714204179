import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: 'reports',
    name: 'admin-reports',
    redirect: '/admin/reports/intake',
    children: [
      {
        path: 'adopter-opt-in-list',
        name: 'admin-reports-adopter-opt-in-list',
        component: () => import('./views/ReportsAdopterOptInList.view.vue'),
      },
      {
        path: 'adoption',
        name: 'admin-reports-adoption',
        component: () => import('./views/ReportsAdoptionAdmin.view.vue'),
      },
      {
        path: 'intake',
        name: 'admin-reports-intake',
        component: () => import('./views/ReportsIntake.view.vue'),
      },
      {
        path: 'outcome',
        name: 'admin-reports-outcome',
        component: () => import('./views/ReportsOutcome.view.vue'),
      },
      {
        path: 'partner-list',
        name: 'admin-reports-partner-list',
        component: () => import('./views/ReportsPartnerList.view.vue'),
      },
      {
        path: 'shelter-stats',
        name: 'admin-reports-shelter-stats',
        component: () => import('./views/ReportsShelterStats.view.vue'),
      },
      {
        path: 'user-list',
        name: 'admin-reports-user-list',
        component: () => import('./views/ReportsUserList.view.vue'),
      },
    ],
  },
];
