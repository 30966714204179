import { ref } from 'vue';

const contactData = ref();
const personId = ref();
const showCard = ref();
const showPhoneCard = ref(false);
const showEmailCard = ref(false);

export default function () {
  return {
    contactData,
    personId,
    showEmailCard,
    showPhoneCard,
    showCard,
  };
}
