<script setup lang="ts">
import { computed, useTemplateRef, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import type { FieldSchema } from '../../../types';
import useForm from '../../../composables/useForm';

const emits = defineEmits<{
  onModelUpdate: [];
  onEditFieldCancel: [];
  onEditFieldClick: [];
  onEditFieldRequest: [];
}>();

const props = withDefaults(
  defineProps<{
    editRuleByPermission?: boolean;
    error?: string;
    field: FieldSchema;
    fieldOnInlineEdit: string;
    hasInlineEdit?: boolean;
    hasRenderMode?: boolean;
    hybridEditMode?: boolean;
    isInline?: boolean;
    isInlineEditing?: boolean;
    isOnEditMode?: boolean;
    isOnODetails?: boolean;
    show?: boolean;
  }>(),
  {
    editRuleByPermission: true,
    isOnODetails: false,
    show: true,
  }
);

const { getComponent } = useForm();

const model = defineModel<any>();

const inlineEditRow = useTemplateRef<any>('inlineEditRow');

const hideCancelButton = computed(() => {
  const dontShowCancelFields = ['checkbox', 'birthday'];
  if (!props.field.settings?.type) return;

  return (
    (props.field.settings?.type === 'checkbox' && props.field?.edit?.options.length <= 5) ||
    !dontShowCancelFields.includes(props.field.settings?.type)
  );
});
const required = computed(() => props.field?.settings.required);

const renderFieldClasses = computed(() => {
  return [
    props.field?.settings?.hasSearch && model.value ? 'flex flex-wrap items-center gap-3' : '',
    props.field?.settings?.editable && props.editRuleByPermission
      ? 'group relative border-b border-gray-100 border-opacity-0 hover:border-opacity-100'
      : '',
  ];
});

const inlineClasses = computed(() => {
  const columnRenderModeTypes = ['location'];
  const isColumnOnRenderMode =
    props.field.settings.type && columnRenderModeTypes.includes(props.field.settings.type);
  if (isColumnOnRenderMode) return 'flex flex-col';

  const defaultClasses = 'grid';
  const inlineClasses = 'grid grid-cols-[130px_1fr] gap-2 items-start';

  const isInlineOnRenderMode = props.isInline && !props.isOnEditMode;
  if (isInlineOnRenderMode) return inlineClasses;

  const noInlineFieldTypes = ['textarea'];
  const needsInlineClasses =
    props.isInline &&
    props.field.settings.type &&
    !noInlineFieldTypes.includes(props.field.settings.type);

  if (needsInlineClasses) return inlineClasses;

  return defaultClasses;
});

const showFieldset = computed(() => {
  const optionsLength = props?.field?.edit?.options?.length;
  const fieldSettingsType = props?.field?.settings?.type;
  const noOptionsHiddenFields =
    fieldSettingsType && ['select', 'checkbox', 'radio'].includes(fieldSettingsType);

  if (noOptionsHiddenFields && !optionsLength) return false;

  return props.field.settings?.show !== undefined ? props.field.settings.show : props.show;
});

onClickOutside(inlineEditRow, () => saveOnClickOutsideField());

watch(
  () => model,
  () => emits('onModelUpdate'),
  { deep: true }
);

function saveOnClickOutsideField() {
  const simpleCheckBox =
    props.field?.settings?.type === 'checkbox' && props.field.edit?.options.length <= 5;
  if (simpleCheckBox) return emits('onEditFieldRequest');

  const ignoredFieldTypes = [
    'select',
    'checkbox',
    'date',
    'breeds',
    'radio-gender',
    'microchip_issuer',
    'birthday',
  ];
  if (props.field.settings.type && ignoredFieldTypes.includes(props.field.settings.type)) return;

  emits('onEditFieldRequest');
}
</script>

<template>
  <fieldset
    v-if="showFieldset"
    class="mb-6 flex flex-col gap-1"
  >
    <div :class="[inlineClasses]">
      <AFieldLabel
        v-if="
          field?.settings?.type !== 'header' &&
          field?.settings?.type !== 'paragraph' &&
          field?.settings?.type !== 'seperator'
        "
        :error
        :required
        :class="hasInlineEdit ? 'mr-1' : ''"
        :disabled="!field?.settings?.editable"
        :label="field.label || field.title"
        @click="emits('onEditFieldClick')"
      />

      <!-- GROUP EDIT -->
      <div v-if="!hasInlineEdit || (hybridEditMode && isOnEditMode)">
        <div v-if="hasRenderMode && !isOnEditMode">
          <AFieldRenderMode
            :field
            :model
          />
        </div>

        <component
          v-else
          v-model="model"
          :field
          :required
          :disabled="!field?.settings?.editable"
          :error="[...field.state.errors][0]"
          :is="getComponent(field.edit?.component)"
          :isOnODetails="true"
          :placeholder="field?.settings?.placeholder || field?.placeholder || undefined"
        />
      </div>

      <!-- INLINE EDIT -->
      <div v-else>
        <div
          v-if="hasRenderMode && field.name !== fieldOnInlineEdit"
          :class="renderFieldClasses"
        >
          <AFieldRenderMode
            :field
            :model
            :class="field?.settings?.editable && editRuleByPermission ? 'cursor-pointer' : ''"
            @click="emits('onEditFieldClick')"
          />

          <MSearchAaha
            v-if="field?.settings?.hasSearch && model"
            :field
            :model
          />

          <i
            v-if="field?.settings?.editable && editRuleByPermission"
            class="pi pi-pencil absolute right-0 top-0 cursor-pointer text-sm text-gray-500 opacity-0 transition-opacity group-hover:opacity-100"
            @click="emits('onEditFieldClick')"
          ></i>
        </div>

        <div
          v-else
          class="relative flex items-center justify-between gap-2"
          ref="inlineEditRow"
        >
          <component
            v-model="model"
            :field
            :isInlineEditing
            :isOnODetails
            :required
            :disabled="!field?.settings?.editable"
            :error="[...field.state.errors][0]"
            :is="getComponent(field.edit?.component)"
            @onDropdownSelectValue="emits('onEditFieldRequest')"
            @onEditFieldCancelMultiselect="emits('onEditFieldCancel')"
            @onHideMultiSelectPanel="emits('onEditFieldRequest')"
            @onMicrochipSelectValue="emits('onEditFieldRequest')"
            @onSelectInlineDate="emits('onEditFieldRequest')"
            @onSelectBirthdayDate="emits('onEditFieldRequest')"
            @onEditBirthdayCancel="emits('onEditFieldCancel')"
          />

          <div
            v-if="hideCancelButton"
            class="absolute right-[-18px] top-[-45px] flex gap-2 rounded-lg rounded-r-none border border-r-0 border-slate-200 bg-white p-1"
          >
            <Button
              v-tooltip.top="$t('common.cancel')"
              outlined
              class="h-7 w-7 rounded-md p-1 !text-xs"
              icon="pi pi-times text-xs"
              pt:icon="text-red-500"
              severity="danger"
              @click="emits('onEditFieldCancel')"
            />
          </div>
        </div>
      </div>
    </div>

    <AFieldError :error />
  </fieldset>
</template>
