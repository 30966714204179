import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'stats',
    name: 'stats',
    meta: {
      permissions: [ROLES.STATS],
    },
    children: [
      {
        path: 'adopt',
        name: 'stats-adopt',
        component: () => import('./views/StatsAdopt.view.vue'),
      },
      {
        path: 'intake',
        name: 'stats-intake',
        component: () => import('./views/StatsIntake.view.vue'),
      },
      {
        path: 'outcome',
        name: 'stats-outcome',
        component: () => import('./views/StatsOutcome.view.vue'),
      },
      {
        path: 'census',
        name: 'stats-census',
        component: () => import('./views/StatsCensus.view.vue'),
      },
      {
        path: 'yearly-intake-outcome',
        name: 'stats-yearly-intake-outcome',
        component: () => import('./views/StatsYearlyIntakeOutcome.view.vue'),
      },
    ],
  },
];
