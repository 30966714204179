import type { ApiParams } from '@Providers';
import { API } from '@Providers';

export default async function (data: any, isMuliple: boolean = false, animalId?: string) {
  const providerParams: ApiParams = {
    endpoint: animalId
      ? `/api/shelter/animal/add-animal-photo-new-ui/${animalId}`
      : '/api/shelter/files/upload-file',
    method: 'post',
    payload: { ...data },
    isUpload: true,
  };

  if (!isMuliple) {
    return await API({ ...providerParams });
  }

  return Promise.all(
    data.file.map((upload: File) => {
      providerParams.payload.file = upload;

      return API({ ...providerParams }).catch((error) => {
        throw error;
      });
    })
  );
}
