<script setup lang="ts">
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { AddAdditionalRtoCostService, RemoveAdditionalRtoCostService } from '../../../services';
import useCartStore from '../../../store';

const { cartId } = storeToRefs(useCartStore());
const { fetchActiveCart } = useCartStore();

const props = defineProps<{
  cartItem: any;
}>();

const error = ref();
const model = ref();

watch(model, () => model.value && (error.value = false));

function onAddNewCost() {
  if (!model.value) return (error.value = 'Please add a cost first.');

  const payload = {
    cost: model.value,
    animal: props.cartItem,
  };

  AddAdditionalRtoCostService(payload).then(() => fetchActiveCart(cartId.value));
}

function onRemoveCost() {
  const payload = {
    animal: props.cartItem,
  };

  RemoveAdditionalRtoCostService(payload).then(() => {
    error.value = '';
    model.value = 0;
    fetchActiveCart(cartId.value);
  });
}
</script>

<template>
  <div class="mt-4 flex w-full flex-col gap-2">
    <div class="flex items-center justify-center gap-1">
      <p class="text-[13px] font-semibold">Additional RTO Costs</p>

      <small v-tooltip="`${$t('cart.tooltip.rtoAddCost')}`">
        <FaIcon :icon="$icons.faQuestionCircle" />
      </small>
    </div>

    <div
      v-if="!cartItem?.additional_rto_cost_applied"
      class="flex flex-col gap-2"
    >
      <InputNumber
        v-model="model"
        class="rto-additiontal flex h-9"
        currency="USD"
        mode="currency"
        pt:input:root="text-xs"
      />

      <Button
        class="af-button-success h-9 text-xs"
        severity="primary"
        :label="$t('common.add')"
        @click="onAddNewCost"
      />

      <AFieldError
        v-if="error"
        :error="$t(error)"
      />
    </div>

    <Button
      v-else
      outlined
      class="h-9 text-xs"
      severity="danger"
      :label="$t('common.remove')"
      @click="onRemoveCost"
    />
  </div>
</template>

<style>
.rto-additiontal > .p-inputnumber-input {
  @apply w-[100px];
}
</style>
