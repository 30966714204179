<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

const emits = defineEmits<{
  change: [string | null];
  focus: [FocusEvent];
  blur: [FocusEvent];
  'update:modelValue': [string | null];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    modelValue: string | null;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const _model = ref(props.modelValue);

const input = useDebounce(_model, 300);

const activeModelProp = computed(() => props?.field?.model || props.modelValue);
const settings = computed(() => props?.field?.settings);

watch(activeModelProp, (newValue) => (_model.value = newValue));

watch([input, _model], () => {
  emits('update:modelValue', _model.value);
  emits('change', _model.value);
});

function unfreezeInput(event: any) {
  // NOTE: When logging out the input can get frozen by Chrome's auto fill feature.
  // This is a Chrome issue. When reassigning the input value to itself the field works again.
  // eslint-disable-next-line no-self-assign
  event.target.value = event.target.value;
}
</script>

<template>
  <InputText
    v-model="_model"
    type="email"
    :class="['h-9 w-full text-sm', { 'af-field-error': !!error }]"
    :invalid="!!error"
    :placeholder="$t(settings?.placeholder || placeholder)"
    :required="settings?.required || required"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @click="unfreezeInput($event)"
  />
</template>
