<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '@FormModule';

export interface AFieldButtonCheckboxOption {
  disabled?: boolean;
  disabledMessage?: string;
  icon?: string;
  label: string;
  value: any;
}

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  field?: FieldSchema;
  disabled?: boolean;
  options?: AFieldButtonCheckboxOption[];
}>();

const model = defineModel();

const disabledField = computed(() => props.field?.state.disabled || props.disabled);
const options = computed(() => props.field?.edit?.options || props.options);

const radioName = computed(() => props.field?.name || `button-checkbox-${Math.random()}`);

function getClasses(value: string, disabled: boolean) {
  return [
    'grid h-9 w-fit cursor-pointer place-content-center rounded-md border px-2 text-sm',
    {
      'af-button-success text-white !border !border-solid border-brand-500': value === model.value,
      'opacity-50 pointer-events-none': disabled || disabledField.value,
    },
  ];
}
</script>

<template>
  <div class="flex flex-wrap items-center gap-4">
    <div
      v-for="({ disabled, disabledMessage, icon, label, value }, index) in options"
      :key="`${label}_${index}`"
      class="flex flex-wrap items-center gap-4"
    >
      <i
        v-if="(disabled || disabledField) && disabledMessage"
        v-tooltip.top="$t(disabledMessage)"
        class="pi pi-info-circle cursor-help text-sm opacity-80"
      ></i>

      <label
        :class="getClasses(value, disabled)"
        :for="`${value}-${radioName}`"
      >
        <div class="flex cursor-pointer items-center gap-1">
          <FaIcon
            v-if="icon"
            :icon="$icons[icon]"
          />

          {{ $t(label) }}
        </div>

        <input
          v-model="model"
          class="hidden"
          :name="radioName"
          type="radio"
          :value
          :id="`${value}-${radioName}`"
        />
      </label>
    </div>
  </div>
</template>
