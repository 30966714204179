import { API } from '@Providers';

interface Payload {
  microchip: string;
  species: string;
}

export default async function (payload: Payload) {
  const endpoint = '/api/shelter/forms/check-duplicate-microchip';

  return API({ endpoint, forwardError: true, method: 'post', payload }).then(
    (result: any) => result
  );
}
