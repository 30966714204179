<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { OptionList } from '@Types';
import { useDataTableFilters } from '@DataTableModule';

const emits = defineEmits<{
  onParamsUpdate: [];
}>();

const externalModel = defineModel<string>('externalModel', { default: '' });

const props = withDefaults(
  defineProps<{
    defaultValue?: string;
    hasClearTrigger?: boolean;
    hasExternalModel?: boolean;
    inputLabel: string;
    isUrl?: boolean;
    loading?: boolean;
    options?: OptionList[];
    paramsLabel?: string;
    optionLabel?: string;
    optionValue?: string;
  }>(),
  {
    optionLabel: 'label',
    optionValue: 'value',
  }
);

const { filterUrl, selectFilter } = useDataTableFilters();

const _model = ref<string>('');
const showClear = ref(props.hasClearTrigger);

onMounted(() => initValue());

function initValue() {
  if (props.hasExternalModel) return (_model.value = externalModel.value);

  const hasSelection = _model.value;
  if (hasSelection) return;

  if (props.isUrl) {
    filterUrl.value = filterUrl.value ? filterUrl.value : props.defaultValue;
    _model.value = filterUrl.value;
    return;
  }

  selectFilter.value = {
    label: props.paramsLabel,
    value: selectFilter.value?.value ?? props.defaultValue,
  };

  const hasStorageValue = selectFilter.value.value;
  if (hasStorageValue) _model.value = selectFilter.value.value;
}

function checkSelectType() {
  if (props.hasClearTrigger) showClear.value = true;

  const targetValue = !_model.value ? props.defaultValue : _model.value;

  if (props.hasExternalModel) {
    externalModel.value = _model.value;
    return;
  }

  props.isUrl
    ? (filterUrl.value = targetValue)
    : (selectFilter.value = {
        label: props.paramsLabel,
        value: targetValue,
      });

  emits('onParamsUpdate');
}
</script>

<template>
  <Dropdown
    v-model="_model"
    class="flex h-9 w-fit gap-3"
    inputId="selectFilter"
    pt:emptymessage="text-sm"
    pt:input="flex items-center p-1 pl-3 pr-6 text-sm text-gray-500"
    pt:item="text-sm"
    :optionLabel
    :optionValue
    :options
    :showClear
    :disabled="loading"
    :placeholder="$t(inputLabel)"
    @change="checkSelectType"
  />
</template>
