import type { DataTableColumn } from '../types';
import { DATA_TABLE_COMPONENTS, FORMAT_COMPONENTS } from '../types';

const { FORMAT, TEXT_AREA } = DATA_TABLE_COMPONENTS;
const { NUMBER_TO_DATE } = FORMAT_COMPONENTS;

export default function(type: string, COLUMN: DataTableColumn) {
  const typesWithCustomComponents = ['date', 'textarea'];
  const noCustomComponentRequired = !typesWithCustomComponents.includes(type);

  if (noCustomComponentRequired) return COLUMN;

  switch (type) {
    case 'date':
      return {
        ...COLUMN,
        settings: {
          component: FORMAT,
          render: {
            type: NUMBER_TO_DATE,
            targetKey: ['$date', '$numberLong'],
          },
        },
      };
    case 'textarea': 
      return {
        ...COLUMN,
        settings: {
          component: TEXT_AREA
        }
      }
    default: return COLUMN;
  }
}