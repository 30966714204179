<script setup lang="ts">
/* TODO :: [AF-289] - Component with several limitations
 * This component is throwing a few warning message. It is a limitation
 * of the library we are using and no clear solution in the doccumenation at this moment.
 * Also, there is no way at this moment to populate the input.
 * We may need to create a component from scratch
 */
import { computed, onMounted, ref } from 'vue';
import { useSessionStore } from '@Stores';
import type { FieldSchema } from '../../../types';
import type { Coordinates, GMapAutocompleteModel } from './types';

const { shelterProfile } = useSessionStore();

const props = withDefaults(
  defineProps<{
    coordinates?: Coordinates;
    disabled?: boolean;
    error?: string;
    field?: FieldSchema;
    height?: string;
    placeholder?: string;
    required?: boolean;
    zoom?: number;
  }>(),
  {
    height: '250px',
    placeholder: 'components.forms.placeholder.location',
    zoom: 12,
  }
);

const defaultCoordinates = computed(() => {
  return shelterProfile?.location
    ? {
        lat: shelterProfile.location.coordinates[1],
        lng: shelterProfile.location.coordinates[0],
      }
    : { lat: 40.701275249835156, lng: -73.99033497545578 };
});

const coordinates = ref(props.coordinates ?? defaultCoordinates.value);

const model = defineModel<GMapAutocompleteModel | string>();

const zoom = ref(props?.field?.settings.zoom || props.zoom);

onMounted(() => initModel());

function initModel() {
  if (!model.value) return;

  updateModel(model.value);
}

function updateModel(address: any) {
  zoom.value = 18;
  coordinates.value = {
    lat: address?.geometry?.location?.lat || Number(address?.geometry?.location?.lat()),
    lng: address?.geometry?.location?.lng || Number(address?.geometry?.location?.lng()),
  };

  model.value = address;
}
</script>

<template>
  <GMapAutocomplete
    :class="[
      'p-inputtext p-component p-inputnumber-input w-full text-sm',
      { 'af-field-error': !!error },
    ]"
    :placeholder="
      $t(
        (model as GMapAutocompleteModel)?.formatted_address ||
          field?.settings.placeholder ||
          placeholder
      )
    "
    @place_changed="updateModel"
  />

  <GMapMap
    v-if="coordinates"
    class="mt-4"
    :zoom
    :center="coordinates"
    :style="{ height: height }"
  >
    <GMapMarker
      icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
      :position="coordinates"
    />
  </GMapMap>
</template>

<style>
.pac-container {
  z-index: 10000;
}
</style>
