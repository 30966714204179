import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'adoptions',
    name: 'adoptions',
    redirect: '/shelter/adoptions/requests',
    meta: {
      featureFlag: FEATURE_FLAGS.ADOPT_APP,
      permissions: [ROLES.ADOPTIONS],
    },
    children: [
      {
        path: 'applications',
        name: 'adoptions-applications',
        component: () => import('./views/AdoptionsApplications.view.vue'),
      },
      {
        path: 'animal/requests/:id/:id2',
        name: 'adoption-individual-request-user',
        component: () => import('./views/AdoptionIndividualRequest.view.vue'),
      },
      {
        path: 'animal/requests/:id',
        name: 'adoption-individual-request',
        component: () => import('./views/AdoptionIndividualRequest.view.vue'),
      },
      {
        path: 'archives',
        name: 'adoptions-archives',
        component: () => import('./views/AdoptionsArchives.view.vue'),
      },
      {
        path: 'requests',
        name: 'adoptions-requests',
        component: () => import('./views/AdoptionsRequests.view.vue'),
      },
    ],
  },
];
