import type { ICON_MAP } from '../types';

const ICONS_MAP: ICON_MAP = {
  csv: 'pi pi-file-excel',
  doc: 'pi pi-file-word',
  docx: 'pi pi-file-word',
  gif: 'pi pi-image',
  jpeg: 'pi pi-image',
  jpg: 'pi pi-image',
  pdf: 'pi pi-file-pdf',
  png: 'pi pi-image',
  txt: 'pi pi-file',
  webp: 'pi pi-image',
  xls: 'pi pi-file-excel',
  xlsx: 'pi pi-file-excel',
};

export { ICONS_MAP };
