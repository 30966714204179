import { API } from '@Providers';

export default async function (data: any, animalId?: string) {
  const payload = { ...data };

  const endpoint = animalId
    ? `/api/shelter/animal/remove-animal-photo/${animalId}`
    : '/api/shelter/files/remove-file';

  return API({ endpoint, method: 'post', payload });
}
