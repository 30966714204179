import { computed, ref, watch } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { formatNumberToDate } from '@Helpers';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();
const isEditingMode = ref();

export default function () {
  const { getDetailsSchema, wasFormSubmitted } = useForm();

  const rawFields: RawField[] = [
    {
      label: 'Follow-Up Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Follow-Up By',
      name: 'by',
      required: false,
      type: 'staff',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Follow-Up Reason',
      name: 'followup_reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Follow-Up Notes',
      name: 'followup_notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      by: {
        settings: {
          show: isEditingMode.value,
        },
      },
      other_by: {
        settings: {
          show: false,
        },
      },
    })
  );

  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'by'));
  const byModel = computed(() => byField.value?.model);

  watch(byModel, () => showOtherVetField());

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.full_name === 'Other';

        field.settings = {
          ...field.settings,
          show: showField,
        };

        if (byModel?.value?.by !== 'Other') return (field.model = '');
      }
    });

    return true;
  }
  function setForm(sourceData?: any, isEditing: boolean = false) {
    isEditingMode.value = isEditing;

    const updatedFieldSchema = getDetailsSchema(rawFields, fieldsSettings.value);
    if (!sourceData) return (fieldsSchema.value = updatedFieldSchema);

    formError.value = null;
    wasFormSubmitted.value = false;

    updatedFieldSchema.forEach((field) => {
      const match = sourceData[field.name];

      // prettier-ignore
      switch (field.name) {
        case 'date': return setDateField(field, match)
        case 'followup_reason': return setReasonField(field, sourceData)
        case 'followup_notes': return setNotesField(field, sourceData)
        case 'other_by': return setOtherByField(field, sourceData);
        default: setMatchField(field, match);
      }
    });

    fieldsSchema.value = updatedFieldSchema;
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
  }

  function setOtherByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    field.model = sourceData.by;
    field.value = sourceData.by;
  }

  function setReasonField(field: any, sourceData: any) {
    field.model = sourceData.reason;
    field.value = sourceData.reason;
  }

  function setNotesField(field: any, sourceData: any) {
    field.model = sourceData.notes;
    field.value = sourceData.notes;
  }

  function setDateField(field: any, match: any) {
    const date = match.$date.$numberLong;

    field.model = formatNumberToDate(date, false, false, true) || new Date();
    field.value = formatNumberToDate(date, false, false, true) || new Date();
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
