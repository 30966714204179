import { computed } from 'vue';
import {
  type ApiSystemFilter,
  type FiltersProps,
  type SystemFilter,
  FILTER_TYPES,
  SYSTEM_FILTERS,
} from '../types';
import useSpeciesAndBreeds from './useSpeciesAndBreeds';
import useActiveFilters from './useActiveFilters';
import { states } from '../constants';

const { activeFilters } = useActiveFilters();
const { orderSpeciesAnBreed, setSpecies } = useSpeciesAndBreeds();

export default function () {
  const { DROPDOWN, MULTISELECT, SELECT_INPUT_NUMBER } = FILTER_TYPES;

  const {
    intake_type,
    adopter_status,
    age,
    altered,
    altered_in_care,
    animals_impacted,
    animal_status,
    application,
    breed,
    call_type,
    categories,
    cause_of_death,
    color_pattern,
    days_lost,
    days_of_support,
    declawed,
    diagnostic,
    euthanasia_reason,
    foster_status,
    foster_status_report,
    frequency,
    gender,
    good_with_dogs,
    good_with_cats,
    groups,
    house_trained,
    hold_status,
    jurisdiction,
    kennel,
    loc,
    lof,
    los,
    location,
    location_status,
    manufacturer,
    medication,
    nof,
    nop,
    nor,
    officers,
    outcome_asilomar,
    outcome_subtype,
    outcome_type,
    payment_types,
    payment_type_1,
    payment_type_2,
    primary_color_map,
    priority,
    product,
    registration_status,
    registration_status_reports,
    reintake,
    resolution,
    return_reason,
    route,
    seizure_reason,
    service_type,
    size_group,
    source,
    species,
    status,
    staff,
    state,
    surgery,
    surrender_reason,
    tags,
    vaccine,
    vaccine_type,
    vouchers,
    voucher_status,
  } = SYSTEM_FILTERS;

  const systemFilters = (): SystemFilter => {
    return {
      [intake_type as string]: {
        name: 'intake_type',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.intakeType',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [adopter_status]: {
        name: 'adopter_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.options.submitted', value: 'submitted' },
            { name: 'filters.options.pending', value: 'pending' },
            { name: 'filters.options.approved', value: 'approved' },
            { name: 'filters.options.canceled', value: 'canceled' },
            { name: 'filters.options.declined', value: 'declined' },
            { name: 'filters.options.doNotAdopt', value: 'do not adopt' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.status',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [age]: {
        name: 'age',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.age',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [altered]: {
        name: 'altered',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.altered',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [altered_in_care]: {
        name: 'altered_in_care',
        model: '',
        type: DROPDOWN,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.alteredInCare',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [animals_impacted]: {
        name: 'animals_impacted',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.animalsImpacted',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [animal_status]: {
        name: 'animal_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.animalStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [application]: {
        name: 'form_title',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.applicationTitle',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [breed]: {
        name: 'breed',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        state: {
          disabled: true,
        },
        text: {
          hint: 'filters.hint.breed',
          label: 'filters.label.breed',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [call_type]: {
        name: 'call_type',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.callType',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [categories]: {
        name: 'donation_category',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.donationCategory',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [cause_of_death]: {
        name: 'cause_of_death',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.causeOfDeath',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [color_pattern]: {
        name: 'color_pattern',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.colorPattern',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [days_lost]: {
        // NOTE :: check the duplicate 'los' name on the backend
        // days_lost = los
        name: 'los',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.daysLost',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,
      // NOTE :: check the duplicate 'los' name on the backend
      // days_of_support = los
      [days_of_support]: {
        name: 'los',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.daysOfSupport',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [declawed]: {
        name: 'declawed',
        model: null,
        type: DROPDOWN,
        settings: {
          options: [
            { name: 'filters.yes', value: 'yes' },
            { name: 'filters.no', value: 'no' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.declawed',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [diagnostic]: {
        name: 'template_name',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.diagnostic',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [euthanasia_reason]: {
        name: 'euthanasia_reason',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.euthanasiaReason',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [foster_status]: {
        // NOTE :: foster_status = status
        name: 'foster_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.options.available', value: 'available' },
            { name: 'filters.options.unavailable', value: 'unavailable' },
            { name: 'filters.options.doNotFoster', value: 'do not foster' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.fosterStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [foster_status_report]: {
        // NOTE :: foster_status = status
        name: 'status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.active', value: 'active' },
            { name: 'filters.ended', value: 'ended' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.fosterStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [frequency]: {
        name: 'frequency',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.frequency',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [gender]: {
        name: 'gender',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.gender',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [good_with_dogs]: {
        name: 'good_with_dogs',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.goodWithDogs',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [good_with_cats]: {
        name: 'good_with_cats',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.goodWithCats',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [groups]: {
        name: 'groups',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.options.volunteer', value: 'volunteer' },
            { name: 'filters.options.donor', value: 'donor' },
            { name: 'filters.options.foster', value: 'foster' },
            { name: 'filters.options.adopter', value: 'adopter' },
            { name: 'filters.options.owner', value: 'owner' },
            { name: 'filters.options.driver', value: 'driver' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.groups',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [house_trained]: {
        name: 'house_trained',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.houseTrained',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [hold_status]: {
        name: 'hold_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.holdStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [jurisdiction]: {
        name: 'jurisdiction',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.jurisdiction',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [kennel]: {
        name: 'kennel',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.kennelId',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [loc]: {
        name: 'loc',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.loc',
          label: 'filters.label.loc',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [lof]: {
        name: 'lof',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.lof',
          label: 'filters.label.lof',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [los]: {
        name: 'los',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.los',
          label: 'filters.label.los',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [location]: {
        name: 'location',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.locationStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [location_status]: {
        name: 'location_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.locationStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [manufacturer]: {
        name: 'manufacturer',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.manufacturer',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [medication]: {
        // NOTE: medication = template_name in reports > daily medications
        name: 'template_name',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.medication',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [nof]: {
        name: 'nof',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.nof',
          label: 'filters.label.nof',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [nop]: {
        name: 'nop',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.nop',
          label: 'filters.label.nop',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [nor]: {
        name: 'nor',
        model: '',
        type: SELECT_INPUT_NUMBER,
        settings: {
          options: [
            { name: 'filters.greater', value: 'greater' },
            { name: 'filters.less', value: 'less' },
            { name: 'filters.equal', value: 'equals' },
          ],
        },
        text: {
          hint: 'filters.hint.nor',
          label: 'filters.label.nor',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [officers]: {
        name: 'assigned_full_name',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.assignedFullName',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [outcome_asilomar]: {
        name: 'outcome_asilomar',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.outcomeAsilomar',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [outcome_subtype]: {
        name: 'outcome_subtype',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.outcomeSubtype',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [outcome_type]: {
        name: 'outcome_type',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.outcomeType',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [payment_types]: {
        name: 'payment_types',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.paymentType',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [payment_type_1]: {
        name: 'payment_type_1',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.paymentTypeOne',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [payment_type_2]: {
        name: 'payment_type_2',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.paymentTypeTwo',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [primary_color_map]: {
        name: 'primary_color_map',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.primaryColorMap',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [priority]: {
        name: 'priority',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.priority',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [product]: {
        name: 'product',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.product',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [registration_status]: {
        name: 'registration_status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.registrationStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [registration_status_reports]: {
        // NOTE registration_status = status in reports > microship
        name: 'status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'Ok', value: 'ok' },
            { name: 'filter.error', value: 'error' },
            { name: 'filter.unknown', value: 'unknown' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.registrationStatus',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [reintake]: {
        name: 'reintake',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [{ name: 'reintake', value: 'reintake' }],
        },
        text: {
          hint: '',
          label: 'filters.label.reintake',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [resolution]: {
        name: 'resolution',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.resolution',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [return_reason]: {
        name: 'return_reason',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.returnReason',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [route]: {
        // NOTE: route = AS in reports > daily medications
        name: 'route',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.route',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [seizure_reason]: {
        name: 'seizure_reason',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.seizureReason',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [service_type]: {
        name: 'service',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.service',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [size_group]: {
        name: 'size',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.size',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [source]: {
        name: 'contact_source',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.contactSource',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [species]: {
        name: 'species',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.species',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [status]: {
        name: 'status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.options.submitted', value: 'submitted' },
            { name: 'filters.options.pending', value: 'pending' },
            { name: 'filters.options.approved', value: 'approved' },
            { name: 'filters.options.canceled', value: 'canceled' },
            { name: 'filters.options.declined', value: 'declined' },
            { name: 'filters.options.doNotAdopt', value: 'do not adopt' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.status',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [staff]: {
        name: 'staff',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.staff',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [state]: {
        name: 'state',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.state',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [surgery]: {
        // NOTE :: surgery = template_name in reports > daily vaccinations
        name: 'template_name',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.surgery',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [surrender_reason]: {
        name: 'surrender_reason',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.surrenderReason',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [tags]: {
        name: 'tags',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.tags',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [vaccine]: {
        // vaccine = template_name in reports > daily vaccinations
        name: 'template_name',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.vaccine',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [vaccine_type]: {
        name: 'vaccine_type',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.vaccineType',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [vouchers]: {
        name: 'title',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [],
        },
        text: {
          hint: '',
          label: 'filters.label.vouchersTitle',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,

      [voucher_status]: {
        name: 'status',
        model: [],
        type: MULTISELECT,
        settings: {
          options: [
            { name: 'filters.options.active', value: 'active' },
            { name: 'filters.options.closed', value: 'closed' },
            { name: 'filters.options.expired', value: 'expired' },
            { name: 'filters.options.deleted', value: 'deleted' },
          ],
        },
        text: {
          hint: '',
          label: 'filters.label.status',
          placeholder: 'filters.selectAnOption',
        },
      } as FiltersProps,
    };
  };

  const filtersPayload = computed(() => {
    let specifics = '';
    let filterObj = '';
    const SPECIFIC_FILTERS = ['los', 'lof', 'loc', 'nop', 'nof', 'nor'];

    activeFilters.value.forEach(({ name, model, type, settings }) => {
      const sanitizedModel = _getFilterModel(model, type, settings.hasComposedValues);
      if (!sanitizedModel) return;

      if (SPECIFIC_FILTERS.includes(name)) {
        const modelWithoutQuotes =
          typeof sanitizedModel === 'string'
            ? sanitizedModel.replace(/^"(.*)"$/, '$1')
            : sanitizedModel;

        const isFirstFilter = specifics.length ? '&' : '';
        specifics += `${isFirstFilter}${name}=${modelWithoutQuotes}`;
        return;
      }

      filterObj += `"${name}":${sanitizedModel},`;
    });

    return {
      specifics,
      filterObj: filterObj.slice(0, -1),
    };
  });

  function _getFilterModel(model: any, type: FILTER_TYPES, hasComposedValues?: boolean) {
    if (model === null) return false;

    const isArrayModel = Array.isArray(model);

    if (hasComposedValues && isArrayModel) {
      if (!model.length) return false;

      const selectedValues = JSON.stringify(model.map((filter: any) => filter.value));
      return encodeURIComponent(selectedValues);
    }

    if (type === FILTER_TYPES.MULTISELECT && isArrayModel) {
      return model.length ? `[${model.map(({ value }) => `"${value}"`)}]` : false;
    }

    if (type === FILTER_TYPES.DROPDOWN) {
      return Array.isArray(model) || typeof model === 'string' ? false : `"${model.name}"`;
    }

    return model ? `"${model}"` : false;
  }

  function clearFilters() {
    const { DROPDOWN, MULTISELECT, SELECT_INPUT_NUMBER } = FILTER_TYPES;

    const stringModels = [SELECT_INPUT_NUMBER];
    const arrayModels = [MULTISELECT];
    const nullableModels = [DROPDOWN];

    activeFilters.value.forEach((filter) => {
      if (stringModels.includes(filter.type)) filter.model = '';
      if (arrayModels.includes(filter.type)) filter.model = [];
      if (nullableModels.includes(filter.type)) filter.model = null;
    });

    return true;
  }

  function getDynamicFilters(fields: any) {
    const NOT_FILTERS = [
      'address1',
      'address2',
      'birthday',
      'city',
      'color',
      'date',
      'country',
      'email',
      'file',
      'google-address',
      'microchip',
      'partner_name',
      'phone',
      'photo',
      'state',
      'text',
      'textarea',
      'zip',
    ];

    let hasBreedsFilter = false;

    const pageFilters: FiltersProps[] = fields
      ?.map((field: any) => {
        if (NOT_FILTERS.includes(field.type)) return false;

        const isValidFilter = _isValidFilter(field);
        if (!isValidFilter) return false;

        const isMappedFilter = (SYSTEM_FILTERS as any)[field.name];
        if (!isMappedFilter) return _getUnmappedFilter(field);

        const matchFilter = systemFilters()[field.name];
        if (!matchFilter) return _getUnmappedFilter(field);

        const isSpeciesFilter = matchFilter.name === SYSTEM_FILTERS.species;
        if (isSpeciesFilter) {
          setSpecies(field.values, matchFilter);
          return matchFilter;
        }

        const isBreedFilter = field.name === SYSTEM_FILTERS.breed;
        if (isBreedFilter) hasBreedsFilter = true;

        const isComposedFilter = field.hasComposedValues;
        if (isComposedFilter) matchFilter.settings.hasComposedValues = true;

        // Common filter
        matchFilter.settings.options = field.values.map((option: any) => ({
          name: isComposedFilter ? option.value : option,
          value: option,
        }));

        return matchFilter;
      })
      .filter((item: any) => item);

    const uniquePageFilters = [...new Set(pageFilters)];

    return hasBreedsFilter ? orderSpeciesAnBreed(uniquePageFilters) : uniquePageFilters;
  }

  function _isValidFilter(field: any) {
    return field?.values && Array.isArray(field.values) && field.values.length;
  }

  function _getUnmappedFilter(field: any) {
    return {
      name: field.name,
      model: [],
      type: MULTISELECT,
      settings: {
        options: field.values.map((field: string) => ({ name: field, value: field })),
      },
      text: {
        hint: '',
        label: field.label,
        placeholder: 'filters.selectAnOption',
      },
    };
  }

  function getStaticFilters(defaultPageFiltersKeys: SYSTEM_FILTERS[]) {
    const matchFilter = defaultPageFiltersKeys.map((filter) => systemFilters()[filter]);

    // TODO :: Catch the state options based on user's country
    const hasStatesFilter = matchFilter.some((field: any) => field.name === SYSTEM_FILTERS.state);
    if (hasStatesFilter) {
      return matchFilter.map((field: any) => {
        if (field.name === SYSTEM_FILTERS.state) {
          const options = states.unitedStates.map((state: string) => {
            return { name: state, value: state };
          });
          field.settings.options = options;
        }
        return field;
      });
    }

    return matchFilter;
  }

  function getActiveFilters(
    fields?: any,
    defaultPageFiltersKeys: SYSTEM_FILTERS[] = [],
    outsideFieldsFilters: ApiSystemFilter[] = []
  ) {
    const outsideFiledsFiltersNames = outsideFieldsFilters.map(({ name }) => name);
    const uniqueDynamicFilters = fields?.filter(
      (field: any) => !outsideFiledsFiltersNames.includes(field.name)
    );

    const defaultPageFilters = getStaticFilters(defaultPageFiltersKeys);
    const dynamicFilters = getDynamicFilters(uniqueDynamicFilters);
    const specificDynamicFilters = getDynamicFilters(outsideFieldsFilters);

    const filters = [...defaultPageFilters, ...dynamicFilters, ...specificDynamicFilters];

    // Set initial model value for each filter coming from localStorage
    activeFilters.value.forEach((filter) => {
      filters.forEach((field: any) => {
        if (field.name === filter.name) field.model = filter.model;
      });
    });

    return filters;
  }

  return {
    filtersPayload,
    systemFilters,
    SYSTEM_FILTERS,

    clearFilters,
    getDynamicFilters,
    getStaticFilters,
    getActiveFilters,
  };
}
