<script setup lang="ts">
import { computed, onMounted, nextTick, useTemplateRef } from 'vue';
import type { FieldSchema } from '../../../types';
import { FILTER_MESSAGES, EMPTY_MESSAGES } from '@Constants';
import Dropdown from 'primevue/dropdown';

const emits = defineEmits<{
  onDropdownSelectValue: [];
}>();

const props = withDefaults(
  defineProps<{
    error?: string | boolean;
    disabled?: boolean;
    field?: FieldSchema;
    isInlineEditing?: boolean;
    optionLabel?: string;
    optionValue?: string;
    options?: any[];
    placeholder?: string;
  }>(),
  {
    isInlineEditing: false,
    placeholder: 'components.forms.placeholder.select',
  }
);

const model = defineModel();

const inlineComponentRef = useTemplateRef<InstanceType<typeof Dropdown> | null>(
  'inlineComponentRef'
);

const settings = computed(() => props?.field?.settings);
const disabled = computed(() => props?.field?.state.disabled || props?.disabled);
const options = computed(() => {
  if (props?.field?.name === 'primary_color_map')
    return props?.field?.edit?.options?.map((color: any) => color.value);

  return props?.field?.edit?.options || props.options;
});

onMounted(() => checkEditType());

function checkEditType() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  nextTick(() => {
    if (!inlineComponentRef.value) return;
    inlineComponentRef.value.show();
  });
}

function onSelectValue() {
  props.isInlineEditing ? emits('onDropdownSelectValue') : null;
}
</script>

<template>
  <Dropdown
    v-bind="{ FILTER_MESSAGES, EMPTY_MESSAGES }"
    v-model="model"
    class="relative flex h-9 w-full justify-between"
    pt:item="text-sm"
    pt:filterInput="h-9 py-1 pl-3 text-sm"
    pt:emptymessage="text-sm"
    pt:item-label="overflow-hidden text-ellipsis max-w-[290px] sm:max-w-[550px] xl:max-w-[620px]"
    ref="inlineComponentRef"
    :invalid="!!error"
    :disabled
    :options
    :optionLabel="field?.edit?.label || optionLabel"
    :optionValue="field?.edit?.value || optionValue"
    :placeholder="$t(settings?.placeholder || placeholder)"
    :pt:trigger:class="{ 'text-red-600': !!error }"
    :emptyFilterMessage="$t('components.emptyFilterMessage.dropdown')"
    :emptyMessage="$t('components.emptyMessage.dropdown')"
    :emptySelectionMessage="$t('components.emptySelectionMessage.dropdown')"
    :filter="options ? options.length > 5 : undefined"
    :filterMessage="$t('components.emptySelectionMessage.dropdown')"
    :filterResultMessage="$t('components.emptySelectionMessage.dropdown')"
    :pt:input:class="{
      'flex items-center text-sm text-ellipsis overflow-hidden max-w-[290px] sm:max-w-[550px] xl:max-w-[620px]': true,
      'af-field-error': !!error,
    }"
    :showClear="field?.settings?.showClear"
    @change="onSelectValue"
  />
</template>
