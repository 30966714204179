<script setup lang="ts">
import { computed } from 'vue';
import { API_URL } from '@Constants';
import { getDefaultDateFormat } from '@Helpers';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const receiptLink = computed(
  () => API_URL + '/shelter/cart/print-all-cart-documents/' + props.rowData?.cart_id?.$oid
);

const receiptLabel = computed(() => {
  if (props.field) return props.field;

  const rowDate = getDefaultDateFormat(props.rowData['created_at']).replace(/\//g, '');
  const animalId = props.rowData['animal_seq_id'];
  const citationId = props.rowData['citation_seq_id'];
  const donationId = props.rowData['donation_seq'];
  const productId = props.rowData['product_seq'];
  const serviceId = props.rowData['service_seq'];

  return `R${rowDate}-${animalId || citationId || donationId || productId || serviceId}`;
});
</script>

<template>
  <a
    v-if="rowData?.cart_id?.$oid && field"
    class="af-link"
    target="_blank"
    :href="receiptLink"
  >
    {{ receiptLabel }}
  </a>

  <p v-else>{{ receiptLabel || '-' }}</p>
</template>
