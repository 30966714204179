<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import type { FieldSchema } from '../../../types';

withDefaults(
  defineProps<{
    disabled?: boolean;
    error?: string;
    field?: FieldSchema;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.select',
  }
);

const { t } = useI18n();

const model = defineModel<{ name: string; type: string; value: string }[]>({
  default: [],
});
const options = computed(() => [t('common.positive'), t('common.negative'), t('common.unknown')]);
const optionsType = computed(() => [t('common.select'), t('common.text')]);

onMounted(() => initModel());

function initModel() {
  if (!model.value.length) {
    model.value = [{ name: t('components.forms.button.result1'), type: 'select', value: '' }];
    return;
  }
}

function updateModel() {
  model.value = model.value.map((entry) => ({
    ...entry,
    type: entry.type.toLowerCase(),
  }));
}

function onNewInput(type: 'select' | 'text') {
  model.value.push({
    name: t('components.forms.labels.result', { index: model.value.length + 1 }),
    type: type.toLowerCase(),
    value: '',
  });
}

function onRemoveInput(index: number) {
  if (model.value.length <= 1) return;

  model.value.splice(index, 1);
}
</script>

<template>
  <div class="mb-0.5 mt-2 flex text-xs font-medium">
    <p class="w-full max-w-[130px]">{{ $t('common.label') }}</p>

    <p class="w-full max-w-9">{{ $t('common.type') }}</p>

    <p>{{ $t('common.results') }}</p>
  </div>

  <div
    v-for="(entry, index) in model"
    :key="index"
    class="mb-2"
  >
    <InputGroup>
      <InputGroupAddon class="h-9 max-w-[130px] p-0">
        <AFieldInputText
          v-model="model[index].name"
          class="rounded-r-none !border-x-0 text-sm"
          placeholder="components.forms.placeholder.addLabel"
          :error
          :disabled="field?.state.disabled || disabled"
          :required="field?.settings?.required || required"
          @change="updateModel"
        />
      </InputGroupAddon>

      <Dropdown
        v-model="model[index].type"
        class="flex h-9 w-full max-w-9 items-center justify-center border-r-0 hover:bg-sky-100 active:bg-sky-200"
        pt:dropdownIcon="!hidden"
        pt:filterInput="h-7 py-1 text-sm"
        pt:input="hidden"
        pt:itemLabel="text-sm"
        pt:trigger="text-sky-500  !p-0 pi pi-pencil"
        pt:wrapper="!max-h-none"
        :error
        :disabled="field?.state.disabled || disabled"
        :options="optionsType"
        :placeholder="$t('common.type')"
        :required="field?.settings?.required || required"
        @change="updateModel"
      />

      <AFieldDropdown
        v-if="model[index].type?.toLowerCase() === 'select'"
        v-model="model[index].value"
        :error
        :options
        :disabled="field?.state.disabled || disabled"
        :placeholder="field?.settings?.placeholder || placeholder"
        :required="field?.settings?.required || required"
        @change="updateModel"
      />

      <AFieldInputText
        v-else
        v-model="model[index].value"
        :error
        :options
        :disabled="field?.state.disabled || disabled"
        :placeholder="$t('components.forms.placeholder.common')"
        :required="field?.settings?.required || required"
        @change="updateModel"
      />

      <InputGroupAddon class="h-9 p-0">
        <Button
          outlined
          class="h-fit w-fit border-none px-2 py-1"
          icon="pi pi-trash text-sm"
          severity="danger"
          :disabled="model.length === 1 || field?.state.disabled || disabled"
          @click="onRemoveInput(index)"
        />
      </InputGroupAddon>
    </InputGroup>
  </div>

  <Button
    v-if="!field?.settings.noButton"
    outlined
    class="af-button w-fit text-sm"
    icon="pi pi-plus text-sm"
    severity="info"
    :disabled="field?.state.disabled || disabled"
    :label="$t('components.forms.button.result')"
    @click="onNewInput('select')"
  />
</template>
