/*
 * NOTE
 * There is a mismatch between what our fields generate as models and what
 * the API expects. This helper formats specific cases to match the
 * payload expected by the API. There are situtations per form
 * and per type. Per type situations can be handled here at
 * getSanitizeFieldPerType() and per form situations at
 * the form passing a function with cases to
 * payloadSanitizerHelper().
 * */

import { formatDateToString } from '@Helpers';
import type { FieldPayload } from '../types';

function getSanitizeFieldPerType({ name, value, type }: FieldPayload) {
  if (!value) return;

  // prettier-ignore
  switch (type) {
    case 'date': { return { [name]: formatDateToString(value) }; }
    case 'date_iso_string': { return { [name]: value.toISOString() }; }
    case 'number': { return { [name]: value.toString() }; }
  }
}

function getSanitizedEntry(
  { name, type, value }: FieldPayload,
  fieldNamesForValidation?: string[],
  getSanitizedFieldPerName?: Function
) {
  if (getSanitizedFieldPerName && fieldNamesForValidation) {
    const NAME_VALIDATION = fieldNamesForValidation;
    const needsNameValidation = NAME_VALIDATION.includes(name);
    if (needsNameValidation) return getSanitizedFieldPerName({ name, value });
  }

  const TYPE_VALIDATION = ['date', 'date_iso_string', 'number'];
  const needsTypeValidation = type && TYPE_VALIDATION.includes(type);
  if (needsTypeValidation) return getSanitizeFieldPerType({ name, value, type });

  return { [name]: value };
}

export function payloadSanitizerHelper(
  fieldPayload: FieldPayload,
  fieldNamesForValidation: string[],
  getSanitizedFieldPerName: Function
) {
  return getSanitizedEntry(fieldPayload, fieldNamesForValidation, getSanitizedFieldPerName);
}
