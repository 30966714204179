<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import 'quill/dist/quill.snow.css';
import ImageResize from 'quill-resize-image';
import Quill from 'quill';

Quill.register('modules/ImageResize', ImageResize);

defineOptions({ inheritAttrs: false });

const emits = defineEmits<{
  'update:modelValue': [string];
}>();

const props = defineProps<{
  error?: string;
  editorHeight?: string;
  modelValue?: string;
  placeholder?: string;
}>();

let quill: Quill;

const _model = ref(props.modelValue || '');
const quillEditor = ref<HTMLElement | null>(null);

const input = useDebounce(_model, 400);

const editorBorder = computed(() => (props.error ? '1px solid red' : '1px solid #ccc'));
const editorHeight = computed(() => props.editorHeight || '100px');

onMounted(() => initQuill());

watch(input, () => emits('update:modelValue', _model.value));

function initQuill() {
  if (!quillEditor.value || !Quill) return;

  quill = new Quill(quillEditor.value, {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'link', 'image', { list: 'bullet' }, { list: 'ordered' }],
      ],
      ImageResize: {},
    },
    theme: 'snow',
    placeholder: props.placeholder,
  });

  quill.on('text-change', () => updateModel());

  quill.root.innerHTML = _model.value;
}

function updateModel() {
  const quillDefaulEmptyValue = '<p><br></p>';
  let newValue = quill.root.innerHTML;

  if (newValue === quillDefaulEmptyValue) newValue = '';

  _model.value = newValue;
}
</script>

<template>
  <div class="editor">
    <div
      ref="quillEditor"
      class="field-editor mt-2 w-full rounded rounded-t-none"
    />
  </div>
</template>

<style scoped>
.field-editor {
  border: v-bind(editorBorder);
  min-height: v-bind(editorHeight);
}
</style>

<style>
.editor .ql-toolbar.ql-snow {
  @apply rounded rounded-b-none;
  border: v-bind(editorBorder);
}
</style>
