import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: 'animal-profile/:id',
    name: 'animal-profile',
    component: () => import('./views/AnimalProfile.view.vue'),
    children: [
      {
        path: 'medical',
        name: 'animal-profile-medical',
        component: () => import('./views/ProfileMedical.view.vue'),
      },
      {
        path: 'files',
        name: 'animal-profile-files',
        component: () => import('./views/ProfileFiles.view.vue'),
      },
      {
        path: 'history',
        name: 'animal-profile-history',
        component: () => import('./views/ProfileHistory.view.vue'),
      },
      {
        path: 'change-log',
        name: 'animal-profile-change-log',
        component: () => import('./views/ProfileChangeLog.view.vue'),
      },
    ],
  },
  {
    path: '/shelter/animal/reintake/:id',
    name: 'reintake-form',
    component: () => import('./views/AnimalReintake.view.vue'),
  },
  {
    path: '/shelter/animal/lost-and-found/:id',
    name: 'lost-and-found-form',
    component: () => import('./views/AnimalLostAndFound.view.vue'),
  },
  {
    path: '/shelter/animal/rehome/:id',
    name: 'rehome-form',
    component: () => import('./views/AnimalRehome.view.vue'),
  },
  {
    path: '/shelter/animal-litter/admission/:id',
    name: 'litter-admission-form',
    component: () => import('./views/AnimalLitterIntake.view.vue'),
  },
  {
    path: '/shelter/animal-litter/lost-and-found/:id',
    name: 'litter-lost-and-found-form',
    component: () => import('./views/AnimalLitterLostAndFound.view.vue'),
  },
  {
    path: '/shelter/animal-litter/rehome/:id',
    name: 'litter-rehome-form',
    component: () => import('./views/AnimalLitterRehome.view.vue'),
  },
];
