import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'tnvr',
    name: 'tnvr',
    redirect: '/shelter/tnvr/calls',
    meta: {
      featureFlag: FEATURE_FLAGS.TNVR,
    },
    children: [
      {
        path: 'calls',
        name: 'tnvr-calls',
        component: () => import('./views/TnvrActiveCalls.view.vue'),
      },
      {
        path: 'map',
        name: 'tnvr-map',
        component: () => import('./views/TnvrMap.view.vue'),
      },
      {
        path: 'stats',
        name: 'tnvr-stats',
        component: () => import('./views/TnvrStats.view.vue'),
      },
      {
        path: 'completed-calls',
        name: 'tnvr-completed-calls',
        component: () => import('./views/TnvrCompletedCalls.view.vue'),
      },
      {
        path: 'add-call',
        name: 'tnvr-add-call',
        component: () => import('./views/TnvrAddCall.view.vue'),
      },
      {
        path: 'call/:id',
        name: 'tnvr-call',
        component: () => import('./views/TnvrCall.view.vue'),
      },
    ],
  },
];
