<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { allCountries } from 'country-region-data';
import type { FieldSchema } from '../../../types';
import { useSessionStore } from '@Stores';

export interface ModelType {
  country?: string;
  state?: string;
}

const props = defineProps<{
  error?: string;
  field?: FieldSchema;
  required?: boolean;
  species?: string;
}>();

const sessionStore = useSessionStore();

const model = defineModel<ModelType>();

const countryModel = ref();
const stateModel = ref();

const stateError = computed(() =>
  !stateModel.value ? props.field?.settings.stateError || props.error : ''
);
const sessionCountry = computed(() => sessionStore.shelterProfile.country);
const sessionState = computed(() => sessionStore.shelterProfile.state);
const countryOptions = computed(() => allCountries.map((country) => country[0]));
const stateOptions = computed(() => {
  const selectedCountry = allCountries.find((country) => country[0] === countryModel.value);
  return selectedCountry ? selectedCountry[2].map((state) => state[0]) : [];
});

onMounted(() => setCountryAndStateModel());

watch(model, () => setCountryAndStateModel());

function setCountryAndStateModel() {
  if (!model.value) {
    countryModel.value = sessionCountry.value;
    stateModel.value = sessionState.value;
    model.value = { country: countryModel.value, state: stateModel.value };
    return;
  }

  const selectedCountry = allCountries.find((country) => country[1] === model.value?.country);
  countryModel.value = selectedCountry ? selectedCountry[0] : model.value?.country;

  if (!selectedCountry) return (stateModel.value = model.value?.state);
  const selectedState = selectedCountry[2].find((state) => state[1] === model.value?.state);

  setTimeout(() => {
    stateModel.value = selectedState ? selectedState[0] : model.value?.state;
  }, 100);
}

function onCountryModelChange() {
  stateModel.value = '';
  setModel();
}

function setModel() {
  model.value = { country: countryModel.value, state: stateModel.value };
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <AFieldDropdown
      v-model="countryModel"
      :error="error"
      :options="countryOptions"
      @change="onCountryModelChange"
    />

    <div>
      <div :class="['mb-1 flex items-center gap-1', { 'af-field-error': !!error }]">
        <i
          v-if="!countryModel"
          v-tooltip.top="$t('components.forms.tooltip.state')"
          class="pi pi-info-circle text-xs"
        ></i>

        <AFieldLabel
          :required
          :error="stateError"
          :label="$t('common.state')"
        />
      </div>

      <AFieldDropdown
        v-model="stateModel"
        :disabled="!countryModel"
        :error="stateError"
        :options="stateOptions"
        @change="setModel"
      />
    </div>
  </div>
</template>
