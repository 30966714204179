import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { GenericField, OId } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useForm } from '@FormModule';
import { useAnimalProfileStore } from '@Stores';

interface MedicalStaff {
  [key: string]: any;
  first_name: string;
  last_name: string;
  _id: OId;
}

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { t } = useI18n();
  const { getDetailsSchema, wasFormSubmitted } = useForm();
  const { medicalStaff, diagnosticTemplates } = storeToRefs(useAnimalProfileStore());

  const rawFields: RawField[] = [
    {
      label: 'Diagnostic',
      name: 'template_name',
      required: true,
      type: 'select',
      value: '',
    },
    {
      label: 'Status',
      name: 'action',
      required: true,
      type: 'radio',
      value: '',
      values: [t('common.schedule'), t('common.complete')],
    },
    {
      label: 'Diagnostic Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Product',
      name: 'product',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: '',
      name: 'separator',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Results',
      name: 'results',
      required: true,
      type: 'result',
      value: '',
    },
    {
      label: '',
      name: 'separator',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Administered By',
      name: 'by',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Schedule a Follow-Up Check',
      name: 'followup_test',
      required: false,
      type: 'toggle',
      value: '',
    },
    {
      label: 'Time Until Follow-Up',
      name: 'next_test',
      required: true,
      type: 'timeframe',
      value: '',
    },
    {
      label: 'Follow-Up Results',
      name: 'followup_results',
      required: true,
      type: 'result',
      value: '',
    },
  ];

  const attendingVetOptions = computed(() =>
    medicalStaff.value.map((staff: MedicalStaff) => {
      const { first_name, last_name } = staff;
      return {
        ...staff,
        by: `${first_name} ${last_name}`,
      };
    })
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      template_name: {
        edit: {
          label: 'template_name',
          options: diagnosticTemplates.value.sort((a, b) =>
            a.template_name.localeCompare(b.template_name)
          ),
        },
      },
      by: {
        edit: {
          label: 'by',
          options: [...attendingVetOptions.value, { by: 'Other' }],
        },
        settings: {
          required: false,
          show: false,
        },
      },
      other_by: {
        settings: {
          show: false,
        },
      },
    })
  );

  const surgeryTemplateField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'template_name')
  );
  const followUpFields = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'followup_test')
  );
  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'by'));
  const byModel = computed(() => byField.value?.model);
  const actionField = computed(() => fieldsSchema.value.find(({ name }) => name === 'action'));
  const isAction = computed(() => actionField.value?.model);
  const isFollowUp = computed(() => followUpFields.value?.model);
  const surgeryTemplateModel = computed(() => surgeryTemplateField.value?.model);

  watch(isAction, () => showActionFields());
  watch(isFollowUp, () => showFollowUpFields());
  watch(surgeryTemplateModel, (newValue, oldValue) => fillFormWithTemplate(newValue, oldValue));
  watch(byModel, () => showOtherVetField());

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.by === 'Other';

        field.settings = {
          ...field.settings,
          show: showField,
        };

        if (byModel?.value?.by !== 'Other') return (field.model = '');
      }
    });

    return true;
  }

  function fillFormWithTemplate(newValue: any, oldValue: any) {
    if (!newValue || oldValue === newValue) return;

    updateSchemaModels(surgeryTemplateModel.value);
  }

  function showActionFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'by') {
        const showField = isAction.value === 'Complete';

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function showFollowUpFields() {
    fieldsSchema.value.forEach((field) => {
      const followUpFields = ['next_test', 'followup_results'];

      if (followUpFields.includes(field.name)) {
        const showField = isFollowUp.value;

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings.value);

    if (!surgeryTemplateModel.value) {
      fieldsSchema.value.forEach((field: any) => {
        if (field.name === 'template_name') return;

        field.state.disabled = true;
        field.settings.disabled = true;
      });
    }
  }

  function updateSchemaModels(sourceData?: any) {
    const toggleableFields = ['next_test', 'template_name'];

    formError.value = null;
    wasFormSubmitted.value = false;

    fieldsSchema.value.forEach((field) => {
      field.state.disabled = false;
      field.settings.disabled = false;

      const match = sourceData[field.name];
      if (toggleableFields.includes(field.name)) return;

      switch (field.name) {
        case 'action': {
          setMatchStatusField(field, sourceData);
          break;
        }
        case 'by': {
          setByField(field, sourceData);
          break;
        }
        case 'other_by': {
          setOtherByField(field, sourceData);
          break;
        }
        case 'followup_test': {
          setMatchField(field, match);
          showFollowUpFields();
          nextTick(() => {
            setMatchFollowUpField(sourceData);
          });
          break;
        }
        case 'date': {
          field.model = new Date();
          field.value = new Date();
          break;
        }
        default: {
          setMatchField(field, match);
        }
      }
    });
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
  }

  function setMatchStatusField(field: any, sourceData: any) {
    field.model = sourceData.status === 'active' ? 'Schedule' : 'Complete';
    field.value = sourceData.status === 'active' ? 'Schedule' : 'Complete';
    field.state.errors.clear();
  }

  function setByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    if (sourceData.by_id === 'other') {
      field.model = { by: 'Other' };
      field.value = { by: 'Other' };

      return;
    }

    const [first_name, last_name] = sourceData.by.split(' ');
    const by = { _id: sourceData.by_id, first_name, last_name, by: sourceData.by };

    field.model = by;
    field.value = by;
  }

  function setOtherByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    field.model = sourceData.by;
    field.value = sourceData.by;
  }

  function setMatchFollowUpField(sourceData: any) {
    const followUpFields = ['next_test'];

    fieldsSchema.value.forEach((field) => {
      if (!followUpFields.includes(field.name)) return;

      field.state.errors.clear();

      const timeFrame = {
        duration: sourceData.next_test,
        duration_unit: sourceData.next_test_unit,
      };

      switch (field.name) {
        case 'next_test': {
          field.model = timeFrame;
          field.value = timeFrame;
          break;
        }
      }
    });
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
