<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { formatPhoneNumber } from '@Helpers';
import type { FieldSchema } from '../../../types';
import { checkDuplicatePersonService } from '../../../services';
import useMFieldContact from './useMFieldContact';

interface PersonResult {
  _id: { $oid: string };
  addresses: string;
  full_name: string;
  id: string;
}

const props = withDefaults(
  defineProps<{
    error?: string;
    field: FieldSchema;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const { contactData, personId, showPhoneCard, showEmailCard } = useMFieldContact();

const model = defineModel();
const phoneResults = ref<PersonResult[]>([]);
const emailResults = ref<PersonResult[]>([]);
const phoneFocused = ref(false);
const emailFocused = ref(false);

watch(model, (newValue) => checkForFieldFocus(newValue));

onMounted(() => resetPersonId());

function resetPersonId() {
  personId.value = '';
}

function checkForFieldFocus(newValue: any) {
  if (phoneFocused.value) return handleDuplicateCheck('phone', newValue);
  if (emailFocused.value) return handleDuplicateCheck('email', newValue);
}

function handleDuplicateCheck(field: string, value: string) {
  if (!value) return;

  const PAYLOAD = {
    entity: 'people',
    field,
    value: field === 'phone' ? formatPhoneNumber(value) : value,
  };

  if (field === 'phone') showPhoneCard.value = false;
  if (field === 'email') showEmailCard.value = false;

  checkDuplicatePersonService(PAYLOAD).then((result: any) => {
    if (!result.length) return;

    if (field === 'phone') {
      phoneResults.value = result;
      showPhoneCard.value = true;
      return;
    }

    if (field === 'email') {
      emailResults.value = result;
      showEmailCard.value = true;
      return;
    }
  });
}

function setPersonData(field: string, data: PersonResult) {
  showPhoneCard.value = false;
  showEmailCard.value = false;

  contactData.value = data;
  personId.value = data._id.$oid;
}
</script>

<template>
  <div>
    <AFieldPhone
      v-if="props.field.name === 'phone'"
      aria-describedby="person-help"
      v-model="model"
      :error
      :field
      :required
      @blur="phoneFocused = false"
      @focus="phoneFocused = true"
    />

    <AFieldEmail
      v-else-if="props.field.name === 'email'"
      aria-describedby="person-help"
      v-model="model"
      :error
      :field
      :required
      @blur="emailFocused = false"
      @focus="emailFocused = true"
    />

    <AFieldPersonCard
      v-if="showPhoneCard"
      :results="phoneResults"
      :mode="field.settings.mode"
      @onUsePerson="(data: any) => setPersonData(field.name, data)"
    />

    <AFieldPersonCard
      v-else-if="showEmailCard"
      :results="emailResults"
      :mode="field.settings.mode"
      @onUsePerson="(data: any) => setPersonData(field.name, data)"
    />
  </div>
</template>
