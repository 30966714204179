<script setup lang="ts">
import { ref } from 'vue';
import useDataTable from '../../../composables/useDataTable';

const { rows, selectedRows } = useDataTable();

const selectAll = ref(false);

function onSelectAllRows() {
  !selectAll.value ? (selectedRows.value = []) : (selectedRows.value = rows.value);
}
</script>

<template>
  <Checkbox
    v-model="selectAll"
    class="grid-item grid-item-header"
    inputId="allRowSelector"
    name="allRowSelector"
    :binary="true"
    @change="onSelectAllRows"
  />
</template>
