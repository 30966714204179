<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { FieldSchema, FieldSettings } from '../../../types';
import { ADOPT_URL, FORM_DEBOUNCE } from '@Constants';
import { useForm } from '../../../composables';

const emits = defineEmits<{
  onFormSubmit: [];
  onFieldUpdate: [FieldSchema];
}>();

const props = withDefaults(
  defineProps<{
    editRuleByPermission?: boolean;
    formError?: string | boolean;
    granularValidation?: boolean;
    hasInlineEdit?: boolean;
    hasRenderMode?: boolean;
    hybridEditMode?: boolean;
    isInline?: boolean;
    isOnEditMode?: boolean;
    isOnODetails?: boolean;
    loading?: boolean;
    loadingTemplate?: number;
    schema: FieldSchema[];
  }>(),
  {
    editRuleByPermission: true,
    granularValidation: true,
    isOnODetails: false,
  }
);

const { granularFieldValidation } = useForm();

let inlineEditOriginalModel: any | undefined = undefined;

const fieldOnInlineEdit = ref('');

const isInlineEditing = defineModel('isInlineEditing', { default: false });
const hasErrors = computed(() => props.schema.some((field) => field.state.errors.size));

watch(hasErrors, () => consoleFieldErrors()); // NOTE: This is a debug for Gerald, please do not remove it
watch(fieldOnInlineEdit, () => (isInlineEditing.value = !!fieldOnInlineEdit.value));

const errorMessage = computed(() => {
  const hasSpecificError = typeof props.formError === 'string' && !!props.formError;

  if (!hasErrors.value && !hasSpecificError) return;

  const isDefaultMessage = props.formError && typeof props.formError === 'boolean';
  if (isDefaultMessage) return 'components.forms.errors.reviewForm';

  return props.formError || '';
});

function consoleFieldErrors() {
  if (ADOPT_URL) return;
  if (!props.schema.filter((field) => field.state.errors.size)) return;

  props.schema.filter((field) => field.state.errors.size).forEach((field) => console.error(field));
}

function onSubmitForm() {
  setTimeout(() => {
    emits('onFormSubmit');
  }, FORM_DEBOUNCE);
}

// NOTE : Returns true so we can use short circuit in the template then avoidind aa extra event emit
function onEditFieldCancel(): true {
  fieldOnInlineEdit.value = '';
  return true;
}

function onEditFieldClick(name: string, model: any, settings: FieldSettings) {
  if (!props.hasInlineEdit) return;
  if (!settings.editable) return;
  if (!props.editRuleByPermission) return;

  if (fieldOnInlineEdit.value) {
    props.schema.forEach((field) => {
      if (field.name !== fieldOnInlineEdit.value) return;

      field.model = inlineEditOriginalModel;
    });
  }

  fieldOnInlineEdit.value = name;
  inlineEditOriginalModel = model;
}

function onEditFieldRequest(field: FieldSchema) {
  emits('onFieldUpdate', field);
  fieldOnInlineEdit.value = '';
  inlineEditOriginalModel = undefined;
}
</script>

<template>
  <AFormRenderSkeleton
    v-if="loading"
    :loadingTemplate
  />

  <form
    v-else
    @submit.prevent="onSubmitForm"
  >
    <OFieldset
      v-for="(field, index) in schema"
      v-model="field.model"
      :editRuleByPermission
      :field
      :fieldOnInlineEdit
      :hasInlineEdit
      :hasRenderMode
      :hybridEditMode
      :isInline
      :isInlineEditing
      :isOnEditMode
      :isOnODetails
      :error="[...field.state.errors][0]"
      :key="index"
      @onModelUpdate="granularValidation && granularFieldValidation(field)"
      @onEditFieldCancel="onEditFieldCancel() && (field.model = inlineEditOriginalModel)"
      @onEditFieldClick="onEditFieldClick(field.name, field.model, field.settings)"
      @onEditFieldRequest="onEditFieldRequest(field)"
    />

    <Teleport
      v-if="errorMessage"
      defer
      to="#formErrorMessage"
    >
      <Message
        v-if="errorMessage"
        class="!mx-0 !mb-3 !mt-0 h-9"
        pt:text="text-xs"
        pt:wrapper="h-9"
        severity="error"
        :closable="false"
      >
        {{ $t(errorMessage) }}
      </Message>
    </Teleport>

    <slot />
  </form>
</template>
