import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'kennel-manager',
    name: 'kennel-manager',
    component: () => import('./views/KennelManager.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.KENNEL_MANAGER,
    },
  },
  {
    path: 'kennel-manager/admit-animal/site/:siteId/building/:buildingId/location/:locationId/kennel/:kennelId',
    name: 'kennel-manager-admission',
    component: () => import('./views/KennelManagerAdmissionForm.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.KENNEL_MANAGER,
    },
  },
];
