import { ref } from 'vue';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema } = useForm();

  const fieldsSettings: FieldInitialSettings = {
    phones: {
      model: [{ id: 1, contact: null, type: 'home', primary: false }],
      edit: {
        value: 'value',
        options: [
          { name: 'Mobile', value: 'mobile' },
          { name: 'Home', value: 'home' },
          { name: 'Work', value: 'work' },
          { name: 'Other', value: 'other' },
        ],
      },
      settings: {
        contactType: 'phone',
        type: 'contact_type',
      },
    },
    emails: {
      model: [{ id: 1, contact: null, type: 'home', primary: false }],
      edit: {
        value: 'value',
        options: [
          { name: 'Home', value: 'home' },
          { name: 'Work', value: 'work' },
          { name: 'Other', value: 'other' },
        ],
      },
      settings: {
        contactType: 'email',
        type: 'contact_type',
      },
    },
  };
  const rawFields: RawField[] = [
    {
      label: 'First Name',
      name: 'first_name',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Last Name',
      name: 'last_name',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Address 1',
      name: 'address1',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Address 2',
      name: 'address2',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'country',
      name: 'country',
      required: true,
      type: 'country',
      value: '',
    },
    {
      label: 'City',
      name: 'city',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Phones',
      name: 'phones',
      required: true,
      type: 'contact_type',
      value: '',
    },
    {
      label: 'Emails',
      name: 'emails',
      required: true,
      type: 'contact_type',
      value: '',
    },
  ];

  function setForm() {
    if (fieldsSchema.value.length) return;

    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings);
  }

  function updateSchemaModels(sourceData: any) {
    setForm();

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];
      const { country, state, zip, city, address1, address2 } = sourceData.addresses;

      switch (field.name) {
        case 'country': {
          field.model = {
            country,
            state,
          };
          field.value = {
            country,
            state,
          };
          break;
        }
        case 'zip': {
          field.model = zip;
          field.value = zip;
          break;
        }
        case 'address1': {
          field.model = address1;
          field.value = address1;
          break;
        }
        case 'address2': {
          field.model = address2;
          field.value = address2;
          break;
        }
        case 'city': {
          field.model = city;
          field.value = city;
          break;
        }
        case 'emails': {
          field.model = formatContacts(sourceData.emails);
          field.value = formatContacts(sourceData.emails);
          break;
        }
        case 'phones': {
          field.model = formatContacts(sourceData.phones);
          field.value = formatContacts(sourceData.phones);
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });
  }

  function formatContacts(contacts: any[]) {
    return contacts.map(({ email, phone, ...contactInfo }) => ({
      ...contactInfo,
      contact: email || phone,
    }));
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    resetFieldSchema,
    setForm,
    updateSchemaModels,
  };
}
