import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'visual-kennel',
    name: 'visual-kennel',
    component: () => import('./views/VisualKennel.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.VISUAL_KENNEL,
    },
    children: [
      {
        path: ':id',
        name: 'visual-kennel-id',
        component: () => import('./components/organisms/OKennelCard/OKennelCard.vue'),
      },
    ],
  },
  {
    path: 'visual-kennel/admit-animal/:id/:name',
    name: 'visual-kennel-admission',
    component: () => import('./views/VisualKennelAdmissionForm.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.VISUAL_KENNEL,
    },
  },
];
