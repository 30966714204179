<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { API } from '@Providers';
import { useAToast } from '@Atoms';
import { sortContactFields, useForm } from '@FormModule';
import { getSanitizedPayloadPeople } from '@PeopleModule';
import useCartStore from '../../../../store';
import { AddPeopleToCartService, CompleteOutcome } from '../../../../services';
import { CART_STEPS } from '../../../../types';
import { useCartSteps } from '../../../../composables';
import useFields from './useFields';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const { cartData, completeOutcomeRoute, isProduct, people, peopleForm, stepState, type } =
  storeToRefs(useCartStore());
const { outcomeCompletedCartReset } = useCartStore();

const { notify, TOAST_TYPES } = useAToast();
const { fieldsValidation } = useForm();
const {
  contactData,
  currentSchemaModels,
  fieldsSchema,
  formError,
  formId,
  formName,
  formType,
  personId,
  rawFields,
  setForm,
} = useFields();
const { currentStep, nextStep, previousStep } = useCartSteps();
const { push } = useRouter();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const loading = ref(false);

const nextStepButton = computed(() => ({
  label: nextStep.value.nextIsFinalStep ? 'common.complete' : 'common.nextStep',
  icon: nextStep.value.step ? nextStep.value.step.header.icon : '',
}));

onMounted(() => (currentStep.value = CART_STEPS.ADD_PEOPLE) && initPeopleForm());

// HELPERS
function initPeopleForm() {
  if (!peopleForm.value) return fetchPeopleForm();

  const { _id, form_type, form_name, fields } = peopleForm.value;
  formId.value = _id.$oid;
  formType.value = form_type;
  formName.value = form_name;

  rawFields.value = [...sortContactFields(fields)];

  setForm(people.value);
}

// SERVICES
async function fetchPeopleForm() {
  const endpoint = '/api/shelter/forms/get-forms/peopleForms/peopleFields';

  return API({ endpoint }).then((result: any) => {
    peopleForm.value = result[0];
    initPeopleForm();
  });
}

function onNextStep() {
  const hasErrors = fieldsValidation(fieldsSchema.value, currentSchemaModels.value);
  if (hasErrors) return (formError.value = true);

  formError.value = false;

  function getSpecificFields() {
    const already_registered = people.value?.already_registered || !!personId.value;
    const already_adopted =
      people.value?.already_adopted || contactData.value?.groups.includes('adopter');
    const email_index =
      people.value?.matching_email_index || contactData.value?.matching_email_index || null;
    const phone_index =
      people.value?.matching_phone_index || contactData.value?.matching_phone_index || null;

    // TODO - AF-440 Add phone type to phone field on add person form
    const phone_type = people.value?.phone_type || contactData.value?.phone_type || 'mobile';

    return {
      formId: formId.value,
      outcome_type: type.value,
      person_id: personId.value,

      already_adopted,
      already_registered,

      email_index,
      phone_index,
      phone_type,
    };
  }

  const initialFields = getSanitizedPayloadPeople(currentSchemaModels.value);
  const specificFields = getSpecificFields();

  const PAYLOAD = {
    ...initialFields,
    ...specificFields,
  };

  // NOTE: Removing updated_at. Not used in the backend.
  delete (PAYLOAD as Partial<typeof PAYLOAD>).updated_at;

  AddPeopleToCartService(PAYLOAD)
    .then((people: any) => {
      cartData.value.people = people;
      stepState.value![currentStep.value] = PAYLOAD;

      if (nextStep.value.nextIsFinalStep) return onCompleteOutcome();

      emits('onStepUpdate', nextStep.value.step!.name);
      loading.value = false;
    })
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onCompleteOutcome() {
  CompleteOutcome(cartData.value)
    .then(() => push(completeOutcomeRoute.value).then(() => outcomeCompletedCartReset()))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex max-w-[700px] flex-col gap-4">
    <FormTemplate
      :formError
      :schema="fieldsSchema"
    />

    <AFormError :formError />

    <div class="flex justify-between">
      <Button
        v-if="previousStep"
        outlined
        class="af-button af-button-success-outlined w-fit"
        icon="pi pi-arrow-left"
        :label="$t('common.back')"
        @click="emits('onStepUpdate', previousStep)"
      />

      <div class="flex w-full justify-end">
        <div class="ml-auto flex items-center gap-2">
          <Button
            v-if="isProduct"
            class="af-button ml-auto"
            iconPos="right"
            severity="secondary"
            :label="$t('components.forms.button.skip')"
            @click="emits('onStepUpdate', nextStep.step!.name)"
          />

          <Button
            class="af-button af-button-success"
            iconPos="right"
            :label="$t(nextStepButton.label)"
            @click="onNextStep"
          >
            <template #icon>
              <FaIcon
                v-if="nextStepButton.icon"
                class="mr-2"
                :icon="$icons[nextStepButton.icon]"
              />
            </template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
