enum MICROCHIP_ISSUER {
  PETWATCH24 = '24PetWatch',
  PETCHIP911 = '911PetChip & Free Pet Chip Registry',
  ACA_MARRS = 'ACA MARRS',
  AKC_REUNITE = 'AKC Reunite',
  AVID = 'AVID',
  BC_PET_REGISTRY = 'BC Pet Registry',
  BANFIELD = 'Banfield',
  BAYER_RESQ = 'Bayer resQ',
  BEKIND_PETFIND = 'BeKind PetFind',
  BUDDYID = 'BuddyID',
  DATAMARS = 'Datamars',
  EIDAP = 'EIDAP',
  FI = 'Fi',
  FOUND_ANIMALS = 'Found Animals',
  FURREKA = 'Furreka',
  HOMEAGAIN = 'HomeAgain',
  HOMEWARD_BOUND_PET = 'Homeward Bound Pet',
  IDETRAC_INC = 'Identrac Inc.',
  INFOPET = 'InfoPET',
  INTERNATIONAL_PET_REGISTRY = 'International Pet Registry',
  MICROCHIP_ID_SOLUTIONS = 'Microchip I.D. Solutions',
  MYPETSCHIP = 'MyPetsChip',
  NANOCHIP_ID_INC = 'Nanochip ID Inc.',
  NATIONAL_ANIMAL_IDENTIFICATION_CENTER = 'National Animal Identification Center',
  PEEVA = 'Peeva',
  PETKEY = 'PetKey',
  PETLINK = 'PetLink',
  PETSTABLISHED = 'Petstablished',
  PRIME_TRACKR = 'Prime Trackr',
  SAVE_THIS_LIFE = 'Save This Life',
  SMARTTAG_MICROCHIP = 'SmartTag Microchip',
  UPET = 'uPet',
}

export { MICROCHIP_ISSUER };
