<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { FieldSchema } from '../../../types';
import { DOSAGES } from '@AnimalModule';

export interface Model {
  doses: string | number;
  duration: number;
  duration_unit: string;
  frequency: keyof typeof DOSAGES;
  time1: any;
  time2: any;
  time3: any;
  time4: any;
}

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    error?: string;
    field?: FieldSchema;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const frequencyMap: Record<string, number> = {
  SID: 1,
  'SID - AM': 1,
  'SID - PM': 1,
  EOD: 1,
  WEEKLY: 1,
  'BI-WEEKLY': 1,
  MONTHLY: 1,
  BID: 2,
  TID: 3,
  QID: 4,
};

const model = defineModel<Model>();
const modelFrequency = ref();
const modelTime = ref();
const modelTime2 = ref();
const modelTime3 = ref();
const modelTime4 = ref();
const modelDuration = ref();
const modelDoses = ref();

const options = computed(() => Object.entries(DOSAGES).map(([label, value]) => ({ label, value })));
const showTime = computed(() => frequencyMap[modelFrequency?.value?.label] || 0);

watch(model, initModel, { deep: true });
watch([modelDoses, modelTime, modelTime2, modelTime3, modelTime4], setModel);
watch([modelFrequency, modelDuration], findTotalDose);

function initModel() {
  if (!model.value || !model.value.frequency || !model.value.duration) return resetFields();

  modelFrequency.value = {
    label: model.value.frequency,
    value: DOSAGES[model.value.frequency as keyof typeof DOSAGES],
  };
  modelTime.value = model.value.time1;
  modelTime2.value = model.value.time2;
  modelTime3.value = model.value.time3;
  modelTime4.value = model.value.time4;
  modelDuration.value = {
    duration: model.value.duration,
    duration_unit: model.value.duration_unit,
  };
}

function resetFields() {
  modelFrequency.value = new Set();
  modelTime.value = undefined;
  modelTime2.value = undefined;
  modelTime3.value = undefined;
  modelTime4.value = undefined;
  modelDuration.value = new Set();
  modelDoses.value = 0;
}

function setDurationValue(unity: string): number {
  return { days: 1, weeks: 7, months: 30, years: 365 }[unity] || 0;
}

function findTotalDose() {
  if (!modelDuration.value || !modelFrequency.value.value) return;

  const frequency = modelFrequency?.value?.value[1];
  const unity = modelDuration.value?.duration_unit;
  const unityValues = setDurationValue(unity);
  const quantity = modelDuration.value?.duration;

  modelDoses.value = Math.round(unityValues * frequency * quantity);
}

function setModel() {
  if (!modelDuration.value.duration || !modelFrequency.value.value)
    return (model.value = undefined);

  const { duration, duration_unity } = modelDuration.value;
  const noFrequencyAndDurationModels = !modelFrequency.value && !duration && !duration_unity;

  if (noFrequencyAndDurationModels) return (model.value = undefined);

  model.value = {
    frequency: modelFrequency.value.label,
    doses: modelDoses.value,
    duration: modelDuration.value.duration.toString(),
    duration_unit: modelDuration.value?.duration_unit,
    time1: modelTime?.value || new Date(),
    time2: showTime.value >= 2 ? modelTime2?.value : null,
    time3: showTime.value >= 3 ? modelTime3?.value : null,
    time4: showTime.value >= 4 ? modelTime4?.value : null,
  };
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <AFieldDropdown
      v-model="modelFrequency"
      optionLabel="label"
      :error
      :options
      :disabled="field?.state?.disabled || disabled"
      :placeholder="field?.settings.placeholder || placeholder"
      :required="field?.settings.required || required"
    />

    <div
      v-if="showTime >= 1"
      class="flex flex-col gap-1"
    >
      <AFieldLabel
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :label="$t('components.forms.labels.dosageTime', { number: 1 })"
        :required="field?.settings.required || required"
      />

      <MFieldDatePicker
        v-model="modelTime"
        fluid
        timeOnly
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :required="field?.settings.required || required"
      />
    </div>

    <div
      v-if="showTime >= 2"
      class="flex flex-col gap-1"
    >
      <AFieldLabel
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :label="$t('components.forms.labels.dosageTime', { number: 2 })"
        :required="field?.settings.required || required"
      />

      <MFieldDatePicker
        v-model="modelTime2"
        fluid
        timeOnly
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :required="field?.settings.required || required"
      />
    </div>

    <div
      v-if="showTime >= 3"
      class="flex flex-col gap-1"
    >
      <AFieldLabel
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :label="$t('components.forms.labels.dosageTime', { number: 3 })"
        :required="field?.settings.required || required"
      />

      <MFieldDatePicker
        v-model="modelTime3"
        fluid
        timeOnly
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :required="field?.settings.required || required"
      />
    </div>

    <div
      v-if="showTime >= 4"
      class="flex flex-col gap-1"
    >
      <AFieldLabel
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :label="$t('components.forms.labels.dosageTime', { number: 4 })"
        :required="field?.settings.required || required"
      />

      <MFieldDatePicker
        v-model="modelTime4"
        fluid
        timeOnly
        :error
        :field
        :required="field?.settings.required || required"
      />
    </div>

    <div class="flex flex-col gap-1">
      <AFieldLabel
        :disabled
        :error
        :field
        :required="field?.settings.required || required"
        :label="$t('components.forms.labels.duration')"
      />

      <MFieldTimeFrame
        v-model="modelDuration"
        :error
        :field
        :disabled="field?.state?.disabled || disabled"
        :required="field?.settings.required || required"
      />
    </div>

    <div
      v-if="modelDoses > 730"
      class="flex gap-1 text-sm font-semibold text-yellow-600"
    >
      <i class="pi pi-exclamation-triangle"></i>

      <p class="text-center">{{ $t('components.forms.errors.excededDose') }}</p>
    </div>

    <div
      v-else
      class="flex flex-wrap items-center gap-1"
    >
      <AFieldLabel
        :field
        :label="$t('components.forms.labels.total') + ':'"
      />

      <p>{{ modelDoses || '-' }}</p>
    </div>
  </div>
</template>
