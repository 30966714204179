<script setup lang="ts">
import type { Kennel } from '@/modules/Entities/Shelter/modules/KennelManager/types/kennel.types';
import { useSessionStore } from '@Stores';
import { useADialog } from '@Atoms';
import { getTruncatedText } from '@Helpers';
import { ROLES } from '@AuthModule';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const { can } = useSessionStore();

const { activeModal, activeModalProps, dialogVisibility } = useADialog();

function openModal() {
  activeModalProps.value = {
    ...props.settings.render.modalProps,
    rowData: props.rowData,
  };
  activeModal.value = props.settings.render.activeModal;
  dialogVisibility.value = true;
}

function filterKennel(kennel_id: string) {
  const kennel = props.settings.kennels.find((kennel: Kennel) => {
    return kennel._id === kennel_id;
  });
  return kennel?.label || '-';
}
</script>

<template>
  <div
    :class="[can(ROLES.EDIT_ANIMAL) ? 'cursor-pointer' : '']"
    @click="can(ROLES.EDIT_ANIMAL) ? openModal() : null"
  >
    <span
      v-if="!field"
      class="flex flex-col items-start gap-0.5"
    >
      -
    </span>

    <p
      v-else
      v-tooltip.top="filterKennel(field.$oid)"
      class="w-fit"
    >
      {{ getTruncatedText(filterKennel(field.$oid), 15) }}
    </p>
  </div>
</template>
