<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useDebounce } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useSessionStore } from '@Stores';
import type { FieldSchema } from '../../../types';

export interface Model {
  weight: string;
  unit: string;
}

defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
  placeholder?: string;
  required?: boolean;
}>();

const { shelterProfile } = storeToRefs(useSessionStore());

const { t } = useI18n();

const model = defineModel<Model | undefined>();
const modelNumber = ref();
const unit = ref();

const options = computed(() => [
  `${t('weight.units.g')}`,
  `${t('weight.units.kg')}`,
  `${t('weight.units.lbs')}`,
  `${t('weight.units.oz')}`,
]);

const input = useDebounce(modelNumber, 400);

onMounted(() => setModelOnStart());

watch(input, updateModel);
watch(model, setModelOnStart);

function setModelOnStart() {
  unit.value = shelterProfile.value?.weight_unit;

  if (!model.value) return;

  modelNumber.value = model.value.weight || 0;
  unit.value = model.value?.unit?.toLowerCase();
}

function updateModel() {
  if (modelNumber.value === undefined || !unit.value) return;

  if (modelNumber.value === 0 && unit.value) return (model.value = undefined);

  model.value = { weight: modelNumber.value, unit: unit.value };
}
</script>

<template>
  <div class="flex flex-col gap-3 sm:flex-row">
    <div class="w-full sm:!w-3/4">
      <AFieldNumber
        v-model="modelNumber"
        :error
        :required
        :disabled="field?.state?.disabled || disabled"
      />
    </div>

    <AFieldDropdown
      v-model="unit"
      class="w-full sm:!w-1/4"
      :error
      :options
      :placeholder
      :required
      :disabled="field?.state?.disabled || disabled"
      @change="updateModel"
    />
  </div>
</template>
