import type { RouteRecordRaw } from 'vue-router';
import { AdoptionRoutes } from './modules/Adoption';
import { AnimalProfileRoutes } from './modules/AnimalProfile';
import { AnimalRoutes } from './modules/Animal';
import { CartRoutes } from './modules/Cart';
import { CommunitySupportRoutes } from './modules/CommunitySupport';
import { FieldServiceRoutes } from './modules/FieldService';
import { FormsAndApplicationsRoutes } from './modules/FormsAndApplication';
import { FosterRoutes } from './modules/Foster';
import { InfoRoutes } from './modules/Info';
import { KennelManagerRoutes } from './modules/KennelManager';
import { LostAndFoundRoutes } from './modules/LostAndFound';
import { PartnerRoutes } from './modules/Partner';
import { PeopleRoutes } from './modules/People';
import { ProductsAndServicesRoutes } from './modules/ProductsAndServices';
import { RehomingRoutes } from './modules/Rehoming';
import { ReportRoutes } from './modules/Report';
import { SettingsRoutes } from './modules/Settings';
import { StaffRoutes } from './modules/Staff';
import { StatsRoutes } from './modules/Stats';
import { TnvrRoutes } from './modules/Tnvr';
import { TransportRoutes } from './modules/Transport';
import { VisualKennelRoutes } from './modules/VisualKennel';

export default <RouteRecordRaw[]>[
  {
    path: '/shelter',
    component: () => import('../../../layouts/TheMainLayout/TheMainLayout.vue'),
    redirect: () => '/shelter/animals/in-care',
    children: [
      ...AdoptionRoutes,
      ...AnimalProfileRoutes,
      ...AnimalRoutes,
      ...CartRoutes,
      ...CommunitySupportRoutes,
      ...FieldServiceRoutes,
      ...FormsAndApplicationsRoutes,
      ...FosterRoutes,
      ...InfoRoutes,
      ...KennelManagerRoutes,
      ...LostAndFoundRoutes,
      ...PartnerRoutes,
      ...PeopleRoutes,
      ...ProductsAndServicesRoutes,
      ...RehomingRoutes,
      ...ReportRoutes,
      ...SettingsRoutes,
      ...StaffRoutes,
      ...StatsRoutes,
      ...TnvrRoutes,
      ...TransportRoutes,
      ...VisualKennelRoutes,
      {
        path: '/shelter/animal-profile/:id', // TODO: move route to correct domain
        redirect: '/shelter/',
      },
      {
        path: '/shelter/people/profile/:id', // TODO: move route to correct domain
        redirect: '/shelter/',
      },
    ],
  },
];
