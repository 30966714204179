import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { capitalizeStr, formatDateToString } from '@Helpers';
import { useForm } from '@FormModule';
import { usePeopleStore } from '../stores';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();
const formId = ref('');
const formName = ref('');
const formType = ref('');

export default function () {
  const { donationsCategories } = storeToRefs(usePeopleStore());
  const { getDetailsSchema } = useForm();
  const { t } = useI18n();

  const fieldsSettings: FieldInitialSettings = {
    donation_category: {
      edit: {
        options: donationsCategories.value.value,
      },
    },
    payment_type: {
      edit: {
        options: [
          t('components.forms.options.cash'),
          t('components.forms.options.check'),
          t('components.forms.options.creditCard'),
          t('components.forms.options.other'),
        ],
      },
    },
  };

  const rawFields: RawField[] = [
    {
      label: 'Donation Date',
      name: 'donation_date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Donation Amount:',
      name: 'donation',
      required: true,
      type: 'money',
      value: '',
    },
    {
      label: 'Donation Category',
      name: 'donation_category',
      required: true,
      type: 'select',
      value: '',
    },
    {
      label: 'Payment Type',
      name: 'payment_type',
      required: true,
      type: 'select',
      value: '',
    },
  ];

  function setForm() {
    if (fieldsSchema.value.length) return;

    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings);
  }

  function updateSchemaModels(sourceData: any) {
    setForm();

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];
      if (!match) return;

      switch (field.name) {
        case 'donation_date': {
          field.model = formatDateToString(match);
          field.value = formatDateToString(match);
          break;
        }
        case 'payment_type': {
          field.model = capitalizeStr(match);
          field.value = capitalizeStr(match);
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    formId,
    formName,
    formType,
    setForm,
    resetFieldSchema,
    updateSchemaModels,
  };
}
