import type { GenericField } from '@Types';
import type { FieldPayload } from '@FormModule';
import type { People } from '@ShelterModule';
import { payloadSanitizerHelper } from '@FormModule';
import { Profile } from '@ShelterModule';

function getSanitizedFieldPerName({ name, value }: FieldPayload) {
  switch (name) {
    case 'country': {
      if (!value.country) return;

      return {
        country: value.country,
        state: value.state,
      };
    }
  }
}

export default function (fields: Set<GenericField>) {
  let payload: People = new Profile();

  const fieldNamesToBeSanitezed = ['country'];

  fields.forEach((field: GenericField) => {
    const entry = payloadSanitizerHelper(field, fieldNamesToBeSanitezed, getSanitizedFieldPerName);
    payload = { ...payload, ...entry };
  });

  return payload;
}
