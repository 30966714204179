const LOCALE = 'en-US';
const CURRENCY = 'USD';

function formatMoney(money: any) {
  const num = parseFloat(money);
  if (isNaN(num)) return undefined;

  return `${num.toFixed(2)}`;
}

function applyShelterCurrency(money?: string | number): string {
  if (money === undefined || money === null) {
    throw new Error(`Undefined money at applyShelterCurrency(): ${money}`);
  }

  const num = Number(money);
  if (isNaN(num)) {
    throw new Error(`Invalid money value: ${money}`);
  }

  return new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency: CURRENCY,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}

export { applyShelterCurrency, formatMoney };
