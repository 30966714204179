import { ref, watch } from 'vue';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useForm, useMFieldContact, setEmptyModelForFieldsWithDateType } from '@FormModule';

const { contactData, personId, showCard } = useMFieldContact();
const personType = ref();

export default function () {
  const { getDetailsSchema } = useForm();

  const currentSchemaModels = ref<Set<GenericField>>(new Set());
  const fieldsSchema = ref<FieldSchema[]>([]);
  const fieldsSettings = ref<FieldInitialSettings>({
    phone: {
      settings: {
        type: 'contact',
      },
    },
    email: {
      settings: {
        type: 'contact',
      },
    },
  });
  const formError = ref();
  const formId = ref('');
  const formName = ref('');
  const formType = ref('');
  const rawFields = ref<RawField[]>([]);

  watch(contactData, () => updateSchemaModels(contactData.value));

  function setForm(people: any) {
    setEmptyModelForFieldsWithDateType(rawFields, fieldsSettings);

    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);

    if (people) updateSchemaModels(people, true);
  }

  function updateSchemaModels(sourceData: any, hasPeople?: boolean) {
    fieldsSchema.value.forEach((field) => {
      if (hasPeople) {
        //prettier-ignore
        switch (field.name) {
          case 'country': return setCountryMatch(field)
          default: setMatch(field, sourceData[field.name])
        }
        return;
      }

      //prettier-ignore
      switch (field.name) {
        case 'address1': return setMatch(field, sourceData.addresses[0].address1)
        case 'address2': return setMatch(field, sourceData.addresses[0].address2)
        case 'city': return setMatch(field, sourceData.addresses[0].city)
        case 'email': return setMatch(field, sourceData.emails[0].email)
        case 'phone': return setMatch(field, sourceData?.phones[0].phone || sourceData.phone)
        case 'zip': return setMatch(field, sourceData.addresses[0].zip)
        case 'country': return setCountryMatch(field)
        default: setMatch(field, sourceData[field.name])
      }
    });

    function setCountryMatch(field: FieldSchema) {
      const country = sourceData.addresses ? sourceData.addresses[0].country : sourceData.country;
      const state = sourceData.addresses ? sourceData.addresses[0].state : sourceData.state;
      const payload = {
        country,
        state,
      };

      field.model = payload;
      field.value = payload;
    }

    function setMatch(field: FieldSchema, match: any) {
      field.model = match;
      field.value = match;
    }

    showCard.value = false;
  }

  return {
    contactData,
    currentSchemaModels,
    fieldsSchema,
    formError,
    formId,
    formName,
    formType,
    personId,
    personType,
    rawFields,

    setForm,
  };
}
