<script setup lang="ts">
import { computed } from 'vue';
import type { Field, FieldRender, FieldSettings } from '../../../types';

const props = defineProps<{
  field?: Field;
  model: any;
  render?: FieldRender;
  settings?: FieldSettings;
}>();

const alternativeLink = computed(() => props.model?._id || '');
const href = computed((): string => props?.settings?.href || props?.field?.settings.href || '');
const targetKey = computed(() => props?.field?.render?.targetKey || props?.render?.targetKey || []);
const currentModel = computed(() => props.model || props?.field?.model || '');
const notRouterLink = computed(
  () => props?.settings?.notRouterLink || props?.field?.settings.notRouterLink || false
);
const label = computed(() => {
  if (!currentModel.value || !targetKey.value.length) return '';

  return targetKey.value.reduce((acc, key) => acc?.[key], currentModel.value);
});
</script>

<template>
  <span
    v-if="!field"
    class="flex flex-col items-start gap-0.5 text-sm"
  >
    -
  </span>

  <!-- TODO :: AF-746 - Loom Explanation - We need to think on other solutions for this.
   This case is for updates on ODetails (Default way - shallow update). The href was not updating
   so we used the model._id but is a very specific case -->
  <RouterLink
    v-else-if="field?.settings?.isUpdatedDetailsLink && alternativeLink"
    class="af-link flex text-sm hover:underline"
    :to="`${field?.settings?.path}/${alternativeLink}`"
  >
    {{ label || model }}
  </RouterLink>

  <span
    v-else-if="
      field?.settings?.isUpdatedDetailsLink &&
      !alternativeLink &&
      field?.settings?.emptyStateMessage
    "
    class="flex flex-col items-start gap-0.5 text-sm italic text-gray-400"
  >
    {{ field?.settings?.emptyStateMessage }}
  </span>

  <a
    v-else-if="notRouterLink"
    class="af-link text-sm hover:underline"
    target="_blank"
    :href
  >
    {{ label || model }}
  </a>

  <span
    v-else-if="!href"
    class="flex flex-col items-start gap-0.5 text-sm"
  >
    {{ label || model }}
  </span>

  <RouterLink
    v-else
    class="af-link flex text-sm hover:underline"
    :to="href"
  >
    {{ label || model }}
  </RouterLink>
</template>
