<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import type { ProductAtCart } from '@ProductsAndServicesModule';
import { useAToast } from '@Atoms';
import { useCartSteps } from '../../../../composables';
import { CART_STEPS } from '../../../../types';
import { AddProductToOutcomeCartService } from '../../../../services';
import useCartStore from '../../../../store';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const { currentStep, previousStep, nextStep } = useCartSteps();
const { loading, cartData } = storeToRefs(useCartStore());

const { notify, TOAST_TYPES } = useAToast();

const nextStepButton = computed(() => ({
  label: nextStep.value.nextIsFinalStep ? 'common.complete' : 'common.nextStep',
  icon: nextStep.value.step ? nextStep.value.step.header.icon : '',
}));

onMounted(() => (currentStep.value = CART_STEPS.PRODUCTS_AND_SERVICES));

// EVENTS
function onNextStep() {
  emits('onStepUpdate', nextStep.value.step!.name);
}

async function onAddToCart(product: ProductAtCart) {
  loading.value = true;

  const TOAST_ERROR = {
    message: 'cart.toast.addItem.error.message',
    title: 'errors.generics.unknownIssue.title',
    type: TOAST_TYPES.ERROR,
  };

  const TOAST_SUCCESS = {
    title: 'cart.toast.addItem.success.title',
    type: TOAST_TYPES.SUCCESS,
  };

  const currentProduct = (
    product.type === 'product' ? cartData.value.products : cartData.value.services
  ).find((item: ProductAtCart) => item._id === product._id);
  const productsLength = currentProduct?.quantity;

  const sanitizedQuantity = productsLength
    ? Number(product.quantity) - productsLength
    : product.quantity;

  const PAYLOAD = {
    products: [
      {
        ...product,
        quantity: Number(product.quantity) === 0 ? 0 : sanitizedQuantity,
      },
    ],
  };

  await AddProductToOutcomeCartService(PAYLOAD)
    .then(() => notify(TOAST_SUCCESS) && useCartStore().fetchActiveCart())
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <main>
    <OProductAndServicesList @onAddToCart="onAddToCart" />
  </main>

  <footer class="flex-shrink">
    <div class="flex justify-between">
      <Button
        v-if="previousStep"
        outlined
        class="af-button af-button-success-outlined w-fit"
        icon="pi pi-arrow-left"
        :label="$t('common.back')"
        @click="emits('onStepUpdate', previousStep)"
      />

      <Button
        class="af-button af-button-success ml-auto"
        iconPos="right"
        :label="$t(nextStepButton.label)"
        @click="onNextStep"
      >
        <template #icon>
          <FaIcon
            v-if="nextStepButton.icon"
            class="mr-2"
            :icon="$icons[nextStepButton.icon]"
          />
        </template>
      </Button>
    </div>
  </footer>
</template>
