import { API } from '@Providers';

interface Payload {
  entity: string;
  field: string;
  value: any;
}

export default async function (payload: Payload) {
  const endpoint = '/api/shelter/forms/checkDuplicate';

  return API({ endpoint, forwardError: true, method: 'post', payload }).then(
    (result: any) => result
  );
}
