<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '../../../types';

const props = defineProps<{
  field: FieldSchema;
  model: any;
}>();

const targetKey = computed(() => props.field?.render?.targetKey || null);
const tags = computed(() => {
  const isArray = Array.isArray(props.model);
  if (isArray) return props.model;

  const singleTag = props.model?.label;

  return singleTag ? [props.model] : [];
});
</script>

<template>
  <div v-if="!tags.length">-</div>
  <Tag
    v-else
    v-for="(tag, index) in tags"
    :class="[
      'w-fit text-[10px] font-semibold',
      targetKey && tag[targetKey[1]] === '#F5F7F8' ? 'text-gray-700' : 'text-white',
    ]"
    :key="index"
    :value="targetKey ? tag[targetKey[0]] : '-'"
    :style="{
      'background-color': targetKey ? tag[targetKey[1]] : '#726EB1',
    }"
  />
</template>
