<script setup lang="ts">
import { computed } from 'vue';
import { ICONS } from '@Plugins';
import { type Toast, TOAST_TYPES } from './types';

const props = withDefaults(defineProps<Toast>(), {
  type: TOAST_TYPES.ERROR,
});

const emit = defineEmits<{
  onDismiss: [];
}>();

const toastSettings = computed(() => {
  const { CUSTOM, ERROR, INFO, SUCCESS, WARNING } = TOAST_TYPES;

  switch (props.type) {
    case SUCCESS:
      return {
        icon: ICONS.faCircleCheck,
        style: 'text-green-700',
        bgColor: 'bg-green-50',
      };
    case ERROR:
      return {
        icon: ICONS.faCircleXmark,
        style: 'text-red-700',
        bgColor: 'bg-red-50',
      };
    case INFO:
      return {
        icon: ICONS.faCircleInfo,
        style: 'text-blue-700',
        bgColor: 'bg-blue-50',
      };
    case WARNING:
      return {
        icon: ICONS.faTriangleExclamation,
        style: 'text-amber-700',
        bgColor: 'bg-amber-50',
      };
    case CUSTOM:
      return {
        icon: props.icon ?? ICONS.faCircleInfo,
        style: 'text-purple-700',
        bgColor: 'bg-purple-50',
      };
    default:
      return {
        icon: ICONS.faCircleInfo,
        style: 'text-blue-700',
        bgColor: 'bg-white',
      };
  }
});
</script>

<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-[9999] flex items-center px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex h-full w-full flex-col items-center space-y-4 sm:items-center">
      <Transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isOpen"
          :class="[
            'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5',
            toastSettings.bgColor,
          ]"
        >
          <div class="p-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <FaIcon
                  aria-hidden
                  :class="['size-5', toastSettings.style]"
                  :icon="toastSettings.icon"
                />
              </div>

              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium text-zinc-900">
                  {{ $t(title) }}
                </p>

                <p
                  v-if="message"
                  class="mt-1 text-sm text-zinc-500"
                >
                  {{ $t(message) }}
                </p>

                <div
                  v-if="hasDimissButton"
                  class="mt-3 flex space-x-7"
                >
                  <button
                    class="rounded-md text-sm font-medium text-zinc-600 transition-[color] duration-200 ease-in-out hover:text-zinc-400 focus:text-zinc-300 focus:outline-none"
                    type="button"
                    @click="emit('onDismiss')"
                  >
                    {{ $t('common.dismiss') }}
                  </button>
                </div>
              </div>

              <div class="ml-4 flex flex-shrink-0">
                <button
                  class="inline-flex cursor-pointer rounded-md text-gray-400 transition-[color] duration-200 ease-in-out hover:text-gray-500 focus:text-gray-600 focus:outline-none"
                  type="button"
                  @click="emit('onDismiss')"
                >
                  <span class="sr-only">Close</span>

                  <FaIcon
                    aria-hidden
                    class="size-4"
                    :icon="$icons.faTimes"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
