<script setup lang="ts">
// TODO: AF-694: Refactor the entire table
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useResizeObserver, useWindowSize } from '@vueuse/core';
import { useDownloadButton } from '@ShelterModule';
import type { CellUniqueIdenfier } from '../../../types';
import type { PageState } from 'primevue/paginator';
import { DATA_TABLE_COMPONENTS } from '../../../types';
import { DATA_TABLE_COLUMNS_ORDER } from '../../../constants';

import useDataTableFilters from '../../../composables/useDataTableFilters';
import useDataTable from '../../../composables/useDataTable';

import AActionLoginAs from '../../atoms/AActionLoginAs/AActionLoginAs.vue';
import AAddressCell from '../../atoms/AAddressCell/AAddressCell.vue';
import ABackgroundCell from '../../atoms/ABackgroundCell/ABackgroundCell.vue';
import ACaseStatusLocation from '../../atoms/ACaseStatusLocation/ACaseStatusLocation.vue';
import AComposedLink from '../../atoms/AComposedLink/AComposedLink.vue';
import ADefaultCell from '../../atoms/ADefaultCell/ADefaultCell.vue';
import AEmptyState from '../../atoms/AEmptyState/AEmptyState.vue';
import AFormatedCell from '../../atoms/AFormatedCell/AFormatedCell.vue';
import AHiddenCell from '../../atoms/AHiddenCell/AHiddenCell.vue';
import AHoldStatusCell from '../../atoms/AHoldStatusCell/AHoldStatusCell.vue';
import AImageCell from '../../atoms/AImageCell/AImageCell.vue';
import AImageLink from '../../molecules/MImageLink/MImageLink.vue';
import AInputNumber from '../../atoms/AInputNumber/AInputNumber.vue';
import AKennelModalCell from '../../atoms/AKennelModalCell/AKennelModalCell.vue';
import ALinkCell from '../../atoms/ALinkCell/ALinkCell.vue';
import ALoadingState from '../../atoms/ALoadingState/ALoadingState.vue';
import AOpenModalCell from '../../atoms/AOpenModalCell/AOpenModalCell.vue';
import ATagCell from '../../atoms/ATagCell/ATagCell.vue';
import ATextArea from '../../atoms/ATextArea/ATextArea.vue';
import AReceiptCell from '../../atoms/AReceiptCell/AReceiptCell.vue';
import MActionVouchers from '../../molecules/MActionVouchers/MActionVouchers.vue';

const emits = defineEmits<{
  onLoading: [state: boolean];
  onParamsUpdate: [];
  onFieldUpdate: [newValue: string, field: any, fieldName: string];
}>();

const props = withDefaults(
  defineProps<{
    actionsColumnPosition?: number;
    customEmptyStateHeight?: string;
    customHeight?: string;
    customLoadingHeight?: string;
    hasActions?: boolean;
    hasAsideColumn?: boolean;
    hasFrozenActions?: boolean;
    hasPaginator?: boolean;
    hasSelector?: boolean;
    tunnelProps?: any;
    tablePageHeader?: any;
    instanceComposable?: any;
    loading?: boolean;
    pageLinkSize?: number;
    sortOrder?: 'asc' | 'desc';
  }>(),
  {
    hasPaginator: true,
    hasSelector: true,
    sortOrder: 'desc',
  }
);

const { activeColumns, initialColumns, page, size } = props.instanceComposable
  ? props.instanceComposable()
  : useDataTableFilters();
// NOTE: We need to get frozenColumns and visibleFields from instanceComposable
const { columns, rows, selectedRows, totalRecords } = props.instanceComposable
  ? props.instanceComposable()
  : useDataTable();
const { FIELD_CLASS, frozenColumns, frozenFields, getFrozenColumnWidth, getFrozenState } =
  useDataTable();
const { height: windowHeight, width } = useWindowSize();
const { tableHeaders } = useDownloadButton();

const {
  ADDRESS,
  BACKGROUND_CELL,
  CASE_STATUS_LOCATION,
  COMPOSED_LINK,
  DROPDOWN,
  DROPDOWN_MODAL,
  FORMAT,
  HIDDEN_CELL,
  HOLD_STATUS,
  IMAGE,
  IMAGE_LINK,
  INPUT_NUMBER,
  KENNEL_MANAGER_CELL,
  LINK,
  LOGIN_AS,
  MULTISELECT,
  OPEN_MODAL_CELL,
  RECEIPT,
  TAG,
  TEXT_AREA,
  VOUCHERS,
} = DATA_TABLE_COMPONENTS;
const HARD_CODED_FIELDS = ['actionsColumn', 'asideColumn', 'rowSelector', 'tunnel'];

const headerHeight = ref();
const cellOnEditMode = ref();
const clickOutsideTarget = ref(null);
const gridContainer = ref();
const hasOverflow = ref();
const selectAll = ref();

const columnsGridSize = computed(() => {
  const { hasSelector, hasAsideColumn, hasActions, tunnelProps } = props;
  const length =
    columns?.value?.length + +hasSelector + +hasAsideColumn + +hasActions + !!tunnelProps;

  return `repeat(${length}, auto)`;
});

const classes = computed(() => [
  'grid-container items-center overflow-auto rounded-md',
  {
    'blur-[3px]': props.loading,
    'min-h-9': windowHeight.value < 576 && rows.value.length === 0,
    'min-h-28': windowHeight.value < 576 && rows.value.length === 1,
    'min-h-[168px]': windowHeight.value < 576 && rows.value.length === 2,
    'min-h-[226px]': windowHeight.value < 576 && rows.value.length === 3,
    'min-h-64': windowHeight.value < 576 && rows.value.length >= 4,
    '!overflow-hidden rounded-b-none': !rows.value.length,
  },
  props.instanceComposable ? 'default' : size.value,
]);

const headers = computed(() => {
  if (!columns.value?.length) return [];

  const result = [...columns.value];

  const { hasSelector, hasAsideColumn, hasActions, tunnelProps } = props;

  if (hasSelector) {
    const rowSelector = {
      header: 'rowSelector',
      field: 'rowSelector',
      settings: undefined,
    };

    result.unshift(rowSelector);
  }

  if (hasAsideColumn) {
    const asideColumn = {
      header: 'asideColumn',
      field: 'asideColumn',
      settings: undefined,
    };

    hasSelector ? result.splice(1, 0, asideColumn) : result.unshift(asideColumn);
  }

  if (tunnelProps) {
    const tunnel = {
      header: tunnelProps?.header || '',
      field: 'tunnel',
      settings: undefined,
    };

    result.push(tunnel);
  }

  if (hasActions) {
    const asideColumn = {
      header: 'actionsColumn',
      field: 'actionsColumn',
      settings: undefined,
    };

    const actionHasPosition = props.actionsColumnPosition;
    actionHasPosition
      ? result.splice(props.actionsColumnPosition - 1, 0, asideColumn)
      : result.push(asideColumn);
  }

  return result;
});

const scrollHeight = computed(() => {
  const topNavHeight = 48;
  const header = headerHeight.value || 138;
  const bodyPadding = 16;
  const paginator = 45;
  const bottomPaddingCut = 0;

  const elementsHeight = topNavHeight + header + bodyPadding + paginator + bottomPaddingCut;

  const calcHeight = windowHeight.value - elementsHeight;

  return `${calcHeight}px`;
});

const tableHeader = computed(() => props.tablePageHeader);

useResizeObserver(gridContainer, () => onGridContainerResize());
useResizeObserver(tableHeader, () => getHeaderHeight());

watch(selectedRows, () => !selectedRows.value.length && resetSelectAll());
watch(activeColumns, () => {
  onToggleColumns();
  setTableHeaders();
});

onMounted(() => getHeaderHeight());

// HELPERS
function getHeaderHeight() {
  if (typeof tableHeader.value === 'undefined') return;
  if (!tableHeader.value) {
    nextTick(() => getHeaderHeight());
    return;
  }
  headerHeight.value = props.tablePageHeader.getBoundingClientRect().height + 16;
}

function setTableHeaders() {
  tableHeaders.value = headers.value.map((header: any) => ({
    label: header.header,
    value: header.field,
  }));
}

function setFrozenColumns() {
  const gridNotReady = !gridContainer.value;
  if (gridNotReady) return resetFrozenColumns();

  hasOverflow.value = gridContainer.value.scrollWidth > gridContainer.value.clientWidth;
  if (!hasOverflow.value) return resetFrozenColumns();

  const staticAndDynamicColumns = [...frozenFields.value, ...DATA_TABLE_COLUMNS_ORDER];
  const uniqueFrozenColumns = [...new Set(staticAndDynamicColumns)];

  const rowColumn = props.hasSelector ? 'rowSelector' : '';
  const asideColumn = props.hasAsideColumn ? 'asideColumn' : '';

  const activeHeaders = headers.value.map(({ field }) => field);
  const activeFrozenColumns = [rowColumn, asideColumn, ...uniqueFrozenColumns].filter((column) =>
    activeHeaders.includes(column)
  );

  frozenColumns.value = width.value > 1024 ? activeFrozenColumns : [];
}

function resetFrozenColumns() {
  frozenColumns.value = [];
}

function resetSelectAll() {
  selectAll.value = false;
}

function componentSwitcher(component?: DATA_TABLE_COMPONENTS) {
  if (!component) return ADefaultCell;

  //prettier-ignore
  switch (component) {
    case ADDRESS: return AAddressCell;
    case BACKGROUND_CELL: return ABackgroundCell
    case CASE_STATUS_LOCATION: return ACaseStatusLocation;
    case COMPOSED_LINK: return AComposedLink;
    case FORMAT: return AFormatedCell;
    case HIDDEN_CELL: return AHiddenCell;
    case HOLD_STATUS: return AHoldStatusCell;
    case IMAGE: return AImageCell;
    case IMAGE_LINK: return AImageLink;
    case INPUT_NUMBER: return AInputNumber;
    case KENNEL_MANAGER_CELL: return AKennelModalCell;
    case LINK: return ALinkCell;
    case LOGIN_AS: return AActionLoginAs;
    case OPEN_MODAL_CELL: return AOpenModalCell;
    case RECEIPT: return AReceiptCell;
    case TAG: return ATagCell;
    case TEXT_AREA: return ATextArea;
    case VOUCHERS: return MActionVouchers;
  }
}

function toggleColumns() {
  // TODO :: review this logic
  if (!initialColumns.value && !activeColumns.value) return;

  const hiddenColumns = initialColumns.value.filter(
    (field: any) => !activeColumns.value.includes(field)
  );

  hiddenColumns.forEach((field: any) => {
    field = field.replace(/'/g, '');

    const COLUMN = document.querySelectorAll(`.dt-column_${field}`);

    for (const column of COLUMN) {
      column.classList.add('hidden');
    }
  });

  activeColumns.value.forEach((field: any) => {
    field = field.replace(/'/g, '');

    const COLUMN = document.querySelectorAll(`.dt-column_${field}`);

    for (const column of COLUMN) {
      column.classList.remove('hidden');
    }
  });
}

// Events
function onGridContainerResize() {
  hasOverflow.value = gridContainer.value.scrollWidth > gridContainer.value.clientWidth;

  hasOverflow.value ? setFrozenColumns() : resetFrozenColumns();
}

function onToggleColumns() {
  toggleColumns();
  setFrozenColumns();
}

function onSelectRow() {
  selectAll.value = selectedRows.value.length === rows.value.length;
}

function onNewPage(params: PageState) {
  page.value = params.page + 1;

  emits('onParamsUpdate');
}

function onEditCell(newValue: any, originalData: any, field: any) {
  emits('onFieldUpdate', newValue, originalData, field);

  // NOTE: The code below resets the width of the edited column, allowing it to adapt dynamically
  // to the largest updated value, i.e., the longest status in Animal Status.
  frozenColumns.value = [];
  nextTick(() => {
    setFrozenColumns();
  });
}

// ORGANIZE THIS
function setNewEditCell(params: CellUniqueIdenfier) {
  cellOnEditMode.value = getCellUniqueIndetifier(params);
}

function isFieldOnEditMode(params: CellUniqueIdenfier) {
  return cellOnEditMode.value === getCellUniqueIndetifier(params);
}

function getCellUniqueIndetifier(params: CellUniqueIdenfier) {
  const { field, index, rowIndex } = params;

  return `${field}-col_${index}-row_${rowIndex}`;
}

function isListBoxEdit(settings: any, field: any, rowIndex: any, index: any) {
  const needsListbox = [DROPDOWN, MULTISELECT].includes(settings?.edit?.type);

  return needsListbox && isFieldOnEditMode({ field, rowIndex, index });
}
</script>

<template>
  <div class="relative">
    <ALoadingState
      v-if="loading"
      :scrollHeight="customLoadingHeight || scrollHeight"
    />

    <div
      v-if="rows.length && !loading"
      ref="gridContainer"
      :class="classes"
      :style="{ 'max-height': customHeight || scrollHeight }"
    >
      <div
        v-for="({ header, field, sortable, editable, settings, hidden }, index) in headers"
        :class="[
          `${FIELD_CLASS}_${field} block`,
          { hidden: ![...HARD_CODED_FIELDS, ...activeColumns].includes(field) },
          { 'frozen sticky z-50': hasOverflow && getFrozenState(field) },
        ]"
        :key="index"
        :style="getFrozenColumnWidth(field, hasOverflow)"
      >
        <MHeaders
          v-if="!hidden"
          :field
          :header
          :settings
          :sortable
          @onSort="emits('onParamsUpdate')"
        />

        <div v-if="!hidden && rows.length">
          <div
            v-for="(row, rowIndex) in rows"
            :class="[
              'grid-item relative',
              {
                'odd-row': rowIndex % 2 > 0,
                'editable-row cursor-pointer': editable,
              },
            ]"
            :key="rowIndex"
          >
            <Checkbox
              v-if="field === 'rowSelector'"
              v-model="selectedRows"
              name="rowSelector1"
              :inputId="`cell_${field}_${index}`"
              :value="row"
              @change="onSelectRow"
            />

            <div v-else-if="field === 'asideColumn'">
              <slot
                name="asideButton"
                :props="row"
              />
            </div>

            <div v-else-if="field === 'tunnel'">
              <slot
                v-if="$slots.tunnel"
                name="tunnel"
                :props="row"
              />
            </div>

            <div v-else-if="field === 'actionsColumn'">
              <slot
                v-if="$slots.actions"
                name="actions"
                :props="row"
              />
            </div>

            <!-- TODO :: This may be a component (aka Row) -->
            <div v-else>
              <MEditCell
                v-if="isListBoxEdit(settings, field, rowIndex, index)"
                v-model:cellOnEditMode="cellOnEditMode"
                ref="clickOutsideTarget"
                :row
                :rowIndex
                :settings
                :column="headers[index]"
                :type="settings?.edit?.type"
                @onFieldUpdate="onEditCell"
              />

              <AApplicationStatusDropdown
                v-else-if="
                  settings?.edit?.type === DROPDOWN_MODAL &&
                  isFieldOnEditMode({ field, rowIndex, index })
                "
                :loading
                :isFormsAndApplications="settings?.edit?.isFormsAndApplications"
                :rowInfo="row"
                @blur="cellOnEditMode = ''"
              />

              <div
                v-else
                class="max-w-64 overflow-hidden text-ellipsis whitespace-nowrap"
                @click="editable && setNewEditCell({ field, rowIndex, index })"
              >
                <component
                  :field="row[field]"
                  :is="componentSwitcher(settings?.component)"
                  :rowData="row"
                  :settings="settings"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!rows.length && !loading"
      :class="classes"
    >
      <div
        v-for="({ header, field, sortable, settings }, index) in headers"
        class="block w-full"
        :key="index"
      >
        <MHeaders
          :field
          :header
          :settings
          :sortable
          @onSort="emits('onParamsUpdate')"
        />
      </div>
    </div>

    <AEmptyState
      v-if="!rows.length && !loading"
      :scrollHeight="customEmptyStateHeight || scrollHeight"
    />

    <MPaginator
      v-if="rows.length"
      :instanceComposable
      :hasPaginator
      :pageLinkSize
      :totalRecords
      @onNewPage="onNewPage($event)"
      @onRowsPerPageUpdate="emits('onParamsUpdate')"
    />
  </div>
</template>

<style scoped>
.grid-container {
  @apply grid border;
  grid-template-columns: v-bind(columnsGridSize);
}

.grid-item {
  @apply grid h-14 w-full items-center whitespace-nowrap border-b border-r bg-white p-2.5 text-sm;
}

.grid-container.compact .grid-item {
  @apply h-12 py-0;
}

.grid-container.large .grid-item {
  @apply h-20;
}

.odd-row {
  @apply bg-gray-100;
}
</style>
