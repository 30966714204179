import { ref } from 'vue';
import type { FiltersProps } from '../types';

const activeFilters = ref<FiltersProps[]>([]);
const _activeFilters = ref<FiltersProps[]>([]);

export default function () {
  function reset() {
    activeFilters.value = [];
    _activeFilters.value = [];
  }

  return {
    _activeFilters,
    activeFilters,
    reset,
  };
}
