import { ref, nextTick, watch } from 'vue';
import type { DataTableColumn } from '../types';

interface FrozenColumn {
  classes: any;
  column: string;
  width: number;
}

const FIELD_CLASS = 'dt-column';
const PER_PAGE_RECORDS = parseInt(import.meta.env.VITE_DEFAULT_PAGINATION) || 100;

const columns = ref<DataTableColumn[]>([]);
const frozenColumns = ref<string[]>([]);
const frozenColumnsProps = ref<FrozenColumn[]>([]);
const frozenFields = ref<string[]>([]);
const rows = ref<any[]>([]);
const rowsPerPage = ref(PER_PAGE_RECORDS);
const selectedRows = ref<any[]>([]);
const totalRecords = ref<number>(0);

watch(frozenColumns, () => onFrozenColumnsUpdate());

function onFrozenColumnsUpdate() {
  const noFrozenColumns = !frozenColumns.value.length;
  if (noFrozenColumns) {
    frozenColumnsProps.value = [];
    return;
  }

  nextTick(() => setFrozenColumnProps());
}

function setFrozenColumnProps() {
  frozenColumns.value.forEach((column) => {
    const COLUMN = getColumnElement(column);
    const notValidSelection = !COLUMN;
    if (notValidSelection) return '';

    const width = Math.floor(COLUMN.getBoundingClientRect().width);

    const newFrozenColumn = {
      column,
      width,
      classes: '',
    };

    const columnIndex = frozenColumnsProps.value.findIndex(
      ({ column }) => column === newFrozenColumn.column
    );
    const alreadySet = columnIndex !== -1;

    alreadySet
      ? (frozenColumnsProps.value[columnIndex] = newFrozenColumn)
      : frozenColumnsProps.value.push(newFrozenColumn);
  });

  frozenColumnsProps.value.forEach((frozenColumn, index) => {
    frozenColumn.classes = getColumnFixedWidthClass(frozenColumn, index);
  });
}

function getColumnElement(column: string): HTMLElement | null {
  const SELECTOR = `.${FIELD_CLASS}_${column}`;

  return document.querySelector(SELECTOR);
}

function getColumnFixedWidthClass(frozenColumn: FrozenColumn, index: number) {
  const floorWidth = `${Math.floor(frozenColumn.width)}px`;
  let nextColumnWidth = 0;

  if (!index) return { left: `${nextColumnWidth}px`, width: floorWidth };

  frozenColumnsProps.value.forEach(({ width }, position) => {
    if (position >= index) return;

    nextColumnWidth += width;
  });

  return { left: `${Math.floor(nextColumnWidth)}px`, width: floorWidth };
}

export default function () {
  function getFrozenState(column: string) {
    return frozenColumns.value.includes(column);
  }

  function getFrozenColumnWidth(column: string, hasOverflow: boolean): any {
    if (!hasOverflow) return '';

    const notFrozenColumn = !getFrozenState(column);
    if (notFrozenColumn) return '';

    const matchColumn = frozenColumnsProps.value.filter(
      (frozenColumn) => frozenColumn.column === column
    );
    const frozenColumNotFind = !matchColumn.length;
    if (frozenColumNotFind) return '';

    return matchColumn[0].classes;
  }

  function reset() {
    columns.value = [];
    frozenColumns.value = [];
    frozenColumnsProps.value = [];
    frozenFields.value = [];
    rows.value = [];
    rowsPerPage.value = PER_PAGE_RECORDS;
    selectedRows.value = [];
    totalRecords.value = 0;
  }

  return {
    columns,
    frozenColumns,
    frozenFields,
    rows,
    rowsPerPage,
    selectedRows,
    totalRecords,

    FIELD_CLASS,
    PER_PAGE_RECORDS,

    getFrozenColumnWidth,
    getFrozenState,
    reset,
  };
}
