<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAToast, useDraggable, useADialog } from '@Atoms';
import { API_URL } from '@Constants';
import { ICONS_MAP } from './constants';
import type { AfFile, AF_FILE_ICON } from './types';
import { AF_FILE_COLLECTIONS, AF_FILE_TYPES, AF_FILE_PROPERTY } from './types';
import { DeleteFileService, UploadFileService } from './services';
import { useConfirm } from 'primevue/useconfirm';
import Draggable from 'vuedraggable';
import imageCompression from 'browser-image-compression';

const emits = defineEmits<{
  updatePhotosOrder: [any];
  refreshData: [];
}>();

const props = withDefaults(
  defineProps<{
    entityId: string;
    files?: AfFile[];
    fullFileName?: boolean;
    hasBackendPath?: boolean;
    hasPermissionsRules?: boolean;
    header?: string;
    hugeFileName?: boolean;
    isOnModal?: boolean;
    isPhoto?: boolean;
    imageOnly?: boolean;
    largeFileName?: boolean;
    mediumFileName?: boolean;
    permissionsRules?: boolean;
    property: AF_FILE_PROPERTY;
    secure?: boolean;
    shelterId?: string;
    showTypeCol?: boolean;
    showVisibilityCol?: boolean;
    specificId?: string;
    type?: AF_FILE_TYPES;
    typeId?: string;
  }>(),
  {
    entityId: '',
    isOnModal: false,
    property: AF_FILE_PROPERTY.FILES,
    secure: false,
    type: AF_FILE_TYPES.GENERAL,
  }
);

const { t } = useI18n();
const { notify, TOAST_TYPES } = useAToast();
const { activeModal, activeModalProps, dialogVisibility, SYSTEM_MODALS } = useADialog();
const { draggableData, dragOptions, onDragStart, onDragEnd, getGrabbingClass } = useDraggable();
const confirm = useConfirm();

const loading = ref();

const filesProperties = computed(() => {
  return props.files
    ?.map((file) => {
      if (!file.filename) return null;

      const missingRequiredPayloadProperties = !file.collection || !file.destinationPath;

      if (missingRequiredPayloadProperties) {
        const { type, fileTypeId } = file;
        const { collection, destinationPath } = getFilePayloadProperties(
          type,
          props.entityId,
          fileTypeId
        );
        file.collection = collection;
        file.destinationPath = destinationPath;
        file.fileTypeId = fileTypeId;
      }

      function sanitizedPath() {
        const isRabies = file?.rabies && file.path === 'na';
        const isSpayNeuter = file?.spay_neuter && file.path === 'na';

        if (isRabies)
          return `${API_URL}/shelter/medical/vaccination/certificate/${file.fileTypeId}`;
        if (isSpayNeuter)
          return `${API_URL}/shelter/medical/surgery/certificate/${file.fileTypeId}`;

        return props.hasBackendPath
          ? file.path
          : getPath(
              file.path ||
                props.shelterId +
                  '/' +
                  (props.type === 'partners'
                    ? props.type + '/' + props.entityId
                    : file.destinationPath) +
                  '/' +
                  file.filename
            );
      }

      // TODO: [AF-342] - Remove file.path variations after the back sends
      // the path in Partner and People Profiles
      return {
        ...file,
        icon: getIcon(file.filename) as AF_FILE_ICON,
        path: sanitizedPath(),
      };
    })
    .filter((file): file is NonNullable<typeof file> => file !== null);
});

onMounted(() => initDraggableModel());

watch(
  () => props.files,
  () => initDraggableModel()
);

function initDraggableModel() {
  if (!props.isPhoto) return;

  draggableData.value = filesProperties.value;
}

// HELPERS
function getIcon(fileName: string) {
  const extension = fileName.split('.').pop()?.toLowerCase() || '';

  return ICONS_MAP[extension] || 'pi pi-file';
}

function getFilePayloadProperties(type: AF_FILE_TYPES, entityId: string, fileTypeId?: string) {
  const {
    ANIMALS,
    COMMUNITY_SUPPORT_CALLS,
    FIELD_SERVICE_CALLS,
    MEDICAL_BEHAVIOR,
    MEDICAL_DIAGNOSTIC,
    MEDICAL_MEDICATION,
    MEDICAL_EXAM,
    MEDICAL_PROCEDURE,
    MEDICAL_SURGERY,
    MEDICAL_VACCINATION,
    PARTNERS_COLLECTION,
    PEOPLE_COLLECTION,
    TNVR_CALLS,
    TRANSPORT_CALLS,
  } = AF_FILE_COLLECTIONS;
  const {
    BEHAVIOR,
    COMMUNITY_SUPPORT_FILE,
    DIAGNOSTIC,
    EXAM,
    EXAMS,
    FIELD_SERVICE_FILE,
    FIELD_SERVICE_CITATION,
    MEDICATION,
    PARTNERS,
    PEOPLE,
    PROCEDURE,
    SURGERY,
    TNVR,
    TRANSPORT,
    VACCINATION,
  } = AF_FILE_TYPES;

  switch (type) {
    case DIAGNOSTIC:
      return {
        collection: MEDICAL_DIAGNOSTIC,
        destinationPath: `${entityId}/medical/diagnostics/${fileTypeId || props.typeId}/`,
      };
    case EXAM:
    case EXAMS:
      return {
        collection: MEDICAL_EXAM,
        destinationPath: `/${entityId}/medical/exams/${fileTypeId || props.typeId}`,
      };
    case PROCEDURE:
      return {
        collection: MEDICAL_PROCEDURE,
        destinationPath: `${entityId}/medical/procedures/${fileTypeId || props.typeId}/`,
      };
    case BEHAVIOR:
      return {
        collection: MEDICAL_BEHAVIOR,
        destinationPath: `${entityId}/behavior/exams/${fileTypeId || props.typeId}`,
      };
    case TNVR:
      return {
        collection: TNVR_CALLS,
        destinationPath: `/tnvr/${entityId}/files/`,
      };
    case TRANSPORT:
      return {
        collection: TRANSPORT_CALLS,
        destinationPath: `transports/${entityId}`,
      };
    case FIELD_SERVICE_FILE:
      return {
        collection: FIELD_SERVICE_CALLS,
        destinationPath: `/field-services/${entityId}/files/`,
      };
    case COMMUNITY_SUPPORT_FILE:
      return {
        collection: COMMUNITY_SUPPORT_CALLS,
        destinationPath: `/community-support/${entityId}/files/`,
      };
    case FIELD_SERVICE_CITATION:
      return {
        collection: FIELD_SERVICE_CALLS,
        destinationPath: `/field-services/${entityId}/citations/`,
      };
    case MEDICATION:
      return {
        collection: MEDICAL_MEDICATION,
        destinationPath: `/${entityId}/medical/medications/${fileTypeId || props.typeId}`,
      };
    case PARTNERS:
      return {
        collection: PARTNERS_COLLECTION,
        destinationPath: `partners/${entityId}`,
      };
    case PEOPLE:
      return {
        collection: PEOPLE_COLLECTION,
        destinationPath: `/people/${entityId}`,
      };
    case SURGERY:
      return {
        collection: MEDICAL_SURGERY,
        destinationPath: `/${entityId}/medical/surgeries/${fileTypeId || props.typeId}`,
      };
    case VACCINATION:
      return {
        collection: MEDICAL_VACCINATION,
        destinationPath: `${entityId}/medical/vaccination/${fileTypeId || props.typeId}/`,
      };
    default:
      return { collection: ANIMALS, destinationPath: `/${entityId}/files/` };
  }
}

function getPath(path: string) {
  return (
    `${API_URL}/shelter/files/get-file/` + props.shelterId + '?file=' + encodeURIComponent(path)
  );
}

function compressFile(file: any) {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  return imageCompression(file, options);
}

// EVENTS
function onOpenSetVisibilityModal(file: AfFile) {
  activeModal.value = SYSTEM_MODALS.FILES_ACTION_FILE;
  activeModalProps.value = {
    isSettingVisibility: true,
    isDeleting: false,
    file,
    fileData: { secure: props.secure, property: props.property },
    entityId: props.entityId,
  };
  dialogVisibility.value = true;
}

function onOpenDeleteModal(event: any, file: AfFile) {
  if (props.isOnModal) return onConfirmDeletePopup(event, file);
  const fileInfo = getFilePayloadProperties(props.type, props.entityId);

  activeModal.value = SYSTEM_MODALS.FILES_ACTION_FILE;
  activeModalProps.value = {
    isSettingVisibility: false,
    isDeleting: true,
    file,
    fileData: { secure: props.secure, property: props.property, typeId: props.typeId },
    fileInfo,
    isPhoto: props.isPhoto,
    entityId: props.entityId,
  };
  dialogVisibility.value = true;
}

function onConfirmDeletePopup(event: any, file: AfFile) {
  confirm.require({
    group: 'files',
    target: event.currentTarget,
    message: t('components.files.confirmationMessage.delete', { fileName: file.filename }),
    icon: 'pi pi-exclamation-triangle text-warning-500',
    acceptIcon: 'pi pi-check',
    rejectClass: 'p-button-secondary p-button-sm text-xs',
    acceptClass: 'p-button-sm text-xs p-button-danger',
    rejectLabel: t('common.cancel'),
    acceptLabel: t('common.yes'),
    accept: () => onDeleteFile(file),
  });
}

function onDeleteFile(file?: AfFile) {
  loading.value = true;
  const fileInfo = getFilePayloadProperties(props.type, props.entityId);

  const { collection, destinationPath } = fileInfo;
  const { property, secure, typeId } = props;

  const data = {
    _id: typeId || file?.fileTypeId,
    collection: typeId ? collection : file?.collection,
    destinationPath: typeId ? destinationPath : file?.destinationPath,
    file: file?.filename,
    property,
    secure,
  };

  const TOAST_ERROR = {
    message: 'errors.generics.unknownIssue.message',
    title: 'errors.generics.unknownIssue.title',
    type: TOAST_TYPES.ERROR,
  };

  const TOAST_SUCCESS = {
    title: 'components.fileUploader.toast.title.delete',
    type: TOAST_TYPES.SUCCESS,
  };

  const photoDelete = props.isPhoto ? props.entityId : undefined;

  DeleteFileService(data, photoDelete)
    .then(() => {
      notify(TOAST_SUCCESS);
      emits('refreshData');
    })
    .catch(() => {
      notify(TOAST_ERROR);
    })
    .finally(() => (loading.value = false));
}

function onFileUpload(upload: File | File[] | any) {
  if (!upload || !Array.isArray(upload)) return;
  loading.value = true;

  const file = getFilePayloadProperties(props.type, props.entityId);
  const isMultiple = Array.from(upload).length > 1;
  const { collection, destinationPath } = file;
  const { secure, property } = props;

  const photoUploadCheck = props.isPhoto ? props.entityId : undefined;
  const fileToUpload = isMultiple ? upload : upload[0];
  const filePayload = props.isPhoto
    ? {
        file: isMultiple ? upload : upload[0],
      }
    : {
        _id: props.specificId || props.typeId || props.shelterId,
        collection,
        destinationPath,
        file: isMultiple ? upload : upload[0],
        property,
        secure,
      };

  const TOAST_ERROR = {
    message: 'components.fileUploader.toast.message.error',
    title: 'components.fileUploader.toast.title.error',
    type: TOAST_TYPES.ERROR,
  };

  const TOAST_SUCCESS = {
    title: 'components.fileUploader.toast.title.success',
    type: TOAST_TYPES.SUCCESS,
  };

  if (photoUploadCheck) {
    compressFile(fileToUpload)
      .then((compressedFile) => {
        onSendCompressedImage(
          compressedFile,
          filePayload,
          fileToUpload,
          isMultiple,
          photoUploadCheck
        );
      })
      .catch(() => notify(TOAST_ERROR) && (loading.value = false));

    return;
  }

  UploadFileService(filePayload, isMultiple, photoUploadCheck)
    .then(() => {
      notify(TOAST_SUCCESS);
      emits('refreshData');
    })
    .catch(() => {
      notify(TOAST_ERROR);
    })
    .finally(() => (loading.value = false));
}

function onSendCompressedImage(
  compressedFile: any,
  filePayload: any,
  fileToUpload: any,
  isMultiple: any,
  photoUploadCheck: any
) {
  const TOAST_ERROR = {
    message: 'components.fileUploader.toast.message.error',
    title: 'components.fileUploader.toast.title.error',
    type: TOAST_TYPES.ERROR,
  };

  const TOAST_SUCCESS = {
    title: 'components.fileUploader.toast.title.success',
    type: TOAST_TYPES.SUCCESS,
  };

  const updatedFilePayload = {
    ...filePayload,
    file: new File([compressedFile], fileToUpload.name, { type: compressedFile.type }),
  };

  UploadFileService(updatedFilePayload, isMultiple, photoUploadCheck)
    .then(() => notify(TOAST_SUCCESS) && emits('refreshData'))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onUpdateOrder() {
  onDragEnd();

  emits('updatePhotosOrder', draggableData.value);
}
</script>

<template>
  <AFileUploader
    :isPhoto
    :imageOnly
    :loading
    @onFileUpload="onFileUpload"
  >
    <template #header>
      <AFilesHeader
        v-if="!isPhoto"
        :files
        :header
        :showTypeCol
        :showVisibilityCol
      />
    </template>

    <template
      v-if="files?.length"
      #content
    >
      <div v-if="isPhoto">
        <div class="mb-2 flex gap-2 text-xs italic text-slate-400">
          <i class="pi pi-arrows-alt"></i>

          <p>{{ $t('animals.individualPage.photos.info') }}</p>
        </div>

        <Draggable
          v-model="draggableData"
          v-bind="dragOptions"
          class="flex h-full max-h-64 w-fit flex-wrap items-center gap-1 overflow-y-auto"
          item-key="filename"
          @start="onDragStart($event, draggableData)"
          @end="onUpdateOrder"
        >
          <template #item="{ element, index }">
            <AFilesPhotos
              :entityId
              :filename="element.filename"
              :filesProperties="draggableData"
              :index="index"
              :path="element.path"
              :shelterId
              :class="getGrabbingClass(element.filename)"
              @deleteFile="onDeleteFile(draggableData[index])"
            />
          </template>
        </Draggable>
      </div>

      <div
        v-else
        v-for="(
          { filename, icon, path, type, created_at, visibility, destinationPath, collection }, index
        ) in filesProperties"
        :class="[
          'relative flex items-center justify-between gap-1 text-sm md:text-base',
          isPhoto ? 'h-full w-fit' : 'h-6 w-full',
        ]"
        :key="filename"
      >
        <AFilesColumns
          :collection
          :created_at
          :destinationPath
          :filename
          :filesProperties
          :fullFileName
          :hasPermissionsRules
          :hugeFileName
          :icon
          :index
          :largeFileName
          :mediumFileName
          :path
          :permissionsRules
          :shelterId
          :showTypeCol
          :showVisibilityCol
          :type
          :visibility
          @deleteFile="onOpenDeleteModal"
          @setFileVisibility="onOpenSetVisibilityModal"
        />
      </div>
    </template>

    <template
      v-else
      #content
    >
      <div
        class="flex w-full flex-col items-center justify-center gap-3 whitespace-normal text-center text-slate-400"
      >
        <i class="pi pi-file text-center text-xl"></i>

        <p>{{ $t('components.fileUploader.empty') }}</p>
      </div>
    </template>
  </AFileUploader>

  <aside>
    <ConfirmPopup group="files" />
  </aside>
</template>
