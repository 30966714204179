<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

defineProps<{
  field?: FieldSchema;
  placeholder?: string;
}>();

const model = defineModel();
const colorModel = ref();

onMounted(() => initModel());

function initModel() {
  if (!model.value) model.value = '#FF0101';

  colorModel.value = model.value;
}

function onModelUpdate() {
  model.value = `#${colorModel.value}`;
}
</script>

<template>
  <div class="flex gap-2">
    <ColorPicker
      v-model="colorModel"
      format="hex"
      inputId="cp-hex"
      @change="onModelUpdate"
    />

    <p
      class="text-sm text-red-500"
      :style="{ color: '#' + colorModel }"
    >
      {{ $t('components.forms.notePreview') }}
    </p>
  </div>
</template>
