import { type RawField } from '@FormModule';

function sortContactFields(result: any) {
  const sortedFilteredFields = result
    .filter((field: RawField) => !['state'].includes(field.name))
    .sort((firstField: any, secondField: any) => {
      const firstPriority = firstField.type === 'phone' ? -2 : firstField.type === 'email' ? -1 : 1;

      const secondPriority =
        secondField.type === 'phone' ? -2 : secondField.type === 'email' ? -1 : 1;
      return firstPriority - secondPriority;
    });

  return sortedFilteredFields;
}

export { sortContactFields };
