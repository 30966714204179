import { defineStore } from 'pinia';

interface State {
  stats: any[];
  totalItems: number;
}

export default defineStore('products', {
  state: (): State => ({
    stats: [],
    totalItems: 0,
  }),
});
