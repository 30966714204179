interface OptionList {
  label: string;
  value: string;
}

interface GenericArray {
  [key: string]: any;
}

interface GenericBooleanInterface {
  [key: string]: boolean;
}

interface OId {
  $oid: string;
}

interface GenericField {
  name: string;
  value: any;
}

interface AfDate {
  $date: {
    $numberLong: string;
  };
}

interface PageHeader {
  id?: number;
  status?: string;
  title: string;
}

interface ApiResponse<T> {
  data: T;
  [key: string]: any;
}

enum DOCUMENT_STATUS {
  ACTIVE = 'active',
  DEACTIVATED = 'inactive',
  DELETED = 'deleted',
  QUARANTINE = 'quarantine',
  UNAVAILABLE = 'unavailable',
}

type ApiDocumentStatus = DOCUMENT_STATUS.ACTIVE | DOCUMENT_STATUS.DELETED;
type UtcDateTime = string & { __utcDateTimeBrand: never };

// TODO: AF-595 - Extend this as `interface GoogleAddress extends Address`
interface Address {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

export type {
  Address,
  AfDate,
  ApiDocumentStatus,
  ApiResponse,
  GenericArray,
  GenericBooleanInterface,
  GenericField,
  OId,
  OptionList,
  PageHeader,
  UtcDateTime,
};

export { DOCUMENT_STATUS };
