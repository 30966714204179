import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: '/',
    name: 'logged-out',
    component: () => import('./views/LoggedOutView.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/LoginView.view.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      isPublic: true,
    },
    component: () => import('./views/ShelterRegister.view.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/ForgotPassword.view.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/password/reset/',
    name: 'password-reset-no-token',
    meta: {
      isPublic: true,
    },
    component: () => import('./views/PasswordReset.view.vue'),
  },
  {
    path: '/password/reset/:token',
    name: 'password-reset',
    meta: {
      isPublic: true,
    },
    component: () => import('./views/PasswordReset.view.vue'),
  },
  {
    path: '/shelter/access-denied',
    name: 'access-denied',
    component: () => import('./views/AccessDenied.view.vue'),
  },
];
