import { computed, onMounted, ref } from 'vue';
import type { GenericField, OId } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { formatNumberToDate } from '@Helpers';
import { ROUTES } from '@AnimalModule';
import { useForm } from '@FormModule';
import { MedicalStaff } from '@ShelterModule';

interface MedicalStaff {
  [key: string]: any;
  first_name: string;
  last_name: string;
  _id: OId;
}

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();
const medicalStaff = ref();

export default function () {
  const { getDetailsSchema, wasFormSubmitted } = useForm();

  const rawFields: RawField[] = [
    {
      label: 'Reason for Procedure',
      name: 'reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Procedure Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Route',
      name: 'route',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Amount',
      name: 'amount',
      required: false,
      type: 'amount_unit',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      schedule_followup: {
        settings: {
          label: 'Schedule a Follow-Up Check',
        },
      },
      route: {
        edit: {
          options: Object.values(ROUTES),
        },
      },
    })
  );

  onMounted(() => fillMedicalStaff());

  function setForm() {
    if (fieldsSchema.value.length) return;

    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings.value);
  }

  function updateSchemaModels(sourceData: any) {
    setForm();

    formError.value = null;
    wasFormSubmitted.value = false;

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];

      //prettier-ignore
      switch (field.name) {
        case 'route': return setMatchField(field, match);
        case 'reason': return setMatchField(field, match);
        case 'notes': return setMatchField(field, match);
        case 'date': return setDateField(field, sourceData)
        case 'amount': return setAmountField(field, sourceData)
        default: setMatchField(field, match)
      }
    });
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
    field.state.errors.clear();
  }

  function setDateField(field: any, sourceData: any) {
    field.model = formatNumberToDate(sourceData.date.$date.$numberLong || new Date());
    field.value = formatNumberToDate(sourceData.date.$date.$numberLong) || new Date();
  }

  function setAmountField(field: any, sourceData: any) {
    field.model = { amount: Number(sourceData.amount), amount_unit: sourceData.amount_unit };
    field.value = { amount: Number(sourceData.amount), amount_unit: sourceData.amount_unit };
  }

  function fillMedicalStaff() {
    MedicalStaff().then((result: any) => (medicalStaff.value = result));
  }

  function resetFieldSchemaEdit() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
    wasFormSubmitted.value = false;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    resetFieldSchemaEdit,
    setForm,
    updateSchemaModels,
  };
}
