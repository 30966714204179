<script setup lang="ts">
import { computed } from 'vue';
import { API_URL } from '@Constants';
import type { FieldSchema } from '../../../types';

const props = defineProps<{
  field?: FieldSchema;
}>();

const href = computed(() => `${API_URL}${props.field?.settings?.link}`);
</script>

<template>
  <a
    target="_blank"
    :href
  >
    <Button
      class="af-button af-button-success w-fit"
      icon="pi pi-print"
      pt:label="text-xs"
      :label="$t(field?.settings?.label)"
    />
  </a>
</template>
