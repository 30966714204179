<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { differenceInCalendarDays, parse } from 'date-fns';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const { t } = useI18n();

const today = new Date();
const expirationDate = parse(props.rowData.hold_expiration_date, 'MM-dd-yyyy', new Date());
const days = differenceInCalendarDays(expirationDate, today);

function daysRemaining() {
  if (!props.rowData.hold_status) return t('components.holdStatus.noHoldSet');

  return days <= 0 ? props.field : `${days}` + ' ' + t('components.holdStatus.daysRemaining', days);
}

function holdStatusLabel() {
  if (daysRemaining() !== props.field || props.field === t('components.holdStatus.canceled'))
    return props.field;

  return t('components.holdStatus.complete');
}
</script>

<template>
  <div
    v-tooltip.top="{ value: daysRemaining(), showDelay: 500 }"
    class="cursor-help overflow-hidden text-ellipsis"
  >
    <p class="cursor-help overflow-hidden text-ellipsis">{{ holdStatusLabel() || '-' }}</p>
  </div>
</template>
