<script setup lang="ts">
// TODO - AF-585 : We should be able to apply discounts per animal and not only per cart
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAToast } from '@Atoms';
import { AddDiscountToCart, DeleteDiscount } from '../../../services';
import useCartStore from '../../../store';

const { activeDiscount, activeDiscountCode, discounts, loading } = storeToRefs(useCartStore());
const { refreshData, fetchActiveDiscounts } = useCartStore();

const { notify, TOAST_TYPES } = useAToast();

const discount = ref();
const error = ref();

const discountIsEmpty = computed(() => activeDiscount.value === '0.00');

onMounted(() => initDiscounts());

function initDiscounts() {
  discount.value = activeDiscountCode.value;

  fetchActiveDiscounts();
}

function onDiscountTrigger() {
  const noDiscountSelected = discountIsEmpty.value && !discount.value;
  if (noDiscountSelected) return (error.value = 'cart.discounts.selectDiscountFirst');

  discountIsEmpty.value ? onApplyDiscount() : onRemoveDiscount();
}

function onApplyDiscount() {
  error.value = '';
  loading.value = true;

  const TOAST_ERROR = {
    message: 'cart.toast.invalidDiscountCode.error.message',
    title: 'cart.toast.invalidDiscountCode.error.title',
    type: TOAST_TYPES.ERROR,
  };

  AddDiscountToCart(discount.value)
    .then(() => refreshData())
    .catch(() => notify(TOAST_ERROR) && (discount.value = null))
    .finally(() => (loading.value = false));
}

function onRemoveDiscount() {
  error.value = '';
  loading.value = true;

  DeleteDiscount()
    .then(() => {
      refreshData();
      discount.value = null;
    })
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-2">
    <div class="flex w-full gap-1">
      <ASelectFilter
        v-model.externalModel="discount"
        hasExternalModel
        class="w-full"
        inputLabel="cart.label.selectDiscount"
        optionLabel="code"
        optionValue="code"
        paramsLabel="status"
        :loading
        :disabled="!discountIsEmpty"
        :hasClearTrigger="false"
        :options="discounts"
      />

      <Button
        :class="['af-button', discountIsEmpty ? 'af-button-success' : '']"
        :label="discountIsEmpty ? $t('common.apply') : $t('common.remove')"
        :outlined="!discountIsEmpty"
        :severity="discountIsEmpty ? 'primary' : 'danger'"
        @click="onDiscountTrigger"
      />
    </div>

    <AFieldError
      v-if="error"
      :error="$t(error)"
    />
  </div>
</template>
