import { ref } from 'vue';
import type { RenderNotesPayload } from '@ShelterModule';
import { useADialog } from '@Atoms';

const { activeModal, activeModalProps, dialogVisibility } = useADialog();

const _fatherNoteId = ref();
const inlineEditingModel = ref('');
const inLineEditorModel = ref('');
const inputCommentModel = ref('');
const isAddComment = ref(false);
const isAddingNote = ref(false);
const isDeletingNote = ref(false);
const isEditingNote = ref(false);
const isLoadingNote = ref(false);
const noteId = ref();
const notes = ref();

export default function () {
  function onCancelAdd() {
    isAddingNote.value = false;
    inLineEditorModel.value = '';
  }

  function onCancelEdit() {
    isAddComment.value = false;
    isEditingNote.value = false;
    dialogVisibility.value = false;
    noteId.value = '';
  }

  function onCancelDelete() {
    isDeletingNote.value = false;
    _fatherNoteId.value = '';
    noteId.value = '';
  }

  function onOpenCommentEditor({ id }: RenderNotesPayload) {
    isAddComment.value = false;
    isEditingNote.value = true;
    noteId.value = id;
  }

  function onOpenEditNoteModal(
    { id, note }: RenderNotesPayload,
    pageData: any,
    modalName: string,
    isActivity: boolean = false
  ) {
    isEditingNote.value = true;

    noteId.value = id;
    activeModalProps.value = {
      id,
      isActivity,
      note,
      notes,
      pageData: pageData,
    };

    activeModal.value = modalName;
    dialogVisibility.value = true;
  }

  function onOpenEditInlineEditor({ id, note }: RenderNotesPayload) {
    isAddComment.value = false;
    isEditingNote.value = true;
    noteId.value = id;
    inlineEditingModel.value = note as string;
  }

  function onOpenInlineEditor() {
    isAddingNote.value = true;
    inLineEditorModel.value = '';
  }

  function onOpenModal(pageData: any, modalName: string, isActivity: boolean = false) {
    activeModalProps.value = {
      pageData: pageData,
      isActivity,
    };

    activeModal.value = modalName;
    dialogVisibility.value = true;
  }

  function onSetDeleteCommentId({ id, fatherNoteId }: RenderNotesPayload) {
    isAddComment.value = false;
    isDeletingNote.value = true;

    _fatherNoteId.value = fatherNoteId;
    noteId.value = id;
  }

  function onSetDeleteId({ id }: RenderNotesPayload) {
    isAddComment.value = false;
    isDeletingNote.value = true;
    noteId.value = id;
  }

  function reset() {
    _fatherNoteId.value = '';
    inLineEditorModel.value = '';
    isAddComment.value = false;
    isAddingNote.value = false;
    isDeletingNote.value = false;
    isEditingNote.value = false;
    noteId.value = '';
    notes.value = '';
  }

  return {
    _fatherNoteId,
    inlineEditingModel,
    inLineEditorModel,
    inputCommentModel,
    isAddComment,
    isAddingNote,
    isDeletingNote,
    isEditingNote,
    isLoadingNote,
    noteId,
    notes,

    onCancelAdd,
    onCancelDelete,
    onCancelEdit,
    onOpenCommentEditor,
    onOpenEditInlineEditor,
    onOpenEditNoteModal,
    onOpenInlineEditor,
    onOpenModal,
    onSetDeleteCommentId,
    onSetDeleteId,
    reset,
  };
}
