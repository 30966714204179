<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import type { DataTableColumn } from '../../../types';
import useDataTable from '../../../composables/useDataTable';
import { DATA_TABLE_COMPONENTS } from '../../../types';

const emits = defineEmits<{
  onFieldUpdate: [newValue: string, field: any, fieldName: string];
}>();

const props = defineProps<{
  column: DataTableColumn;
  row: any;
  rowIndex: number;
  settings: any;
  type: DATA_TABLE_COMPONENTS;
}>();

const { rows } = useDataTable();

const { DROPDOWN } = DATA_TABLE_COMPONENTS;

let INITAL_DATA: any = undefined;

const cellOnEditMode = defineModel('cellOnEditMode', { default: false });

const _model = ref<any>(props.type === DROPDOWN ? {} : []);
const clickOutsideTarget = ref(null);

const isDropdown = computed(() => props.type === DROPDOWN);
const listBoxPlaceholderDiff = computed(() => (props.type === DROPDOWN ? 1 : 2));
const options = computed(() =>
  props.settings.edit.options
    .slice()
    .sort((a: any, b: any) => (a.label ? a.label.localeCompare(b.label) : a.localeCompare(b)))
);

onClickOutside(clickOutsideTarget, () => resetEditMode());
onMounted(() => setInitialModel());

// HELPERS
function setInitialModel() {
  INITAL_DATA = props.row[props.column.field];

  if (!isDropdown.value) {
    _model.value = INITAL_DATA;
  }

  const updateOnEditFields = props.column?.settings?.edit?.updateOnEditFields;
  const targetField = rows.value[props.rowIndex];

  if (updateOnEditFields) {
    updateOnEditFields.forEach(({ field, key }) => (_model.value[key] = targetField[field]));
  }
}

function hasUpdates() {
  if (!_model.value) return false;

  const notSameLength = INITAL_DATA !== _model.value.length;
  if (notSameLength) return true;

  if (!INITAL_DATA) return true;
  const notSameResults = INITAL_DATA?.filter((item: any) => _model.value.includes(item)).length;
  return notSameResults;
}

function resetEditMode() {
  cellOnEditMode.value = false;
}

// EVENTS
function onChangeSelection() {
  if (!isDropdown.value) return;

  onEditCell();
}

function onEditCell() {
  const { field } = props.column;

  const hasNewSelections = hasUpdates();
  if (!hasNewSelections) {
    cellOnEditMode.value = false;
    return;
  }

  emits('onFieldUpdate', _model.value, props.row, field);

  onEditComplete();
}

function onEditComplete() {
  const { field } = props.column;
  const updateOnEditFields = props.column?.settings?.edit?.updateOnEditFields;
  const targetField = rows.value[props.rowIndex];

  resetEditMode();

  if (isDropdown.value && updateOnEditFields) {
    updateOnEditFields.forEach(({ field, key }) => (targetField[field] = _model.value[key]));
    return;
  }

  targetField[field] = _model.value;
}
</script>

<template>
  <div ref="clickOutsideTarget">
    <span class="block w-fit rounded-md bg-gray-100 p-1 text-center text-xs text-gray-700">
      {{ $t('components.dataTable.editCell.select', listBoxPlaceholderDiff) }}
    </span>

    <Listbox
      v-model="_model"
      class="absolute z-10 mt-1 max-h-64 overflow-auto shadow"
      pt:item="text-sm"
      :options
      :multiple="!isDropdown"
      :optionLabel="isDropdown ? 'label' : settings.edit.options.value"
      @change="onChangeSelection"
    >
      <template #footer>
        <div>
          <Divider class="m-0" />

          <div>
            <Button
              v-if="!isDropdown"
              outlined
              class="w-full border-0 text-sm"
              severity="primary"
              :label="$t('common.save')"
              @click="onEditCell"
            />
          </div>
        </div>
      </template>
    </Listbox>
  </div>
</template>
