import { capitalizeStr } from '@Helpers';

enum DATA_TABLE_COMPONENTS {
  ADDRESS = 'ADDRESS',
  BACKGROUND_CELL = 'BACKGROUND_CELL',
  CASE_STATUS_LOCATION = 'CASE_STATUS_LOCATION',
  COMPOSED_LINK = 'COMPOSED_LINK',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_MODAL = 'DROPDOWN_MODAL',
  FORMAT = 'FORMAT',
  HIDDEN_CELL = 'HIDDEN_CELL',
  HOLD_STATUS = 'HOLD_STATUS',
  IMAGE = 'IMAGE',
  IMAGE_LINK = 'IMAGE_LINK',
  INPUT_NUMBER = 'INPUT_NUMBER',
  KENNEL_MANAGER_CELL = 'KENNEL_MANAGER_CELL',
  LINK = 'LINK',
  LOGIN_AS = 'LOGIN_AS',
  MULTISELECT = 'MULTISELECT',
  OPEN_MODAL_CELL = 'OPEN_MODAL_CELL',
  RECEIPT = 'RECEIPT',
  TAG = 'TAG',
  TEXT_AREA = 'TEXT_AREA',
  VOUCHERS = 'VOUCHERS',
}

enum FORMAT_COMPONENTS {
  BIRTHDAY = 'birthday',
  COMPOSED_STRING_LINK = 'composedStringLink',
  DATE_FROM_OID = 'dateFromOId',
  DATE_TO_ISO_STRING = 'dateToIsoString',
  DATE_STRING_WITH_TIME = 'dateStringWithTime',
  DAYS_TO_FIXED = 'daysToFixed',
  DATE_TIME_TO_DATE = 'dateTimeToDate',
  DECIMAL_TO_MONEY = 'decimalToMoney',
  EXTRA_INFO = 'extraInfo',
  NUMBER_TO_BIRTHDAY = 'numberBirthday',
  NUMBER_TO_DATE = 'dateNumber',
  MONEY = 'money',
  OBJECT_MODEL = 'objectModel',
  PHONE = 'phone',
  WEIGHT = 'weight',
}

interface CellUniqueIdenfier {
  field: string;
  rowIndex: number;
  index: number;
}

interface DataTableEditField {
  key: string;
  field: string;
}

interface DataTableSizeSetting {
  label: string;
  disabled?: boolean;
  value: undefined | 'small' | 'large';
}

interface RawDataTableColumn {
  default?: boolean;
  edit?: boolean;
  editable?: boolean;
  label: string;
  name: string;
  required?: boolean;
  sortable?: boolean;
  type: string; // TODO : AF-533 This should be an ENUM
  visible?: boolean;
}

interface DataTableColumn {
  field: string;
  header: string;
  editable?: boolean;
  sortable?: boolean;
  hidden?: boolean;
  visible?: boolean;
  settings?: {
    component?: DATA_TABLE_COMPONENTS;
    edit?: {
      [key: string]: any;
      updateOnEditFields?: DataTableEditField[];
    };
    render?: {
      [key: string]: any;
    };
  };
}

class AFDataTableColumn implements DataTableColumn {
  editable?: boolean;
  field: string;
  header: string;
  sortable?: boolean;
  visible?: boolean;

  constructor(field: RawDataTableColumn, sortable?: boolean) {
    this.editable = !!field.editable;
    this.field = field.name;
    this.header = field.label || capitalizeStr(field.name);
    this.sortable = typeof sortable !== 'undefined' ? sortable : field.sortable;
    this.visible = typeof field?.visible === 'undefined' ? true : !!field.visible;
  }
}

export {
  type CellUniqueIdenfier,
  type DataTableColumn,
  type DataTableEditField,
  type DataTableSizeSetting,
  AFDataTableColumn,
  DATA_TABLE_COMPONENTS,
  FORMAT_COMPONENTS,
};
