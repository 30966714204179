<script setup lang="ts">
import { computed, nextTick, onMounted, useTemplateRef } from 'vue';
import type { FieldSchema } from '../../../types';
import type Dropdown from 'primevue/dropdown';
import { FILTER_MESSAGES, EMPTY_MESSAGES } from '@Constants';
import { MICROCHIP_ISSUER } from '@AnimalModule';

const emits = defineEmits<{
  onMicrochipSelectValue: [];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    isInlineEditing?: boolean;
    options?: any[];
    placeholder?: string;
  }>(),
  {
    isInlineEditing: false,
    placeholder: 'components.forms.placeholder.select',
  }
);

const model = defineModel();

const inlineComponentRef = useTemplateRef<InstanceType<typeof Dropdown> | null>(
  'inlineComponentRef'
);

const settings = computed(() => props?.field?.settings);
const options = computed(() => props.options || Object.values(MICROCHIP_ISSUER) || []);

onMounted(() => checkEditType());

function checkEditType() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  nextTick(() => {
    if (!inlineComponentRef.value) return;
    inlineComponentRef.value.show();
  });
}

function onSelectValue() {
  props.isInlineEditing ? emits('onMicrochipSelectValue') : null;
}
</script>

<template>
  <Dropdown
    v-bind="{ FILTER_MESSAGES, EMPTY_MESSAGES }"
    v-model="model"
    class="flex h-9 w-full justify-between"
    pt:item="text-sm"
    pt:filterInput="h-9 py-1 pl-3 text-sm"
    ref="inlineComponentRef"
    :invalid="!!error"
    :options
    :placeholder="$t(settings?.placeholder || placeholder)"
    :pt:trigger:class="{ 'text-red-600': !!error }"
    :emptyFilterMessage="$t('components.emptyFilterMessage.dropdown')"
    :emptyMessage="$t('components.emptyMessage.dropdown')"
    :emptySelectionMessage="$t('components.emptySelectionMessage.dropdown')"
    :filter="options.length > 5"
    :filterMessage="$t('components.emptySelectionMessage.dropdown')"
    :filterResultMessage="$t('components.emptySelectionMessage.dropdown')"
    :pt:input:class="{
      'flex items-center text-sm max-w-[170px]': true,
      'af-field-error': !!error,
    }"
    @change="onSelectValue"
  />
</template>
