<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { GenericArray } from '@Types';
import type { PaginateResult } from '@Providers';
import type { Product } from '../../../types';
import { API_URL } from '@Constants';
import { useSessionStore } from '@Stores';
import { ROLES } from '@AuthModule';
import { useDataTable, useDataTableFilters } from '@DataTableModule';
import { GetProductListService } from '../../../services';
import { useProductsStore } from '../../../stores';
import productsAndServicesTableHelper from './productsAndServicesTableHelper';

const emits = defineEmits<{
  onAddToCart: [Product];
}>();

const { can } = useSessionStore();
const { stats, totalItems } = storeToRefs(useProductsStore());

const { t } = useI18n();
const { columns, frozenFields, rows, totalRecords } = useDataTable();
const { activeColumns, initialColumns } = useDataTableFilters();

const loading = ref(false);
const quantity = ref<GenericArray>({});

onMounted(() => onGetProductListService());

// EVENTS
async function onGetProductListService() {
  loading.value = true;

  await GetProductListService()
    .then((result) => {
      _handler(result);
      _setColumns();
    })
    .finally(() => (loading.value = false));

  function _handler({ data, total }: PaginateResult) {
    rows.value = data;
    totalRecords.value = total;
    totalItems.value = total;
    stats.value = [
      {
        name: t('stats.products'),
        value: data.filter((item: any) => item.type === 'product').length.toString(),
      },
      {
        name: t('stats.services'),
        value: data.filter((item: any) => item.type === 'service').length.toString(),
      },
      {
        name: t('stats.total'),
        value: total.toString(),
      },
    ];
  }

  function _setColumns() {
    const staticColumns = [
      {
        name: 'sku',
        label: 'SKU#',
      },
      {
        name: 'photo',
        label: 'Photo',
      },
      {
        name: 'product',
        label: 'Name',
      },
      {
        name: 'type',
        label: 'Type',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'price',
        label: 'Price',
      },
      // TODO :: AF-935 - Products part II
      // {
      //   name: 'in_stock',
      //   label: 'In Stock',
      // },
    ];

    const dynamicColumns = staticColumns.map((field: any) => productsAndServicesTableHelper(field));

    columns.value = dynamicColumns;
    initialColumns.value = dynamicColumns.map(({ field }) => field);
    activeColumns.value = initialColumns.value;

    frozenFields.value = ['sku'];
  }
}

function onAddToCart(product: Product) {
  const PAYLOAD = {
    ...product,
    quantity: quantity.value[product._id] || 0,
  };

  emits('onAddToCart', PAYLOAD);
}
</script>

<template>
  <MEmptySection
    v-if="!rows.length && !loading"
    class="mt-4"
    icon="pi pi-box"
    label="productsAndServices.emptyState.label"
  >
    <template #customAction>
      <a
        v-if="can(ROLES.OUTCOME_SETTINGS)"
        :href="`${API_URL}/shelter/settings/products/manage-products`"
      >
        <Button
          class="af-button af-button-success h-9"
          icon="pi pi-plus"
          :label="$t('productsAndServices.emptyState.action')"
        />
      </a>
    </template>
  </MEmptySection>

  <MDataTable
    v-else
    :loading
    :hasAsideColumn="false"
    :hasSelector="false"
    :tunnelProps="{ header: 'Quantity' }"
  >
    <template #tunnel="{ props }">
      <AInputNumber
        v-model="quantity[props._id]"
        :min="0"
        @onDebouncedModel="onAddToCart(props)"
      />
    </template>
  </MDataTable>
</template>
