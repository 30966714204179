import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStorage } from '@vueuse/core';
import { useFilters, routesWithFilterAsSearchParams, useActiveFilter } from '@FilterModule';
import useDataTable from './useDataTable';
import isEqual from 'lodash/isEqual';

const { filtersPayload } = useFilters();
const { activeFilters } = useActiveFilter();
const { columns } = useDataTable();

// Local Storage
let currentLocalStore: any;
const currentRouteName = ref<string | undefined>('UNDEFINED');
const newStoreReady = ref(false);

// Columns
const activeColumns = ref<string[]>([]);
const initialColumns = ref<string[]>([]);
const activeColumnsEdited = ref<boolean>(false);

// DatePicker
const endDateUnformatted = ref();
const startDateUnformatted = ref();
const endDate = ref();
const startDate = ref();
const singleDate = ref();

// Filters
const filterUrl = ref();
const page = ref(1);
const searchQuery = ref('');
const selectFilter = ref();

// Sort
const activeSortField = ref<string>('');
const activeSortOrder = ref<'asc' | 'desc'>('desc');
const sort = ref<string>();

// Other
const size = ref<'compact' | 'default' | 'large'>('default');

const defaultVisibleColumns = computed(() => {
  const activeColumns: Set<string> = new Set();

  if (!columns.value?.length) return [];

  columns.value.forEach(({ field, visible }) => !!visible && activeColumns.add(field));
  return Array.from(activeColumns);
});

watch(currentRouteName, (routeName) => initLocalStore(routeName));

function initLocalStore(routeName?: string) {
  currentLocalStore = useStorage(getStorageKey(routeName), {
    endDateUnformatted: '',
    startDateUnformatted: '',
    startDate: '',
    endDate: '',
    filterUrl: '',
    selectFilter: '',
    searchQuery: '',
    activeColumns: [],
    initialColumns: [],
    columns: [],
    activeFilters: <any[]>[],
    size: 'default',
  });

  setInitialRefsValue();
}

function setInitialRefsValue() {
  if (!currentLocalStore) return;

  endDate.value = currentLocalStore.value.endDate;
  endDateUnformatted.value = currentLocalStore.value.endDateUnformatted;
  filterUrl.value = currentLocalStore.value.filterUrl;
  selectFilter.value = currentLocalStore.value.selectFilter;
  startDate.value = currentLocalStore.value.startDate;
  startDateUnformatted.value = currentLocalStore.value.startDateUnformatted;
  searchQuery.value = currentLocalStore.value.searchQuery;

  activeColumns.value = currentLocalStore.value.activeColumns;
  initialColumns.value = currentLocalStore.value.initialColumns;
  activeFilters.value = currentLocalStore.value.activeFilters;
  columns.value = currentLocalStore.value.columns;
  size.value = currentLocalStore.value.size;

  newStoreReady.value = true;
}

function getStorageKey(name?: string) {
  return name ? `af-filters-${name}` : 'UNDEFINED';
}

function getFilterObj(filterObj: string) {
  const filterObjString = `{${filtersPayload.value.filterObj}}`;
  const noAmpersandChar = !filterObjString.includes('&');

  if (noAmpersandChar) return `&filterObj=${filterObjString}`;

  filterObj = JSON.parse(filterObjString);
  const filterObjEncoded = encodeURIComponent(JSON.stringify(filterObj));

  return `&filterObj=${filterObjEncoded}`;
}

export default function (refreshLocalStorage: boolean = true) {
  const ROUTE = useRoute();

  if (refreshLocalStorage) updateRouteName();

  const mainRequestUrlParams = computed(() => {
    const searchParam = routesWithFilterAsSearchParams.includes(ROUTE.path) ? 'filter' : 'search';
    let params = '';

    if (sort.value) params += `&sort=${sort.value}`;
    if (searchQuery.value) params += `&${searchParam}=${searchQuery.value}`;
    if (filtersPayload.value.filterObj) params += getFilterObj(filtersPayload.value.filterObj);
    if (filtersPayload.value.specifics) params += `&${filtersPayload.value.specifics}`;
    // TODO :: Once Gerald change the expected response on endpoint, remove startDateUnformatted and endDateUnformatted
    if (startDateUnformatted.value) params += `&start_date=${startDateUnformatted.value}`;
    if (endDateUnformatted.value) params += `&end_date=${endDateUnformatted.value}`;
    if (page.value) params += `&page=${page.value}`;
    if (selectFilter.value?.value || selectFilter.value)
      params += selectFilter.value.label
        ? `&${selectFilter.value.label}=${selectFilter.value.value}`
        : selectFilter.value;

    return params;
  });

  watch(
    [
      endDateUnformatted,
      searchQuery,
      startDate,
      endDate,
      startDateUnformatted,
      filterUrl,
      selectFilter,
      activeFilters,
      activeColumns,
      initialColumns,
      columns,
      size,
    ],
    () => updateLocalStorage(),
    { deep: true }
  );

  function updateLocalStorage() {
    if (!refreshLocalStorage) return;
    if (!newStoreReady.value) return;
    if (!currentLocalStore?.value) return;

    const columnsInStorage = currentLocalStore.value?.columns?.length
      ? currentLocalStore.value.columns
      : columns.value;

    const apiColumns = columns.value.map(({ field }) => field);
    const localColumns = columnsInStorage.map((column: any) => column.field);
    const newColumns = isEqual(apiColumns, localColumns);

    if (Array.isArray(columnsInStorage) && !newColumns) {
      columnsInStorage.forEach((col: any) => {
        col.visible = activeColumns.value.includes(col.field);
      });
    }

    currentLocalStore.value = {
      endDateUnformatted: endDateUnformatted.value,
      endDate: endDate.value,
      startDateUnformatted: startDateUnformatted.value,
      startDate: startDate.value,
      selectFilter: selectFilter.value,
      filterUrl: filterUrl.value,
      activeColumns: activeColumns.value,
      initialColumns: initialColumns.value,
      activeFilters: activeFilters.value,
      searchQuery: searchQuery.value,
      columns: newColumns ? columns.value : columnsInStorage,
      size: size.value,
    };
  }

  function updateRouteName() {
    if (!ROUTE) return;

    const routeName = ROUTE.name?.toString();

    currentRouteName.value = routeName;
  }

  function reset() {
    page.value = 1;

    activeSortField.value = '';
    activeSortOrder.value = 'desc';

    newStoreReady.value = false;
  }

  return {
    activeColumns,
    activeColumnsEdited,
    currentLocalStore,
    endDate,
    endDateUnformatted,
    filterUrl,
    initialColumns,
    page,
    searchQuery,
    selectFilter,
    singleDate,
    size,
    startDate,
    startDateUnformatted,
    defaultVisibleColumns,

    activeSortField,
    activeSortOrder,
    sort,

    mainRequestUrlParams,
    reset,
  };
}
