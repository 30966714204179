import { computed, ref, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import type { Step } from '../types';
import { useSessionStore } from '@Stores';
import { CART_STEPS, OUTCOMES_TYPES } from '../types';

import MCartStepAddProductsAndServices from '../components/molecules/MCartStep/MCartStepAddProductsAndServices/MCartStepAddProductsAndServices.vue';
import MCartStepAdoptionInfo from '../components/molecules/MCartStep/MCartStepAdoptionInfo/MCartStepAdoptionInfo.vue';
import MCartStepCheckoutSelector from '../components/molecules/MCartStep/MCartStepCheckoutSelector.vue';
import MCartStepMobileCheckout from '../components/molecules/MCartStep/MCartStepMobileCheckout.vue';
import MCartStepOutcomeInfo from '../components/molecules/MCartStep/MCartStepOutcomeInfo/MCartStepOutcomeInfo.vue';
import MCartStepPaymentMethod from '../components/molecules/MCartStep/MCartStepPaymentMethod.vue';
import MCartStepProductionInfo from '../components/molecules/MCartStep/MCartStepProductInfo/MCartStepProductInfo.vue';
import MCartStepReceipt from '../components/molecules/MCartStep/MCartStepReceipt.vue';

const ADD_PEOPLE_STEP_HEADERS = {
  adopter: {
    tooltip: 'cart.tooltip.adopterInfo',
    header: {
      icon: 'faAddressCard',
      title: 'cart.stepper.adopter.title',
      subtitle: 'cart.stepper.adopter.subtitle',
    },
  },
  citation: {
    tooltip: 'cart.tooltip.adopterInfo',
    header: {
      icon: 'faAddressCard',
      title: 'cart.stepper.citation.title',
      subtitle: 'cart.stepper.citation.subtitle',
    },
  },
  guardian: {
    tooltip: 'cart.tooltip.summary',
    header: {
      icon: 'faUser',
      title: 'cart.stepper.guardian.title',
      subtitle: 'cart.stepper.guardian.subtitle',
    },
  },
  product: {
    tooltip: 'cart.tooltip.summary',
    header: {
      icon: 'faUser',
      title: 'cart.stepper.buyer.title',
      subtitle: 'cart.stepper.buyer.subtitle',
    },
  },
};

const currentFlow = ref<CART_STEPS[]>([]);
const currentStep = ref<CART_STEPS>(CART_STEPS.SUMMARY);

const STEPS = ref<Step[]>([
  {
    active: false,
    component: shallowRef(MCartStepOutcomeInfo),
    name: CART_STEPS.OUTCOME_INFO,
    tooltip: 'cart.tooltip.outcomeInfo',
    header: {
      icon: 'faListCheck',
      title: 'cart.stepper.outcomeInfo.title',
      subtitle: 'cart.stepper.outcomeInfo.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepProductionInfo),
    name: CART_STEPS.PRODUCTS_INFO,
    tooltip: 'cart.tooltip.outcomeInfo',
    header: {
      icon: 'faBox',
      title: 'cart.stepper.productsInfo.title',
      subtitle: 'cart.stepper.productsInfo.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepAddProductsAndServices),
    name: CART_STEPS.PRODUCTS_AND_SERVICES,
    tooltip: 'cart.tooltip.addProducts',
    header: {
      icon: 'faBox',
      title: 'cart.stepper.products.title',
      subtitle: 'cart.stepper.products.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepCheckoutSelector),
    name: CART_STEPS.CHECKOUT_METHOD,
    tooltip: 'cart.tooltip.checkoutMethod',
    header: {
      icon: 'faFileInvoiceDollar',
      title: 'cart.stepper.checkoutMethod.title',
      subtitle: 'cart.stepper.checkoutMethod.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepAdoptionInfo),
    name: CART_STEPS.ADD_PEOPLE,
    tooltip: 'cart.tooltip.adopterInfo',
    header: {
      icon: 'faAddressCard',
      title: 'cart.stepper.adopter.title',
      subtitle: 'cart.stepper.adopter.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepMobileCheckout),
    name: CART_STEPS.MOBILE_CHECKOUT,
    tooltip: 'cart.tooltip.mobileCheckout',
    header: {
      icon: 'faMobile',
      title: 'cart.stepper.pendingMobileCheckout.title',
      subtitle: 'cart.stepper.pendingMobileCheckout.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepPaymentMethod),
    name: CART_STEPS.PAYMENT_METHOD,
    tooltip: 'cart.tooltip.paymentMethod',
    header: {
      icon: 'faWallet',
      title: 'cart.stepper.paymentMethod.title',
      subtitle: 'cart.stepper.paymentMethod.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepReceipt),
    name: CART_STEPS.MOBILE_PROCESSING,
    tooltip: 'cart.tooltip.summary',
    header: {
      icon: 'faCheck',
      title: 'cart.stepper.summary.title',
      subtitle: 'cart.stepper.summary.subtitle',
    },
  },
  {
    active: false,
    component: shallowRef(MCartStepReceipt),
    name: CART_STEPS.SUMMARY,
    tooltip: 'cart.tooltip.summary',
    header: {
      icon: 'faCheck',
      title: 'cart.stepper.summary.title',
      subtitle: 'cart.stepper.summary.subtitle',
    },
  },
]);

const previousStep = computed(() => {
  const previousStepIndex = currentFlow.value.indexOf(currentStep.value) - 1;
  return currentFlow.value[previousStepIndex];
});

const nextStep = computed(() => {
  const nextStepIndex = currentFlow.value.indexOf(currentStep.value) + 1;
  const nextStepName = currentFlow.value[nextStepIndex];

  return {
    step: STEPS.value.find(({ name }) => name === nextStepName),
    nextIsFinalStep: nextStepIndex + 1 >= currentFlow.value.length,
  };
});

const nextStepButton = computed(() => ({
  label: nextStep.value.nextIsFinalStep ? 'common.complete' : 'common.nextStep',
  icon: nextStep.value.step ? nextStep.value.step.header.icon : '',
}));

export default function () {
  const { shelterHasProducts } = storeToRefs(useSessionStore());

  const {
    ADD_PEOPLE,
    CHECKOUT_METHOD,
    MOBILE_CHECKOUT,
    OUTCOME_INFO,
    PAYMENT_METHOD,
    PRODUCTS_AND_SERVICES,
    SUMMARY,
  } = CART_STEPS;

  const {
    // IN CARE
    ADOPTED,
    DIED_IN_CARE,
    DOA,
    LOST,
    OWNER_REQUESTED_EUTHANASIA,
    RETURN_TO_FIELD,
    RETURN_TO_OWNER,
    SERVICE_OUT,
    SHELTER_EUTHANASIA,
    TRANSFER_OUT,

    // LOST & FOUND
    FINDER_KEPT,
    REHOMED,
    REUNITED_WITH_OWNER,
    SHELTER_INTAKE,
    UNKNOWN,

    // REHOME
    REHOME,
    REHOME_SHELTER_INTAKE,
    REHOME_UNKNOWN,
    REMAINED_WITH_OWNER,

    // CITATION
    CITATION,

    // INTAKE
    INTAKE,

    // PRODUCTS
    PRODUCTS,
  } = OUTCOMES_TYPES;

  const PLAIN_OUTCOME_STEPS = [OUTCOME_INFO, SUMMARY];
  const PLAIN_OUTCOME_TYPES = [
    DIED_IN_CARE,
    DOA,
    LOST,
    OWNER_REQUESTED_EUTHANASIA,
    REHOME,
    REHOME_SHELTER_INTAKE,
    REHOME_UNKNOWN,
    REMAINED_WITH_OWNER,
    RETURN_TO_FIELD,
    SERVICE_OUT,
    SHELTER_EUTHANASIA,
    SHELTER_INTAKE,
    TRANSFER_OUT,
    UNKNOWN,
  ];

  const OUTCOME_GUARDIAN_STEPS = [OUTCOME_INFO, ADD_PEOPLE, SUMMARY];
  const OUTCOME_GUARDIAN_TYPES = [FINDER_KEPT, REUNITED_WITH_OWNER, REHOMED];

  const OUTCOMES_CART_MOBILE_STEPS = [OUTCOME_INFO, CHECKOUT_METHOD, MOBILE_CHECKOUT, SUMMARY];

  const OUTCOMES_CART_MANUAL_STEPS = [
    OUTCOME_INFO,
    CHECKOUT_METHOD,
    ADD_PEOPLE,
    PAYMENT_METHOD,
    SUMMARY,
  ];

  const OUTCOMES_CART_MOBILE_WITH_PRODUCTS_STEPS = [
    OUTCOME_INFO,
    PRODUCTS_AND_SERVICES,
    CHECKOUT_METHOD,
    MOBILE_CHECKOUT,
    SUMMARY,
  ];

  const OUTCOMES_CART_MANUAL_WITH_PRODUCTS_STEPS = [
    OUTCOME_INFO,
    PRODUCTS_AND_SERVICES,
    CHECKOUT_METHOD,
    ADD_PEOPLE,
    PAYMENT_METHOD,
    SUMMARY,
  ];

  const OUTCOMES_CART_TYPES = [ADOPTED, RETURN_TO_OWNER];

  const CITATION_CART_STEPS = [ADD_PEOPLE, PAYMENT_METHOD, SUMMARY];

  const PRODUCTS_CART_MANUAL_STEPS = [
    PRODUCTS_AND_SERVICES,
    CHECKOUT_METHOD,
    ADD_PEOPLE,
    PAYMENT_METHOD,
    SUMMARY,
  ];
  const PRODUCTS_CART_MOBILE_STEPS = [
    PRODUCTS_AND_SERVICES,
    CHECKOUT_METHOD,
    MOBILE_CHECKOUT,
    SUMMARY,
  ];

  const INTAKE_CART_MANUAL_STEPS = [CHECKOUT_METHOD, PAYMENT_METHOD, SUMMARY];
  const INTAKE_CART_MOBILE_STEPS = [CHECKOUT_METHOD, MOBILE_CHECKOUT, SUMMARY];

  function setActiveOutcomeSteps(cartType?: OUTCOMES_TYPES, outcomeType?: OUTCOMES_TYPES) {
    const type =
      cartType && [INTAKE, CITATION, PRODUCTS].includes(cartType)
        ? cartType
        : outcomeType ?? cartType ?? null;

    if (!type) return;

    const isIntake = type === INTAKE;
    const isCitation = type === CITATION;
    const isPlainOutcome = PLAIN_OUTCOME_TYPES.includes(type);
    const isGuardianOutcome = OUTCOME_GUARDIAN_TYPES.includes(type);
    const isCartOutcome = OUTCOMES_CART_TYPES.includes(type);
    const isProduct = type === 'product';

    const hasProductStep = shelterHasProducts.value && !isIntake && !isCitation;

    const outcomeStepsFlow = hasProductStep
      ? OUTCOMES_CART_MOBILE_WITH_PRODUCTS_STEPS
      : OUTCOMES_CART_MOBILE_STEPS;

    // prettier-ignore
    switch (true) {
      case isProduct: return setOutcomeFlow(PRODUCTS_CART_MOBILE_STEPS, 'product');
      case isIntake: return setOutcomeFlow(INTAKE_CART_MANUAL_STEPS);
      case isCitation: return setOutcomeFlow(CITATION_CART_STEPS, 'citation');
      case isPlainOutcome: return setOutcomeFlow(PLAIN_OUTCOME_STEPS);
      case isGuardianOutcome: return setOutcomeFlow(OUTCOME_GUARDIAN_STEPS, 'guardian');
      case isCartOutcome: return setOutcomeFlow(outcomeStepsFlow, 'adopter');
      default: throw new Error(`Unexpected outcome type as setActiveOutcomeSteps(): ${type}`);;
    }
  }

  function setOutcomeFlow(
    flow: CART_STEPS[],
    peopleStepType?: keyof typeof ADD_PEOPLE_STEP_HEADERS
  ) {
    const activeSteps: Step[] = [];
    const inactiveSteps: Step[] = [];

    STEPS.value.forEach((step: Step) => {
      const peopleStepNeedsHeaderUpdate = step.name === ADD_PEOPLE && peopleStepType;
      if (peopleStepNeedsHeaderUpdate) {
        const { header, tooltip } = ADD_PEOPLE_STEP_HEADERS[peopleStepType];
        step.header = header;
        step.tooltip = tooltip;
      }
      const isActive = flow.includes(step.name);
      step.active = isActive;

      if (isActive) {
        const position = flow.indexOf(step.name);
        activeSteps[position] = step;
        return;
      }

      inactiveSteps.push(step);
    });

    STEPS.value = [...activeSteps, ...inactiveSteps];

    currentFlow.value = flow;
  }

  return {
    currentFlow,
    currentStep,
    nextStep,
    nextStepButton,
    previousStep,

    ADD_PEOPLE_STEP_HEADERS,
    OUTCOMES_CART_MANUAL_STEPS,
    OUTCOMES_CART_MANUAL_WITH_PRODUCTS_STEPS,
    OUTCOMES_CART_MOBILE_STEPS,
    OUTCOMES_CART_MOBILE_WITH_PRODUCTS_STEPS,
    PRODUCTS_CART_MANUAL_STEPS,
    PRODUCTS_CART_MOBILE_STEPS,
    INTAKE_CART_MANUAL_STEPS,
    INTAKE_CART_MOBILE_STEPS,
    STEPS,

    setActiveOutcomeSteps,
    setOutcomeFlow,
  };
}
