import { API } from '@Providers';

// NOTE: This should be a GET method but for some reason the back asks for post with this payload
export default async function (type?: string) {
  const payload = { lists: type ? [type] : [] };

  const endpoint = '/api/shelter/users/get-all-staff';

  return API({ endpoint, method: 'post', payload });
}
