<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  scrollHeight: string;
}>();

const updatedScrollHeight = computed(() => {
  const numericHeight = parseInt(props.scrollHeight, 10);
  const newHeight = numericHeight - 16;
  return `${newHeight}px`;
});
</script>

<template>
  <div
    class="flex min-h-max flex-col items-center justify-center gap-3 rounded-md rounded-t-none border border-t-0 border-gray-200 py-4 text-center text-slate-400"
    :style="{ height: updatedScrollHeight }"
  >
    <span class="pi pi-inbox mb-2 text-center text-2xl"></span>

    <p>{{ $t('components.dataTable.emptyMessages.emptyMessage') }}</p>
  </div>
</template>
