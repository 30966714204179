<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import useCartStore from '../../../store';

const props = defineProps<{
  cartData?: any;
  outcomeCompleted?: boolean;
}>();

const cartStore = storeToRefs(useCartStore());
const { cartTypeIsCitation, cartTypeIsProduct, cartTypeIsService, getType } = useCartStore();

const cartType = computed(() => (props.cartData ? getType(props.cartData) : cartStore.type.value));
const cartItemType = computed(() => {
  // prettier-ignore
  return isCitation.value ? 'citation' :
         isProduct.value ? 'product' :
         isService.value ? 'service' :
         undefined;
});
const isCitation = computed(() =>
  props.cartData ? cartTypeIsCitation(props.cartData) : cartStore.isCitation.value
);
const isProduct = computed(() =>
  props.cartData ? cartTypeIsProduct(props.cartData) : cartStore.isProduct.value
);
const isService = computed(() =>
  props.cartData ? cartTypeIsService(props.cartData) : cartStore.isService.value
);

const showDiscountsSelector = computed(
  () =>
    !props.outcomeCompleted &&
    (!isCitation.value || !isProduct.value || isService.value) &&
    ['outcome', 'intake'].includes(cartType.value)
);
</script>

<template>
  <div>
    <div>
      <div class="flex items-center gap-4">
        <h3 class="block min-w-20 font-semibold">{{ $t('cart.label.cartTotal') }}</h3>

        <Divider
          class="before:border-t-2"
          type="dashed"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 items-start gap-4 pt-2">
      <MCartDiscounts v-if="showDiscountsSelector" />

      <ACartItemCost
        v-if="cartData"
        totalOnly
        :cartData
        :cartItemType
        :item="cartData"
      />
    </div>
  </div>
</template>
