const ADOPT_URL = import.meta.env.VITE_ADOPT_URL || 'https://adopt.animalsfirst.com';
const API_URL = import.meta.env.VITE_API_URL || 'https://animalsfirst.com';
const APP_URL = import.meta.env.VITE_APP_URL || 'app.animalsfirst.com';
const DEFAULT_PAGINATION = import.meta.env.VITE_DEFAULT_PAGINATION || 100;
const DEMO_URL = 'appdev.animalsfirst.com';
const FORM_DEBOUNCE = import.meta.env.VITE_FORM_DEBOUNCE || 300;
const FOSTER_URL = import.meta.env.VITE_FOSTER_URL || 'https://foster.animalsfirst.com';
const IDLE_TIME = 3600000;
const IS_PROD = import.meta.env.PROD;
const MAIN_DOMAIN = import.meta.env.VITE_MAIN_DOMAIN || '.animalsfirst.com';
const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN || null;
const SENTRY_DNS = import.meta.env.VITE_SENTRY || null;
const SENTRY_PROPAGATION_TARGETS = import.meta.env.VITE_SENTRY_PROPAGATION_TARGETS || null;

export {
  ADOPT_URL,
  API_URL,
  APP_URL,
  DEFAULT_PAGINATION,
  DEMO_URL,
  FORM_DEBOUNCE,
  FOSTER_URL,
  IDLE_TIME,
  IS_PROD,
  MAIN_DOMAIN,
  SENTRY_AUTH_TOKEN,
  SENTRY_DNS,
  SENTRY_PROPAGATION_TARGETS,
};
