<script setup lang="ts">
import { computed } from 'vue';
import { DATE_FIELDS } from '@Constants';
import { getDefaultDateFormat } from '@Helpers';
import type { FieldSchema } from '../../../types';
import { FIELDS_COMPONENTS } from '../../../constants';

const props = defineProps<{
  field: FieldSchema;
  model: any;
}>();

const { DATE } = FIELDS_COMPONENTS;

const fieldValue = computed(() => {
  const { model } = props;

  const isArray = Array.isArray(model);
  if (isArray) return model.length ? model.join(', ') : '-';

  const isObjectValue = model?.value;
  if (isObjectValue) return isObjectValue;

  const isUndefined = !model;
  if (isUndefined) return '-';

  const isDate =
    props.field.render?.component === DATE ||
    DATE_FIELDS.includes(String(props.field.settings.type));
  const isBirthdayField = props.field.settings.type === 'birthday';

  if (isDate && isBirthdayField && model?.birthdayModel)
    return getDefaultDateFormat(model.birthdayModel, props.field.settings?.withTime);

  if (isDate) return getDefaultDateFormat(model, props.field.settings?.withTime);

  return model;
});
</script>

<template>
  <p class="whitespace-pre-line text-sm">{{ fieldValue }}</p>
</template>
