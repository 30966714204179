import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'rehoming',
    name: 'rehoming',
    meta: {
      featureFlag: FEATURE_FLAGS.REHOME,
      permissions: [ROLES.REHOME],
    },
    children: [
      {
        path: 'animals',
        name: 'rehome-animals',
        component: () => import('./views/RehomeAnimals.view.vue'),
      },
      {
        path: 'map',
        name: 'rehome-map',
        component: () => import('./views/RehomeMap.view.vue'),
      },
      {
        path: 'stats',
        name: 'rehome-stats',
        component: () => import('./views/RehomeStats.view.vue'),
      },
      {
        path: 'outcome-report',
        name: 'rehome-outcome-report',
        component: () => import('./views/RehomeOutcomeReport.view.vue'),
      },
      {
        path: 'rehome-animal',
        name: 'rehome-animal',
        component: () => import('./views/RehomeForm.view.vue'),
      },
      {
        path: 'add-animal/:id',
        name: 'rehome-people-linked-animal-form',
        component: () => import('./views/RehomePeopleLinkedForm.view.vue'),
      },
    ],
  },
];
