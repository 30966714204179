import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useSessionStore } from '@Stores';
import { useForm } from '@FormModule';

const animalId = ref('');
const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formData = ref();
const formError = ref();
const formId = ref('');
const formList = ref();
const formName = ref('');
const formTemplateId = ref('');
const formTemplateName = ref('');
const formTitle = ref('');
const formType = ref('');
const intakeFormType = ref('');
const kennels = ref();
const rawFields = ref<RawField[]>([]);

export default function () {
  const { getDetailsSchema } = useForm();
  const { shelterProfile } = storeToRefs(useSessionStore());

  const INITIAL_FORM_TEMPLATE = 'dog';

  const hasVisualKennel = computed(() => {
    if (!kennels.value) return;

    return kennels?.value?.find((kennel: any) => kennel?.name);
  });
  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      kennel: {
        edit: {
          label: hasVisualKennel.value ? 'name' : '',
          options: kennels.value,
        },
        settings: {
          show: !shelterProfile.value?.use_kennel_manager,
        },
      },
      kennel_manager: {
        settings: {
          show: false,
        },
      },
      animal_name: {
        settings: {
          type: 'animal_name',
        },
      },
      animal_status: {
        edit: {
          label: 'label',
        },
      },
      secondary_color_map: {
        edit: {
          label: 'value',
        },
      },
      tertiary_color_map: {
        edit: {
          label: 'value',
        },
      },
      animal_template: {
        model: INITIAL_FORM_TEMPLATE,
      },
      breed: {
        edit: {
          options: [],
        },
        settings: {
          type: 'breeds',
        },
      },
      intake_date: {
        model: new Date(),
      },
      'google-address': {
        settings: {
          type: 'location',
        },
      },
      partner_name: {
        edit: {
          label: 'name',
          options: [],
        },
      },
      photo: {
        settings: {
          type: 'file',
          multiple: true,
          fileLimit: 5,
        },
      },
      file: {
        settings: {
          imageOnly: false,
          multiple: true,
          fileLimit: 5,
        },
      },
      secondary_breed: {
        edit: {
          options: [],
        },
        settings: {
          type: 'select',
        },
      },
      species: {
        model: [],
        edit: {
          options: [],
        },
        settings: {
          show: true,
        },
      },
      email: {
        settings: {
          type: 'contact',
        },
      },
      phone: {
        settings: {
          type: 'contact',
        },
      },
    })
  );

  const selectedTemplate = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'animal_template')
  );
  const formTemplate = computed(() => selectedTemplate.value?.model);

  function setForm(sourceData?: any) {
    formData.value = dataParser(rawFields.value, sourceData);
    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);
  }

  function dataParser(rawFields: RawField[], sourceData: any) {
    const settingsKeys = Object.keys(fieldsSettings.value);

    rawFields.forEach((field) => {
      const { name } = field;
      const fieldWithNoSettings = !settingsKeys.includes(name);
      if (fieldWithNoSettings) return;

      const SETTINGS = fieldsSettings.value[name].settings;
      if (!SETTINGS) return;
    });

    return sourceData;
  }

  function resetFieldSchema() {
    fieldsSchema.value = [];
    formId.value = '';
    formName.value = '';
    formTitle.value = '';
    rawFields.value = [];
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formData.value = null;
    formError.value = null;
  }

  return {
    INITIAL_FORM_TEMPLATE,
    animalId,
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formData,
    formError,
    formId,
    formList,
    formName,
    formTemplate,
    formTemplateId,
    formTemplateName,
    formTitle,
    formType,
    intakeFormType,
    kennels,
    rawFields,
    resetFieldSchema,
    setForm,
  };
}
