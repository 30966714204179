<script setup lang="ts">
// TODO - AF-535 - This component needs a full review to double check the validations
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import type { GenericArray } from '@Types';
import type { CartItemType } from '../../../types';
import { capitalizeStr, formatTimestampToSimpleDate } from '@Helpers';
import useCartStore from '../../../store';

interface CartDetail {
  extraClass?: string;
  hasDivider: boolean;
  label: string;
  quantityValue?: string;
  sectionDivider?: string;
  totalOnly?: boolean;
  value?: string | boolean;
}

const props = defineProps<{
  cartData: any;
  cartItemType?: CartItemType;
  item: GenericArray;
  totalOnly?: boolean;
}>();

const cartStore = storeToRefs(useCartStore());
const { getHasCart, cartTypeIsProduct } = useCartStore();

const { t } = useI18n();

const isProduct = computed(() => cartTypeIsProduct(props.cartData));
const paymentTypeCc = computed(() => props.cartData?.payment_type === 'credit_card');
const hasCart = computed(() => {
  if (props.cartData && isProduct.value) return isProduct.value;

  return props.cartData ? getHasCart(props.cartData) : cartStore.hasCart.value;
});

const useI18NPlural = computed(() => (props.totalOnly ? 2 : 1));
const isReturnToOwner = computed(() => props.item?.outcome_type === 'return_to_owner');

// INDIVIDUAL FIELDS
const additionalFees = computed(() => {
  const isTotal = !Array.isArray(props.item.additional_fees);
  if (isTotal) {
    return [
      {
        hasDivider: false,
        label: 'Additional Fees',
        value: parseFloat(props.item?.additional_fees)
          ? fixStringNumber(props.item.additional_fees)
          : false,
      },
    ];
  }

  const emptyAdditionalFees = !props.item?.additional_fees.length;
  if (emptyAdditionalFees) return [];

  const additionalFeesFieldHeader = {
    sectionDivider: 'Additional Fees',
    hasDivider: true,
    label: '',
    value: true,
  };

  const additionalFeesFields = props.item.additional_fees.map((fee: any) => {
    return {
      hasDivider: false,
      label: fee.description,
      value: parseFloat(fee.amount) ? fixStringNumber(fee.amount) : false,
    };
  });

  return [additionalFeesFieldHeader, ...additionalFeesFields];
});

const discountField = computed(() => {
  const discountKey = props.totalOnly ? 'discount' : 'discount_amount';
  const hasDiscount = props.item[discountKey] && props.item[discountKey] !== '0.00';

  return [
    {
      extraClass: 'text-red-500',
      hasDivider: false,
      label: 'cart.summary.discount',
      value: hasDiscount ? `- ${fixStringNumber(props.item[discountKey])}` : false,
    },
  ];
});

const intakeDate = computed(() => {
  return props.totalOnly
    ? []
    : {
        hasDivider: false,
        label: 'cart.summary.intakeDate',
        value: props.item?.intake_date ? formatTimestampToSimpleDate(props.item.intake_date) : '-',
      };
});

// TYPE FIELDS
const adoptionFields = computed(() => {
  const isAdopted = props.item?.outcome_type === 'adopted';
  if (!isAdopted) return [];

  return [
    {
      hasDivider: false,
      label: t('cart.summary.adoptionFee', useI18NPlural.value),
      value: parseFloat(props.item?.fee) > 0 ? fixStringNumber(props.item?.fee) : false,
    },
  ];
});

const intakeFields = computed(() => {
  const isIntake = props.cartData?.cart_type === 'intake';
  if (!isIntake) return [];

  //prettier-ignore
  const intakeFee = isIntake
    ? fixStringNumber(props.item?.fee) ?? '0.00'
    : parseFloat(props.item?.fee) > 0 ? fixStringNumber(props.item?.fee)
    : false;

  return [
    {
      totalOnly: false,
      hasDivider: false,
      label: t('Intake Fee', useI18NPlural.value),
      value: intakeFee,
    },
  ];
});

const rtoFields = computed(() => {
  if (!isReturnToOwner.value) return [];

  const dailyFeeFields = getRtoDailyFeeFields();

  const rtoFee = props.totalOnly ? props.item?.fee : props.item?.rto_fee;
  const rtoHeader = !props.totalOnly
    ? {
        sectionDivider: 'RTO details',
        hasDivider: false,
        label: '',
        value: true,
      }
    : '';

  return [
    rtoHeader,
    intakeDate.value,
    {
      hasDivider: false,
      label: 'Fee Type',
      value: props.item?.rto_fee_type ? capitalizeStr(props.item.rto_fee_type) : false,
    },
    ...dailyFeeFields,
    {
      hasDivider: false,
      label: t('cart.summary.rtoFee', useI18NPlural.value),
      value: parseFloat(rtoFee) ? fixStringNumber(rtoFee) : false,
    },
    {
      hasDivider: false,
      label: 'Extra Cost',
      value: parseFloat(props.item.additional_rto_cost)
        ? fixStringNumber(props.item.additional_rto_cost)
        : false,
    },
  ];
});

// GENERAL
const cartDetails = computed((): CartDetail[] =>
  [
    {
      hasDivider: false,
      label: 'cart.summary.products',
      value:
        parseFloat(props.item?.product_subtotal) > 0
          ? fixStringNumber(props.item?.product_subtotal)
          : false,
    },
    {
      hasDivider: false,
      label: 'cart.summary.services',
      value:
        parseFloat(props.item?.service_subtotal) > 0
          ? fixStringNumber(props.item?.service_subtotal)
          : false,
    },
    ...adoptionFields.value,
    ...rtoFields.value,
    ...intakeFields.value,
    ...additionalFees.value,
    {
      hasDivider: props.item?.discount === '0.00',
      label: t('cart.summary.serviceFee', props.totalOnly ? 2 : 1),
      value:
        parseFloat(props.item?.service_fee) > 0 ? fixStringNumber(props.item?.service_fee) : false,
    },
    {
      hasDivider: false,
      label: 'Extra Costs',
      value: parseFloat(props.item.additional_cost)
        ? fixStringNumber(props.item.additional_cost)
        : false,
    },
    ...discountField.value,
    {
      hasDivider: true,
      label: 'cart.summary.subtotal',
      value:
        parseFloat(props.item?.subtotal) && parseFloat(props.item?.tax)
          ? fixStringNumber(props.item?.subtotal)
          : false,
    },
    {
      hasDivider: false,
      label: 'cart.summary.tax',
      value: parseFloat(props.item?.tax) ? fixStringNumber(props.item?.tax) : false,
    },
    ...(paymentTypeCc.value
      ? [
          {
            hasDivider: false,
            label: 'cart.label.creditCardFee',
            value:
              props.item?.charge_adopter_cc_fee && parseFloat(props.item?.cc_fee)
                ? fixStringNumber(props.item?.cc_fee)
                : false,
          },
        ]
      : []),
    {
      hasDivider: false,
      label: 'stats.donations',
      value: parseFloat(props.item?.donation) ? fixStringNumber(props.item?.donation) : false,
    },
    {
      hasDivider: true,
      label: 'cart.summary.total',
      value: getTotal(),
    },
  ].filter((item) => !!item.value)
);

const outcomeDetails = computed((): CartDetail[] => [
  {
    hasDivider: false,
    label: 'cart.summary.intakeDate',
    value: props.item?.intake_date ? formatTimestampToSimpleDate(props.item.intake_date) : '-',
  },
  {
    hasDivider: false,
    label: 'cart.summary.microchip',
    value: props.item?.microchip || '-',
  },
  {
    hasDivider: false,
    label: 'cart.summary.breed',
    value: props.item?.breed || '-',
  },
  {
    hasDivider: false,
    label: 'cart.summary.gender',
    value: props.item?.gender || '-',
  },
]);

const citationDetails = computed((): CartDetail[] =>
  [
    {
      totalOnly: false,
      hasDivider: false,
      label: 'cart.summary.citation',
      value: props.item?.citation ?? '-',
    },
    {
      totalOnly: false,
      hasDivider: false,
      label: 'cart.summary.defendant',
      value: props.item?.defendant_full_name ?? '-',
    },
    {
      totalOnly: false,
      hasDivider: false,
      label: 'cart.summary.citationFee',
      value: parseFloat(props.item?.fee) ? `${fixStringNumber(props.item?.fee)}` : false,
    },
    {
      hasDivider: false,
      totalOnly: true,
      label: 'cart.summary.citations',
      value: parseFloat(props.item?.total) ? `${fixStringNumber(props.item?.total)}` : false,
    },
    {
      hasDivider: true,
      totalOnly: true,
      label: 'cart.summary.total',
      value: getTotal(),
    },
  ].filter(({ totalOnly, value }) => !!value && props.totalOnly == totalOnly)
);

const productsDetails = computed((): CartDetail[] =>
  [
    {
      totalOnly: false,
      hasDivider: false,
      label: 'cart.summary.price',
      quantityValue: parseFloat(props.item?.quantity) ? props.item?.quantity : false,
      value: parseFloat(props.item?.price) ? fixStringNumber(props.item?.price) : false,
    },
    {
      totalOnly: props.totalOnly,
      hasDivider: !props.totalOnly,
      label: 'cart.summary.tax',
      value: parseFloat(props.item?.tax) ? fixStringNumber(props.item?.tax) : false,
    },
    {
      totalOnly: props.totalOnly,
      hasDivider: false,
      label: 'cart.summary.subtotal',
      value: parseFloat(props.item?.subtotal) ? fixStringNumber(props.item?.subtotal) : false,
    },
    {
      totalOnly: props.totalOnly,
      hasDivider: false,
      label: 'cart.label.creditCardFee',
      value:
        props.item?.charge_adopter_cc_fee && parseFloat(props.item?.cc_fee)
          ? fixStringNumber(props.item?.cc_fee)
          : false,
    },
    {
      totalOnly: props.totalOnly,
      hasDivider: true,
      label: 'cart.summary.total',
      value: getTotal(),
    },
  ].filter(({ totalOnly, value }) => !!value && props.totalOnly == totalOnly)
);

const activeDetails = computed(() => {
  //prettier-ignore
  switch(props.cartItemType) {
    case 'citation': return citationDetails.value;
    case 'product': return productsDetails.value;
    default: return hasCart.value ? cartDetails.value : outcomeDetails.value;
  }
});

// HELPERS
function getTotal() {
  const total_charge = parseFloat(props.item?.total_charge);
  const total = parseFloat(props.item?.total);

  const finalTotal = total_charge > 0 ? total_charge : total;

  return fixStringNumber(finalTotal.toString());
}

function fixStringNumber(target?: string | boolean) {
  if (!target) return target;

  return typeof target === 'boolean' ? target : `$${parseFloat(target).toFixed(2).toString()}`;
}

function getRtoDailyFeeFields() {
  const dailyFeeType = props.item?.rto_fee_type === 'daily';
  if (!dailyFeeType) return [];

  const losDays = parseInt(props.item.los) > 1 ? 2 : 1;
  return [
    {
      hasDivider: false,
      label: 'Base Fee',
      value:
        parseFloat(props.item?.base_rto_fee) > 0
          ? fixStringNumber(props.item?.base_rto_fee)
          : false,
    },
    {
      hasDivider: false,
      label: 'LOS',
      value: props.item?.los ? `${props.item.los} ${t('common.day', losDays)}` : false,
    },
  ];
}
</script>

<template>
  <div>
    <div
      v-for="{
        extraClass,
        hasDivider,
        label,
        sectionDivider,
        value,
        quantityValue,
      } in activeDetails"
      :key="label"
    >
      <div v-if="sectionDivider">
        <Divider
          v-if="hasDivider"
          class="my-2"
        />

        <h3 class="mt-2 text-[15px] font-semibold">
          {{ sectionDivider }}
        </h3>
      </div>

      <div v-else>
        <Divider
          v-if="hasDivider"
          class="my-2"
        />

        <div class="grid grid-cols-2 items-center gap-2">
          <span class="text-[13px] font-semibold">
            {{ $t(label) }}
          </span>

          <div class="flex justify-end gap-1">
            <span
              v-if="quantityValue"
              :class="['text-right text-[13px]', extraClass]"
            >
              {{ quantityValue }}x
            </span>

            <span :class="['text-right text-[13px]', extraClass]">
              {{ value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
