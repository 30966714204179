enum SPECIES {
  DOG = 'Dog',
  CAT = 'Cat',
  HORSE = 'Horse',
  RABBIT = 'Rabbit',
  AFRICAN_GRAY = 'African Gray',
  ALLIGATOR = 'Alligator',
  ALPACA = 'Alpaca',
  AMAZON = 'Amazon',
  BADGER = 'Badger',
  BAT = 'Bat',
  BEAR = 'Bear',
  BOBCAT = 'Bobcat',
  BUDGIE = 'Budgie',
  CAIQUE = 'Caique',
  CANARY = 'Canary',
  CHICKEN = 'Chicken',
  CHINCHILLA = 'Chinchilla',
  CHIPMUNK = 'Chipmunk',
  COCKATIEL = 'Cockatiel',
  COCKATOO = 'Cockatoo',
  CONURE = 'Conure',
  COW = 'Cow',
  COYOTE = 'Coyote',
  CROCODILE = 'Crocodile',
  DEER = 'Deer',
  DEGU = 'Degu',
  DONKEY = 'Donkey',
  DOVE = 'Dove',
  DUCK = 'Duck',
  EAGLE = 'Eagle',
  FERRET = 'Ferret',
  FINCH = 'Finch',
  FOX = 'Fox',
  FROG = 'Frog',
  GERBIL = 'Gerbil',
  GOAT = 'Goat',
  GOOSE = 'Goose',
  GUINEA_FOWL = 'Guinea Fowl',
  GUINEA_PIG = 'Guinea Pig',
  HAMSTER = 'Hamster',
  HAWK = 'Hawk',
  HEDGEHOG = 'Hedgehog',
  HERON = 'Heron',
  IGUANA = 'Iguana',
  LIZARD = 'Lizard',
  LLAMA = 'Llama',
  LOVEBIRD = 'Lovebird',
  MACAW = 'Macaw',
  MINK = 'Mink',
  MOUFLON = 'Mouflon',
  MOUSE = 'Mouse',
  MULE = 'Mule',
  MUSKRAT = 'Muskrat',
  NEWT = 'Newt',
  OPOSSUM = 'Opossum',
  OSTRICH = 'Ostrich',
  OWL = 'Owl',
  PARAKEET = 'Parakeet',
  PARROT = 'Parrot',
  PEACOCK = 'Peacock',
  PELICAN = 'Pelican',
  PHEASANT = 'Pheasant',
  PIG = 'Pig',
  PIGEON = 'Pigeon',
  PONY = 'Pony',
  PRAIRIE_DOG = 'Prairie Dog',
  QUAIL = 'Quail',
  RACCOON = 'Raccoon',
  RAT = 'Rat',
  RAVEN = 'Raven',
  RHEA = 'Rhea',
  SALAMANDER = 'Salamander',
  SHEEP = 'Sheep',
  SHREW = 'Shrew',
  SKUNK = 'Skunk',
  SNAKE = 'Snake',
  SPARROW = 'Sparrow',
  SQUIRREL = 'Squirrel',
  SUGAR_GLIDER = 'Sugar Glider',
  SWAN = 'Swan',
  TEST_OTHER = 'Test Other',
  TORTOISE = 'Tortoise',
  TOUCAN = 'Toucan',
  TURKEY = 'Turkey',
  TURTLE = 'Turtle',
  VOLE = 'Vole',
  WARTHOG = 'Warthog',
  WEASEL = 'Weaseal',
  WEREN = 'Weren',
  WOLF = 'Wolf',
  WOODCHUCK = 'Woodchuck',
  WOODPECKER = 'Woodpecker',
}

enum CAT_BREEDS {
  MIXED = 'Mixed',
  ABYSSINIAN = 'Abyssinian',
  AEGEAN = 'Aegean',
  AMERICAN_BOBTAIL = 'American Bobtail',
  AMERICAN_CURL = 'American Curl',
  AMERICAN_POLYDACTYL = 'American Polydactyl',
  AMERICAN_SHORTHAIR = 'American Shorthair',
  AMERICAN_WIREHAIR = 'American Wirehair',
  APPLEHEAD_SIAMESE = 'Applehead Siamese',
  APHRODITES_GIANT = 'Aphrodites Giant',
  ARABIAN_MAU = 'Arabian Mau',
  ASIAN = 'Asian',
  ASIAN_SEMI_LONGHAIR = 'Asian Semi-longhair',
  AUSTRALIAN_MIST = 'Australian Mist',
  BALINESE = 'Balinese',
  BENGAL_CATS = 'Bengal Cats',
  BIAZILAIN_SHORTHAIR = 'Biazilain Shorthair',
  BIRMAN = 'Birman',
  BOMBAY = 'Bombay',
  BRITISH_LONGHAIR = 'British Longhair',
  BRITISH_SEMI_LONGHAIR = 'British Semi-longhair',
  BRITISH_SHORTHAIR = 'British Shorthair',
  BURMESE = 'Burmese',
  BURMILLA = 'Burmilla',
  CALICO = 'Calico',
  CALIFORNIA_SPANGLED_CAT = 'California Spangled Cat',
  CANADIAN_HAIRLESS = 'Canadian Hairless',
  CHANTILLY = 'Chantilly',
  CHARTREUX = 'Chartreux',
  CHAUSIE = 'Chausie',
  CHINCHILLA = 'Chinchilla',
  CHINESE_LI_HUA = 'Chinese Li Hua',
  COLORPOINT_SHORTHAIR = 'Colorpoint Shorthair',
  CORNISH_REX = 'Cornish Rex',
  COUPARI = 'Coupari',
  CYMRIC = 'Cymric',
  DEVON_REX = 'Devon Rex',
  DILUTE_CALICO = 'Dilute Calico',
  DILUTE_TORTOISESHELL = 'Dilute Tortoiseshell',
  DOMESTIC_LONG_HAIR = 'Domestic Long Hair',
  DOMESTIC_MEDIUM_HAIR = 'Domestic Medium Hair',
  DOMESTIC_SHORT_HAIR = 'Domestic Short Hair',
  DONSKOY = 'Donskoy',
  DRAGON_LI = 'Dragon Li',
  EGYPTIAN_MAU = 'Egyptian Mau',
  EUROPEAN_BURMESE = 'European Burmese',
  EXOTIC_SHORTHAIR = 'Exotic Shorthair',
  EXTRA_TOES_CAT_HEMINGWAY_POLYDACTYL = 'Extra-Toes Cat / Hemingway Polydactyl',
  FOLDEX = 'Foldex',
  GERMAN_REX = 'German Rex',
  HAVANA_BROWN = 'Havana Brown',
  HIGHLANDER = 'Highlander',
  HIMALAYAN = 'Himalayan',
  JAPANESE_BOBTAIL = 'Japanese Bobtail',
  JAVANESE = 'Javanese',
  KHAO_MANEE = 'Khao Manee',
  KORAT = 'Korat',
  KOREN_BOBTAIL = 'Koren Bobtail',
  KORN_JA = 'Korn Ja',
  KURILIAN_BOBTAIL = 'Kurilian Bobtail',
  LAPERM = 'LaPerm',
  LYKOI = 'Lykoi',
  MAINE_COON = 'Maine Coon',
  MANCHKIN = 'Manchkin',
  MANX = 'Manx',
  MUNCHKIN = 'Munchkin',
  MEKON_BOBTAIL = 'Mekon Bobtail',
  MINSKIN = 'Minskin',
  NEBELUNG = 'Nebelung',
  NORWEGIAN_FOREST = 'Norwegian Forest',
  OCICAT = 'Ocicat',
  OREGON_REX = 'Oregon Rex',
  ORIENTAL = 'Oriental',
  ORIENTAL_BICOLOR = 'Oriental Bicolor',
  ORIENTAL_LONGHAIR = 'Oriental Longhair',
  ORIENTAL_SHORTHAIR = 'Oriental Shorthair',
  ORIENTAL_TABBY = 'Oriental Tabby',
  PEKE_FACED = 'Peke-Faced',
  PERSIAN = 'Persian',
  PETERBALD = 'Peterbald',
  PIXIE_BOB = 'Pixie-Bob',
  RAAS = 'Raas',
  RAGAMUFFIN = 'Ragamuffin',
  RAGDOLL = 'Ragdoll',
  RUSSIAN_BLUE = 'Russian Blue',
  SAVANNAH = 'Savannah',
  SCOTTISH_FOLD = 'Scottish Fold',
  SELKIRK_REX = 'Selkirk Rex',
  SERRADE_PETIT = 'Serrade Petit',
  SIAMESE_CAT = 'Siamese Cat',
  SIBERIAN = 'Siberian',
  SILVER = 'Silver',
  SINGAPURA = 'Singapura',
  SNOWSHOE = 'Snowshoe',
  SOKOKE_FOREST = 'Sokoke Forest',
  SOMALI = 'Somali',
  SPHYNX = 'Sphynx',
  SUPHALEK = 'Suphalek',
  TABBY = 'Tabby',
  TIGER = 'Tiger',
  THAI = 'Thai',
  THAI_LILAC = 'Thai Lilac',
  TONKINESE = 'Tonkinese',
  TOYGER = 'Toyger',
  TORTOISESHELL = 'Tortoiseshell',
  TURKISH_ANGORA = 'Turkish Angora',
  TURKISH_VAN = 'Turkish Van',
  UKRAINIAN_LEVKOY = 'Ukrainian Levkoy',
  TUXEDO = 'Tuxedo',
  YORK_CHOCOLATE = 'York Chocolate',
}

enum DOG_BREEDS {
  MIX = 'Mix',
  AFFENPINSCHER = 'Affenpinscher',
  AFGHAN_HOUND = 'Afghan Hound',
  AIREDALE_TERRIER = 'Airedale Terrier',
  AKITA = 'Akita',
  ALASKAN_KLEE_KAI = 'Alaskan Klee Kai',
  ALASKAN_MALAMUTE = 'Alaskan Malamute',
  AMERICAN_BULLDOG = 'American Bulldog',
  AMERICAN_BULLY = 'American Bully',
  AMERICAN_ENGLISH_COONHOUND = 'American English Coonhound',
  AMERICAN_ESKIMO_DOG = 'American Eskimo Dog',
  AMERICAN_HAIRLESS_TERRIER = 'American Hairless Terrier',
  AMERICAN_LEOPARD_HOUND = 'American Leopard Hound',
  AMERICAN_PIT_BULL_TERRIER = 'American Pit Bull Terrier',
  AMERICAN_STAFFORDSHIRE_TERRIER = 'American Staffordshire Terrier',
  AMERICAN_WATER_SPANIEL = 'American Water Spaniel',
  ANATOLIAN_SHEPHERD_DOG = 'Anatolian Shepherd Dog',
  APPENZELLER_SENNHUNDE = 'Appenzeller Sennenhunde',
  APPENZELL_MOUNTAIN_DOG = 'Appenzell Mountain Dog',
  AUSTRALIAN_CATTLE_DOG = 'Australian Cattle Dog',
  AUSTRALIAN_KELPIE = 'Australian Kelpie',
  AUSTRALIAN_SHEPHERD = 'Australian Shepherd',
  AUSTRALIAN_TERRIER = 'Australian Terrier',
  AZAWAKH = 'Azawakh',
  BARBET = 'Barbet',
  BASENJI = 'Basenji',
  BASSET_FAUVE_DE_BRETAGNE = 'Basset Fauve De Bretagne',
  BASSET_HOUND = 'Basset Hound',
  BAVARIAN_MOUNTAIN_SCENT_HOUND = 'Bavarian Mountain Scent Hound',
  BEAGLE = 'Beagle',
  BEARDED_COLLIE = 'Bearded Collie',
  BEAUCERON = 'Beauceron',
  BEDLINGTON_TERRIER = 'Bedlington Terrier',
  BELGIAN_LAEKENOIS = 'Belgian Laekenois',
  BELGIAN_MALINOIS = 'Belgian Malinois',
  BELGIAN_SHEEPDOG = 'Belgian Sheepdog',
  BELGIAN_TERVUREN = 'Belgian Tervuren',
  BERGAMASCO_SHEEPDOG = 'Bergamasco Sheepdog',
  BERGER_PICARD = 'Berger Picard',
  BERNESE_MOUNTAIN_DOG = 'Bernese Mountain Dog',
  BICHON_FRISE = 'Bichon Frise',
  BIEWER_TERRIER = 'Biewer Terrier',
  BLACK_AND_TAN_COONHOUND = 'Black and Tan Coonhound',
  BLACK_LABRADOR_RETRIEVER = 'Black Labrador Retriever',
  BLACK_MOUTH_CUR = 'Black Mouth Cur',
  BLACK_RUSSIAN_TERRIER = 'Black Russian Terrier',
  BLOODHOUND = 'Bloodhound',
  BLUETICK_COONHOUND = 'Bluetick Coonhound',
  BLUE_LACY = 'Blue Lacy',
  BOERBOEL = 'Boerboel',
  BOLOGNESE = 'Bolognese',
  BORDER_COLLIE = 'Border Collie',
  BORDER_TERRIER = 'Border Terrier',
  BORZOI = 'Borzoi',
  BOSTON_TERRIER = 'Boston Terrier',
  BOUVIER_DES_FLANDRES = 'Bouvier des Flandres',
  BOXER = 'Boxer',
  BOYKIN_SPANIEL = 'Boykin Spaniel',
  BRACCO_ITALIANO = 'Bracco Italiano',
  BRAQUE_DU_BOURDONNAIS = 'Braque Du Bourdonnais',
  BRAQUE_FRANCAIS_PYRENEAN = 'Braque Francais Pyrenean',
  BRIARD = 'Briard',
  BRITTANY = 'Brittany',
  BROHOLMER = 'Broholmer',
  BRUSSELS_GRIFFON = 'Brussels Griffon',
  BULLDOG = 'Bulldog',
  BULLMASTIFF = 'Bullmastiff',
  BULL_TERRIER = 'Bull Terrier',
  CAIRN_TERRIER = 'Cairn Terrier',
  CANAAN_DOG = 'Canaan Dog',
  CANE_CORSO = 'Cane Corso',
  CARDIGAN_WELSH_CORGI = 'Cardigan Welsh Corgi',
  CAROLINA_DOG = 'Carolina Dog',
  CATAHOULA_LEOPARD_DOG = 'Catahoula Leopard Dog',
  CATTLE_DOG = 'Cattle Dog',
  CAUCASIAN_SHEPHERD_DOG = 'Caucasian Shepherd Dog',
  CAVALIER_KING_CHARLES_SPANIEL = 'Cavalier King Charles Spaniel',
  CENTRAL_ASIAN_SHEPHERD_DOG = 'Central Asian Shepherd Dog',
  CESKY_TERRIER = 'Cesky Terrier',
  CHESAPEAKE_BAY_RETRIEVER = 'Chesapeake Bay Retriever',
  CHIHUAHUA = 'Chihuahua',
  CHINESE_CRESTED = 'Chinese Crested',
  CHINESE_SHAR_PEI = 'Chinese Shar-Pei',
  CHINOOK = 'Chinook',
  CHOCOLATE_LABRADOR_RETRIEVER = 'Chocolate Labrador Retriever',
  CHOW_CHOW = 'Chow Chow',
  CIRNECO_DELLETNA = 'Cirneco Delletna',
  CLUMBER_SPANIEL = 'Clumber Spaniel',
  COCKAPOO = 'Cockapoo',
  COCKER_SPANIEL = 'Cocker Spaniel',
  COLLIE = 'Collie',
  COONHOUND = 'Coonhound',
  CORGI = 'Corgi',
  COTON_DE_TULEAR = 'Coton de Tulear',
  CURLY_COATED_RETRIEVER = 'Curly-Coated Retriever',
  CZECHOSLOVAKIAN_VLCAK = 'Czechoslovakian Vlcak',
  DACHSHUND = 'Dachshund',
  DALMATIAN = 'Dalmatian',
  DANDIE_DINMONT_TERRIER = 'Dandie Dinmont Terrier',
  DANISH_SWEDISH_FARMDOG = 'Danish-Swedish Farmdog',
  DEUTSCHER_WACHTELHUND = 'Deutscher Wachtelhund',
  DOBERMAN_PINSCHER = 'Doberman Pinscher',
  DOGO_ARGENTINO = 'Dogo Argentino',
  DOGUE_DE_BORDEAUX = 'Dogue de Bordeaux',
  DRENTSCHE_PATRIJSHOND = 'Drentsche Patrijshond',
  DREVER = 'Drever',
  DUTCH_SHEPHERD = 'Dutch Shepherd',
  ENGLISH_BULLDOG = 'English Bulldog',
  ENGLISH_COCKER_SPANIEL = 'English Cocker Spaniel',
  ENGLISH_COONHOUND = 'English Coonhound',
  ENGLISH_FOXHOUND = 'English Foxhound',
  ENGLISH_POINTER = 'English Pointer',
  ENGLISH_SETTER = 'English Setter',
  ENGLISH_SHEPHERD = 'English Shepherd',
  ENGLISH_SPRINGER_SPANIEL = 'English Springer Spaniel',
  ENGLISH_TOY_SPANIEL = 'English Toy Spaniel',
  ENTLEBUCHER_MOUNTAIN_DOG = 'Entlebucher Mountain Dog',
  ESKIMO_DOG = 'Eskimo Dog',
  ESTRELA_MOUNTAIN_DOG = 'Estrela Mountain Dog',
  FEIST = 'Feist',
  FIELD_SPANIEL = 'Field Spaniel',
  FINNISH_LAPPUND = 'Finnish Lapphund',
  FINNISH_SPITZ = 'Finnish Spitz',
  FLAT_COATED_RETRIEVER = 'Flat-Coated Retriever',
  FOXHOUND = 'Foxhound',
  FOX_TERRIER = 'Fox Terrier',
  FRENCH_BULLDOG = 'French Bulldog',
  FRENCH_SPANIEL = 'French Spaniel',
  GERMAN_LONGHAIRED_POINTER = 'German Longhaired Pointer',
  GERMAN_PINSCHER = 'German Pinscher',
  GERMAN_SHEPHERD_DOG = 'German Shepherd Dog',
  GERMAN_SHORTHAIRED_POINTER = 'German Shorthaired Pointer',
  GERMAN_SPITZ = 'German Spitz',
  GERMAN_WIREHAIRED_POINTER = 'German Wirehaired Pointer',
  GIANT_SCHNAUZER = 'Giant Schnauzer',
  GLEN_OF_IMAAL_TERRIER = 'Glen of Imaal Terrier',
  GOLDADOR = 'Goldador',
  GOLDENDOODLE = 'Goldendoodle',
  GOLDEN_RETRIEVER = 'Golden Retriever',
  GORDON_SETTER = 'Gordon Setter',
  GRAND_BASSET_GRIFFON_VENDEEN = 'Grand Basset Griffon Vendeen',
  GREATER_SWISS_MOUNTAIN_DOG = 'Greater Swiss Mountain Dog',
  GREAT_DANE = 'Great Dane',
  GREAT_PYRENEES = 'Great Pyrenees',
  GREYHOUND = 'Greyhound',
  HAMILTONSTOVARE = 'Hamiltonstovare',
  HANOVERIAN_SCENTHOUND = 'Hanoverian Scenthound',
  HARRIER = 'Harrier',
  HAVANESE = 'Havanese',
  HOKKAIDO = 'Hokkaido',
  HOUND = 'Hound',
  HOVAWART = 'Hovawart',
  HUSKY = 'Husky',
  IBIZAN_HOUND = 'Ibizan Hound',
  ICELANDIC_SHEEPDOG = 'Icelandic Sheepdog',
  IRISH_RED_AND_WHITE_SETTER = 'Irish Red and White Setter',
  IRISH_SETTER = 'Irish Setter',
  IRISH_TERRIER = 'Irish Terrier',
  IRISH_WATER_SPANIEL = 'Irish Water Spaniel',
  IRISH_WOLFHOUND = 'Irish Wolfhound',
  ITALIAN_GREYHOUND = 'Italian Greyhound',
  JACK_RUSSELL_TERRIER = 'Jack Russell Terrier',
  JAGDTERRIER = 'Jagdterrier',
  JAPANESE_CHIN = 'Japanese Chin',
  JINDO = 'Jindo',
  KAI_KEN = 'Kai Ken',
  KARELIAN_BEAR_DOG = 'Karelian Bear Dog',
  KEESHOND = 'Keeshond',
  KERRY_BLUE_TERRIER = 'Kerry Blue Terrier',
  KISHU = 'Kishu',
  KISHU_KEN = 'Kishu Ken',
  KOMONDOR = 'Komondor',
  KOOIKERHONDJE = 'Kooikerhondje',
  KOREAN_JINDO = 'Korean Jindo',
  KROMFOHRLANDER = 'Kromfohrlander',
  KUVASZ = 'Kuvasz',
  KYI_LEO = 'Kyi Leo',
  LABRADOODLE = 'Labradoodle',
  LABRADOR_RETRIEVER = 'Labrador Retriever',
  LAGOTTO_ROMAGNOLO = 'Lagotto Romagnolo',
  LAKELAND_TERRIER = 'Lakeland Terrier',
  LANCASHIRE_HEELER = 'Lancashire Heeler',
  LAPPONIAN_HERDER = 'Lapponian Herder',
  LEONBERGER = 'Leonberger',
  LHASA_APSO = 'Lhasa Apso',
  LOWCHEN = 'Lowchen',
  MALTESE = 'Maltese',
  MALTESE_SHIH_TZU = 'Maltese Shih Tzu',
  MALTIPOO = 'Maltipoo',
  MANCHESTER_TERRIER = 'Manchester Terrier',
  MAREMMA_SHEEPDOG = 'Maremma Sheepdog',
  MASTIFF = 'Mastiff',
  MINIATURE_AMERICAN_SHEPHERD = 'Miniature American Shepherd',
  MINIATURE_BULL_TERRIER = 'Miniature Bull Terrier',
  MINIATURE_DACHSHUND = 'Miniature Dachshund',
  MINIATURE_PINSCHER = 'Miniature Pinscher',
  MINIATURE_POODLE = 'Miniature Poodle',
  MINIATURE_SCHNAUZER = 'Miniature Schnauzer',
  MOUNTAIN_CUR = 'Mountain Cur',
  MOUNTAIN_DOG = 'Mountain Dog',
  MUDI = 'Mudi',
  MUNSTERLANDER = 'Munsterlander',
  NEAPOLITAN_MASTIFF = 'Neapolitan Mastiff',
  NEDERLANDSE_KOOIKERHONDJE = 'Nederlandse Kooikerhondje',
  NEWFOUNDLAND = 'Newfoundland',
  NORFOLK_TERRIER = 'Norfolk Terrier',
  NORRBOTTENSPETS = 'Norrbottenspets',
  NORWEGIAN_BUHUND = 'Norwegian Buhund',
  NORWEGIAN_ELKHOUND = 'Norwegian Elkhound',
  NORWEGIAN_LUNDEHUND = 'Norwegian Lundehund',
  NORWICH_TERRIER = 'Norwich Terrier',
  NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER = 'Nova Scotia Duck Tolling Retriever',
  OLD_ENGLISH_SHEEPDOG = 'Old English Sheepdog',
  OTTERHOUND = 'Otterhound',
  PAPILLON = 'Papillon',
  PARSON_RUSSELL_TERRIER = 'Parson Russell Terrier',
  PATTERDALE_TERRIER_FELL_TERRIER = 'Patterdale Terrier / Fell Terrier',
  PEEKAPOO = 'Peekapoo',
  PEKINGESE = 'Pekingese',
  PEMBROKE_WELSH_CORGI = 'Pembroke Welsh Corgi',
  PERRO_DE_PRESA_CANARIO = 'Perro De Presa Canario',
  PERUVIAN_INCA_ORCHID = 'Peruvian Inca Orchid',
  PETIT_BASSET_GRIFFON_VENDEEN = 'Petit Basset Griffon Vendeen',
  PHARAOH_HOUND = 'Pharaoh Hound',
  PIT_BULL_TERRIER = 'Pit Bull Terrier',
  PLOTT_HOUND = 'Plott Hound',
  POCKET_BEAGLE = 'Pocket Beagle',
  POINTER = 'Pointer',
  POLISH_LOWLAND_SHEEPDOG = 'Polish Lowland Sheepdog',
  POMERANIAN = 'Pomeranian',
  POODLE = 'Poodle',
  PORCELAINE = 'Porcelaine',
  PORTUGUESE_PODENGO = 'Portuguese Podengo',
  PORTUGUESE_PODENGO_PEQUENO = 'Portuguese Podengo Pequeno',
  PORTUGUESE_POINTER = 'Portuguese Pointer',
  PORTUGUESE_SHEEPDOG = 'Portuguese Sheepdog',
  PORTUGUESE_WATER_DOG = 'Portuguese Water Dog',
  PRESA_CANARIO = 'Presa Canario',
  PUDELPOINTER = 'Pudelpointer',
  PUG = 'Pug',
  PUGGLE = 'Puggle',
  PULI = 'Puli',
  PUMI = 'Pumi',
  PYRENEAN_MASTIFF = 'Pyrenean Mastiff',
  PYRENEAN_SHEPHERD = 'Pyrenean Shepherd',
  RAFERIO_DO_ALENTEJO = 'Rafeiro Do Alentejo',
  RAT_TERRIER = 'Rat Terrier',
  REDBONE_COONHOUND = 'Redbone Coonhound',
  RETRIEVER = 'Retriever',
  RHODESIAN_RIDGEBACK = 'Rhodesian Ridgeback',
  ROTTWEILER = 'Rottweiler',
  ROUGH_COLLIE = 'Rough Collie',
  RUSSELL_TERRIER = 'Russell Terrier',
  RUSSIAN_HUNTING_SPANIEL = 'Russian Hunting Spaniel',
  RUSSIAN_SPANIEL = 'Russian Spaniel',
  RUSSIAN_TOY = 'Russian Toy',
  RUSSIAN_TSVETNAYA_BOLONKA = 'Russian Tsvetnaya Bolonka',
  SAINT_BERNARD = 'Saint Bernard',
  SALUKI = 'Saluki',
  SAMOYED = 'Samoyed',
  SCHAPENDOES = 'Schapendoes',
  SCHIPPERKE = 'Schipperke',
  SCHNAUZER = 'Schnauzer',
  SCHNOODLE = 'Schnoodle',
  SCOTTISH_DEERHOUND = 'Scottish Deerhound',
  SCOTTISH_TERRIER = 'Scottish Terrier',
  SEALYHAM_TERRIER = 'Sealyham Terrier',
  SETTER = 'Setter',
  SHAR_PEI = 'Shar-Pei',
  SHEEP_DOG = 'Sheep Dog',
  SHEPHERD = 'Shepherd',
  SHETLAND_SHEEPDOG = 'Shetland Sheepdog',
  SHIBA_INU = 'Shiba Inu',
  SHIH_TZU = 'Shih Tzu',
  SHIKOKU = 'Shikoku',
  SIBERIAN_HUSKY = 'Siberian Husky',
  SILKY_TERRIER = 'Silky Terrier',
  SKYE_TERRIER = 'Skye Terrier',
  SLOUGHI = 'Sloughi',
  SLOVENSKY_CUVAC = 'Slovensky Cuvac',
  SLOVENSKY_KOPOV = 'Slovensky Kopov',
  SMALL_MUNSTERLANDER_POINTER = 'Small Munsterlander Pointer',
  SMOOTH_COLLIE = 'Smooth Collie',
  SMOOTH_FOX_TERRIER = 'Smooth Fox Terrier',
  SOFT_COATED_WHEATEN_TERRIER = 'Soft Coated Wheaten Terrier',
  SPANIEL = 'Spaniel',
  SPANISH_MASTIFF = 'Spanish Mastiff',
  SPANISH_WATER_DOG = 'Spanish Water Dog',
  SPIONE_ITALIANO = 'Spione Italiano',
  SPITZ = 'Spitz',
  STABYHOUN = 'Stabyhoun',
  STAFFORDSHIRE_BULL_TERRIER = 'Staffordshire Bull Terrier',
  STANDARD_POODLE = 'Standard Poodle',
  STANDARD_SCHNAUZER = 'Standard Schnauzer',
  SUSSEX_SPANIEL = 'Sussex Spaniel',
  SWEDISH_VALLHUND = 'Swedish Vallhund',
  TAIWAN_DOG = 'Taiwan Dog',
  TEDDY_ROOSEVELT_TERRIER = 'Teddy Roosevelt Terrier',
  TERRIER = 'Terrier',
  THAI_RIDGEBACK = 'Thai Ridgeback',
  TIBETAN_MASTIFF = 'Tibetan Mastiff',
  TIBETAN_SPANIEL = 'Tibetan Spaniel',
  TIBETAN_TERRIER = 'Tibetan Terrier',
  TORNJAK = 'Tornjak',
  TOSA_INU = 'Tosa Inu',
  TOY_FOX_TERRIER = 'Toy Fox Terrier',
  TOY_MANCHESTER_TERRIER = 'Toy Manchester Terrier',
  TRANSYLVANIAN_HOUND = 'Transylvanian Hound',
  TREEING_TENNESSEE_BRINDLE = 'Treeing Tennessee Brindle',
  TREEING_WALKER_COONHOUND = 'Treeing Walker Coonhound',
  VIZSLA = 'Vizsla',
  WEIMARANER = 'Weimaraner',
  WELSH_SPRINGER_SPANIEL = 'Welsh Springer Spaniel',
  WELSH_TERRIER = 'Welsh Terrier',
  WEST_HIGHLAND_WHITE_TERRIER = 'West Highland White Terrier',
  WHEATEN_TERRIER = 'Wheaten Terrier',
  WHIPPET = 'Whippet',
  WHITE_GERMAN_SHEPHERD = 'White German Shepherd',
  WIREHAIRED_DACHSHUND = 'Wirehaired Dachshund',
  WIREHAIRED_POINTING_GRIFFON = 'Wirehaired Pointing Griffon',
  WIREHAIRED_TERRIER = 'Wirehaired Terrier',
  WIREHAIRED_VIZSLA = 'Wirehaired Vizsla',
  WIRE_FOX_TERRIER = 'Wire Fox Terrier',
  WORKING_KELPIE = 'Working Kelpie',
  XOLOITZCUINTLI = 'Xoloitzcuintli',
  YAKUTIAN_LAIKA = 'Yakutian Laika',
  YELLOW_LABRADOR_RETRIEVER = 'Yellow Labrador Retriever',
  YORKIPOO = 'Yorkipoo',
  YORKSHIRE_TERRIER = 'Yorkshire Terrier',
}

enum HORSE_BREEDS {
  MIX = 'Mix',
  APPALOOSA = 'Appaloosa',
  ARABIAN = 'Arabian',
  BELGIAN = 'Belgian',
  CLYDESDALE = 'Clydesdale',
  CONNEMARA = 'Connemara',
  CURLY_HORSE = 'Curly Horse',
  DONKEY = 'Donkey',
  DRAFT = 'Draft',
  FRIESIAN = 'Friesian',
  GAITED = 'Gaited',
  GRADE = 'Grade',
  GYPSY_VANNER = 'Gypsy Vanner',
  HAFLINGER = 'Haflinger',
  ICELANDIC_HORSE = 'Icelandic Horse',
  LIPIZZAN = 'Lipizzan',
  MINIATURE_HORSE = 'Miniature Horse',
  MISSOURI_FOXTROTTER = 'Missouri Foxtrotter',
  MORGAN = 'Morgan',
  MULE = 'Mule',
  MUSTANG = 'Mustang',
  OTHER = 'Other',
  PAINT_PINTO = 'Paint / Pinto',
  PALOMINO = 'Palomino',
  PASO_FINO = 'Paso Fino',
  PERCHERON = 'Percheron',
  PERUVIAN_PASO = 'Peruvian Paso',
  PONY = 'Pony',
  PONY_OF_THE_AMERICAS = 'Pony of the Americas',
  QUARTERHORSE = 'Quarterhorse',
  ROCKY_MOUNTAIN_HORSE = 'Rocky Mountain Horse',
  SADDLEBRED = 'Saddlebred',
  SHETLAND_PONY = 'Shetland Pony',
  STANDARDBRED = 'Standardbred',
  TENNESSEE_WALKER = 'Tennessee Walker',
  THOROUGHBRED = 'Thoroughbred',
  WARMBLOOD = 'Warmblood',
}

// prettier-ignore
enum RABBIT_BREEDS {
  MIX = 'Mix',
  AMERICAN = 'American',
  AMERICAN_FUZZY_LOP = 'American Fuzzy Lop',
  AMERICAN_SABLE = 'American Sable',
  ANGORA_RABBIT = 'Angora Rabbit',
  BELGIAN_HARE = 'Belgian Hare',
  BEVEREN = 'Beveren',
  BRITANNIA_PETITE = 'Britannia Petite',
  BUNNY_RABBIT = 'Bunny Rabbit',
  CALIFORNIAN = 'Californian',
  CHAMPAGNE_D_ARGENT = 'Champagne D\'Argent',
  CHECKERED_GIANT = 'Checkered Giant',
  CHINCHILLA = 'Chinchilla',
  CINNAMON = 'Cinnamon',
  CREME_D_ARGENT = 'Creme D\'Argent',
  DUTCH = 'Dutch',
  DWARF = 'Dwarf',
  DWARF_EARED = 'Dwarf Eared',
  ENGLISH_LOP = 'English Lop',
  ENGLISH_SPOT = 'English Spot',
  FLEMISH_GIANT = 'Flemish Giant',
  FLORIDA_WHITE = 'Florida White',
  FRENCH_LOP = 'French Lop',
  HARLEQUIN = 'Harlequin',
  HAVANA = 'Havana',
  HIMALAYAN = 'Himalayan',
  HOLLAND_LOP = 'Holland Lop',
  HOTOT = 'Hotot',
  JERSEY_WOOLY = 'Jersey Wooly',
  LILAC = 'Lilac',
  LIONHEAD = 'Lionhead',
  LOP_EARED = 'Lop Eared',
  MINI_LOP = 'Mini Lop',
  MINI_REX = 'Mini Rex',
  NETHERLAND_DWARF = 'Netherland Dwarf',
  NEW_ZEALAND = 'New Zealand',
  OTHER = 'Other',
  PALOMINO = 'Palomino',
  POLISH = 'Polish',
  REX = 'Rex',
  RHINELANDER = 'Rhinelander',
  SATIN = 'Satin',
  SILVER = 'Silver',
  SILVER_FOX = 'Silver Fox',
  SILVER_MARTEN = 'Silver Marten',
  TAN = 'Tan',
}

export { SPECIES, CAT_BREEDS, DOG_BREEDS, HORSE_BREEDS, RABBIT_BREEDS };
