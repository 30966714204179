<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { FieldSchema } from '../../../types';
import { GetAllStaffService } from '@ShelterModule';

const props = defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
  placeholder?: string;
  required?: boolean;
}>();

const model = defineModel<string>();

const allStaff = ref();
const loading = ref(false);

onMounted(() => getStaff());

function getStaff() {
  loading.value = true;

  GetAllStaffService(props.field?.settings.staffType)
    .then((result: any) => {
      allStaff.value = result;

      const match = result.find((staff: any) => staff.full_name === model.value);
      if (!match) return;

      model.value = match;
    })
    .finally(() => (loading.value = false));
}
</script>

<template>
  <AFieldDropdown
    v-model="model"
    optionLabel="full_name"
    :disabled="loading"
    :error
    :placeholder
    :class="['h-9 w-full text-sm', { 'af-field-error': !!error }]"
    :options="allStaff"
  />
</template>
