import type { GenericField } from '@Types';

export default <GenericField[]>[
  {
    name: 'components.speciesField.options.dog',
    value: 'dog',
  },
  {
    name: 'components.speciesField.options.cat',
    value: 'cat',
  },
  {
    name: 'components.speciesField.options.rabbit',
    value: 'rabbit',
  },
  {
    name: 'components.speciesField.options.horse',
    value: 'horse',
  },
  {
    name: 'components.speciesField.options.wildlife',
    value: 'wildlife',
  },
  {
    name: 'components.speciesField.options.barn',
    value: 'barn',
  },
  {
    name: 'components.speciesField.options.bird',
    value: 'bird',
  },
  {
    name: 'components.speciesField.options.pocket',
    value: 'pocket',
  },
  {
    name: 'components.speciesField.options.reptile',
    value: 'reptile',
  },
  {
    name: 'components.speciesField.options.other',
    value: 'other',
  },
];
