import { ref } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema } = useForm();

  const fieldsSettings: FieldInitialSettings = {};
  const rawFields: RawField[] = [
    {
      label: 'Follow-Up Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Reason',
      name: 'followup_reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Notes',
      name: 'followup_notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings);
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
