import { defineStore } from 'pinia';
import type { Building, Kennel, Site, SiteStats } from '../types/kennel.types';
import { DOCUMENT_STATUS } from '@Types';
import { GetIndividualSiteService } from '../services';

// TODO: AF-606: Add types
interface State {
  availableSpots: number;
  cascadeModel: any;
  currentSite: any;
  currentBuilding: any;
  currentLocation: any;
  kennels: Kennel;
  kennelId: string;
  loadingChangeBuilding: boolean;
  loading: boolean;
  sites: Site[];
  stats: SiteStats | null;
}

export default defineStore('kennelManager', {
  state: (): State => ({
    availableSpots: 0,
    cascadeModel: null,
    currentSite: null,
    currentBuilding: null,
    currentLocation: null,
    kennels: {
      _id: {
        $oid: '',
      },
      label: '',
      capacity: 0,
      position: 0,
      state: 'active',
      status: DOCUMENT_STATUS.ACTIVE,
      animalsIds: [],
      animalDetails: [],
    },
    kennelId: '',
    loading: false,
    loadingChangeBuilding: false,
    sites: [],
    stats: null,
  }),
  getters: {
    currentSiteId: (state: State) => state.currentSite._id,
    currentBuildingId: (state: State) => state.currentBuilding._id,
    currentLocationId: (state: State) => state.currentLocation._id,
  },
  actions: {
    async refreshSiteData(siteId: string) {
      await GetIndividualSiteService(siteId).then((response) => {
        const site = response.data[0];

        this.currentSite = site;
        this.stats = site.stats;
      });
    },
  },
});
