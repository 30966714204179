import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import type { GenericField, OId } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { formatNumberToDate } from '@Helpers';
import { useForm } from '@FormModule';
import { useAnimalProfileStore } from '@Stores';

interface MedicalStaff {
  [key: string]: any;
  first_name: string;
  last_name: string;
  _id: OId;
}

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema, wasFormSubmitted } = useForm();
  const { medicalStaff } = storeToRefs(useAnimalProfileStore());

  const rawFields: RawField[] = [
    {
      label: 'Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Administered By',
      name: 'by',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  const attendingVetOptions = computed(() =>
    medicalStaff.value.map((staff: MedicalStaff) => {
      const { first_name, last_name } = staff;
      return {
        ...staff,
        by: `${first_name} ${last_name}`,
      };
    })
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      by: {
        edit: {
          label: 'by',
          options: [...attendingVetOptions.value, { by: 'Other' }],
        },
      },
      other_by: {
        settings: {
          show: false,
        },
      },
    })
  );

  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'by'));
  const byModel = computed(() => byField.value?.model);

  watch(byModel, () => showOtherVetField());

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.by === 'Other';

        field.settings = {
          ...field.settings,
          show: showField,
        };

        if (byModel?.value?.by !== 'Other') return (field.model = '');
      }
    });

    return true;
  }

  function setForm(sourceData: any) {
    const updatedFieldSchema = getDetailsSchema(rawFields, fieldsSettings.value);

    formError.value = null;
    wasFormSubmitted.value = false;

    updatedFieldSchema.forEach((field) => {
      const match = sourceData[field.name];

      switch (field.name) {
        case 'date': {
          setDateField(field, match);
          break;
        }
        case 'by': {
          setByField(field, sourceData);
          break;
        }
        case 'other_by': {
          setOtherByField(field, sourceData);
          break;
        }
        default: {
          setMatchField(field, match);
        }
      }
    });

    fieldsSchema.value = updatedFieldSchema;
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
  }

  function setDateField(field: any, sourceData: any) {
    const date = sourceData.$date.$numberLong;

    field.model = formatNumberToDate(date, false, false, true) || new Date();
    field.value = formatNumberToDate(date, false, false, true) || new Date();
  }

  function setByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    if (sourceData.by_id === 'other') {
      field.model = { by: 'Other' };
      field.value = { by: 'Other' };

      return;
    }

    const [first_name, last_name] = sourceData.by.split(' ');
    const by = { _id: sourceData.by_id, first_name, last_name, by: sourceData.by };

    field.model = by;
    field.value = by;
  }

  function setOtherByField(field: any, sourceData: any) {
    if (!sourceData.by) return;

    field.model = sourceData.by;
    field.value = sourceData.by;
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
