<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import type { OnClickOutsideHandler } from '@vueuse/core';
import type { FieldSchema } from '../../../types';

const emits = defineEmits<{
  onHideMultiSelectPanel: [];
  onEditFieldCancelMultiselect: [];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    isInlineEditing?: boolean;
    isOnODetails?: boolean;
    placeholder?: string;
    options?: any[];
    optionLabel?: string;
    optionValue?: string;
    maxSelectedLabels?: number;
  }>(),
  {
    isInlineEditing: false,
    isOnODetails: false,
    maxSelectedLabels: 2,
    placeholder: 'components.forms.placeholder.multiselect',
  }
);

const model = defineModel<string[]>({ default: [] });

const inlineComponentRef = useTemplateRef<any>('inlineComponentRef');

const panelRef = ref<HTMLElement | null>(null);
const initialModelValue = ref<any>([]);

const settings = computed(() => props?.field?.settings);
const options = computed(() => props?.field?.edit?.options || props?.options || []);

onMounted(() => setInitialDetails());

onClickOutside(inlineComponentRef, dropdownHandler);

watch(
  () => inlineComponentRef.value,
  () => setPanelRef()
);

function setInitialDetails() {
  nextTick(() => {
    checkEditType();
    setPanelRef();
  });
}

function dropdownHandler(event: Event): OnClickOutsideHandler | undefined {
  const target = event.target as HTMLElement;

  if (!inlineComponentRef.value || !panelRef.value) return;

  if (inlineComponentRef.value.$el.contains(target) || panelRef.value.contains(target)) return;

  emits('onHideMultiSelectPanel');
  inlineComponentRef.value.hide();
}

function setPanelRef() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  initialModelValue.value = model.value;

  nextTick(() => {
    const panel = document.querySelector('.p-multiselect-panel') as HTMLElement;
    if (!panel) return;

    panelRef.value = panel;
  });
}

function checkEditType() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;
  inlineComponentRef.value.show();
}

function onCancel() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  emits('onEditFieldCancelMultiselect');
}
</script>

<template>
  <MultiSelect
    v-model="model"
    pt:filterInput="h-9 py-1 pl-3 text-sm"
    pt:headerCheckbox:box="size-5"
    pt:itemCheckbox:box="size-5"
    :pt:labelContainer="[
      'flex h-9 items-center text-sm text-gray-700 max-w-[150px]',
      isOnODetails ? '' : 'sm:max-w-full',
    ]"
    pt:option="text-sm"
    pt:panel="sm:w-[265px] md:w-[330px] lg:w-[268px] "
    pt:root="w-full"
    pt:token="rounded text-sm py-1 px-2"
    ref="inlineComponentRef"
    :options
    :class="['flex h-9 w-full justify-between', { 'af-field-error': !!error }]"
    :emptyFilterMessage="$t('components.emptyFilterMessage.dropdown')"
    :emptyMessage="$t('components.emptyMessage.dropdown')"
    :emptySelectionMessage="$t('components.emptySelectionMessage.dropdown')"
    :filter="options.length > 5"
    :filterMessage="$t('components.emptySelectionMessage.dropdown')"
    :filterResultMessage="$t('components.emptySelectionMessage.dropdown')"
    :invalid="!!error"
    :maxSelectedLabels="settings?.maxSelectedLabels || maxSelectedLabels"
    :optionLabel="field?.edit?.label || optionLabel"
    :optionValue="field?.edit?.value || optionValue"
    :placeholder="$t(settings?.placeholder || placeholder)"
    :pt:label:class="{ 'max-w-[150px] sm:max-w-full': true, 'text-red-600': !!error }"
    :pt:trigger:class="{ 'text-red-600': !!error }"
    :selectedItemsLabel="$t('filters.selectedItems', { length: model?.length })"
    @hide="onCancel"
  />
</template>
