<script setup lang="ts">
import { convertToTitleCase } from '@Helpers';

defineProps<{
  field: string;
  header: string;
  sortable?: boolean;
}>();

const emits = defineEmits<{
  onSort: [];
}>();
</script>

<template>
  <ASelectAllRows
    v-if="field === 'rowSelector'"
    class="!bg-slate-100"
  />

  <div
    v-else-if="field === 'asideColumn'"
    class="grid-item grid-item-header items-center !bg-slate-100"
  >
    <span class="pi pi-th-large px-0.5 text-brand-500"></span>
  </div>

  <div
    v-else-if="field === 'actionsColumn'"
    class="grid-item grid-item-header !bg-slate-100 text-center font-medium"
  >
    {{ $t('components.dataTable.actionsHeader') }}
  </div>

  <div
    v-else
    class="grid-item grid-item-header !bg-slate-100 font-medium"
  >
    <div class="flex items-center gap-1">
      <span>{{ convertToTitleCase(header) }}</span>

      <ASortableHeader
        v-if="sortable"
        :field
        :header
        @onNewSort="emits('onSort')"
      />
    </div>
  </div>
</template>

<style scoped>
.grid-item-header {
  @apply sticky top-0 z-40 grid bg-gray-50;
}
</style>
