<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import { loadStripe } from '@stripe/stripe-js';
import useCartStore from '../../../store';

const { cardHolderName, cardFieldError } = storeToRefs(useCartStore());

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);
const creditCardModel = defineModel();

const showProgressBar = ref(false);

let stripe: any, elements: any, cardElement: any;

onMounted(() => setupStripe());

async function setupStripe() {
  stripe = await stripePromise;

  elements = stripe.elements();
  cardElement = elements.create('card', { style: { base: { fontSize: '14px' } } });

  const debouncedValidateCard = useDebounceFn(() => {
    onValidateCard();
  }, 1000);

  cardElement.on('change', () => {
    debouncedValidateCard();
  });

  cardElement.mount('#card-element');
}

async function onValidateCard() {
  showProgressBar.value = true;
  cardFieldError.value = '';

  const { token, error: stripeError } = await stripe.createToken(cardElement, {
    name: cardHolderName.value,
  });

  if (stripeError) {
    cardFieldError.value = stripeError.message;
    showProgressBar.value = false;
    return;
  }

  creditCardModel.value = {
    name: cardHolderName.value,
    token,
  };

  showProgressBar.value = false;
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex w-full flex-col">
      <AFieldLabel
        required
        :label="$t('common.name')"
      />

      <AFieldInputText
        required
        v-model="cardHolderName"
        :placeholder="$t('cart.paymentMethod.validateName')"
      />
    </div>

    <div class="flex w-full flex-col">
      <AFieldLabel
        required
        :error="cardFieldError"
        :label="$t('cart.label.creditCard')"
      />

      <div
        class="rounded-md border border-slate-300 px-3 py-2"
        id="card-element"
      ></div>

      <AFieldError :error="cardFieldError" />

      <div class="flex h-5 items-center gap-2">
        <AFieldLabel
          v-if="showProgressBar"
          class="text-xs text-slate-500"
          :label="$t('cart.paymentMethod.validating')"
        />

        <ProgressBar
          v-if="showProgressBar"
          class="h-0.5 w-full"
          mode="indeterminate"
          pt:value="bg-brand-400"
        />
      </div>
    </div>
  </div>
</template>
