<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '../../../types';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    error?: string;
    field: FieldSchema;
    placeholder?: string;
    required?: boolean;
    rows?: string;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
    rows: '5',
  }
);

const model = defineModel<string>();

const settings = computed(() => props.field.settings);
</script>

<template>
  <Textarea
    v-model="model"
    :disabled="field?.state?.disabled || disabled"
    :required
    :class="['w-full text-sm', { 'af-field-error': !!error }]"
    :invalid="!!error"
    :placeholder="$t(settings.placeholder || placeholder)"
    :rows="settings.rows || rows"
  />
</template>
