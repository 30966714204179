import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'partners',
    name: 'partners',
    component: () => import('./views/ShelterPartners.view.vue'),
    meta: {
      permissions: [ROLES.PARTNERS],
    },
  },
  {
    path: 'partner/profile/:id',
    name: 'partner-profile',
    component: () => import('./views/PartnerProfile.view.vue'),
    meta: {
      permissions: [ROLES.PARTNERS],
    },
  },
  {
    path: 'partners/add-partner',
    name: 'add-partner',
    component: () => import('./views/ShelterPartnersForm.view.vue'),
    meta: {
      permissions: [ROLES.PARTNERS],
    },
  },
  {
    path: 'partners/animal-intake/:id',
    name: 'admit-animal-partners',
    component: () => import('./views/ShelterPartnersAnimalForm.view.vue'),
    meta: {
      permissions: [ROLES.PARTNERS],
    },
  },
  {
    path: 'partners/animal-transfer-in/:id',
    name: 'transfer-in-animal-partners',
    component: () => import('./views/ShelterPartnersTransferInForm.view.vue'),
  },
];
