const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const MONTHS_ABBR = [
  'jan', 
  'feb', 
  'mar', 
  'apr', 
  'may', 
  'jun', 
  'jul', 
  'aug', 
  'sep', 
  'oct', 
  'nov', 
  'dec',
];

const DAY_NAMES = [
  'sunday', 
  'monday', 
  'tuesday', 
  'wednesday', 
  'thursday', 
  'friday', 
  'saturday',
];

const DAY_NAMES_MIN = [
  'su', 
  'mo', 
  'tu', 
  'we', 
  'th', 
  'fr', 
  'sa',
];

const DAY_NAMES_SHORT = [
  'sun', 
  'mon', 
  'tue', 
  'wed', 
  'thu', 
  'fri', 
  'sat'
];

export { DAY_NAMES, DAY_NAMES_MIN, DAY_NAMES_SHORT, MONTHS, MONTHS_ABBR }