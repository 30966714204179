import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
import type { AfDate } from '@Types';

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
const DEFAULT_TIME_FORMAT = 'hh:mm A';
const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

function dateRelation(inputDateString?: string) {
  if (!inputDateString) return;

  const { t } = useI18n();

  let parts: string[];
  let inputDate: Date;

  if (/^\d{4}-\d{2}-\d{2}$/.test(inputDateString)) {
    // YYYY-DD-MM
    parts = inputDateString.split('-');
    inputDate = new Date(parseInt(parts[0]), parseInt(parts[2]) - 1, parseInt(parts[1]));
  } else if (/^\d{2}-\d{2}-\d{4}$/.test(inputDateString)) {
    // DD-MM-YYYY or MM-DD-YYYY
    parts = inputDateString.split('-');
    const year = parseInt(parts[2]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[0]);

    month >= 0 && month < 12 && day > 12
      ? (inputDate = new Date(year, month, day))
      : (inputDate = new Date(year, parseInt(parts[0]) - 1, parseInt(parts[1])));
  } else {
    throw new Error('Invalid date format');
  }

  const today = new Date();

  let years = today.getFullYear() - inputDate.getFullYear();
  let months = today.getMonth() - inputDate.getMonth();
  let days = today.getDate() - inputDate.getDate();

  if (months < 0 || (months === 0 && days < 0)) {
    years--;
    months += 12;
  }

  if (days < 0) {
    months--;
    const lastMonthDate = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonthDate.getDate();
  }

  return t('components.dataTable.dateRange', { years, months, days });
}

function formatNumberToDate(
  dateNumber: string,
  includeTime: boolean = false,
  afDateTimeFormat: boolean = false,
  usDateFormat: boolean = false // New parameter
) {
  const timestamp = Number(dateNumber);
  if (isNaN(timestamp)) return;

  const date = new Date(timestamp);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  let formattedDate = `${year}-${formattedMonth}-${formattedDay}`; // Default format

  if (usDateFormat) {
    formattedDate = `${formattedMonth}-${formattedDay}-${year}`; // New MM/DD/YYYY format
  }

  if (includeTime) {
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be 12

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    formattedDate += ` ${formattedHours}:${formattedMinutes}`;

    if (afDateTimeFormat) {
      formattedDate += ` ${amOrPm}`;
    }
  }

  return formattedDate;
}

function formatDateTime(dateTimeString: string) {
  const { locale } = useI18n();

  const formatedDate = new Date(dateTimeString);

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const datePart = formatedDate.toLocaleDateString(locale.value, dateOptions);
  const timePart = formatedDate.toLocaleTimeString(locale.value, timeOptions);

  return `${datePart} ${timePart}`;
}

function formatTimestampToDateTime(timestamp: number | string): string {
  const timestampInSeconds = Number(timestamp) / 1000;

  return moment.unix(timestampInSeconds).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
}

function formatStringToRelation(dateString: string) {
  const { t } = useI18n();

  const currentDate: Date = new Date();
  const submissionDate: Date = new Date(dateString);

  const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
  const millisecondsPerMonth: number = 30 * millisecondsPerDay;
  const millisecondsPerYear: number = 365 * millisecondsPerDay;

  const timeDifference: number = currentDate.getTime() - submissionDate.getTime();
  const daysDifference: number = Math.round(timeDifference / millisecondsPerDay);
  const monthsDifference: number = Math.round(timeDifference / millisecondsPerMonth);
  const yearsDifference: number = Math.round(timeDifference / millisecondsPerYear);

  if (daysDifference === 0) return t('common.today');
  if (daysDifference < 30)
    return `${daysDifference} ${t('common.day', daysDifference)} ${t('common.ago')}`;
  if (monthsDifference < 12)
    return `${monthsDifference} ${t('common.month', monthsDifference)} ${t('common.ago')}`;

  return `${yearsDifference} ${t('common.year', yearsDifference)} ${t('common.ago')}`;
}

function formatTimestampToDate(timestamp: string) {
  const timestampNumber = Number(timestamp);

  if (isNaN(timestampNumber)) {
    return;
  }

  const date = new Date(timestampNumber);

  const year = date.getUTCFullYear();
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + date.getUTCDate()).slice(-2);
  const hours = ('0' + date.getUTCHours()).slice(-2);
  const minutes = ('0' + date.getUTCMinutes()).slice(-2);
  const seconds = ('0' + date.getUTCSeconds()).slice(-2);
  const milliseconds = ('00' + date.getUTCMilliseconds()).slice(-3);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

function toIsoString(date: string | Date, defaultFormat: boolean = false) {
  const currentTime = moment();
  const inputDate = moment(date);

  const combinedDate = moment.utc({
    year: inputDate.year(),
    month: inputDate.month(),
    day: inputDate.date(),
    hour: currentTime.utc().hour(),
    minute: currentTime.utc().minute(),
    second: currentTime.utc().second(),
    millisecond: currentTime.utc().millisecond(),
  });

  return defaultFormat ? combinedDate.toISOString() : combinedDate.toISOString().split('T')[0];
}

function formatDateToString(dateInput: any) {
  let date: Date;

  if (typeof dateInput === 'string' || dateInput instanceof Date) {
    date = new Date(dateInput);
  } else if (dateInput?.$date?.$numberLong) {
    date = new Date(parseInt(dateInput.$date.$numberLong, 10));
  } else {
    throw new Error('Invalid date format');
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function numberToDate(date: number | string): string {
  const dateNumber = Number(date);

  return new Date(Date.now() + dateNumber * 24 * 60 * 60 * 1000).toISOString();
}

function formatTimestampToSimpleDate(date: number | string, format?: string) {
  const dateFormatted = formatTimestampToDateTime(date);
  const defaultFormat = format || 'MM-DD-YYYY';

  return moment(dateFormatted).format(defaultFormat);
}

function isAfDate(date: any): date is AfDate {
  return date && typeof date === 'object' && '$date' in date && '$numberLong' in date.$date;
}

function isValidDate(date: any): date is Date {
  return moment.isDate(date) || moment(date).isValid();
}

function getDefaultDateFormat(
  date: Date | AfDate | string | number,
  withTime?: boolean,
  timeOnly?: boolean
) {
  const format = getDefaultFormat(withTime);
  const today = moment().format('YYYY-MM-DD');

  if (isAfDate(date)) return formatTimestampToSimpleDate(date.$date.$numberLong, format);

  const isString = typeof date === 'string';
  if (isString) date = (date as string).replace(/(\d{2}:\d{2}:\d{2})\s?(AM|PM)/i, '$1');

  const isTimeOnly = isString && timeOnly;
  if (isTimeOnly) date = `${today} ${date}`;

  const isTimestamp = isString && /^-?\d+$/.test(date as string);
  if (isTimestamp) date = Number(date);

  if (!isValidDate(date)) {
    console.error('Invalid date on getDefaultDateFormat()', date);
    return date.toString();
  }

  return moment(date).format(format);
}

function getPayloadDate(
  date: string,
  output: 'string' | 'utc' | 'iso' = 'utc',
  withTime?: boolean,
  customFormat?: string
) {
  const format = customFormat ?? getDefaultFormat(withTime);

  //prettier-ignore
  switch (output) {
    case 'string': return moment(date).format(format);
    case 'utc': return moment(date).utc().format(format);
    case 'iso': return toIsoString(date, true);
    default: return moment(date).utc().format(format);
  }
}

function getDefaultFormat(withTime: boolean = false) {
  return withTime ? DEFAULT_DATETIME_FORMAT : DEFAULT_DATE_FORMAT;
}

function getDateFromOId(OId: string) {
  const validOId = OId.length === 24;

  return validOId
    ? moment(new Date(parseInt(OId.substring(0, 8), 16) * 1000)).format('YYYY-MM-DD')
    : '- ';
}

function timestampToTime(dateString: string): string {
  const isTimeFormat = /^\d{1,2}:\d{2}:\d{2} [APM]{2}$/.test(dateString);
  if (isTimeFormat) return dateString;

  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  return `${formattedHours}:${minutes} ${period}`;
}

function getTimeFromDate(date: Date) {
  return moment(date).format(DEFAULT_TIME_FORMAT);
}

function handleInvalidTime(timeString: string): Date {
  const isValidTime = (str: string) => {
    const regex = /^(\d{1,2}):(\d{2}):(\d{2}) (AM|PM)$/;
    return regex.test(str);
  };

  if (!isValidTime(timeString)) {
    return new Date();
  }

  const [_, hours, minutes, seconds, period] =
    timeString.match(/^(\d{1,2}):(\d{2}):(\d{2}) (AM|PM)$/) || [];
  const adjustedHours =
    period === 'PM' && +hours < 12 ? +hours + 12 : period === 'AM' && +hours === 12 ? 0 : +hours;

  const date = new Date();
  date.setHours(adjustedHours, +minutes, +seconds, 0);

  return date;
}

function formatHourToDateTime(timeString: string): string {
  const [hours, minutes, seconds, period] = timeString
    .match(/(\d{2}):(\d{2}):(\d{2}) (AM|PM)/)!
    .slice(1);
  const adjustedHours =
    period === 'PM' && +hours < 12 ? +hours + 12 : period === 'AM' && +hours === 12 ? 0 : +hours;

  const date = new Date();
  date.setHours(adjustedHours, +minutes, +seconds, 0);

  return date.toString();
}

function calculatedYearlyBooster(boosterConfig: { booster: string; booster_unit: string }) {
  const { booster, booster_unit } = boosterConfig;
  const boosterValue = parseInt(booster, 10);

  const unitMapping: { [key: string]: 'days' | 'weeks' | 'months' | 'years' } = {
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
  };

  const unit = unitMapping[booster_unit];
  const now = new Date();
  const nextDate = new Date(now);

  if (unit) {
    switch (unit) {
      case 'days':
        nextDate.setDate(now.getDate() + boosterValue);
        break;
      case 'weeks':
        nextDate.setDate(now.getDate() + boosterValue * 7);
        break;
      case 'months':
        nextDate.setMonth(now.getMonth() + boosterValue);
        break;
      case 'years':
        nextDate.setFullYear(now.getFullYear() + boosterValue);
        break;
    }
  }
}

function formatDateToSimpleString(dateTimeString: string): string {
  if (!dateTimeString) return '-';

  const [datePart] = dateTimeString.split(' ');
  return datePart;
}

function dateStringToUtc(date: string) {
  return moment.tz(date, 'UTC').format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]');
}

function ageCalculator(date: Date) {
  const today = moment();
  const birthday = moment(date);

  const years = today.diff(birthday, 'years');
  birthday.add(years, 'years');

  const months = today.diff(birthday, 'months');
  birthday.add(months, 'months');

  const days = today.diff(birthday, 'days');

  return `${years} y, ${months} m, ${days} d`;
}

function dateRangeLabel(startDate: any, endDate: any) {
  const { t } = useI18n();

  const currentDate = moment().startOf('day');
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');

  const range = {
    last3Days:
      start.isSame(currentDate.clone().subtract(3, 'days')) &&
      end.isSame(currentDate.clone().subtract(0, 'days')),
    last5Days:
      start.isSame(currentDate.clone().subtract(5, 'days')) &&
      end.isSame(currentDate.clone().subtract(0, 'days')),
    last15Days:
      start.isSame(currentDate.clone().subtract(15, 'days')) &&
      end.isSame(currentDate.clone().subtract(0, 'days')),
    last7Days:
      start.isSame(currentDate.clone().subtract(7, 'days')) &&
      end.isSame(currentDate.clone().subtract(0, 'days')),
    last30Days:
      start.isSame(currentDate.clone().subtract(30, 'days')) &&
      end.isSame(currentDate.clone().subtract(0, 'days')),
    today: start.isSame(currentDate) && end.isSame(currentDate),
    yesterday:
      start.isSame(currentDate.clone().subtract(1, 'days')) &&
      end.isSame(currentDate.clone().subtract(1, 'days')),
    thisMonth:
      start.isSame(currentDate.clone().startOf('month'), 'day') &&
      end.isSame(currentDate.clone().endOf('month'), 'day'),
    lastMonth:
      start.isSame(currentDate.clone().subtract(1, 'month').startOf('month'), 'day') &&
      end.isSame(currentDate.clone().subtract(1, 'month').endOf('month'), 'day'),
    thisYear:
      start.isSame(currentDate.clone().startOf('year'), 'day') &&
      end.isSame(currentDate.clone().endOf('year'), 'day'),
    lastYear:
      start.isSame(currentDate.clone().subtract(1, 'year').startOf('year'), 'day') &&
      end.isSame(currentDate.clone().subtract(1, 'year').endOf('year'), 'day'),
  };

  //prettier-ignore
  switch (true) {
    case range.today: return t('common.today');
    case range.yesterday: return t('common.yesterday');
    case range.last3Days: return t('common.last3Days');
    case range.last5Days: return t('common.last5Days');
    case range.last7Days: return t('common.last7Days');
    case range.last15Days: return t('common.last15Days');
    case range.thisMonth: return t('common.thisMonth');
    case range.last30Days: return t('common.last30Days');
    case range.lastMonth: return t('common.lastMonth');
    case range.thisYear: return t('common.thisYear');
    case range.lastYear: return t('common.lastYear');
    default: return `${getDefaultDateFormat(startDate)} - ${getDefaultDateFormat(endDate)}`;
  }
}

export {
  ageCalculator,
  calculatedYearlyBooster,
  dateRelation,
  dateStringToUtc,
  formatDateTime,
  formatDateToSimpleString,
  formatDateToString,
  formatHourToDateTime,
  formatNumberToDate,
  formatStringToRelation,
  formatTimestampToDate,
  formatTimestampToDateTime,
  formatTimestampToSimpleDate,
  getDateFromOId,
  getDefaultDateFormat,
  getTimeFromDate,
  handleInvalidTime,
  isAfDate,
  isValidDate,
  numberToDate,
  timestampToTime,
  toIsoString,
  getPayloadDate,
  dateRangeLabel,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATETIME_FORMAT,
};
