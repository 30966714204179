import { ref } from 'vue';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useForm } from '@FormModule';

const fieldsSchema = ref<FieldSchema[]>([]);

export default function () {
  const { getDetailsSchema } = useForm();

  const currentSchemaModels = ref<Set<GenericField>>(new Set());
  const fieldsSettings: FieldInitialSettings = {
    outcome_date: {
      model: new Date(),
      settings: {
        minDate: new Date(),
      },
    },
  };
  const rawFields = ref<RawField[]>([
    {
      label: 'Checkout Date',
      name: 'outcome_date',
      required: true,
      type: 'date',
      value: '',
    },
  ]);

  const formError = ref();

  function setForm(oldestIntakeDate: number) {
    fieldsSettings!.outcome_date!.settings!.minDate = new Date(oldestIntakeDate);

    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings);
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,
    setForm,
  };
}
