import { ref } from 'vue';
interface DynamicSelectedRows {
  [key: string]: Set<string>;
}

const dynamicSelectedRows = ref<DynamicSelectedRows>({});
const selectedRows = ref<any[]>([]);

export default function () {
  function reset() {
    dynamicSelectedRows.value = {};
    selectedRows.value = [];
  }

  return {
    dynamicSelectedRows,
    selectedRows,

    reset,
  };
}
