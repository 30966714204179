<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { vMaska } from 'maska/vue';
import { useDebounce } from '@vueuse/core';
import type { FieldSchema } from '../../../types';

const emits = defineEmits<{
  'update:modelValue': [model?: string];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    modelValue?: string;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: '(999) 999-9999',
  }
);

const _model = ref(props.modelValue || '');

const input = useDebounce(_model, 300);

const activeModelProp = computed(() => props?.field?.model || props.modelValue);
const settings = computed(() => props?.field?.settings);

watch(activeModelProp, (newValue) => (_model.value = newValue));
watch(input, () => emits('update:modelValue', _model.value));
</script>

<template>
  <input
    v-maska
    v-model="_model"
    type="tel"
    data-maska="['(###) ###-####', '#(###) ###-####', '##(###) ###-####', '###(###) ###-####']"
    :class="[
      'h-9 w-full rounded-md !border-gray-300 text-sm text-gray-700 focus:!border-green-500 focus:!ring-0\t',
      { 'af-field-error !border-red-600': !!props.error },
    ]"
    :placeholder="settings?.placeholder || placeholder"
  />
</template>
