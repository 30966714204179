<script setup lang="ts">
defineProps<{
  error?: string;
}>();

const model = defineModel();
</script>

<template>
  <div class="flex flex-col gap-1">
    <AFieldLabel
      required
      :error
      :label="$t('cart.label.lastFour')"
    />

    <AFieldNumber
      v-model="model"
      required
      :error
      :max="9999"
      :maxFractionDigits="null"
      :minFractionDigits="null"
      :placeholder="$t('cart.label.lastFourPlaceholder')"
      :useGrouping="false"
    />
  </div>
</template>
