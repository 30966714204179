<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '../../../types';

const props = defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
}>();

const model = defineModel({
  default: false,
});

const parsedModel = computed({
  get: () => (typeof model.value === 'string' ? false : model.value),
  set: (value) => (model.value = value),
});
const settings = computed(() => props?.field?.settings);
</script>

<template>
  <div class="flex flex-col gap-1">
    <AFieldLabel :label="settings?.label" />

    <InputSwitch
      v-model="parsedModel"
      :disabled="field?.state?.disabled || disabled"
    />
  </div>
</template>
