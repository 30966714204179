import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import type { GenericArray, GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField, Field } from '@FormModule';
import { DATE_FIELDS } from '@Constants';
import { useAnimalProfileStore } from '@Stores';
import { CAT_BREEDS, DOG_BREEDS, HORSE_BREEDS, RABBIT_BREEDS, CATEGORIES } from '@AnimalModule';
import { capitalizeStr } from '@Helpers';
import { FIELDS_COMPONENTS, useForm } from '@FormModule';

const preUpdateSchemaValues = ref<GenericArray>();
const fieldsSchema = ref<FieldSchema[]>([]);
const currentSchemaModels = ref<Set<GenericField>>(new Set());

export default function () {
  const { pageData, animal } = storeToRefs(useAnimalProfileStore());

  const { getDetailsSchema } = useForm();

  const noMicrochipSpecies = ['wildlife', 'pocket', 'reptile'];
  const categoriesWithSecondarySpecies = [
    'barn',
    'bird',
    'horse',
    'other',
    'pocket',
    'reptile',
    'wildlife',
  ];

  const isOnEditMode = ref(false);

  const detailsData = computed(() => pageData.value?.animal);
  const animalFields = computed(() => pageData.value?.animalFields);
  const templateLabel = computed(() => pageData.value?.template_label);
  const animalCategoryName = computed(() => CATEGORIES.map((category) => category.name));
  const secondarySpeciesOptions = computed(() => pageData.value?.speciesField?.values);

  const showMicrochip = computed(
    () => !noMicrochipSpecies.includes(pageData.value?.template_name.toLowerCase())
  );
  const primaryColorFields = computed(() =>
    pageData.value?.primary_color.map((color: any) => ({
      label: color.value,
      value: color.value,
    }))
  );
  const hasSecondarySpecies = computed(() =>
    categoriesWithSecondarySpecies.includes(pageData.value?.template_name.toLowerCase())
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      animal_category: {
        label: !hasSecondarySpecies.value ? 'Species' : 'Animal Category',
      },
      species: {
        settings: {
          show: hasSecondarySpecies.value,
        },
      },
      import_animal_id: {
        settings: {
          editable: false,
          show: !!(
            pageData.value.animal?.import_animal_id &&
            pageData.value.animal.import_animal_id !== null
          ),
        },
      },
      primary_color_map: {
        edit: {
          options: primaryColorFields.value,
        },
      },
      breed: {
        edit: {
          options: [],
        },
        settings: {
          type: 'breeds',
        },
      },
      secondary_breed: {
        edit: {
          options: [],
        },
        settings: {
          type: 'select',
        },
      },
      birthday: {
        settings: {
          estBirthday: animal.value?.est_birthday,
        },
      },
      est_age: {
        settings: {
          editable: false,
          show: animalFields.value.some((field: Field) => field.name === 'birthday'),
        },
      },
      microchip: {
        render: {
          component: FIELDS_COMPONENTS.MICROCHIP,
        },
        settings: {
          hasSearch: true,
          show: showMicrochip.value,
          type: 'microchip',
        },
      },
      microchip_issuer: {
        settings: {
          show: showMicrochip.value,
        },
      },
    })
  );

  const rawFields: RawField[] = [
    {
      label: 'Animal Category',
      name: 'animal_category',
      required: true,
      type: 'select',
      values: animalCategoryName.value,
    },
    {
      label: 'Species',
      name: 'species',
      required: true,
      type: 'select',
      values: secondarySpeciesOptions.value,
    },
    {
      label: 'Import ID',
      name: 'import_animal_id',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Microchip Issuer',
      name: 'microchip_issuer',
      required: false,
      type: 'microchip_issuer',
      value: '',
    },
    {
      label: 'Est. Age',
      name: 'est_age',
      required: false,
      type: 'text',
      value: '',
    },
  ];

  watch(isOnEditMode, () => updateAnimalCategoryVisibility());

  function setBreedsOptions() {
    const { breed, secondary_breed } = fieldsSettings.value;
    const breeds: { [key: string]: any } = {
      cat: Object.values(CAT_BREEDS),
      dog: Object.values(DOG_BREEDS),
      horse: Object.values(HORSE_BREEDS),
      rabbit: Object.values(RABBIT_BREEDS),
    };

    if (breed.edit) breed.edit.options = breeds[pageData.value.template_name.toLowerCase()];
    if (secondary_breed.edit)
      secondary_breed.edit.options = breeds[pageData.value.template_name.toLowerCase()];
  }

  function positionStaticFields(fields: any) {
    const speciesField = fields.splice(
      fields.findIndex((field: Field) => field.name === 'animal_category'),
      1
    )[0];
    fields.unshift(speciesField);

    const ageIndex = fields.findIndex((field: Field) => field.name === 'est_age');
    const ageField = fields.splice(ageIndex, 1)[0];

    const birthdayIndex = fields.findIndex((field: Field) => field.name === 'birthday');
    birthdayIndex !== -1
      ? fields.splice(birthdayIndex + 1, 0, ageField)
      : fields.splice(ageIndex, 0, ageField);

    ['microchip', 'microchip_issuer'].forEach((name) => {
      const field = fields.splice(
        fields.findIndex((f: Field) => f.name === name),
        1
      )[0];
      fields.push(field);
    });

    return fields;
  }

  function updateAnimalCategoryVisibility() {
    fieldsSchema.value.forEach((field) => {
      if (field.name !== 'animal_category') return;

      field.settings.editable = !isOnEditMode.value;
    });
  }

  function setForm() {
    setBreedsOptions();

    fieldsSchema.value = getSchemaModels(detailsData.value);
  }

  function getSchemaModels(sourceData: any) {
    const excludedNames = ['header', 'paragraph', 'seperator', 'weight'];
    const dynamicFields = pageData.value.animalFields.filter(
      (field: Field) => !excludedNames.some((excluded) => field.name.includes(excluded))
    );

    const fields = dynamicFields ? [...rawFields, ...dynamicFields] : rawFields;
    const reorderedField = positionStaticFields(fields);
    const schema = getDetailsSchema(reorderedField, fieldsSettings.value);

    schema.forEach((field) => {
      const match = sourceData[field.name];

      if (DATE_FIELDS.includes(String(field.settings.type)) && field.name !== 'birthday') {
        field.settings.noModelOnStart = true;
      }

      switch (field.name) {
        case 'animal_category': {
          field.model = templateLabel.value;
          field.value = templateLabel.value;
          break;
        }
        case 'species': {
          field.model = capitalizeStr(sourceData.species);
          field.value = capitalizeStr(sourceData.species);
          break;
        }
        case 'est_age': {
          field.label = sourceData?.est_birthday ? 'Est. Age' : 'Age';
          field.model = sourceData.display_age;
          field.value = sourceData.display_age;
          break;
        }
        case 'birthday': {
          field.label = sourceData?.est_birthday ? 'Est. Birthday' : 'Birthday';
          field.model = sourceData.birthday;
          field.value = sourceData.birthday;
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });

    schema.forEach((field: Field) => (field.settings.required = false));

    return schema;
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    isOnEditMode,
    preUpdateSchemaValues,
    rawFields,

    setForm,
    resetForm,
  };
}
