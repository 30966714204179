import { capitalizeStr } from './string.helpers';

export function getTagsColors(field: string): string {
  // prettier-ignore
  switch (capitalizeStr(field)) {
    case 'Adopted': return '#2484C6';
    case 'Behavior Eval': return '#EBBB2D';
    case 'Canceled': return '#e22658';
    case 'Closed': return '#D1DADE';
    case 'Completed': return '#9fa5a3';
    case 'Declined': return '#f90250';
    case 'Deleted': return '#A71727';
    case 'Do Not Foster': return '#ED5565';
    case 'Do not adopt': return '#ED5565';
    case 'Do not foster': return '#ED5565';
    case 'Error': return '#ED5565';
    case 'Foster': return '#EBBB2D';
    case 'High': return '#ED5565';
    case 'Low': return '#ED5565';
    case 'Medical Evalutation': return '#E23347';
    case 'Medium': return '#ebbb2d';
    case 'Ok': return '#3DC6C8';
    case 'Paid': return '#2484C6';
    case 'Past Due': return '#e22658';
    case 'Past due': return '#e22658';
    case 'Pending': return '#1CB394';
    case 'Pre-adopt': return '#9F58DA';
    case 'Quarantine': return '#ED5565';
    case 'Return To Owner': return '#3B7BD7';
    case 'Shelter': return '#33BDA2';
    case 'Service': return '#726FB1';
    case 'Skipped': return '#e5c94e';
    case 'Submitted': return '#F8AC5A';
    case 'Test': return '#969682';
    case 'Unavailable': return '#D1DADE';
    case 'Unknown': return '#CECECE';
    case 'Waiting to call back': return '#83c18c';
    case 'Waived': return '#F8AC5A'; 
    case 'Warning': return '#F8AC5A';
    default: return '#37B394';
  }
}
