// Numbers refer to [time, frequency] respectively
const DOSAGES = {
  SID: [8, 1],
  'SID - AM': [8, 1],
  'SID - PM': [18, 1],
  BID: [6, 2],
  TID: [6, 3],
  QID: [6, 4],
  EOD: [6, 0.5],
  E3D: [6, 0.3],
  E5D: [6, 0.2],
  Q3H: [0, 8],
  Q4H: [0, 6],
  Q6H: [0, 4],
  WEEKLY: [8, 0.1428],
  'BI-WEEKLY': [8, 0.07142],
  MONTHLY: [8, 0.0328767],
};

export { DOSAGES };
