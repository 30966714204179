<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useADialog } from '@Atoms';
import type { Field } from '../../../types';

const props = defineProps<{
  field?: Field;
}>();

const { activeModal, activeModalProps, dialogVisibility } = useADialog();

const address = computed(() => props.field?.settings?.address);

const coordinates = ref({
  lat: props.field?.settings?.coordinates?.length === 2 ? props.field.settings.coordinates[1] : 0,
  lng: props.field?.settings?.coordinates?.length === 2 ? props.field.settings.coordinates[0] : 0,
});

watch(
  () => props.field,
  () => applyNewCoordinates()
);

function onOpenChangeLocationModal() {
  activeModalProps.value = props.field?.settings?.modalProps;
  activeModal.value = props.field?.settings?.activeModal;
  dialogVisibility.value = true;
}

function applyNewCoordinates() {
  coordinates.value = {
    lat: props.field?.settings?.coordinates?.length === 2 ? props.field.settings.coordinates[1] : 0,
    lng: props.field?.settings?.coordinates?.length === 2 ? props.field.settings.coordinates[0] : 0,
  };
}
</script>

<template>
  <div
    v-if="address"
    v-tooltip.top="{ value: $t('components.forms.title.changeLocation'), showDelay: 1000 }"
    class="group mt-2 flex gap-2"
    @click="onOpenChangeLocationModal"
  >
    <p class="cursor-pointer text-sm">
      <span class="font-medium">{{ $t('mapsInfo.address') }}: </span>{{ address }}
    </p>

    <i class="pi pi-pencil text-sm opacity-0 transition-opacity group-hover:opacity-100"></i>
  </div>

  <p
    v-else-if="!address"
    class="cursor-pointer text-sm text-sky-500 hover:text-sky-600"
    @click="onOpenChangeLocationModal"
  >
    {{
      props.field?.settings?.editLocation
        ? $t(`components.forms.title.${address ? 'changeLocation' : 'addLocation'}`)
        : ''
    }}
  </p>

  <GMapMap
    v-if="field?.settings?.coordinates?.length === 2"
    class="mt-2"
    :center="coordinates"
    :style="{ height: '250px' }"
    :zoom="13"
  >
    <GMapMarker
      icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
      :position="coordinates"
    />
  </GMapMap>
</template>
