import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'staff',
    redirect: '/staff/profile',
    children: [
      {
        path: 'profile',
        name: 'staff-profiles',
        component: () => import('./views/StaffProfiles.view.vue'),
      },
      {
        path: 'profile/:id',
        name: 'staff-profile',
        component: () => import('./views/StaffUserProfile.view.vue'),
        meta: {
          permissions: [ROLES.USERS],
        },
      },
      {
        path: 'add-staff-user',
        name: 'staff-user-form',
        component: () => import('./views/StaffProfilesForm.view.vue'),
        meta: {
          permissions: [ROLES.USERS],
        },
      },
    ],
  },
];
