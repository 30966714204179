<script setup lang="ts">
import { useADialog } from '@Atoms';
import type { Field } from '../../../types';

const props = defineProps<{
  field?: Field;
}>();

const { activeModal, activeModalProps, dialogVisibility } = useADialog();

function openModal() {
  activeModalProps.value = props.field?.settings?.modalProps;
  activeModal.value = props.field?.settings?.activeModal;
  dialogVisibility.value = true;
}
</script>

<template>
  <div
    class="cursor-pointer text-sm"
    @click="openModal"
  >
    <span v-if="!field?.model"> - </span>

    <p
      v-else
      class="text-sky-500 hover:text-sky-700"
    >
      {{ field?.model }}
    </p>
  </div>
</template>
