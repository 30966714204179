import { useAToast } from '@Atoms';
import { AddSelectedAnimalsService } from '../../KennelManager/services';

const { notify, TOAST_TYPES } = useAToast();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

interface AddAnimalOnKennelPayload {
  site_id: string;
  building_id: string;
  location_id: string;
  kennel_id: string;
  animals_ids: string[];
}

async function onAddAnimalOnKennel(payload: AddAnimalOnKennelPayload) {
  const PAYLOAD = payload;

  await AddSelectedAnimalsService(PAYLOAD).catch(() => notify(TOAST_ERROR));
}
export default { onAddAnimalOnKennel };
