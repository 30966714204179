<script setup lang="ts">
import { watch } from 'vue';
import { useDebounce } from '@vueuse/core';

const emit = defineEmits<{
  onDebouncedModel: any;
}>();

defineProps<{
  field?: any;
  rowData?: any;
  settings?: any;
}>();

const model = defineModel('model', { default: 0 });
const debouncedModel = useDebounce(model, 300);

watch(debouncedModel, () => emit('onDebouncedModel', model));
</script>

<template>
  <InputNumber
    v-model="model"
    showButtons
    buttonLayout="horizontal"
    inputId="horizontal-buttons"
    pt:incrementbutton:root="w-8"
    pt:decrementbutton:root="w-8"
    pt:input:root="max-w-[50px] h-9 text-center text-sm text-zinc-500 flex items-center flex"
    :step="1"
  >
    <template #incrementbuttonicon>
      <span class="pi pi-plus text-xs" />
    </template>

    <template #decrementbuttonicon>
      <span class="pi pi-minus text-xs" />
    </template>
  </InputNumber>
</template>
