<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import type { FieldSchema } from '../../../types';
import { checkDuplicateMicrochipService } from '../../../services';

interface MicrochipResult {
  animal_name: string;
  breed: string;
  id: string;
  _id: { $oid: string };
  path: string;
}

const emits = defineEmits<{
  change: [string | null];
  'update:modelValue': [string | null];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    modelValue: string | null;
    placeholder?: string;
    required?: boolean;
    species?: string;
  }>(),
  {
    modelValue: null,
    placeholder: 'components.forms.placeholder.common',
    species: 'cat',
  }
);

const model = ref(props.modelValue);
const results = ref<MicrochipResult[]>([]);
const duplicatedMicrochip = ref(false);

const activeModelProp = computed(() => props?.field?.model || props.modelValue);
const settings = computed(() => props?.field?.settings);

const input = useDebounce(model, 500);

watch(activeModelProp, (newValue) => (model.value = newValue));

watch(input, () => {
  emits('update:modelValue', model.value);
  emits('change', model.value);
  onMicrochipDuplicated({ microchip: model.value, species: props.species });
});

// EVENTS
function onMicrochipDuplicated(microchip: any) {
  duplicatedMicrochip.value = false;

  checkDuplicateMicrochipService(microchip)
    .then((result: any) => {
      if (!result.results.length) return;

      results.value = result.results;
      duplicatedMicrochip.value = true;
    })
    .catch(() => (duplicatedMicrochip.value = false));
}
</script>

<template>
  <div>
    <InputText
      v-model="model"
      aria-describedby="microchip-help"
      :class="['h-9 w-full text-sm', { 'af-field-error': !!error }]"
      :invalid="!!error"
      :placeholder="$t(settings?.placeholder || placeholder)"
      :required="settings?.required || required"
    />

    <div v-if="duplicatedMicrochip">
      <AFieldAnimalCard :results />
    </div>
  </div>
</template>
