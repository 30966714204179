<script setup lang="ts">
import { useADialog } from '@Atoms';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const { activeModal, activeModalProps, dialogVisibility } = useADialog();

function openModal() {
  activeModalProps.value = {
    ...props.settings.render.modalProps,
    rowData: props.rowData,
  };
  activeModal.value = props.settings.render.activeModal;
  dialogVisibility.value = true;
}
</script>

<template>
  <div
    class="cursor-pointer"
    @click="openModal"
  >
    <span
      v-if="!field"
      class="flex flex-col items-start gap-0.5"
    >
      -
    </span>

    <p v-else>{{ field }}</p>
  </div>
</template>
