import { API } from '@Providers';
import type { AdditionalFee } from '../types';

interface AdditionFeePayload {
  animals: {
    additional_fees: AdditionalFee[];
    animal_id: string;
    outcome_field: boolean; // NOTE: always true
  }
}

export default async function (payload: AdditionFeePayload) {
  const endpoint = '/api/shelter/animal/update-animal-additional-fees';

  return API({ endpoint, method: 'post', payload });
}
