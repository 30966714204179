import { computed } from 'vue';
import { logout } from '@/commons/api';
import type { TUserProfile } from '@/commons/types';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

// TODO :: Needs to be reviewed
const STORE_BASE_NAME = 'af-session-store';
const getKey = (key: string) => `${STORE_BASE_NAME}__${key}`;
const storeKeys = {
  initialUserId: getKey('initial-user-id'),
  isInvalidSession: getKey('isInvalidSession'),
  isLogged: getKey('is-logged'),
  isSU: getKey('is-super-user'),
  isSUGLO: getKey('is-super-user-glo'),
  permissions: getKey('permissions'),
  shelterProfile: getKey('shelter-profile'),
  useNewPlatform: getKey('useNewPlatform'),
  userProfile: getKey('user-profile'),
};

export default defineStore('session', () => {
  // State
  const initialUserId = useStorage<string | null>(storeKeys.initialUserId, null);
  const isInvalidSession = useStorage(storeKeys.isInvalidSession, false);
  const isLogged = useStorage(storeKeys.isLogged, false);
  const isSU = useStorage(storeKeys.isSU, false);
  const isSUGLO = useStorage(storeKeys.isSUGLO, false);
  const permissions = useStorage<ROLES[]>(storeKeys.permissions, []);
  const userProfile = useStorage<TUserProfile | null>(storeKeys.userProfile, null, undefined, {
    serializer: StorageSerializers.object,
  });
  const shelterProfile = useStorage<any | null>(storeKeys.shelterProfile, null, undefined, {
    serializer: StorageSerializers.object,
  });

  // Getters
  const shelterHasProducts = computed(
    () => !!shelterProfile.value?.enable_products && !!shelterProfile.value?.products_length
  );

  const checkForPermissionSettings = computed(
    () =>
      can(ROLES.ANIMAL_SETTINGS) ||
      can(ROLES.PEOPLE_SETTINGS) ||
      can(ROLES.PARTNER_SETTINGS) ||
      can(ROLES.INTEGRATIONS) ||
      can(ROLES.MEDICAL_SETTINGS) ||
      can(ROLES.OUTCOME_SETTINGS) ||
      can(ROLES.ROLES) ||
      can(ROLES.FIELD_SERVICES_SETTINGS) ||
      can(ROLES.TRANSPORT_SETTINGS) ||
      can(ROLES.FOSTER_SETTINGS) ||
      can(ROLES.VOLUNTEER_SETTINGS) ||
      can(ROLES.LOST_FOUND_SETTINGS_2) ||
      can(ROLES.COMMUNITY_SUPPORT_SETTINGS) ||
      can(ROLES.UNLIMITED_FORMS)
  );

  const hasUseNewPlaformFlag = computed(() => shelterProfile.value?.new_platform);

  function can(permissionName: ROLES) {
    if (isSU.value || isSUGLO.value) {
      return true;
    }

    if (permissions.value && permissions.value.length > 0) {
      return permissions.value.includes(permissionName);
    }

    return false;
  }

  function getActiveFeatures() {
    if (!shelterProfile.value) return [];

    const flags = Object.values(FEATURE_FLAGS);

    return flags.filter((flag) => shelterProfile.value[flag]);
  }

  // Actions
  // TODO : Create interface for initProfile
  async function initProfile(profileData: any) {
    initialUserId.value = userProfile.value?._id;
    isLogged.value = true;
    isSUGLO.value =
      profileData.super_admin_glo ||
      profileData.user.role === 'af-admin-glo' ||
      profileData.user.role === 'af-admin-staff';
    isSU.value = isSUGLO.value || profileData.super_admin;
    userProfile.value = profileData.user;
    shelterProfile.value = profileData.shelter;
    permissions.value = profileData.permissions;

    // NOTE :: Keep this console.log(). Gerald's request.
    console.log(shelterProfile.value?._id?.$oid);
  }

  async function clearProfile() {
    isSU.value = false;
    isSUGLO.value = false;
    userProfile.value = null;
    shelterProfile.value = null;
    permissions.value = [];
  }

  function reset() {
    initialUserId.value = null;
    isInvalidSession.value = null;
    isLogged.value = false;
    isSU.value = false;
    isSUGLO.value = false;
    permissions.value = null;
    shelterProfile.value = null;
    userProfile.value = null;
    localStorage.clear();
    sessionStorage.clear();
  }

  async function end() {
    let response;
    try {
      response = await logout();
    } catch (error) {
      console.error('sessionStore::logout::error', error);
    } finally {
      isLogged.value = false;
      clearProfile();
      console.log('sessionStore::logout::response', response);
    }
  }

  return {
    checkForPermissionSettings,
    hasUseNewPlaformFlag,
    initialUserId,
    isInvalidSession,
    isLogged,
    isSU,
    isSUGLO,
    permissions,
    shelterHasProducts,
    shelterProfile,
    userProfile,

    can,

    end,
    initProfile,
    clearProfile,
    reset,

    getActiveFeatures,
  };
});
