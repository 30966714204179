<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const dynamicKey = computed(() => props.settings.render.dynamicKey);
const targetRef = computed(() => {
  const { href, targetKey, secondaryKey } = props.settings.render;

  if (secondaryKey)
    return `${href}${props.rowData[targetKey[0]] + '/' + props.rowData[secondaryKey[0]]}`;

  return targetKey.length > 1
    ? `${href}${props.rowData[targetKey[0]][targetKey[1]]}`
    : `${href}${props.rowData[targetKey]}`;
});
</script>

<template>
  <span
    v-if="!field"
    class="flex flex-col items-start gap-0.5"
  >
    -
  </span>

  <RouterLink
    v-else
    class="text-sky-600 transition-colors hover:text-sky-900 hover:underline"
    :to="targetRef"
  >
    {{ rowData[dynamicKey] }} - {{ field }}
  </RouterLink>
</template>
