import { ref } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema, wasFormSubmitted } = useForm();

  const fieldsSettings: FieldInitialSettings = {};
  const rawFields: RawField[] = [
    {
      label: 'Follow-Up Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Follow-Up Reason',
      name: 'followup_reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Follow-Up Notes',
      name: 'followup_notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  function setForm(sourceData?: any) {
    const updatedFieldSchema = getDetailsSchema(rawFields, fieldsSettings);
    if (!sourceData) return (fieldsSchema.value = updatedFieldSchema);

    formError.value = null;
    wasFormSubmitted.value = false;

    updatedFieldSchema.forEach((field) => {
      const match = sourceData[field.name];
      const timeFrame = {
        duration: sourceData.time_until_followup,
        duration_unit: sourceData.followup_unit,
      };

      switch (field.name) {
        case 'time_until_followup': {
          field.model = timeFrame;
          field.value = timeFrame;
          break;
        }
        default: {
          setMatchField(field, match);
        }
      }
    });

    fieldsSchema.value = updatedFieldSchema;
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
