<script setup lang="ts">
import { computed, nextTick, onMounted, useTemplateRef } from 'vue';
import type { FieldSchema } from '../../../types';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    error?: string;
    field?: FieldSchema;
    isInlineEditing?: boolean;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    isInlineEditing: false,
    placeholder: 'components.forms.placeholder.common',
  }
);

const model = defineModel<string>();

const inlineComponentRef = useTemplateRef<any>('inlineComponentRef');

const settings = computed(() => props?.field?.settings);
const _disabled = computed(() => {
  if (props.field?.state?.disabled) return props.field?.state.disabled;

  return settings.value?.editable ? !settings.value?.editable : props.disabled;
});

onMounted(() => checkEditType());

function checkEditType() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  nextTick(() => {
    inlineComponentRef.value.$el.focus();
  });
}
</script>

<template>
  <InputText
    v-model="model"
    ref="inlineComponentRef"
    :class="['h-9 w-full text-sm', { 'af-field-error': !!error }]"
    :disabled="_disabled"
    :invalid="!!error"
    :placeholder="$t(settings?.placeholder || placeholder)"
    :required="settings?.required || required"
  />
</template>
