import { computed } from 'vue';
import { useScroll, useElementSize } from '@vueuse/core';

function scrollToTop() {
  const scrollForm = document.querySelector('.scrollToTop') as HTMLElement | null;
  const scrollModal = document.querySelector('.scrollToTopModal') as HTMLElement | null;

  if (scrollForm) {
    const { y: yForm } = useScroll(scrollForm);
    yForm.value = 0;
  }

  if (scrollModal) {
    const { y: yModal } = useScroll(scrollModal);
    yModal.value = 0;
  }
}

// TODO: [AF-781] Review this function as it returns an error
function addPaddingOnScroll(refElement: any) {
  const { height } = useElementSize(refElement);

  const isScrollable = computed(() => {
    if (!refElement.value) return false;

    return refElement.value.scrollHeight > height.value;
  });

  return { isScrollable };
}

export { addPaddingOnScroll, scrollToTop };
