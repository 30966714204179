import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

const { ADOPTER, DONOR, DRIVER, FOSTER, FOSTERS, OWNER, PEOPLE, VOLUNTEER } = ROLES;

export default <RouteRecordRaw[]>[
  {
    path: 'people',
    name: 'people',
    component: () => import('./views/ShelterPeople.view.vue'),
    meta: {
      permissions: [PEOPLE],
    },
  },
  {
    path: 'people/profile/:id',
    name: 'people-profile',
    component: () => import('./views/PeopleProfile.view.vue'),
    meta: {
      permissions: [
        FOSTERS || PEOPLE || ADOPTER || OWNER || FOSTER || DONOR || VOLUNTEER || DRIVER,
      ],
    },
  },
  {
    path: 'people/add-people',
    name: 'add-people',
    component: () => import('./views/PeopleForm.view.vue'),
    meta: {
      permissions: [PEOPLE],
    },
  },
  {
    path: 'people/animal-intake/:id',
    name: 'admit-animal',
    component: () => import('./views/PeopleAnimalForm.view.vue'),
    meta: {
      permissions: [PEOPLE],
    },
  },
  {
    path: 'people/add-people/:id',
    name: 'add-people-animal-linked',
    component: () => import('./views/PeopleAnimalLinkedForm.view.vue'),
    meta: {
      permissions: [PEOPLE],
    },
  },
];
