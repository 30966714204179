import moment from 'moment-timezone';

const DEFAULT_DATA_RANGE = [new Date(moment().subtract(30, 'days').format()), new Date()];

const PRESET_MODE = [
  {
    name: 'common.date',
    value: 'date',
  },
  {
    name: 'common.month',
    value: 'month',
  },
  {
    name: 'common.year',
    value: 'year',
  },
];

const PRESET_FULL_DATES = [
  { label: 'common.today', value: [new Date(), new Date()] },
  {
    label: 'common.yesterday',
    value: [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(new Date().setDate(new Date().getDate() - 1)),
    ],
  },
  {
    label: 'common.last7Days',
    value: [new Date(moment().subtract(7, 'days').format()), new Date()],
  },
  {
    label: 'common.last30Days',
    value: [new Date(moment().subtract(30, 'days').format()), new Date()],
  },
  {
    label: 'common.thisMonth',
    value: [
      new Date(moment().startOf('month').format()),
      new Date(moment().endOf('month').format()),
    ],
  },
  {
    label: 'common.lastMonth',
    value: [
      new Date(moment().subtract(1, 'month').startOf('month').format()),
      new Date(moment().subtract(1, 'month').endOf('month').format()),
    ],
  },
  {
    label: 'common.thisYear',
    value: [new Date(moment().startOf('year').format()), new Date(moment().endOf('year').format())],
  },
  {
    label: 'common.lastYear',
    value: [
      new Date(moment().startOf('year').subtract(1, 'year').format()),
      new Date(moment().endOf('year').subtract(1, 'year').format()),
    ],
  },
];

const PRESET_WITHIN_YEAR_DATES = [
  {
    label: 'common.last3Days',
    value: [new Date(moment().subtract(3, 'days').format()), new Date()],
  },
  {
    label: 'common.last5Days',
    value: [new Date(moment().subtract(5, 'days').format()), new Date()],
  },
  {
    label: 'common.last7Days',
    value: [new Date(moment().subtract(7, 'days').format()), new Date()],
  },
  {
    label: 'common.last15Days',
    value: [new Date(moment().subtract(15, 'days').format()), new Date()],
  },
  {
    label: 'common.last30Days',
    value: [new Date(moment().subtract(30, 'days').format()), new Date()],
  },
  {
    label: 'common.thisMonth',
    value: [
      new Date(moment().startOf('month').format()),
      new Date(moment().endOf('month').format()),
    ],
  },
  {
    label: 'common.thisYear',
    value: [new Date(moment().startOf('year').format()), new Date()],
  },
  {
    label: 'common.lastYear',
    value: [new Date(moment().subtract(1, 'year').format()), new Date()],
  },
];

export { DEFAULT_DATA_RANGE, PRESET_MODE, PRESET_FULL_DATES, PRESET_WITHIN_YEAR_DATES };
