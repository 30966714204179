import { API } from '@Providers';
import { PAYMENT_METHODS } from '../types';

interface CheckoutRequest {
  _id: string; // TODO AF-545 : Probably not necessary
  cart_id: string;
  checkoutFlow?: string; // TODO AF-533 - Should be an ENUM
  email: string | null;
  payment_type: PAYMENT_METHODS | null;
  phone: string;
  resend?: boolean;
  show_application?: boolean | null;
  show_contract?: boolean | null;
  status: string; // TODO AF-533 - Should be an ENUM
}

export default async function (payload: CheckoutRequest) {
  const endpoint = '/api/shelter/cart/send-checkout-link';

  return API({ endpoint, method: 'post', payload });
}
