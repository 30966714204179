<script setup lang="ts">
import { computed, ref, onMounted, watch, useTemplateRef } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { GenericField } from '@Types';
import type { FieldSchema } from '../../../types';

const emits = defineEmits<{
  onSelectBirthdayDate: [];
  onEditBirthdayCancel: [];
}>();

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    isInlineEditing?: boolean;
    label?: string;
  }>(),
  {
    isInlineEditing: false,
  }
);

const { t } = useI18n();

const model = defineModel<any>();

const inlineComponentRef = useTemplateRef<any>('inlineComponentRef');

const estBirthday = props.field?.settings?.estBirthday ? 'estimated' : 'actual';

const birthdayModel = ref();
const isActualDate = ref(false);
const radioModel = ref<'estimated' | 'actual'>(estBirthday);

const options = computed((): GenericField[] => [
  {
    name: t('animals.birthdayField.estimation'),
    value: 'estimated',
  },
  {
    name: t('animals.birthdayField.actual'),
    value: 'actual',
  },
]);

onMounted(() => setModelOnStart());

onClickOutside(inlineComponentRef, () => checkBirthdayType());

watch(birthdayModel, () => fieldModel());

function setModelOnStart() {
  if (!model.value && props.field?.settings?.noModelOnStart) return;

  if (model.value?.birthdayModel) return (birthdayModel.value = model.value.birthdayModel);

  if (model.value) return (birthdayModel.value = model.value);

  const estBirthday = props.field?.settings?.estBirthday ? props.field.settings.estBirthday : true;
  model.value = { birthdayModel: new Date(), radioModelEst: estBirthday };
}

function fieldModel() {
  const radioModelEst = radioModel.value === 'estimated' ? true : false;

  model.value = { birthdayModel, radioModelEst };

  setTimeout(() => {
    if (isActualDate.value) {
      emits('onSelectBirthdayDate');
      isActualDate.value = false;
    }
  }, 100);
}

function checkBirthdayType() {
  if (!props.isInlineEditing || radioModel.value === 'actual') return;

  emits('onSelectBirthdayDate');
}
</script>

<template>
  <div
    class="flex flex-col gap-2"
    ref="inlineComponentRef"
  >
    <AFieldRadio
      v-model="radioModel"
      :options
    />

    <MFieldDateComposer
      v-if="radioModel === 'estimated'"
      v-model="birthdayModel"
      :error
      :label
    />

    <MFieldDatePicker
      v-else-if="radioModel === 'actual'"
      v-model="birthdayModel"
      :isInlineEditing
      :noModelOnStart="field?.settings?.noModelOnStart"
      :maxDate="new Date()"
      @onSelectInlineDate="isActualDate = true"
    />

    <!-- NOTE :: This button its just for cancel edit inline on birthday field (ODetails) -->
    <div
      v-if="isInlineEditing"
      class="absolute right-[-18px] top-[-45px] flex gap-2 rounded-lg rounded-r-none border border-r-0 border-slate-200 bg-white p-1"
    >
      <Button
        v-tooltip.top="$t('common.cancel')"
        outlined
        class="h-7 w-7 rounded-md p-1 !text-xs"
        icon="pi pi-times text-xs"
        pt:icon="text-red-500"
        severity="danger"
        @click="emits('onEditBirthdayCancel')"
      />
    </div>
  </div>
</template>
