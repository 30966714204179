import { defineStore } from 'pinia';

interface State {
  donationId: string;
  donationsCategories: any;
  isEdit: boolean;
  loading: boolean;
  household: any;
  pageData: any;
  rowData: any;
}

export default defineStore('people', {
  state: (): State => ({
    donationId: '',
    donationsCategories: [],
    isEdit: false,
    loading: false,
    household: {},
    pageData: {},
    rowData: {},
  }),
});
