import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'lost-and-found',
    name: 'lost-and-found',
    redirect: '/shelter/lost-and-found/lost-animals',
    meta: {
      featureFlag: FEATURE_FLAGS.LOST_AND_FOUND,
      permissions: [ROLES.LOST_FOUND_2],
    },
    children: [
      {
        path: 'found-animals',
        name: 'lost-and-found-found-animals',
        component: () => import('./views/FoundAnimals.view.vue'),
      },
      {
        path: 'found-report',
        name: 'lost-and-found-found-report',
        component: () => import('./views/FoundReport.view.vue'),
      },
      {
        path: 'map',
        name: 'lost-and-found-map',
        component: () => import('./views/LostAndFoundMap.view.vue'),
      },
      {
        path: 'stats',
        name: 'lost-and-found-stats',
        component: () => import('./views/LostAndFoundStats.view.vue'),
      },
      {
        path: 'lost-animals',
        name: 'lost-and-found-lost-animals',
        component: () => import('./views/LostAnimals.view.vue'),
      },
      {
        path: 'lost-report',
        name: 'lost-and-found-lost-report',
        component: () => import('./views/LostReport.view.vue'),
      },
      {
        path: 'outcome-report',
        name: 'lost-and-found-outcome-report',
        component: () => import('./views/OutcomeReport.view.vue'),
      },
      {
        path: 'add-animal',
        name: 'lost-and-found-animal-form',
        component: () => import('./views/LostAndFoundForm.view.vue'),
      },
      {
        path: 'add-animal/:id',
        name: 'lost-and-found-people-linked-animal-form',
        component: () => import('./views/LostAndFoundPeopleLinkedForm.view.vue'),
      },
    ],
  },
];
