<script setup lang="ts">
import { computed, ref } from 'vue';
import { API_URL } from '@Constants';
import { capitalizeStr } from '@Helpers';

interface Voucher {
  [key: string]: any;
  _id: string;
  amount: string;
  file: string;
  path: string;
  shelter_id: string;
  status: string;
  voucher_seq: number;
}

const props = defineProps<{
  field?: Voucher[];
  rowData?: any;
  settings?: any;
}>();

const visibleRight = ref(false);

const fieldVoucher = computed(() => props.field?.length.toString() || '0');

function getFileUrl(shelterId: string, path: string) {
  return `${API_URL}/shelter/files/get-file/${shelterId}?file=${encodeURIComponent(path)}`;
}
</script>

<template>
  <Button
    outlined
    class="af-button"
    icon="pi pi-money-bill"
    severity="info"
    :disabled="!field?.length"
    :label="fieldVoucher"
    @click="visibleRight = true"
  />

  <Sidebar
    v-model:visible="visibleRight"
    position="right"
    :header="$t('communitySupport.aside.vouchers')"
  >
    <div
      v-for="{ _id, shelter_id, title, file, amount, status, voucher_seq } in field"
      class="mb-3 border-b pb-3"
      :key="_id"
    >
      <a
        class="text-sky-600 transition-colors hover:text-sky-900"
        target="_blank"
        :href="getFileUrl(shelter_id, file)"
      >
        <h2 class="font-bold">{{ title }}</h2>
      </a>

      <div class="flex gap-1 text-sm">
        <p class="font-semibold">Amount:</p>
        <p>{{ amount }}</p>
      </div>

      <div class="flex gap-1 text-sm">
        <p class="font-semibold">Status:</p>
        <p>{{ capitalizeStr(status) }}</p>
      </div>

      <div class="flex gap-1 text-sm">
        <p class="font-semibold">ID:</p>
        <p>{{ voucher_seq }}</p>
      </div>
    </div>
  </Sidebar>
</template>
