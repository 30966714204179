import type { Ref } from 'vue';
import type { FieldInitialSettings, RawField } from '../types';

function setEmptyModelForFieldsWithDateType(
  rawFields: Ref<RawField[]>,
  fieldsSettings: Ref<FieldInitialSettings>
) {
  const dynamicFieldsSettings = ['date'];

  rawFields.value.forEach((field: RawField) => {
    if (!dynamicFieldsSettings.includes(field.type)) return;

    fieldsSettings.value[field.name] = {
      settings: {
        noModelOnStart: true,
      },
    };
  });
}

export { setEmptyModelForFieldsWithDateType };
