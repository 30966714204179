import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'reports',
    name: 'reports',
    redirect: '/shelter/reports/intakes',
    children: [
      {
        path: 'adopt-applications',
        name: 'reports-adopt-applications',
        component: () => import('./views/ReportsAdoptApplications.view.vue'),
        meta: {
          featureFlag: FEATURE_FLAGS.ADOPT_APP,
        },
      },
      {
        path: 'adoption',
        name: 'reports-adoption',
        component: () => import('./views/ReportsAdoption.view.vue'),
        meta: {
          permissions: [ROLES.ADOPTIONS_REPORT],
        },
      },
      {
        path: 'adopt-requests',
        name: 'reports-adopt-requests',
        component: () => import('./views/ReportsAdoptRequests.view.vue'),
        meta: {
          featureFlag: FEATURE_FLAGS.ADOPT_APP,
          permissions: [ROLES.ADOPT_REQUEST_REPORT],
        },
      },
      {
        path: 'donations',
        name: 'reports-donations',
        component: () => import('./views/FinancialReports/ReportsDonations.view.vue'),
      },
      {
        path: 'foster',
        name: 'reports-foster',
        component: () => import('./views/ReportsFoster.view.vue'),
        meta: {
          permissions: [ROLES.FOSTERS_REPORT],
        },
      },
      {
        path: 'hills',
        name: 'reports-hills',
        component: () => import('./views/ReportsHills.view.vue'),
        meta: {
          featureFlag: FEATURE_FLAGS.HILLS_REPORT,
          permissions: [ROLES.HILLS_REPORT],
        },
      },
      {
        path: 'intakes',
        name: 'reports-intakes',
        component: () => import('./views/ReportsIntakes.view.vue'),
        meta: {
          permissions: [ROLES.INTAKES_REPORT],
        },
      },
      {
        path: 'microchips',
        name: 'reports-microchips',
        component: () => import('./views/ReportsMicrochips.view.vue'),
        meta: {
          permissions: [ROLES.MICROCHIP_REPORT],
        },
      },
      {
        path: 'microchips-options',
        name: 'reports-microchips-options',
        component: () => import('./oldViews/ReportsMicrochipsOptions.view.vue'),
      },
      {
        path: 'outcomes',
        name: 'reports-outcomes',
        component: () => import('./views/ReportsOutcomes.view.vue'),
        meta: {
          permissions: [ROLES.OUTCOMES_REPORT],
        },
      },
      {
        path: 'petcademy',
        name: 'reports-petcademy',
        component: () => import('./views/ReportsPetcademy.view.vue'),
        meta: {
          featureFlag: FEATURE_FLAGS.PETCADEMY,
          permissions: [ROLES.PETCADEMY_REPORT],
        },
      },
      {
        path: 'requests-for-care',
        name: 'reports-requests-for-care',
        component: () => import('./views/ReportsRequestsForCare.view.vue'),
        meta: {
          permissions: [ROLES.RFC_REPORT],
        },
      },
      {
        path: 'rto',
        name: 'reports-rto',
        component: () => import('./views/ReportsRto.view.vue'),
        meta: {
          permissions: [ROLES.RTO_REPORT],
        },
      },
      {
        path: 'shelter-animals-count',
        name: 'reports-shelter-animals-count',
        component: () => import('./views/ReportsShelterAnimalsCount.view.vue'),
        meta: {
          permissions: [ROLES.SAC_REPORT_2],
        },
      },
      {
        path: 'stray-map',
        name: 'reports-stray-map',
        component: () => import('./views/ReportsStrayMap.view.vue'),
        meta: {
          permissions: [ROLES.INTAKES_REPORT],
        },
      },
      {
        path: 'voucher',
        name: 'reports-voucher',
        component: () => import('./views/ReportsVoucher.view.vue'),
      },
      {
        path: 'financial',
        name: 'reports-financial',
        redirect: '/shelter/reports/financial/adoption-payments',
        children: [
          // TODO: [AF-898] - Add intake payments and end of day ROLEs
          {
            path: 'end-of-day',
            name: 'reports-end-of-day',
            component: () => import('./views/FinancialReports/ReportsEndOfDay.view.vue'),
          },
          {
            path: 'intake-payments',
            name: 'reports-intake-payments',
            component: () => import('./views/FinancialReports/ReportsIntakePayments.view.vue'),
          },
          {
            path: 'adoption-payments',
            name: 'reports-adoption-payments',
            component: () => import('./views/FinancialReports/ReportsAdoptionPayments.view.vue'),
            meta: {
              permissions: [ROLES.ADOPT_PAYMENT_REPORT],
            },
          },
          {
            path: 'citation-payments',
            name: 'reports-citation-payments',
            component: () => import('./views/FinancialReports/ReportsCitationPayments.view.vue'),
            meta: {
              permissions: [ROLES.CITATION_PAYMENT_REPORT],
            },
          },
          {
            path: 'rto-payments',
            name: 'reports-rto-payments',
            component: () => import('./views/FinancialReports/ReportsRtoPayments.view.vue'),
            meta: {
              permissions: [ROLES.RTO_PAYMENT_REPORT],
            },
          },
          {
            path: 'donations',
            name: 'reports-donations',
            component: () => import('./views/FinancialReports/ReportsDonations.view.vue'),
            meta: {
              permissions: [ROLES.DONATION_REPORT],
            },
          },
          {
            path: 'outcome-fee',
            name: 'reports-outcome-fee',
            component: () => import('./views/FinancialReports/ReportsOutcomeFee.view.vue'),
            meta: {
              permissions: [ROLES.OUTCOME_FEE_REPORT],
            },
          },
          // TODO: [AF-898] - Add products and services ROLE
          {
            path: 'products',
            name: 'reports-products',
            component: () => import('./views/FinancialReports/ReportsProducts.view.vue'),
          },
          {
            path: 'services',
            name: 'reports-services',
            component: () => import('./views/FinancialReports/ReportsServices.view.vue'),
          },
        ],
      },
      {
        path: 'medical',
        name: 'reports-medical',
        redirect: '/shelter/reports/medical/daily-diagnostics',
        children: [
          {
            path: 'daily-diagnostics',
            name: 'reports-medical-daily-diagnostics',
            component: () => import('./views/MedicalReport/MedicalReportDailyDiagnostics.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'daily-medications',
            name: 'reports-medical-daily-medications',
            component: () => import('./views/MedicalReport/MedicalReportDailyMedications.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'daily-procedures',
            name: 'reports-medical-daily-procedures',
            component: () => import('./views/MedicalReport/MedicalReportDailyProcedures.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'daily-surgeries',
            name: 'reports-medical-daily-surgeries',
            component: () => import('./views/MedicalReport/MedicalReportDailySurgeries.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'daily-vaccinations',
            name: 'reports-medical-daily-vaccinations',
            component: () =>
              import('./views/MedicalReport/MedicalReportDailyVaccinations.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'exam',
            name: 'reports-medical-exam',
            component: () => import('./views/MedicalReport/MedicalReportExam.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'behavior',
            name: 'reports-medical-behavior',
            component: () => import('./views/MedicalReport/MedicalReportBehavior.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'follow-up',
            name: 'reports-medical-follow-up',
            component: () => import('./views/MedicalReport/MedicalReportFollowUp.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'rabies',
            name: 'reports-medical-rabies',
            component: () => import('./views/MedicalReport/MedicalReportRabies.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
          {
            path: 'spay-and-neuter',
            name: 'reports-medical-spay-and-neuter',
            component: () => import('./views/MedicalReport/MedicalReportSpayAndNeuter.view.vue'),
            meta: {
              permissions: [ROLES.MEDICAL_REPORTS],
            },
          },
        ],
      },
    ],
  },
];
