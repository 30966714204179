function convertToTitleCase(str: string) {
  if (!str) return;

  return str
    .split('_')
    .map((word) => capitalizeStr(word))
    .join(' ');
}

function capitalizeEachWord(str: string) {
  if (!str) return str;

  return str
    .split(' ')
    .map((word) => capitalizeStr(word))
    .join(' ');
}

function capitalizeStr(str: string) {
  if (typeof str !== 'string' || !str) return str;

  const firstChar = str.charAt(0);
  if (!firstChar.match(/[a-zA-Z]/)) return str;

  return firstChar.toUpperCase() + str.slice(1);
}

function firstLetterOnly(word: string) {
  return word.charAt(0).toUpperCase();
}
const getTruncatedText = (text: string, size?: number) => {
  const maxSize = size || 30;

  return text.length > maxSize ? `${text.slice(0, maxSize)}...` : text;
};

export { capitalizeStr, capitalizeEachWord, convertToTitleCase, firstLetterOnly, getTruncatedText };
