import type { AfDate } from '@Types';

// TODO :: Double check if we are actually going to use the bellow types
interface DATE_NUMBER {
  $numberLong?: string;
}

interface DATE {
  $date?: DATE_NUMBER;
}

interface FILES {
  _id: string;
  created_at?: DATE;
  filename: string;
  path: string;
  type?: string;
  visibility?: string;
  collection: string;
  destinationPath: string;
  icon?: string;
}

interface ICON_MAP {
  [key: string]: string;
}

interface DeleteFile {
  _id: string;
  collection: string;
  destinationPath: string;
  file: string;
  property: string;
  secure: boolean;
}
// TODO :: Double check if we are actually going to use the above types

enum AF_FILE_TYPES {
  BEHAVIOR = 'behavior',
  COMMUNITY_SUPPORT_FILE = 'csFile',
  DIAGNOSTIC = 'diagnostic',
  EXAM = 'exam',
  EXAMS = 'exams',
  FIELD_SERVICE_FILE = 'fSFile',
  FIELD_SERVICE_CITATION = 'fSCitation',
  GENERAL = 'general',
  MEDICATION = 'medication',
  PARTNERS = 'partners',
  PEOPLE = 'people',
  PROCEDURE = 'procedure',
  SURGERY = 'surgery',
  TRANSPORT = 'transport',
  VACCINATION = 'vaccination',
  TNVR = 'tnvr',
}

enum AF_FILE_COLLECTIONS {
  ANIMALS = 'animals',
  COMMUNITY_SUPPORT_CALLS = 'communitySupportCalls',
  FIELD_SERVICE_CALLS = 'fieldServicesCalls',
  INTAKES = 'intakes',
  MEDICAL_BEHAVIOR = 'behaviorRecords',
  MEDICAL_DIAGNOSTIC = 'medicalDiagnostics',
  MEDICAL_EXAM = 'medicalRecords',
  MEDICAL_MEDICATION = 'medicalMedications',
  MEDICAL_PROCEDURE = 'procedures',
  MEDICAL_SURGERY = 'medicalSurgeries',
  MEDICAL_VACCINATION = 'medicalVaccinations',
  PARTNERS_COLLECTION = 'partners',
  PEOPLE_COLLECTION = 'people',
  TNVR_CALLS = 'tnvrCalls',
  TRANSPORT_CALLS = 'transports',
}

enum AF_FILE_ICON {
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  GIF = 'gif',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PDF = 'pdf',
  PNG = 'png',
  TXT = 'txt',
  WEBP = 'webp',
  XLS = 'xls',
  XLSX = 'xlsx',
}

enum AF_FILE_PROPERTY {
  CITATIONS = 'citations',
  FILES = 'files',
}

interface AfFile {
  _id: string;
  collection?: AF_FILE_COLLECTIONS;
  created_at: AfDate;
  destinationPath?: string;
  fileTypeId?: string;
  filename: string;
  rabies: boolean;
  spay_neuter: boolean;
  icon: AF_FILE_ICON;
  path: string;
  type: AF_FILE_TYPES;
  visibility: 'public' | 'private';
}

export type { AfFile, AF_FILE_ICON, DeleteFile, FILES, ICON_MAP };
export { AF_FILE_COLLECTIONS, AF_FILE_TYPES, AF_FILE_PROPERTY };
