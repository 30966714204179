enum ROUTES {
  AD = 'AD - right ear',
  AS = 'AS - left ear',
  AU = 'AU - both ears',
  IC = 'IC - intracardiac',
  IM = 'IM - intramuscle',
  IN = 'IN - intranasal',
  IP = 'IP - abdominal',
  IV = 'IV - intravenous',
  OD = 'OD - right eye',
  OS = 'OS - left eye',
  OU = 'OU - both eyes',
  PN = 'PN - by nebulization',
  PO = 'PO - by mouth',
  PR = 'PR - by rectum',
  SL = 'SL - sublingual ',
  SQ = 'SQ - subcutaneous',
  TD = 'TD - transdermal',
  TM = 'TM - transmucosal/buccal',
  TOP = 'TOP - topically',
  BUC = 'BUC - inside mouth',
}

export { ROUTES };
