import { useI18n } from 'vue-i18n';
import { FORM_VALIDATIONS } from '../constants';
import type { FieldSchema } from '../types';
import { emailValidation, microchipValidation, phoneValidation } from '../helpers';

export default function () {
  const { t } = useI18n();
  // CASES
  function emailFieldValidation(field: FieldSchema) {
    const ERROR_MESSAGE = 'components.forms.errors.validEmail';
    const { state, model } = field;

    if (!model) return state.errors.delete(ERROR_MESSAGE);

    const isValid = emailValidation(model);
    if (isValid) return state.errors.delete(ERROR_MESSAGE);

    state.errors.add(ERROR_MESSAGE);
  }

  function microchipFieldValidation(field: FieldSchema) {
    const ERROR_MESSAGE = 'components.forms.errors.requiredMicrochipLength';
    const { state, model } = field;

    if (!model) return state.errors.delete(ERROR_MESSAGE);

    const isValid = microchipValidation(model);
    if (isValid) return state.errors.delete(ERROR_MESSAGE);

    state.errors.add(ERROR_MESSAGE);
  }

  function phoneFieldValidation(field: FieldSchema) {
    const ERROR_MESSAGE = 'components.forms.errors.validPhone';
    const { state, model } = field;

    if (!model) return state.errors.delete(ERROR_MESSAGE);

    const isValid = phoneValidation(model);
    if (isValid) return state.errors.delete(ERROR_MESSAGE);

    state.errors.add(ERROR_MESSAGE);
  }

  function emailTypeFieldValidation(field: FieldSchema) {
    const EMPTY_EMAIL = 'components.forms.errors.onePrimaryEmailRequired';
    const { state, model } = field;

    if (!model) return state.errors.delete(EMPTY_EMAIL);

    const hasPrimary = field.model.filter((entry: any) => entry.primary).length;
    if (hasPrimary) return state.errors.delete(EMPTY_EMAIL);

    state.errors.add(EMPTY_EMAIL);
  }

  // CUSTOM VALIDATIONS
  function minLengthValidation(field: FieldSchema, rule: number) {
    const ERROR_MESSAGE = t('components.forms.errors.minLength', { length: rule });
    const { state, model } = field;

    if (!model) return state.errors.delete(ERROR_MESSAGE);

    const isValid = model.length >= rule;
    if (isValid) return state.errors.delete(ERROR_MESSAGE);

    state.errors.add(ERROR_MESSAGE);
  }

  function maxLengthValidation(field: FieldSchema, rule: number) {
    const ERROR_MESSAGE = t('components.forms.errors.maxLength', { length: rule });
    const { state, model } = field;

    if (!model) return state.errors.delete(ERROR_MESSAGE);

    const isValid = model.length <= rule;
    if (isValid) return state.errors.delete(ERROR_MESSAGE);

    state.errors.add(ERROR_MESSAGE);
  }

  // VALIDATION TYPES
  function fieldTypeValidation(field: FieldSchema) {
    const { type } = field.settings;

    const needsValidation = ['email', 'email_type', 'microchip', 'tel'];
    if (!type || !needsValidation.includes(type)) return;

    //prettier-ignore
    switch (type) {
      case 'email': return emailFieldValidation(field);
      case 'tel': return phoneFieldValidation(field);
      case 'email_type': return emailTypeFieldValidation(field);
      case 'microchip': return microchipFieldValidation(field);
    }
  }

  function fieldCustomValidation(field: FieldSchema) {
    if (!field.settings?.validations) return;

    const { validations } = field.settings;
    const { MAX_LENGTH, MIN_LENGTH } = FORM_VALIDATIONS;

    validations?.forEach(({ validation, rule }) => {
      //prettier-ignore
      switch (validation) {
        case MIN_LENGTH: return minLengthValidation(field, rule);
        case MAX_LENGTH: maxLengthValidation(field, rule);
      }
    });
  }

  function _requiredField(field: any) {
    const REQUIRED_ERROR_MESSAGE = 'components.forms.errors.required';
    const { model, settings, state } = field;
    const { required } = settings;

    const modelNotEmpty: boolean = Array.isArray(model) ? !!model.length : !!model;

    if (required && !modelNotEmpty) return state.errors.add(REQUIRED_ERROR_MESSAGE);

    state.errors.delete(REQUIRED_ERROR_MESSAGE);
  }

  // HANDLER
  function validateField(field: FieldSchema, wasFormSubmitted: boolean = false) {
    if (field.settings.required && wasFormSubmitted) _requiredField(field);

    fieldTypeValidation(field);
    fieldCustomValidation(field);
  }

  return {
    validateField,
  };
}
