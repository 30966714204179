import { defineStore } from 'pinia';
import type { OutcomeForm, RtoFee } from './types';
import { PAYMENT_METHODS } from './types';
import {
  INTAKE_ROUTES,
  INTAKES_WITH_PAYMENT,
  OUTCOME_ROUTES,
  OUTCOMES_WITH_CART,
} from './constants';
import { CART_STEPS } from './types';
import {
  GetCartActiveDiscounts,
  GetCartSessionService,
  GetLostAndFoundOutcomeFormService,
  GetOutcomeDetails,
  GetOutcomeFormsService,
  GetRehomeOutcomeFormService,
  GetRtoFeesService,
} from './services';

const {
  ADD_PEOPLE,
  CHECKOUT_METHOD,
  MOBILE_CHECKOUT,
  MOBILE_PROCESSING,
  OUTCOME_INFO,
  PAYMENT_METHOD,
  PRODUCTS_INFO,
  PRODUCTS_AND_SERVICES,
  SUMMARY,
} = CART_STEPS;

// TODO - AF-527 :: Each step should have a proper interface
interface StepData {
  [ADD_PEOPLE]: any;
  [CHECKOUT_METHOD]: any;
  [MOBILE_CHECKOUT]: any;
  [MOBILE_PROCESSING]: any;
  [OUTCOME_INFO]: any;
  [PAYMENT_METHOD]: any;
  [PRODUCTS_AND_SERVICES]: any;
  [PRODUCTS_INFO]: any;
  [SUMMARY]: any;
}

interface State {
  cartAside: boolean;
  cartAsideButton: boolean;
  cartData: any;
  cardFieldError: string;
  cardHolderName: string;
  checkoutPendingData: any; // TODO : AF-530 Probably deprecated
  discounts: any;
  fetchingCart: boolean; // TODO : AF-528 Still needs implementation
  forms: OutcomeForm[];
  hasRtoFee: boolean;
  loading: boolean;
  mainLoading: boolean;
  outcomeCheckoutType: any;
  outcomeCompleted: boolean;
  outcomeForm: any; // TODO : AF-530 Probably deprecated
  outcomeInfoActiveStep: number; // TODO : AF-530 Probably deprecated
  peopleForm: any;
  redirectUrl: string;
  rtoFees: RtoFee[];
  selectedRtoFee: RtoFee[];
  stepState: StepData;
}

const initialStepState = {
  [ADD_PEOPLE]: {},
  [CHECKOUT_METHOD]: {},
  [MOBILE_CHECKOUT]: {},
  [MOBILE_PROCESSING]: {},
  [OUTCOME_INFO]: {},
  [PAYMENT_METHOD]: {},
  [PRODUCTS_AND_SERVICES]: {},
  [PRODUCTS_INFO]: {},
  [SUMMARY]: {},
};

const STATE: State = {
  cartAside: false,
  cartAsideButton: true,
  cartData: {},
  cardFieldError: '',
  cardHolderName: '',
  checkoutPendingData: {},
  discounts: [],
  fetchingCart: false, // TODO : AF-528 Still needs implementation
  forms: [],
  hasRtoFee: false,
  loading: false,
  mainLoading: false,
  outcomeCheckoutType: null,
  outcomeCompleted: false,
  outcomeForm: {}, // TODO : AF-530 Probably deprecated
  outcomeInfoActiveStep: 0, // TODO : AF-530 Probably deprecated
  peopleForm: undefined,
  redirectUrl: '',
  rtoFees: [],
  selectedRtoFee: [],
  stepState: initialStepState,
};

export default defineStore('cart', {
  state: (): State => ({ ...STATE }),

  getters: {
    // TODO - AF-529 - All getters should be using getActions
    activeDiscount: (state: State) => state.cartData?.discount || 0,
    activeDiscountCode: (state: State) => state.cartData?.discount_code || '',
    animals: (state: State) => state.cartData?.animals || [],
    cartId: (state: State) => state.cartData?.cart_id.$oid || '',
    citations: (state: State) => state.cartData?.citations || [],
    isAdoption: (state: State) => state.cartData?.outcome_type === 'adopted',
    isCheckoutPending: (state: State) => state.cartData?.checkout_status === 'pending',
    isCitation: (state: State) => state.cartData?.cart_type === 'citation',
    isIntake: (state: State) => state.cartData?.cart_type === 'intake',
    isProduct: (state: State) => state.cartData?.cart_type === 'product',
    isService: (state: State) => state.cartData?.cart_type === 'service',
    isReturnToOwner: (state: State) => state.cartData?.outcome_type === 'return_to_owner',
    outcomeType: (state: State) => state.cartData?.outcome_type || '',
    products: (state: State) => state.cartData?.products || [],
    productsLength: (state: State) => state.cartData?.products.length,
    people: (state: State) => state.cartData?.people || undefined,
    services: (state: State) => state.cartData?.services || [],
    servicesLength: (state: State) => state.cartData?.services.length,
    type: (state: State) => state.cartData?.cart_type || undefined,

    hasCart: (state: State) =>
      [...OUTCOMES_WITH_CART, ...INTAKES_WITH_PAYMENT].includes(state.cartData?.outcome_type),

    completeOutcomeRoute(state: State) {
      return state.cartData.cart_type === 'intake'
        ? `${INTAKE_ROUTES.completed}${state.cartData?.cart_id.$oid}`
        : `${OUTCOME_ROUTES.completed}${state.cartData?.cart_id.$oid}`;
    },

    pendingOutcomeRoute(state: State) {
      return state.cartData.cart_type === 'intake'
        ? `${INTAKE_ROUTES.pending}${state.cartData?.cart_id.$oid}`
        : `${OUTCOME_ROUTES.pending}${state.cartData?.cart_id.$oid}`;
    },

    cartLength: (state: State) =>
      (state.cartData?.animals ? state.cartData?.animals.length : 0) +
      (state.cartData?.citations ? state.cartData?.citations.length : 0) +
      (state.cartData?.products ? state.cartData?.products.length : 0) +
      (state.cartData?.services ? state.cartData?.services.length : 0),

    emptyCart: (state: State) =>
      (!state.cartData?.animals || !state.cartData?.animals.length) &&
      (!state.cartData?.citations || !state.cartData?.citations.length) &&
      (!state.cartData?.services || !state.cartData?.services.length) &&
      (!state.cartData?.products || !state.cartData?.products.length),
  },

  actions: {
    // GETTERS
    cartTypeIsCitation: (cartData: any) => cartData?.cart_type === 'citation',
    cartTypeIsIntake: (cartData: any) => cartData?.cart_type === 'intake',
    cartTypeIsProduct: (cartData: any) => cartData?.cart_type === 'product',
    cartTypeIsService: (cartData: any) => cartData?.cart_type === 'service',
    getAnimals: (cartData: any) => cartData?.animals || [],
    getCitations: (cartData: any) => cartData?.citations || [],
    getHasCart: (cartData: any) =>
      [...OUTCOMES_WITH_CART, ...INTAKES_WITH_PAYMENT].includes(cartData?.outcome_type),
    getOutcomeType: (cartData: any) => cartData?.outcome_type || '',
    getProducts: (cartData: any) => cartData?.products || [],
    getServices: (cartData: any) => cartData?.services || [],
    getType: (cartData: any) => cartData?.cart_type,

    getCompletedOutcomeRoute(cartId: string, cartType?: string) {
      return cartType === 'intake'
        ? `${INTAKE_ROUTES.completed}${cartId}`
        : `${OUTCOME_ROUTES.completed}${cartId}`;
    },

    getPendingOutcomeRoute(cartId: string, cartType?: string) {
      return cartType === 'intake'
        ? `${INTAKE_ROUTES.pending}${cartId}`
        : `${OUTCOME_ROUTES.pending}${cartId}`;
    },

    getCartLength(cartData: any) {
      return (
        (cartData?.animals ? cartData?.animals.length : 0) +
        (cartData?.citations ? cartData?.citations.length : 0) +
        (cartData?.products ? cartData?.products.length : 0) +
        (cartData?.services ? cartData?.services.length : 0)
      );
    },

    isCardReaderLastFourInvalid(digits?: number, paymentMethod?: PAYMENT_METHODS) {
      return (
        paymentMethod === PAYMENT_METHODS.CARD_READER && (!digits || digits.toString().length !== 4)
      );
    },
    // PROVIDERS
    // TODO - AF-528 - Review this logic to hit the API only the necessary amount of time.
    async fetchCompletedOutcome(cartId: string) {
      return GetOutcomeDetails(cartId)
        .then((data: any) => {
          // NOTE : Empty cart
          if (!data?.cart_id?.$oid) return;

          // NOTE : Mobile checkout
          const isCheckoutPending = data.checkout_status === 'pending';
          if (isCheckoutPending) {
            this.checkoutPendingData = data;
            this.redirectUrl = this.getPendingOutcomeRoute(data.cart_id.$oid);
            return;
          }

          // NOTE : Outcome completed
          this.outcomeCompleted = true;
          this.redirectUrl = this.getCompletedOutcomeRoute(data.cart_id.$oid);
          return;
        })
        .finally(() => (this.fetchingCart = false));
    },

    async fetchActiveCart(cartId?: string) {
      this.fetchingCart = true;

      return GetCartSessionService()
        .then((data: any) => {
          const noCartData = !data?.cart_id?.$oid;
          if (noCartData && cartId) this.fetchCompletedOutcome(cartId);

          this.cartData = data;
        })
        .finally(() => (this.fetchingCart = false));
    },

    async fetchActiveDiscounts() {
      return GetCartActiveDiscounts().then((data: any) => (this.discounts = data));
    },

    async fetchOutcomeForms(displayFormType?: string) {
      this.loading = true;

      let formService: () => Promise<OutcomeForm[]>;

      //NOTE : Do not use pretter-ignore here as formService() needs to be called
      switch (displayFormType) {
        case 'outcome':
          formService = GetOutcomeFormsService;
          break;
        case 'lost_found':
          formService = GetLostAndFoundOutcomeFormService;
          break;
        case 'rehome':
          formService = GetRehomeOutcomeFormService;
          break;
        default:
          return Promise.reject(new Error('Invalid form type'));
      }

      return formService()
        .then((data: OutcomeForm[]) => {
          this.forms = data;
          this.getRtoFees();
        })
        .finally(() => (this.loading = false));
    },

    async getRtoFees() {
      await GetRtoFeesService().then(({ rto_fees }: any) => (this.hasRtoFee = !!rto_fees.length));
    },

    async refreshData() {
      this.loading = true;
      return this.fetchActiveCart(this.$state.cartData.cart_id.$oid).finally(
        () => (this.loading = false)
      );
    },

    // RESETS
    resetCart() {
      this.$state = {
        ...STATE,
        cartData: {},
        discounts: this.discounts,
        forms: this.forms,
        hasRtoFee: this.hasRtoFee,
      };
    },

    outcomeCompletedCartReset() {
      // NOTE : This method is here in case we need to do specific resets for completed outcomes
      this.resetCart();

      return true;
    },
  },
});
