<script setup lang="ts">
import type { GenericField } from '@Types';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
  options?: GenericField[];
}>();

const model = defineModel<string>();

const isSimpleOption = (option: any): option is string => {
  return typeof option === 'string';
};
</script>

<template>
  <div class="flex flex-wrap gap-4">
    <div
      v-for="option in field?.edit?.options || options"
      :key="isSimpleOption(option) ? option : `${option.name}_${option.value}`"
      class="flex items-center"
    >
      <RadioButton
        v-model="model"
        :disabled="field?.state?.disabled || disabled"
        :inputId="isSimpleOption(option) ? option : `${option.name}_${option.value}`"
        :invalid="!!error"
        :name="isSimpleOption(option) ? option : option.name"
        :value="isSimpleOption(option) ? option : option.value"
      />

      <label
        :class="['ml-2 text-sm', { 'af-field-error': !!error }]"
        :for="`${option.name}_${option.value}`"
      >
        {{ isSimpleOption(option) ? option : option.name || option.value }}
      </label>
    </div>
  </div>
</template>
