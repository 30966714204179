import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { ROUTES } from '@AnimalModule';
import { useForm } from '@FormModule';
import { useAnimalProfileStore } from '@Stores';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { t } = useI18n();
  const { proceduresTemplates, medicalStaff } = storeToRefs(useAnimalProfileStore());
  const { getDetailsSchema, wasFormSubmitted } = useForm();

  const rawFields: RawField[] = [
    {
      label: 'Procedure',
      name: 'procedure',
      required: true,
      type: 'select',
      value: '',
    },
    {
      label: 'Reason for Procedure',
      name: 'reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Status',
      name: 'action',
      required: true,
      type: 'radio',
      value: '',
    },
    {
      label: 'Procedure Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Route',
      name: 'route',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Amount',
      name: 'amount',
      required: false,
      type: 'amount_unit',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: '',
      name: 'schedule_followup',
      required: false,
      type: 'toggle',
      value: '',
    },
    {
      label: '',
      name: 'separator_followup',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Time Until Follow-Up',
      name: 'time_until_followup',
      required: false,
      type: 'timeframe',
      value: '',
    },
    {
      label: 'Follow-Up Reason',
      name: 'followup_reason',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: 'Follow-Up Notes',
      name: 'followup_notes',
      required: false,
      type: 'textarea',
      value: '',
    },
    {
      label: '',
      name: 'separator_followup',
      required: false,
      type: 'seperator',
      value: '',
    },
    {
      label: 'Performed By',
      name: 'by',
      required: false,
      type: 'select',
      value: '',
    },
    {
      label: 'Other Veterinarian',
      name: 'other_by',
      required: false,
      type: 'text',
      value: '',
    },
  ];

  const byOptions = computed(() =>
    medicalStaff.value.map((staff: any) => {
      const { first_name, last_name } = staff;
      return {
        ...staff,
        by: `${first_name} ${last_name}`,
      };
    })
  );

  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      by: {
        edit: {
          label: 'by',
          options: [...byOptions.value, { by: 'Other' }],
        },
        settings: {
          required: false,
          show: false,
        },
      },
      other_by: {
        settings: {
          show: false,
        },
      },
      action: {
        edit: {
          options: [t('common.schedule'), t('common.complete')],
        },
      },
      procedure: {
        edit: {
          label: 'template_name',
          options: proceduresTemplates.value.sort((a, b) =>
            a.template_name.localeCompare(b.template_name)
          ),
        },
      },
      schedule_followup: {
        settings: {
          label: 'Schedule a Follow-Up Check',
        },
      },
      route: {
        edit: {
          options: Object.values(ROUTES),
        },
      },
    })
  );

  const procedureField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'procedure')
  );
  const followUpField = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'schedule_followup')
  );
  const actionField = computed(() => fieldsSchema.value.find(({ name }) => name === 'action'));
  const byField = computed(() => fieldsSchema.value.find(({ name }) => name === 'by'));
  const byModel = computed(() => byField.value?.model);
  const procedureModel = computed(() => procedureField.value?.model);
  const isAction = computed(() => actionField.value?.model);
  const isFollowUp = computed(() => followUpField.value?.model);

  watch(byModel, () => showOtherVetField());
  watch(isAction, () => showActionFields());
  watch(isFollowUp, () => showFollowUpFields());
  watch(procedureModel, (newValue, oldValue) => fillFormWithTemplate(newValue, oldValue));

  function showOtherVetField() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'other_by') {
        const showField = byModel?.value?.by === 'Other';

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };

        if (byModel?.value?.by !== 'Other') return (field.model = '');
      }
    });

    return true;
  }

  function fillFormWithTemplate(newValue: any, oldValue: any) {
    if (!newValue || oldValue === newValue) return;

    updateSchemaModels(procedureModel.value);
  }

  function showFollowUpFields() {
    fieldsSchema.value.forEach((field) => {
      const followUpFields = [
        'followup_notes',
        'followup_reason',
        'separator_followup',
        'time_until_followup',
      ];

      if (followUpFields.includes(field.name)) {
        const showField = isFollowUp.value === true;

        field.settings = {
          ...field.settings,
          required: field.name === 'separator_followup' ? false : showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function showActionFields() {
    fieldsSchema.value.forEach((field) => {
      if (field.name === 'by') {
        const showField = isAction.value === 'Complete';

        field.settings = {
          ...field.settings,
          required: showField,
          show: showField,
        };
      }
    });

    return true;
  }

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings.value);

    if (!procedureModel.value) {
      fieldsSchema.value.forEach((field: any) => {
        if (field.name === 'procedure') return;

        field.state.disabled = true;
        field.settings.disabled = true;
      });
    }
  }

  function updateSchemaModels(sourceData: any) {
    formError.value = null;
    wasFormSubmitted.value = false;

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];
      if (field.name === 'procedure') return;

      field.state.disabled = false;
      field.settings.disabled = false;

      // prettier-ignore
      switch (field.name) {
        case 'action': return setMatchStatusField(field, sourceData);
        case 'expiration_date': case 'date': return setDateField(field, sourceData);
        case 'time_until_followup': return setTimeUntilFollowUpField(field, sourceData);
        default:
          setMatchField(field, match);
      }
    });
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
    field.state.errors.clear();
  }

  function setTimeUntilFollowUpField(field: any, sourceData: any) {
    field.model = {
      duration: sourceData.time_until_followup,
      duration_unit: sourceData.followup_unit,
    };
    field.value = {
      duration: sourceData.time_until_followup,
      duration_unit: sourceData.followup_unit,
    };
  }

  function setMatchStatusField(field: any, sourceData: any) {
    field.model = sourceData.status === 'active' ? 'Schedule' : 'Complete';
    field.value = sourceData.status === 'active' ? 'Schedule' : 'Complete';
    field.state.errors.clear();
  }

  function setDateField(field: any, sourceData: any) {
    if (field.name !== 'expiration_date') {
      field.model = new Date();
      field.value = new Date();
      field.state.errors.clear();

      return;
    }

    const numberDate = Number(sourceData?.expiration_date?.$date?.$numberLong);
    const validDate = isNaN(numberDate) ? new Date() : new Date(numberDate);

    field.model = validDate;
    field.value = validDate;
    field.state.errors.clear();
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
    wasFormSubmitted.value = false;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    resetFieldSchema,
    setForm,
  };
}
