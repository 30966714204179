function formatPhoneNumber(value: string) {
  // Returns phone number in the format (123)456-7890
  // Appends 1 if passed in
  // TODO: how to format international numbers with more than 11 digits

  if (!value) {
    return;
  }

  const cleaned = ('' + value).replace(/\D/g, '');
  let match;

  if (value.length === 7) {
    match = cleaned.match(/^(\d{3})(\d{4})$/);
  } else if (value.length === 10) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  } else {
    match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  }

  if (match && value.length === 7) {
    return `${match[1]}-${match[2]}`;
  } else if (match && value.length === 10) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  } else if (match && value.length !== 10) {
    return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return value;
}

export { formatPhoneNumber };
