import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'transports',
    name: 'transports',
    component: () => import('./views/TransportsAnimals.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.TRANSPORTS,
    },
  },
  {
    path: 'transports/:id',
    name: 'transport',
    component: () => import('./views/TransportDetails.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.TRANSPORTS,
    },
  },
  {
    path: 'transports/create-transport',
    name: 'create-transport',
    component: () => import('./views/TransportForm.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.TRANSPORTS,
    },
  },
];
