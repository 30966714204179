import type { RouteRecordRaw } from 'vue-router';
import { AdminRoutes } from '@AdminModule';
import { AuthRoutes } from '@AuthModule';
import { SettingsRoutes } from '@SettingsModule';
import { ShelterRoutes } from '@ShelterModule';
import { SystemFilterRoutes } from '@FilterModule';

export default <RouteRecordRaw[]>[
  ...AdminRoutes,
  ...AuthRoutes,
  ...SettingsRoutes,
  ...ShelterRoutes,
  ...SystemFilterRoutes,
];
