import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'forms-and-applications',
    name: 'forms-and-applications',
    component: () => import('./views/FormsAndApplications.view.vue'),
    meta: {
      featureFlag: FEATURE_FLAGS.FORMS_AND_APPLICATIONS,
      permissions: [ROLES.UNLIMITED_FORMS],
    },
  },
];
