import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

enum TOAST_TYPES {
  CUSTOM = 'CUSTOM',
  ERROR = 'ERROR',
  INFO = 'INFORMATION',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

interface Toast {
  hasDimissButton?: boolean;
  icon?: IconDefinition;
  isOpen?: boolean;
  message?: string;
  title: string;
  type?: TOAST_TYPES;
}

export { type Toast, TOAST_TYPES };
