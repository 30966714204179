import type { GenericField } from '@Types';
import type { FieldPayload } from '@FormModule';
import { payloadSanitizerHelper } from '@FormModule';

function getSanitizedFieldPerName({ name, value }: FieldPayload) {
  switch (name) {
    case 'additional_fees': {
      return {
        additional_fees: value ? value : [],
      };
    }
    case 'file': {
      return {
        files: value
          ? value.map((file: any) => ({
              filename: file.name,
            }))
          : [],
      };
    }
  }
}

export default function (fields: Set<GenericField>) {
  let payload: { [key: string]: any } = {};
  const fieldNamesToBeSanitezed = ['file', 'additional_fees'];

  fields.forEach((field: GenericField) => {
    const entry = payloadSanitizerHelper(field, fieldNamesToBeSanitezed, getSanitizedFieldPerName);
    payload = { ...payload, ...entry };
  });

  return payload;
}
