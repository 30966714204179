<script setup lang="ts">
import { computed } from 'vue';
import useDataTableFilters from '../../../composables/useDataTableFilters';

const emits = defineEmits<{
  onNewSort: [];
  onSelectAllRows: [];
}>();

const props = defineProps<{
  field: string;
  header: string;
}>();

const { activeSortField, activeSortOrder, sort } = useDataTableFilters();

const classes = computed(() => [
  'w-fit border-0 px-2 py-1 hover:bg-brand-200 hover:text-brand-500',
  {
    'bg-brand-100 text-brand-500 hover:text-brand-600': activeSortField.value === props.field,
  },
]);

const activeIcon = computed(() =>
  activeSortOrder.value === 'desc' ? 'pi pi-sort-alpha-down-alt' : 'pi pi-sort-alpha-down'
);

function onSort() {
  activeSortOrder.value = activeSortOrder.value === 'asc' ? 'desc' : 'asc';
  activeSortField.value = props.field;

  sort.value = !props.field ? '' : `${props.field}|${activeSortOrder.value}`;

  emits('onNewSort');
}
</script>

<template>
  <Button
    outlined
    severity="secondary"
    :class="classes"
    :icon="activeIcon"
    @click="onSort"
  />
</template>
