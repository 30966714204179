<script setup lang="ts">
import { useClipboard } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useConfirm } from 'primevue/useconfirm';
import type { FieldSchema } from '../../../types';

const props = defineProps<{
  field: FieldSchema;
  model: any;
}>();

const { t } = useI18n();
const { copy, copied } = useClipboard({ source: props.model, legacy: true });
const confirm = useConfirm();

function onConfirmSearch(event: any) {
  confirm.require({
    group: 'searchAhaa',
    target: event.currentTarget,
    icon: 'pi pi-search text-sky-500',
    acceptIcon: 'pi pi-external-link',
    rejectClass: 'p-button-secondary p-button-sm text-xs',
    acceptClass: 'p-button-sm text-xs p-button-danger',
    rejectLabel: t('common.cancel'),
    acceptLabel: t('common.open'),
    accept: () => onSearch(),
  });
}

function onSearch() {
  window.open(
    'https://www.aaha.org/petmicrochiplookup?microchip_id=' +
      props.model +
      '&AllowNonAlphaNumberic=0/',
    '_blank'
  );
}
</script>

<template>
  <i
    :class="[
      'w-fit cursor-pointer text-sm text-gray-500 hover:text-brand-500',
      !copied ? 'pi pi-copy' : 'pi pi-check',
    ]"
    @click="copy(model)"
  />

  <div
    class="group/search flex cursor-pointer items-center gap-1 pr-4 text-sm"
    @click="onConfirmSearch"
  >
    <i
      class="pi pi-search w-fit text-sm text-gray-500 transition-colors group-hover/search:text-sky-500"
    />

    <p class="transition-colors group-hover/search:text-sky-500">
      {{ $t('components.forms.aaha.label') }}
    </p>
  </div>

  <aside>
    <ConfirmPopup group="searchAhaa">
      <template #message>
        <div class="flex flex-col items-center justify-center gap-3 p-4 text-center">
          <i class="pi pi-search text-3xl text-sky-500"></i>

          <div>
            <h2 class="font-medium">{{ t('components.forms.aaha.aahaHeader') }}</h2>

            <p class="text-sm">{{ t('components.forms.aaha.aahaMessage') }}</p>
          </div>
        </div>
      </template>
    </ConfirmPopup>
  </aside>
</template>
