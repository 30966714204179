<script setup lang="ts">
import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import type { PageState } from 'primevue/paginator';
import useDataTable from '../../../composables/useDataTable';

const emits = defineEmits<{
  onNewPage: [event: PageState];
  onRowsPerPageUpdate: [];
}>();

const props = withDefaults(
  defineProps<{
    instanceComposable?: any;
    pageLinkSize: number;
    totalRecords: number;
  }>(),
  {
    pageLinkSize: 5,
  }
);

const { width } = useWindowSize();
const { rowsPerPage } = props.instanceComposable ? props.instanceComposable() : useDataTable();

const hasPaginator = computed(() => props.totalRecords > rowsPerPage.value);

function getShowingRows() {
  const showingAllResults = rowsPerPage.value >= props.totalRecords;

  return showingAllResults ? props.totalRecords : rowsPerPage.value;
}
</script>

<template>
  <Paginator
    pt:root="!flex-nowrap"
    :pageLinkSize
    :totalRecords
    :class="!hasPaginator ? 'hide-paginator h-[61px] px-5' : 'h-[61px] px-5'"
    :rows="rowsPerPage"
    :template="{
      '1024px': 'PrevPageLink CurrentPageReport NextPageLink',
      default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    }"
    @page="emits('onNewPage', $event)"
  >
    <template #start>
      <span
        v-if="width > 768"
        class="text-sm text-gray-500"
      >
        {{ $t('components.dataTable.paginator.summary', { rows: getShowingRows(), totalRecords }) }}
      </span>
    </template>

    <template #end>
      <div class="flex flex-nowrap items-center justify-center gap-8">
        <div class="flex items-center gap-2">
          <label
            v-if="width > 1024"
            class="text-sm text-gray-500"
          >
            {{ $t('components.dataTable.paginator.perPage') }}
          </label>

          <Dropdown
            v-model="rowsPerPage"
            pt:input="text-sm"
            pt:item="text-sm"
            :options="[10, 25, 50, 75, 100]"
            @change="emits('onRowsPerPageUpdate')"
          />
        </div>
      </div>
    </template>
  </Paginator>
</template>

<style>
.hide-paginator .p-paginator-first,
.hide-paginator .p-paginator-prev,
.hide-paginator .p-paginator-pages,
.hide-paginator .p-paginator-page,
.hide-paginator .p-paginator-next,
.hide-paginator .p-paginator-last {
  @apply hidden;
}

.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev {
  @apply min-w-0;
}

.p-paginator-first,
.p-paginator-prev,
.p-paginator-pages,
.p-paginator-page,
.p-paginator-next,
.p-paginator-last {
  @apply flex;
}

.p-paginator-right-content {
  @apply flex items-center;
}

.p-paginator .p-dropdown {
  @apply flex h-8 w-20 items-center;
}

.p-dropdown-trigger {
  @apply w-fit pr-3;
}
</style>
