<script setup lang="ts">
import { computed, ref, onMounted, watch, useTemplateRef, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { usePrimeVue } from 'primevue/config';
import type { AfDate } from '@Types';
import type { FieldSchema } from '../../../types';
import { getDefaultDateFormat, DEFAULT_DATETIME_FORMAT } from '@Helpers';
import { DAY_NAMES, DAY_NAMES_MIN, DAY_NAMES_SHORT, MONTHS, MONTHS_ABBR } from './constants';
import usePresetDates from './usePresetDates';

const emits = defineEmits<{
  'update:modelValue': [Date | Date[] | null];
  onSelectInlineDate: [Date | Date[] | null];
}>();

const props = withDefaults(
  defineProps<{
    dateFormat?: string;
    disabled?: boolean;
    disableDates?: Date[];
    disableDays?: number[];
    error?: string;
    field?: FieldSchema;
    fluid?: boolean;
    hasShortcutButtons?: boolean;
    hideOnDateTimeSelect?: boolean;
    hideOnRangeSelection?: boolean;
    hourFormat?: string;
    iconDisplay?: 'input' | 'button';
    isInlineEditing?: boolean;
    manualInput?: boolean;
    maxDate?: Date;
    maxDateCount?: number;
    minDate?: Date;
    modelValue?: Date | Date[] | AfDate | string;
    noModelOnStart?: boolean;
    numberOfMonths?: number;
    selectionMode?: 'multiple' | 'range' | 'single';
    showIcon?: boolean;
    showTime?: boolean;
    timeOnly?: boolean;
  }>(),
  {
    dateFormat: 'mm/dd/yy',
    hasShortcutButtons: false,
    hideOnDateTimeSelect: true,
    hideOnRangeSelection: true,
    hourFormat: '12',
    iconDisplay: 'input',
    isInlineEditing: false,
    manualInput: true,
    noModelOnStart: false,
    numberOfMonths: 1,
    selectionMode: 'single',
    showIcon: true,
  }
);

const primeVue = usePrimeVue();
const { presetDates } = usePresetDates();
const { t, locale } = useI18n();

const inlineComponentRef = useTemplateRef<any>('calendarRef');

const calendarRef = ref();

const currentDate = computed(() => moment(moment().format(DEFAULT_DATETIME_FORMAT)).toDate());
const selectionMode = computed(() => props?.field?.settings?.selectionMode || props.selectionMode);
const settings = computed(() => props?.field?.settings);
const timeOnly = computed(() => props.timeOnly || props?.field?.settings?.timeOnly || false);
const availableMonths = computed(() => {
  const DEFAULT = props.selectionMode === 'range' ? 2 : 1;

  return props?.field?.settings?.numberOfMonths || props.numberOfMonths || DEFAULT;
});
const calendarSelectionMode = computed(
  () => props?.field?.settings?.selectionMode || props.selectionMode
);
const inputErrorClass = computed(() => [
  'text-sm text-gray-700',
  { 'af-field-error': !!props.error },
]);
const _model = computed({
  get: () => {
    return (props.field?.settings.noModelOnStart || props.noModelOnStart) &&
      props.modelValue === null
      ? null
      : getSanitizedDate(props.modelValue);
  },
  set: (newSelection: Date | Date[] | null) => {
    emits('update:modelValue', newSelection);

    if (!props.isInlineEditing) return;
    emits('onSelectInlineDate', newSelection);
  },
});
const shortCuts = computed(() =>
  presetDates.value.filter(({ selectionModes }) => selectionModes.includes(selectionMode.value))
);
const showShortcuts = computed(
  () => props?.field?.settings?.hasShortcutButtons || props.hasShortcutButtons
);
const showTime = computed(
  () => timeOnly.value || props?.showTime || props?.field?.settings?.showTime || false
);

onMounted(() => setInitialModel());

watch(locale, () => updatePrimeVueDatePickerLocale());

function setInitialModel() {
  checkEditType();
  if (props.isInlineEditing) return;

  (props.field?.settings.noModelOnStart || props.noModelOnStart) && !props.modelValue
    ? emits('update:modelValue', null)
    : emits('update:modelValue', _model.value);
}

function updatePrimeVueDatePickerLocale() {
  const { locale } = primeVue.config;
  if (!locale) return;

  locale.dayNames = DAY_NAMES.map((day) => t(`components.datePicker.dayNames.${day}`));
  locale.dayNamesMin = DAY_NAMES_MIN.map((day) => t(`components.datePicker.dayNamesMin.${day}`));
  locale.dayNamesShort = DAY_NAMES_SHORT.map((day) =>
    t(`components.datePicker.dayNamesShort.${day}`)
  );

  locale.monthNames = MONTHS.map((month) => t(`components.datePicker.months.${month}`));
  locale.monthNamesShort = MONTHS_ABBR.map((month) =>
    t(`components.datePicker.monthsAbbr.${month}`)
  );
}

function getSanitizedDate(model: Date | Date[] | AfDate | string | undefined): Date | Date[] {
  const isUndefinedModel = !model;
  if (isUndefinedModel)
    return selectionMode.value === 'single'
      ? currentDate.value
      : [currentDate.value, currentDate.value];

  const isDateRange = Array.isArray(model);
  if (isDateRange) return model.map((date) => getFormattedModel(date));

  const isSingleDate = moment.isDate(model);
  if (isSingleDate) return getFormattedModel(model);

  /**
   * NOTE
   * There is a possibility of receiving AfDate[]. This case is ommited
   * on purpose. So far, this moment that case never happened.
   */
  return getFormattedModel(model);
}

function getFormattedModel(model: Date | AfDate | string) {
  const sanitizedDate = getDefaultDateFormat(model, showTime.value, timeOnly.value);

  return moment(sanitizedDate).toDate();
}

function checkEditType() {
  if (!props.isInlineEditing || !inlineComponentRef.value) return;

  nextTick(() => {
    const inputEl = inlineComponentRef.value?.$el?.querySelector('input');
    if (!inputEl) return;

    inputEl.focus();
  });
}

function onDataRangeSelection(value: Date | Date[]) {
  _model.value = value;
  calendarRef.value.overlayVisible = false;
}
</script>

<template>
  <div class="flex">
    <!-- NOTE: As strange as this div is, this enables the placeholder of the Calendar component
     Without it the placeholder don't show up. -->
    <div
      v-if="!_model"
      class
    ></div>

    <Calendar
      v-model="_model"
      pt:root="h-9 w-full"
      ref="calendarRef"
      :showTime
      :dateFormat="settings?.dateFormat || dateFormat"
      :disabled="settings?.disabled || disabled"
      :disableDates="settings?.disableDates || disableDates"
      :disableDays="settings?.disableDays || disableDays"
      :fluid="settings?.fluid || fluid"
      :hideOnDateTimeSelect="settings?.hideOnDateTimeSelect || hideOnDateTimeSelect"
      :hideOnRangeSelection="settings?.hideOnRangeSelection || hideOnRangeSelection"
      :hourFormat="settings?.dateFormat || hourFormat"
      :iconDisplay="settings?.iconDisplay || iconDisplay"
      :invalid="!!error"
      :manualInput="settings?.manualInput || manualInput"
      :maxDate="settings?.maxDate || maxDate"
      :maxDateCount="settings?.maxDateCount || maxDateCount"
      :minDate="settings?.minDate || minDate"
      :numberOfMonths="availableMonths"
      :placeholder="$t('components.forms.placeholder.calendar')"
      :pt:input:class="inputErrorClass"
      :pt:inputIcon:class="{ 'text-red-600': !!error }"
      :selectionMode="calendarSelectionMode"
      :showIcon="settings?.showIcon || showIcon"
      :timeOnly="settings?.timeOnly || timeOnly"
    >
      <template
        v-if="showShortcuts"
        #footer
      >
        <Button
          v-for="{ label, value } in shortCuts"
          :label
          :key="label"
          class="mr-2 mt-2 bg-brand-300 px-2 py-1 text-sm"
          @click="onDataRangeSelection(value)"
        />
      </template>
    </Calendar>

    <Button
      v-if="field?.settings?.noModelOnStart || noModelOnStart"
      outlined
      v-tooltip.top="{ value: $t('tooltip.eraseDate'), showDelay: 300 }"
      class="af-button ml-1 w-9 border-none text-gray-400"
      icon="pi pi-eraser"
      severity="secondary"
      @click="_model = null"
    />
  </div>
</template>

<style>
.p-datepicker-other-month {
  @apply opacity-30;
}
</style>
