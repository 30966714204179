<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useADialog } from '@Atoms';

const emits = defineEmits<{
  blur: [];
}>();

const props = defineProps<{
  isFormsAndApplications?: boolean;
  isEditingOnCell?: boolean;
  loading?: boolean;
  rowInfo?: any;
}>();

const { t } = useI18n();
const { activeModal, activeModalProps, dialogVisibility, SYSTEM_MODALS } = useADialog();

const _initialModelValue = ref(props.rowInfo.status);
const _model = ref(props.rowInfo.status);

const options = computed(() => [
  { label: t('filters.options.submitted'), value: 'submitted' },
  { label: t('filters.options.pending'), value: 'pending' },
  { label: t('filters.options.approved'), value: 'approved' },
  { label: t('filters.options.canceled'), value: 'canceled' },
  { label: t('filters.options.declined'), value: 'declined' },
  {
    label: props.isFormsAndApplications
      ? t('filters.options.deleted')
      : t('filters.options.doNotAdopt'),
    value: props.isFormsAndApplications ? 'deleted' : 'doNotAdopt',
  },
]);

const applicationId = computed(() => {
  return props.isFormsAndApplications
    ? props.rowInfo?._id?.$oid
    : props.rowInfo?.application_id?.$oid;
});

watch(applicationId, () => (_model.value = props.rowInfo.status));

function openModal() {
  const checkModel = !_model.value || _model.value === _initialModelValue.value;
  if (checkModel) return;

  const { adopt_user_id, application_id, form_type, status, people_id, shelter_id, _id } =
    props.rowInfo;
  const modalProps = props.isFormsAndApplications
    ? {
        applicationId: _id.$oid,
        oldStatus: status,
        status: _model.value,
      }
    : {
        adoptUserId: adopt_user_id?.$oid,
        applicationId: application_id?.$oid,
        formType: form_type,
        oldStatus: status,
        peopleId: people_id,
        shelterId: shelter_id.$oid,
        status: _model.value,
      };

  activeModalProps.value = modalProps;
  activeModal.value = SYSTEM_MODALS.INDIVIDUAL_ADOPTION_APPLICATIONS;
  dialogVisibility.value = true;
  emits('blur');
}
</script>

<template>
  <Dropdown
    v-model="_model"
    showClear
    optionLabel="label"
    optionValue="value"
    pt:input="flex items-center py-1 px-2 !text-sm text-gray-500"
    pt:item="!overflow-x-hidden !text-sm px-4"
    :options
    :disabled="loading"
    :placeholder="$t('filters.changeApplicationStatus.title')"
    :virtualScrollerOptions="{ itemSize: 36 }"
    @change="openModal"
  >
    <template #clearicon>
      <Button
        outlined
        class="af-button border-none p-0 !text-slate-400"
        icon="pi pi-times"
        pt:icon="text-sm"
        @click="emits('blur')"
      />
    </template>
  </Dropdown>
</template>
