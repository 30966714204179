<script setup lang="ts">
import type { FieldSchema } from '../../../types';
import ANIMAL_NAMES from './constants';

withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const model = defineModel<string>();

function generateRandomName(gender: 'male' | 'female') {
  const { MALE, FEMALE } = ANIMAL_NAMES;
  const target = gender === 'male' ? MALE : FEMALE;

  model.value = target[Math.floor(Math.random() * target.length)];
}
</script>

<template>
  <div>
    <AFieldInputText
      v-model="model"
      :error
      :field
      :placeholder="field?.settings.placeholder || placeholder"
      :required="field?.settings.required || required"
    />

    <div class="mt-2 flex items-center gap-1">
      <span class="text-xs font-medium"> {{ $t('animals.nameField.generateName') }}: </span>

      <Button
        outlined
        class="px-2 py-1 text-xs"
        severity="secondary"
        :label="$t('common.male')"
        @click="generateRandomName('male')"
      />

      <span class="text-xs">|</span>

      <Button
        outlined
        class="px-2 py-1 text-xs"
        severity="secondary"
        :label="$t('common.female')"
        @click="generateRandomName('female')"
      />
    </div>
  </div>
</template>
