import type { Ref } from 'vue';
import type { GenericArray, GenericField } from '@Types';
import { FIELDS_COMPONENTS, FORM_VALIDATIONS } from './constants';

const {
  AMOUNT_UNIT,
  ANIMAL_BIRTHDAY,
  ANIMAL_NAME,
  ANIMAL_TEMPLATE,
  BOOSTER,
  BUTTON_CHECKBOX,
  CHECKBOX,
  COLOR_SELECTOR,
  CONTACT,
  CONTACT_TYPE,
  COUNTRY,
  DATE,
  DROPDOWN,
  EDITOR,
  EMAIL,
  FILE,
  FREQUENCY,
  GMAP,
  HEADER,
  HOLD_STATUS,
  KENNEL_MANAGER,
  LINK,
  LOCATION,
  MICROCHIP,
  MICROCHIP_ISSUER,
  MONEY,
  MULTISELECT,
  NUMBER,
  PARAGRAPH,
  OPEN_MODAL,
  PASSWORD,
  PHONE_CARD,
  RADIO,
  RESULT,
  SEPARATOR,
  STAFF,
  TEL,
  TEXT,
  TEXTAREA,
  TIMEFRAME,
  TOGGLE,
  WEIGHT,
} = FIELDS_COMPONENTS;

interface FieldRender {
  [key: string]: any;
  component?: FIELDS_COMPONENTS;
  targetKey?: string[];
}

interface FieldEdit {
  [key: string]: any;
  component?: FIELDS_COMPONENTS;
  key?: string;
  label?: string;
  options?: any;
  value?: any;
}

interface Validation {
  validation: FORM_VALIDATIONS;
  rule: any;
}

interface FieldSettings {
  [key: string]: any;
  editable?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: string;
  validations?: Validation[];
}

interface FieldState {
  disabled?: boolean;
  errors: Set<string>;
  loading?: boolean;
}

interface FieldSchema {
  edit?: FieldEdit;
  label: string;
  model?: any;
  name: string;
  placeholder?: string;
  render?: FieldRender;
  settings: FieldSettings;
  state: FieldState;
  text?: string;
  title?: string;
  value: any;
}

interface FieldInitialSettings {
  [key: string]: FieldDefault;
}

interface FieldDefault {
  [key: string]: any;
  edit?: FieldEdit;
  label?: string;
  model?: any;
  name?: string;
  render?: FieldRender;
  settings?: FieldSettings;
  state?: FieldState;
  value?: any;
}

interface RawField {
  [key: string]: any;
  edit?: boolean;
  label: string;
  name: string;
  required: boolean;
  type: string;
  value?: any;
  values?: any[];
}

interface FieldPayload {
  name: string;
  value: any;
  type?: string;
}

interface FormComposable {
  currentSchemaModels: Ref<Set<GenericField>>;
  fieldsSchema: Ref<FieldSchema[]>;
  rawFields: Ref<RawField[]>;
  preUpdateSchemaValues?: Ref<GenericArray>;

  setForm: (data?: any) => void;
  resetForm: () => void;
}

class Field {
  edit?: FieldEdit;
  label: string;
  model: any;
  name: string;
  placeholder?: string;
  render?: FieldRender;
  settings: FieldSettings;
  state: FieldState;
  text?: string;
  title?: string;
  value: any;

  constructor(field: RawField, defaults?: FieldDefault, targetData?: any) {
    this.edit = this.getDefaultEdit(field, defaults);
    this.label = defaults?.label || field.label || '';
    this.model = this.getDefaultModel(field.type, targetData, defaults);
    this.name = defaults?.name || field.name || '';
    this.placeholder = defaults?.placeholder || field.placeholder || '';
    this.render = this.getDefaultRender(field, defaults?.render);
    this.settings = this.getDefaultSettings(field, defaults?.settings);
    this.state = this.getDefaultState(defaults?.state);
    this.text = defaults?.text || field.text || '';
    this.title = defaults?.title || field.title || '';
    this.value = defaults?.value || targetData || '';
  }

  private getDefaultEdit(field: RawField, defaults?: FieldDefault) {
    const settingsOptions = defaults?.edit?.options || field?.values || [];

    return {
      ...defaults?.edit,
      component: this.getEditComponent(field, settingsOptions, defaults?.settings),
      options: settingsOptions,
    };
  }

  private getDefaultModel(type: string, targetData: any, defaults?: FieldSettings) {
    const hasOldValue = defaults?.model || targetData;
    if (hasOldValue) return defaults?.model || targetData;

    const arrayModel = ['checkbox', 'multiselect'];

    return arrayModel.includes(type) ? [] : '';
  }

  private getDefaultRender(field: RawField, renderSettings?: FieldRender) {
    if (renderSettings) return renderSettings;

    return {
      component: this.getRenderComponent(field),
    };
  }

  private getDefaultSettings(field: RawField, defaults?: FieldSettings): FieldSettings {
    const { required, type } = field;

    return {
      ...defaults,
      editable: defaults?.editable ?? field?.editable ?? true,
      required: defaults?.required || required,
      type: defaults?.type || type,
    };
  }

  private getDefaultState(defaults?: FieldState): FieldState {
    return {
      disabled: defaults?.disabled || false,
      errors: defaults?.errors || new Set(),
      loading: defaults?.loading || false,
    };
  }

  private getEditComponent(field: RawField, settingsOptions: any[], settings?: FieldSettings) {
    const hasValues = settingsOptions.length;

    const type = settings?.type || field.type;

    // prettier-ignore
    switch (type) {
      case 'amount_unit': return AMOUNT_UNIT;
      case 'animal_birthday': case 'birthday': return ANIMAL_BIRTHDAY;
      case 'animal_name': return ANIMAL_NAME;
      case 'animal_template': return ANIMAL_TEMPLATE;
      case 'booster': return  BOOSTER;
      case 'breeds': return  !hasValues ? TEXT : DROPDOWN;
      case 'button_checkbox': return BUTTON_CHECKBOX;
      case 'checkbox': return hasValues > 5 ? MULTISELECT : CHECKBOX;
      case 'color_selector': return COLOR_SELECTOR;
      case 'contact': return CONTACT;
      case 'contact_type': return CONTACT_TYPE;
      case 'country': return COUNTRY;
      case 'date': case 'date_iso_string': return DATE;
      case 'editor': return EDITOR;
      case 'email': return EMAIL;
      case 'file': return FILE;
      case 'frequency': return FREQUENCY;
      case 'google-address': return LOCATION;
      case 'header': return HEADER;
      case 'hold_status': return HOLD_STATUS;
      case 'kennel_manager': return KENNEL_MANAGER;
      case 'location': return LOCATION;
      case 'microchip': return MICROCHIP;
      case 'microchip_issuer': return MICROCHIP_ISSUER;
      case 'money': return MONEY;
      case 'multiselect': return hasValues ? MULTISELECT : TEXT;
      case 'number': return NUMBER;
      case 'paragraph': return PARAGRAPH;
      case 'password': return PASSWORD;
      case 'phone_card': return PHONE_CARD;
      case 'radio': return hasValues > 5 ? DROPDOWN : RADIO;
      case 'result': return RESULT;
      case 'select': return DROPDOWN;
      case 'seperator': return SEPARATOR; // NOTE: This is a typo from the back
      case 'species': return hasValues ? DROPDOWN : TEXT;
      case 'staff': return STAFF;
      case 'tel': return TEL;
      case 'textarea': return TEXTAREA;
      case 'text': return TEXT;
      case 'timeframe': return TIMEFRAME;
      case 'toggle': return TOGGLE;
      case 'weight': return WEIGHT;
      default: return hasValues ? DROPDOWN : TEXT;
    }
  }

  private getRenderComponent(field: RawField) {
    const { type } = field;

    // prettier-ignore
    switch (type) {
      case 'date': return DATE;
      case 'link': return LINK;
      case 'open_modal': return OPEN_MODAL;
      case 'g_map': return GMAP;
      default: return undefined;
    }
  }
}

export { Field };
export type {
  FieldInitialSettings,
  FieldPayload,
  FieldRender,
  FieldSchema,
  FieldSettings,
  FormComposable,
  RawField,
};
