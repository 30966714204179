<script setup lang="ts">
import { API_URL } from '@Constants';

defineEmits(['deleteFile']);

const props = defineProps<{
  entityId: string;
  filename: string;
  filesProperties: any;
  index: number;
  path: string;
  shelterId: string;
}>();

function sanitizeEntityPath() {
  return props.path
    ? props.path
    : `${API_URL}.s3.amazonaws.com/${props.shelterId}/${props.entityId}/images/${props.filename}`;
}
</script>

<template>
  <div class="group relative">
    <a
      v-tooltip.top="
        index === 0
          ? $t('animals.individualPage.profileInfo.profilePhoto') + ' - ' + filename
          : filename
      "
      class="flex h-20 w-20 items-center justify-evenly p-2"
      target="_blank"
      :href="sanitizeEntityPath()"
    >
      <AAvatar
        :class="[
          'h-full w-full border ',
          index === 0 ? 'border-af-purple-500' : 'border-brand-500',
        ]"
        :src="sanitizeEntityPath()"
      />
    </a>

    <Button
      outlined
      class="absolute right-0 top-0 h-fit w-fit border-none p-0 opacity-0 transition-opacity hover:bg-red-100 group-hover:opacity-100"
      icon="pi pi-trash"
      severity="danger"
      @click="$emit('deleteFile')"
    />
  </div>
</template>
