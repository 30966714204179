enum ROLES {
  ADOPT_APPLICATION_REPORT = 'adopt-application-report',
  ADOPT_PAYMENT_REPORT = 'adopt-payment-report',
  ADOPT_REQUEST_REPORT = 'adopt-request-report',
  ADOPTER = 'adopter',
  ADOPTIONS = 'adoptions',
  ADOPTIONS_REPORT = 'adoptions-report',
  ADOPTIONS_SETTINGS = 'adoptions-settings',
  ANIMAL_SETTINGS = 'animal-settings',
  BEHAVIOR_MODULE = 'behavior_module',
  BEHAVIOUR_MODULE = 'behaviour_module',
  CITATION_PAYMENT_REPORT = 'citation-payment-report',
  COMMUNITY_SERVICE = 'community-service',
  COMMUNITY_SERVICE_SETTINGS = 'community-service-settings',
  COMMUNITY_SUPPORT = 'community-support',
  COMMUNITY_SUPPORT_SETTINGS = 'community-support-settings',
  COMPANION_PROTECT_REPORT = 'companion-protect-report',
  DELETE_ANIMAL = 'delete-animal',
  DONATION_REPORT = 'donation-report',
  DONATION_SETTINGS = 'donation_settings',
  DONOR = 'donor',
  DRIVER = 'driver',
  EDIT_ANIMAL = 'edit-animal',
  FIELD_SERVICES = 'field-services',
  FIELD_SERVICES_SETTINGS = 'field-services-settings',
  FOSTER = 'foster',
  FOSTER_SETTINGS = 'foster-settings',
  FOSTERS = 'fosters',
  FOSTERS_REPORT = 'fosters-report',
  HILLS_REPORT = 'hills-report',
  INTAKE_ANIMAL = 'intake-animal',
  INTAKES_REPORT = 'intakes-report',
  INTEGRATIONS = 'integrations',
  LICENSE = 'license',
  LOST_FOUND = 'lost_found',
  LOST_FOUND_2 = 'lost-found',
  LOST_FOUND_SETTINGS_1 = 'lost_found_settings',
  LOST_FOUND_SETTINGS_2 = 'lost-found-settings',
  MANAGE_FORMS = 'manage-forms',
  MANAGE_TAGS = 'manage-tags',
  MEDICAL_REPORTS = 'medical-reports',
  MEDICAL_SETTINGS = 'medical-settings',
  MEET_ME_REQUESTS = 'meet-me-requests',
  MICROCHIP_REPORT = 'microchip-report',
  OUTCOME_ANIMAL = 'outcome-animal',
  OUTCOME_FEE_REPORT = 'outcome-fee-report',
  OUTCOME_SETTINGS = 'outcome-settings',
  OUTCOMES_REPORT = 'outcomes-report',
  OWNER = 'owner',
  PARTNER_SETTINGS = 'partner-settings',
  PARTNERS = 'partners',
  PEOPLE = 'people',
  PEOPLE_SETTINGS = 'people-settings',
  PETCADEMY_REPORT = 'petcademy-report',
  REHOME = 'rehome',
  REHOME_SETTINGS = 'rehome-settings',
  REVERSE_OUTCOME_ANIMAL = 'reverse-outcome-animal',
  RFC_REPORT = 'rfc-report',
  ROLES = 'roles',
  RTO_PAYMENT_REPORT = 'rto-payment-report',
  RTO_REPORT = 'rto-report',
  SAC_REPORT_1 = 'SAC-report',
  SAC_REPORT_2 = 'sac-report',
  SHELTER_INFO = 'shelter-info',
  STATS = 'stats',
  SUPER_ADMIN = 'super-admin',
  TNVR = 'tnvr',
  TNVR_SETTINGS = 'tnvr-settings',
  TRANSPORT_SETTINGS = 'transport-settings',
  UNLIMITED_FORMS = 'unlimited_forms',
  USERS = 'users',
  VOLUNTEER = 'volunteer',
  VOLUNTEER_SETTINGS = 'volunteer-settings',
}

export { ROLES };
