export default {
  MALE: [
    'Ace',
    'Adam',
    'Ace',
    'Adam',
    'Alfonso',
    'Alger',
    'Allie',
    'Amos',
    'Anderson',
    'Angel',
    'Apollo',
    'Ash',
    'Ashe',
    'Aspen',
    'Austin',
    'Baby',
    'Bailey',
    'Bandit',
    'Barney',
    'Barnum',
    'Barry',
    'Batman',
    'Beaker',
    'Bear',
    'Bebop',
    'Beerus',
    'Belize',
    'Benson',
    'Beuferd',
    'Bew',
    'Birdie',
    'Blackie',
    'Blade',
    'Blake',
    'Blue',
    'Bobo',
    'Bogie',
    'Bojangles',
    'Bonkers',
    'Boots',
    'Bosco',
    'Boy',
    'Brewsky',
    'Brownie',
    'Buddy',
    'Bugsy',
    'Burly',
    'Butters',
    'Cade',
    'California',
    'Callie',
    'Calvin',
    'Cane',
    'Carbon',
    'Carter',
    'Casper',
    'Cass',
    'Chadwick',
    'Chaplin',
    'Chaps',
    'Charley',
    'Charlie',
    'Charr',
    'Chi',
    'Chip',
    'Churchhill',
    'Cigar',
    'Clark',
    'Clyde',
    'Coal',
    'Coco',
    'Cody',
    'Colby',
    'Colt',
    'Cooper',
    'Copper',
    'Cosmo',
    'Crash',
    'Dakota',
    'Damien',
    'Dash',
    'Deacon',
    'Dede',
    'Demon',
    'Deuce',
    'Dexter',
    'Diamond',
    'Diego',
    'Diesel',
    'Digit',
    'Dilbert',
    'Dipper',
    'Disney',
    'Dodge',
    'Domino',
    'Donald',
    'Donnie',
    'Douglas',
    'Drax',
    'Dude',
    'Duke',
    'Duster',
    'Edgar',
    'Elliott',
    'Ernest',
    'Feisty',
    'Felix',
    'Fendi',
    'Ferris',
    'Fin',
    'Finn',
    'Finnigan',
    'Flame',
    'Flanagan',
    'Flash',
    'Fluffy',
    'Ford',
    'Forrest',
    'Foster',
    'Fowler',
    'Frankie',
    'Frazier',
    'Freckle',
    'Frito',
    'Furbee',
    'Gabe',
    'Garfield',
    'Gary',
    'Gator',
    'Gatsby',
    'George',
    'Gideon',
    'Gilligan',
    'Gopher',
    'Graham',
    'Grayson',
    'Groucho',
    'Grover',
    'Guiness',
    'Gunner',
    'Gunther',
    'Hamilton',
    'Hancock',
    'Hank',
    'Hardy',
    'Harley',
    'Harry',
    'Harvard',
    'Harvey',
    'Hastings',
    'Hawk',
    'Hawkeye',
    'Hayes',
    'Hercules',
    'Hershey',
    'Hiccup',
    'Hitch',
    'Hodenie',
    'Honey',
    'Huey',
    'Hunter',
    'Hyde',
    'Hyper',
    'Iggy',
    'Igloo',
    'Indie',
    'Irving',
    'Jabrill',
    'Jack',
    'Jackpot',
    'Jacks',
    'Jackson',
    'Jacob',
    'Jade',
    'Jake',
    'James',
    'Jasper',
    'Jaws',
    'Jazz',
    'Jeff',
    'Jeffie',
    'Jeffrey',
    'Jekyll',
    'Jiggles',
    'Jiji',
    'Jimmy',
    'Jingles',
    'Jinx',
    'Jobe',
    'Joe',
    'Joey',
    'John',
    'Johnny',
    'Joker',
    'Jones',
    'Jonesy',
    'Justin',
    'Juvi',
    'Kano',
    'Keaton',
    'Kermit',
    'Kesler',
    'Khalil',
    'Kiefer',
    'Kiki',
    'Killer',
    'Kirk',
    'Kitten',
    'Knox',
    'Koko',
    'Kovu',
    'Kurt',
    'Lady',
    'Lambert',
    'Lance',
    'Larry',
    'Laurel',
    'League',
    'Legend',
    'Leland',
    'Leo',
    'Leroy',
    'Lestat',
    'Levi',
    'Liberty',
    'Lightning',
    'Lily',
    'Lincoln',
    'Logan',
    'Loki',
    'Lokii',
    'Lou',
    'Louie',
    'Luca',
    'Lucky',
    'Luke',
    'Major',
    'Mama',
    'Marbles',
    'Marc',
    'Marley',
    'Marlow',
    'Mars',
    'Marty',
    'Matt',
    'Max',
    'Maximus',
    'Mazda',
    'Meeko',
    'Mickey',
    'Midnight',
    'Midnite',
    'Milsap',
    'Mist',
    'Moe',
    'Mojo',
    'Mokie',
    'Moon',
    'Morgan',
    'Mufasa',
    'Mulkey',
    'Nacho',
    'Ned',
    'Nicky',
    'Niles',
    'Ninja',
    'Noodle',
    'Nox',
    'Obrien',
    'Oj',
    'Olaf',
    'Oliver',
    'Ollie',
    'Onyx',
    'Oreo',
    'Orion',
    'Orville',
    'Oscar',
    'Oso',
    'Oswald',
    'Ozzie',
    'Pan',
    'Paolo',
    'Patches',
    'Peaches',
    'Peilican',
    'Pell',
    'Pepe',
    'Pepper',
    'Pepperoni',
    'Pete',
    'Peter',
    'Pewee',
    'Pickles',
    'Pierre',
    'Pinky',
    'Podrick',
    'Poppy',
    'Pops',
    'Prada',
    'Prince',
    'Professor',
    'Pugsley',
    'Pumpkin',
    'Quest',
    'Quicksilver',
    'Quiji',
    'Rambo',
    'Ranger',
    'Rascal',
    'Reggie',
    'Rex',
    'Rikki',
    'Riley',
    'Ringo',
    'Rio',
    'Ripple',
    'Roamer',
    'Rob',
    'Robin',
    'Rocko',
    'Rocky',
    'Roman',
    'Romeo',
    'Rooster',
    'Roscoe',
    'Rowan',
    'Rowdy',
    'Rudy',
    'Rufus',
    'Rusty',
    'Salem',
    'Sally',
    'Sam',
    'Sampson',
    'Samson',
    'Sarge',
    'Shadow',
    'Sharkey',
    'Shrimpy',
    'Silas',
    'Simba',
    'Siro',
    'Skipper',
    'Skittles',
    'Skitty',
    'Skunk',
    'Sky',
    'Slate',
    'Sloane',
    'Smokey',
    'Snoopy',
    'Snowball',
    'Solo',
    'Spaz',
    'Spot',
    'Stache',
    'Stevie',
    'Stimpy',
    'Storm',
    'Stumpy',
    'Sven',
    'Sylvester',
    'Tate',
    'Taz',
    'Teeny',
    'Terry',
    'Thomas',
    'Thor',
    'Tiger',
    'Tigger',
    'Tigress',
    'Timothy',
    'Tina',
    'Tink',
    'Tinker',
    'Tinny',
    'Tiny',
    'Tippy',
    'Toby',
    'Tom',
    'Tommy',
    'Topaz',
    'Trevor',
    'Trouble',
    'Trump',
    'Turtle',
    'Tux',
    'Tyrion',
    'Vaedrex',
    'Victor',
    'Viktor',
    'Wally',
    'Wampus',
    'Willow',
    'Willy',
    'Wolfie',
    'Wonder',
    'Yeller',
    'Yuri',
    'Zeb',
    'Zed',
    'Ziggy',
    'Zues',
    'Zwlda',
  ],
  FEMALE: [
    'Abby',
    'Ada',
    'Addie',
    'Aida',
    'Alejandro',
    'Alex',
    'Ali',
    'Alice',
    'Amber',
    'Angel',
    'Annabella',
    'Annie',
    'Apple',
    'April',
    'Arabella',
    'Ariel',
    'Arpeggio',
    'Ash',
    'Ashes',
    'Ashley',
    'Athena',
    'Aulani',
    'Autumn',
    'Ava',
    'Baby',
    'Bailey',
    'Bali',
    'Bc',
    'Bean',
    'Bearbear',
    'Beauty',
    'Bee',
    'Beemer',
    'Belinda',
    'Bell',
    'Bella',
    'Belle',
    'Betty',
    'Bitty',
    'Blackie',
    'Blackly',
    'Blue',
    'Bonnie',
    'Boo',
    'Boots',
    'Bootsie',
    'Brassy',
    'Buddy',
    'Bug',
    'Bunkie',
    'Butter',
    'Buttercup',
    'Butterscotch',
    'Button',
    'Cali',
    'Calico',
    'Calie',
    'Callie',
    'Camo',
    'Candy',
    'Caramel',
    'Carla',
    'Carly',
    'Cassie',
    'Cece',
    'Chance',
    'Chantal',
    'Charlie',
    'Chaz',
    'Cheer',
    'Cheesecake',
    'Cheeto',
    'Chelsea',
    'Chelsey',
    'Cher',
    'Cheyenne',
    'Chigger',
    'China',
    'Chloe',
    'Christy',
    'Church',
    'Cici',
    'Cindy',
    'Circe',
    'Cleo',
    'Clip',
    'Clop',
    'Cloud',
    'Clover',
    'Coco',
    'Cody',
    'Cookie',
    'Cora',
    'Cortessa',
    'Cotton',
    'Courtney',
    'Cranberry',
    'Cupcake',
    'Curry',
    'Cutie',
    'Daisy',
    'Dani',
    'Dazzle',
    'Deborah',
    'Deja',
    'Delta',
    'Dena',
    'Diamond',
    'Dixie',
    'Dolly',
    'Doodle',
    'Dori',
    'Dory',
    'Duchess',
    'Dunya',
    'Dusty',
    'Dutchess',
    'Ebony',
    'Eleanor',
    'Ella',
    'Emie',
    'Emily',
    'Eris',
    'Esmerelda',
    'Ethel',
    'Faith',
    'Fancy',
    'Farrah',
    'Feisty',
    'Feral',
    'Fiesty',
    'Fiona',
    'Flannery',
    'Flash',
    'Flora',
    'Fluff',
    'Fluffy',
    'Flurry',
    'Fozzie',
    'Frankie',
    'Freeway',
    'Furball',
    'Gabby',
    'Georgette',
    'Gizmo',
    'Gonzo',
    'Grace',
    'Gracie',
    'Gray',
    'Grayson',
    'Gremlin',
    'Greta',
    'Gretal',
    'Gretchen',
    'Gretel',
    'Gypsy',
    'Haiti',
    'Happy',
    'Harleigh',
    'Harley',
    'Hazel',
    'Heidi',
    'Helena',
    'Hollis',
    'Holly',
    'Honey',
    'Hope',
    'Illana',
    'Inca',
    'Indy',
    'Irene',
    'Iris',
    'Isabel',
    'Island',
    'Itsy',
    'Ivory',
    'Ivy',
    'Izzy',
    'Jackie',
    'Jada',
    'Jade',
    'Jasmine',
    'Java',
    'Jazzy',
    'Jecky',
    'Jemima',
    'Jenny',
    'Jessie',
    'Jet',
    'Jillian',
    'Jingle',
    'Jordan',
    'Josie',
    'Journey',
    'Jubliee',
    'Julie',
    'June',
    'Junebug',
    'Jynx',
    'Kala',
    'Kali',
    'Kangaroo',
    'Karen',
    'Karma',
    'Katie',
    'Kay',
    'Keeta',
    'Keiko',
    'Kelly',
    'Kelyn',
    'Kendall',
    'Khalia',
    'Kiki',
    'Kim',
    'Kitten',
    'Kitty',
    'Kylie',
    'Lacie',
    'Lacy',
    'Lady',
    'Layla',
    'Leah',
    'Lego',
    'Lexi',
    'Lexus',
    'Libby',
    'Lilith',
    'Lilly',
    'Lillyanne',
    'Lily',
    'Linda',
    'Lindsey',
    'Lipstick',
    'Liv',
    'Lj',
    'Llama',
    'Lola',
    'Lolly',
    'Loudon',
    'Louise',
    'Luci',
    'Lucille',
    'Lucky',
    'Lucy',
    'Lulu',
    'Luna',
    'Lynn',
    'Mable',
    'Madigan',
    'Madison',
    'Magic',
    'Magnolia',
    'Magpie',
    'Mako',
    'Malibu',
    'Mallory',
    'Mama',
    'Maple',
    'Maraline',
    'Marble',
    'Marie',
    'Marla',
    'Marley',
    'Mary',
    'Matty',
    'Mavis',
    'Maya',
    'Mayble',
    'Meanie',
    'Melody',
    'Mercedes',
    'Merelo',
    'Mia',
    'Michelle',
    'Midget',
    'Midnight',
    'Mila',
    'Milkshake',
    'Millie',
    'Milo',
    'Mima',
    'Miney',
    'Mini',
    'Minnie',
    'Missy',
    'Misty',
    'Mittens',
    'Moana',
    'Moe',
    'Molly',
    'Momma',
    'Mommy',
    'Monet',
    'Moomoo',
    'Morgan',
    'Motor',
    'Moxie',
    'Muru',
    'Natasha',
    'Nattie',
    'Neevah',
    'Nellie',
    'Nesi',
    'Nibblet',
    'Nica',
    'Nikki',
    'Nix',
    'Noel',
    'Nyko',
    'Nym',
    'Omara',
    'Onsie',
    'Onyx',
    'Opie',
    'Orange',
    'Oreo',
    'Orphan',
    'Padma',
    'Paisley',
    'Pancake',
    'Panda',
    'Panther',
    'Patsy',
    'Paws',
    'Peaches',
    'Pearl',
    'Pebbles',
    'Peewee',
    'Penelope',
    'Pepper',
    'Petunia',
    'Peyton',
    'Phantom',
    'Phoebe',
    'Pickles',
    'Piglet',
    'Pillow',
    'Piper',
    'Pippi',
    'Poca',
    'Porkchop',
    'Precious',
    'Princess',
    'Puddin',
    'Puff',
    'Puffy',
    'Puppy',
    'Queenie',
    'Quincey',
    'Quinn',
    'Quintessa',
    'Quiz',
    'Ra',
    'Rachael',
    'Ralphne',
    'Rama',
    'Reagan',
    'Red',
    'Remi',
    'Remmington',
    'Repo',
    'Riley',
    'River',
    'Roadie',
    'Rose',
    'Rosie',
    'Rousey',
    'Roxy',
    'Ruby',
    'Ruffles',
    'Runt',
    'Sabrina',
    'Sadie',
    'Sahara',
    'Salem',
    'Salina',
    'Sally',
    'Samantha',
    'Saphira',
    'Sara',
    'Sarah',
    'Sasha',
    'Sassafrass',
    'Sassy',
    'Satin',
    'Scarlet',
    'Scarlett',
    'Selena',
    'Shadow',
    'Shakira',
    'Shasta',
    'Shelley',
    'Sirus',
    'Sissy',
    'Skye',
    'Skytree',
    'Slim',
    'Smidgeon',
    'Smokey',
    'Smokie',
    'Smoky',
    'Smudge',
    'Snickers',
    'Snoopy',
    'Snow',
    'Snowball',
    'Snowflake',
    'Snowy',
    'Snuggles',
    'Socks',
    'Sofie',
    'Sola',
    'Sophia',
    'Sophie',
    'Sox',
    'Spanky',
    'Speckles',
    'Squirrel',
    'Squirt',
    'Star',
    'Stella',
    'Stormy',
    'Strudel',
    'Stubby',
    'Sugar',
    'Summer',
    'Sunny',
    'Susan',
    'Suzy',
    'Sweetie',
    'Sydney',
    'Sylvia',
    'Tabby',
    'Tammy',
    'Tara',
    'Tawny',
    'Teagan',
    'Thelma',
    'Thomasina',
    'Thunder',
    'Tiger',
    'Tilley',
    'Tilly',
    'Tizzy',
    'Toby',
    'Tora',
    'Tortie',
    'Trixie',
    'Tsubaki',
    'Tula',
    'Tuppence',
    'Turbo',
    'Uno',
    'Vada',
    'Vanjie',
    'Venus',
    'Via',
    'Waffle',
    'Wanda',
    'Wern',
    'Wesa',
    'Whitney',
    'Willow',
    'Wonder',
    'Xena',
    'Yellowcat',
    'Yoruichi',
    'Zabel',
    'Zarrie',
    'Zeta',
    'Ziggy',
    'Zoe',
    'Zoey',
  ],
};
