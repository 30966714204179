import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';

const i18n = createI18n({
  fallbackLocale: 'en',
  fallbackWarn: false,
  legacy: false,
  locale: navigator.language || 'en',
  messages,
  missingWarn: false,
});

export default i18n;
