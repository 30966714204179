import type { RouteRecordRaw } from 'vue-router';
import { ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'info',
    name: 'info',
    component: () => import('./views/ShelterInfo.view.vue'),
    meta: {
      permissions: [ROLES.SHELTER_INFO],
    },
  },
  {
    path: 'onboarding',
    name: 'onboarding',
    component: () => import('./views/ShelterOnboarding.view.vue'),
    meta: {
      permissions: [ROLES.SHELTER_INFO],
    },
  },
];
