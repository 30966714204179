<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import type { PaymentType } from '../../../types';
import { useAToast } from '@Atoms';
import { useSessionStore } from '@Stores';
import useCartStore from '../../../store';
import { CART_STEPS, PAYMENT_METHODS } from '../../../types';
import {
  CompleteOutcome,
  PostCitationPaymentService,
  ProcessStripePaymentService,
} from '../../../services';
import { PAYMENT_OPTIONS } from '../../../constants';
import { useCartSteps } from '../../../composables';
import { UpdatePaymentTypeService } from '../../../services';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const {
  animals,
  cartData,
  completeOutcomeRoute,
  cardFieldError,
  cardHolderName,
  isCitation,
  isProduct,
  loading,
  type,
} = storeToRefs(useCartStore());
const { isCardReaderLastFourInvalid, outcomeCompletedCartReset } = useCartStore();
const { shelterProfile } = useSessionStore();

const { currentStep, previousStep } = useCartSteps();
const { notify, TOAST_TYPES } = useAToast();
const { push } = useRouter();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const creditCardModel = ref();
const showErrorMessage = ref('');
const cardReaderLastFour = ref();
const paymentMethod = ref<PaymentType | null>(null);

const isCardReader = computed(() => paymentMethod.value === PAYMENT_METHODS.CARD_READER);
const isCreditCard = computed(() => paymentMethod.value === PAYMENT_METHODS.CREDIT);
const disableSubmitButton = computed(
  () =>
    !!showErrorMessage.value ||
    (!creditCardModel?.value?.value && !creditCardModel?.value?.token && isCreditCard.value) ||
    (!cardHolderName.value && isCreditCard.value) ||
    (cardFieldError.value && isCreditCard.value) ||
    (!cardReaderLastFour.value && isCardReader.value) ||
    !paymentMethod.value
);
const cartItemType = computed(() =>
  //prettier-ignore
  isCitation.value ? 'citation' :
  isProduct.value ? 'product' :
  undefined
);
const options = computed(() =>
  PAYMENT_OPTIONS.map((option) => {
    const disabledForEmptyTotal =
      option.value === PAYMENT_METHODS.CREDIT && !parseFloat(cartData.value?.total);
    const disableCreditCard = option.value === PAYMENT_METHODS.CREDIT && !shelterProfile.stripe;

    const message = disableCreditCard
      ? 'shelterInfo.settings.stripe.notIntegrated'
      : 'cart.title.noCreditCardPaymentAvailable';

    return {
      ...option,
      disabled: disabledForEmptyTotal || disableCreditCard,
      disabledMessage: message,
    };
  })
);

onMounted(() => (currentStep.value = CART_STEPS.PAYMENT_METHOD));

watch(paymentMethod, () => updatePaymentType());

// SERVICES
function updatePaymentType() {
  if (!paymentMethod.value) return;

  showErrorMessage.value = '';

  const PAYLOAD = {
    payment_type: paymentMethod.value,
  };

  UpdatePaymentTypeService(PAYLOAD)
    .then(() => useCartStore().fetchActiveCart())
    .catch(() => notify(TOAST_ERROR));
}

// EVENTS
function onNextStep() {
  if (!paymentMethod.value) return (showErrorMessage.value = 'cart.paymentMethod.error');

  const notValidCardReadLastFour = isCardReaderLastFourInvalid(
    cardReaderLastFour.value,
    paymentMethod.value
  );
  if (notValidCardReadLastFour) return (showErrorMessage.value = 'cart.paymentMethod.lastFour');

  loading.value = true;

  const animalsBaseRtoSanitezed = animals.value.map((animal: any) => {
    const needsAdjustments = animal.base_rto_fee && typeof animal.base_rto_fee === 'object';

    if (!needsAdjustments) return animal;

    return {
      ...animal,
      base_rto_fee: animal.base_rto_fee.amount.toString() || 0,
      outcome: {
        ...animal.outcome,
        fee: animal.base_rto_fee.amount.toString(),
      },
    };
  });

  const payload = {
    ...cartData.value,
    animals: animalsBaseRtoSanitezed,
    payment_type: paymentMethod.value,
    token: isCreditCard.value ? creditCardModel.value?.token : undefined,
    name_on_card: isCreditCard.value ? creditCardModel.value?.name : undefined,
    last_four_digits: isCardReader.value ? cardReaderLastFour.value : undefined,
  };

  // prettier-ignore
  switch(true){
    case(isCitation.value): return onCitationPayment(payload)
    case(isCreditCard.value): return onCreditPayment(payload)
    default: return onCompleteOutcome(payload)
}
}

function onCitationPayment(payload: any) {
  PostCitationPaymentService(payload)
    .then(() => push(completeOutcomeRoute.value).then(() => outcomeCompletedCartReset()))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onCreditPayment(payload: any) {
  ProcessStripePaymentService(payload)
    .then(() => push(completeOutcomeRoute.value).then(() => outcomeCompletedCartReset()))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onCompleteOutcome(payload: any) {
  CompleteOutcome(payload)
    .then(() => push(completeOutcomeRoute.value).then(() => outcomeCompletedCartReset()))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex h-fit flex-col gap-4">
    <main class="flex-1">
      <header class="flex flex-wrap items-center gap-4">
        <AFieldCheckboxButton
          v-model="paymentMethod"
          class="text-nowrap"
          :options
        />
      </header>

      <div v-if="paymentMethod">
        <Divider
          class="before:border-t-2"
          type="dashed"
        />

        <div class="flex max-w-[500px] flex-col justify-between gap-8">
          <ACartItemCost
            v-if="cartData"
            totalOnly
            :cartData
            :cartItemType
            :item="cartData"
          />

          <div v-if="paymentMethod === PAYMENT_METHODS.CREDIT">
            <MCartStripeCard v-model="creditCardModel" />
          </div>

          <div v-if="paymentMethod === PAYMENT_METHODS.CARD_READER">
            <MCartCardReader
              v-model="cardReaderLastFour"
              :error="showErrorMessage"
            />
          </div>
        </div>
      </div>
    </main>

    <footer class="flex-shrink">
      <Message
        v-if="showErrorMessage"
        pt:text="text-sm"
        severity="error"
      >
        {{ $t(showErrorMessage) }}
      </Message>

      <div class="flex justify-between">
        <Button
          outlined
          class="af-button af-button-success-outlined w-fit"
          icon="pi pi-arrow-left"
          :loading
          :label="$t('common.back')"
          @click="emits('onStepUpdate', previousStep)"
        />

        <Button
          class="af-button af-button-success ml-auto"
          iconPos="right"
          :loading
          :disabled="disableSubmitButton"
          :label="$t('cart.label.submitAndComplete')"
          @click="onNextStep"
        >
          <template #icon>
            <FaIcon
              class="mr-2"
              :icon="$icons.faPaw"
            />
          </template>
        </Button>
      </div>
    </footer>
  </div>
</template>
