<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '../../../types';

const props = withDefaults(
  defineProps<{
    error?: string;
    feedback?: boolean;
    field?: FieldSchema;
    placeholder?: string;
    required?: boolean;
    validationRules?: string[];
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const model = defineModel<string>();

const settings = computed(() => props?.field?.settings);

function unfreezeInput(event: any) {
  // NOTE: When logging out the input can get frozen by Chrome's auto fill feature.
  // This is a Chrome issue. When reassigning the input value to itself the field works again.
  // eslint-disable-next-line no-self-assign
  event.target.value = event.target.value;
}
</script>

<template>
  <Password
    v-model="model"
    toggleMask
    class="w-full"
    pt:showicon="cursor-pointer"
    pt:hideicon="cursor-pointer"
    :feedback="settings?.feedback || feedback"
    :invalid="!!error"
    :mediumLabel="$t('components.formFields.password.requirements.mediumLabel')"
    :placeholder="$t(settings?.placeholder || placeholder)"
    :promptLabel="$t('components.formFields.password.requirements.promptLabel')"
    :pt:input:root="['h-9 w-full text-sm', { 'af-field-error': !!error }]"
    :required="settings?.required || required"
    :strongLabel="$t('components.formFields.password.requirements.strongLabel')"
    :weakLabel="$t('components.formFields.password.requirements.weakLabel')"
    @click="unfreezeInput($event)"
  >
    <template #footer>
      <Divider />

      <p class="mt-2">{{ $t('components.formFields.password.requirements.title') }}:</p>

      <ul class="ml-2 mt-0 pl-2">
        <li
          v-for="rule in settings?.validationRules || validationRules"
          :key="rule"
        >
          - {{ $t(rule) }}
        </li>
      </ul>
    </template>
  </Password>
</template>
