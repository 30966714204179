import { ref, watch } from 'vue';
import { type Toast, TOAST_TYPES } from './types';

let pauseStart = 0;
let startTime = 0;
let remainingTime = 0;

const INITIAL_STATE = (): Toast => ({
  hasDimissButton: false,
  message: undefined,
  isOpen: false,
  title: '',
  type: TOAST_TYPES.SUCCESS,
});

const state = ref<Toast>(INITIAL_STATE());
const activeTimeout = ref();
const isOnHover = ref(false);

export default function useAToast() {
  const TIME_TO_DISMISS = 5000;

  watch(isOnHover, (state) => (state ? _pauseTimer() : _resumeTimer()));

  function _setDismissTimer(timeToDismiss: number) {
    startTime = Date.now();
    remainingTime = timeToDismiss;
    activeTimeout.value = setTimeout(() => {
      reset();
    }, timeToDismiss);
  }

  function _pauseTimer() {
    if (!activeTimeout.value) return;

    clearTimeout(activeTimeout.value);
    pauseStart = Date.now();
    remainingTime -= pauseStart - startTime;
    activeTimeout.value = null;

    startTime = pauseStart;
  }

  function _resumeTimer() {
    if (activeTimeout.value || pauseStart === 0) return;
    if (!remainingTime) return reset();

    activeTimeout.value = setTimeout(() => {
      reset();
    }, remainingTime);
  }

  function notify(toastSettings: Toast, timeToDismiss = TIME_TO_DISMISS): true {
    reset();

    setTimeout(() => {
      state.value = {
        ...toastSettings,
        isOpen: true,
      };
      
      if (timeToDismiss) _setDismissTimer(timeToDismiss);
    }, 200);

    return true;
  }

  function reset() {
    if (activeTimeout.value) clearTimeout(activeTimeout.value);

    pauseStart = 0;
    remainingTime = 0;
    startTime = 0;
    activeTimeout.value = null;
    state.value = INITIAL_STATE();
  }

  return {
    state,
    isOnHover,

    notify,
    reset,

    TOAST_TYPES,
  };
}
