import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: 'animal/outcome/',
    name: 'animal-outcome',
    component: () => import('./views/CartView.vue'),
  },
  {
    path: 'animal/outcome/:id',
    name: 'animal-outcome-with-id',
    component: () => import('./views/CartView.vue'),
  },
  {
    path: 'animal/outcome-pending/:id',
    name: 'animal-outcome-pending',
    component: () => import('./views/CartCheckoutPendingView.vue'),
  },
  {
    path: 'animal/outcome-completed/:id',
    name: 'animal-outcome-completed',
    component: () => import('./views/CartCompletedOutcomeView.vue'),
  },
  {
    path: 'animal/intake-payemnt/',
    name: 'animal-intake-payment',
    component: () => import('./views/CartView.vue'),
  },
  {
    path: 'animal/intake-payment/:id',
    name: 'animal-intake-with-id',
    component: () => import('./views/CartView.vue'),
  },
  {
    path: 'animal/intake-payment-pending/:id',
    name: 'animal-intake-payment-pending',
    component: () => import('./views/CartCheckoutPendingView.vue'),
  },
  {
    path: 'animal/intake-payment-completed/:id',
    name: 'animal-intake-payment-completed',
    component: () => import('./views/CartCompletedOutcomeView.vue'),
  },
];
