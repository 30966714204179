<script setup lang="ts">
import { useTemplateRef, ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';

defineProps<{
  field: any;
}>();

const extraInfoPanel = useTemplateRef<InstanceType<typeof OverlayPanel> | null>('extraInfoPanel');
const isHovering = ref(false);

function showPanel(event: any) {
  isHovering.value = true;
  extraInfoPanel.value?.show(event);
}

function hidePanel() {
  setTimeout(() => (!isHovering.value ? extraInfoPanel.value?.hide() : ''), 100);
}
</script>

<template>
  <div
    v-if="field"
    class="flex cursor-context-menu items-center justify-between gap-2 overflow-hidden truncate text-ellipsis whitespace-nowrap"
    @mouseenter="showPanel"
    @mouseleave="
      isHovering = false;
      hidePanel();
    "
  >
    <p
      class="!h-5 w-max max-w-[200px] overflow-hidden truncate text-ellipsis whitespace-pre-line text-sm"
      v-html="field"
    ></p>

    <i class="pi pi-eye text-sm text-slate-500"></i>

    <OverlayPanel
      ref="extraInfoPanel"
      @mouseenter="isHovering = true"
      @mouseleave="
        isHovering = false;
        hidePanel();
      "
    >
      <div class="flex gap-2 border-t py-1 text-sm first:border-t-0 first:pt-0 last:pb-0">
        <p
          class="max-h-[450px] max-w-[300px] overflow-auto whitespace-pre-line text-sm"
          v-html="field"
        ></p>
      </div>
    </OverlayPanel>
  </div>

  <p v-else>-</p>
</template>
