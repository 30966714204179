import { ref } from 'vue';
import { SYSTEM_ASIDES } from './constants';

const activeAside = ref('');
const activeAsideProps = ref();
const asideVisibility = ref(false);
const currentRowId = ref();
const styles = ref(['w-full sm:w-[450px] lg:w-[600px] 2xl:w-[900px]']);

export default function () {
  function isCurrentAsideOpen(targetModal: SYSTEM_ASIDES) {
    return targetModal === activeAside.value;
  }

  function reset() {
    activeAside.value = '';
    activeAsideProps.value = '';
    asideVisibility.value = false;
    styles.value = ['w-full md:w-[500px] lg:w-[600px] xl:w-[700px]'];
    currentRowId.value = undefined;
  }

  return {
    activeAside,
    activeAsideProps,
    asideVisibility,
    currentRowId,
    styles,

    SYSTEM_ASIDES,

    isCurrentAsideOpen,
    reset,
  };
}
