<script setup lang="ts">
// TODO :: Review this entire component logics and validations
import { computed, ref } from 'vue';
import {
  dateRelation,
  dateStringToUtc,
  formatDateToSimpleString,
  formatMoney,
  formatNumberToDate,
  formatPhoneNumber,
  getDateFromOId,
  getDefaultDateFormat,
} from '@Helpers';
import { FORMAT_COMPONENTS } from '@DataTableModule';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const {
  BIRTHDAY,
  DATE_FROM_OID,
  DATE_TIME_TO_DATE,
  DATE_STRING_WITH_TIME,
  DATE_TO_ISO_STRING,
  DAYS_TO_FIXED,
  EXTRA_INFO,
  MONEY,
  NUMBER_TO_BIRTHDAY,
  NUMBER_TO_DATE,
  PHONE,
  OBJECT_MODEL,
} = FORMAT_COMPONENTS;

const extraInfoPanel = ref();

const type = computed(() => props.settings?.render?.type);
const isNegativeValue = computed(() => (sanitizedValue.value < 0 ? 'text-red-500' : ''));
const isProductsInfo = computed(() => props.settings.type === 'products');
const isServicesInfo = computed(() => props.settings.type === 'services');
const hasDolar = computed(() => (props.settings.type === 'money' ? '$' : ''));
const extraInfos = computed(() =>
  // prettier-ignore
  isServicesInfo.value ? props.rowData.services :
  isProductsInfo.value ? props.rowData.products :
  props.rowData.additional_fees
);
const checkForInfoLength = computed(() =>
  // prettier-ignore
  isProductsInfo.value ? props.rowData.products.length : 
  isServicesInfo.value ? props.rowData.services.length : 
  props.rowData.additional_fees.length
);

const sanitizedValue = computed(() => {
  return props.settings.render.targetKey &&
    props.settings.render.targetKey.length > 0 &&
    props.field[props.settings.render.targetKey[0]] !== undefined
    ? props.field[props.settings.render.targetKey[0]]
    : props.field;
});

const formattedMoney = computed(() =>
  sanitizedValue.value < 0
    ? `(${formatMoney(Math.abs(sanitizedValue.value))})`
    : formatMoney(sanitizedValue.value)
);

const isDateFromId = computed(() => {
  const notValidType = type.value !== DATE_FROM_OID;
  if (notValidType) return false;

  const hasOId = props.settings.render.targetKey.includes('$oid');
  return hasOId;
});

function toggle(event: any) {
  extraInfoPanel.value.toggle(event);
}
</script>

<template>
  <p v-if="type === DAYS_TO_FIXED">{{ field?.toFixed(2) }} {{ $t('common.day', field) }}</p>

  <p v-else-if="type === NUMBER_TO_DATE && field">
    {{
      settings?.render?.targetKey?.[1]
        ? getDefaultDateFormat(
            field?.[settings.render.targetKey?.[0]]?.[settings.render.targetKey?.[1]] || ''
          )
        : ''
    }}
  </p>

  <div
    v-else-if="type === NUMBER_TO_BIRTHDAY && field"
    class="flex flex-col"
  >
    <span>
      {{ formatNumberToDate(field[settings.render.targetKey[0]][settings.render.targetKey[1]]) }}
    </span>

    <span>
      {{
        dateRelation(
          formatNumberToDate(
            field[settings.render.targetKey[0]][settings.render.targetKey[1]] || '-'
          ) || '-'
        )
      }}
    </span>
  </div>

  <p v-else-if="type === PHONE">{{ !field?.length ? '-' : formatPhoneNumber(field) }}</p>

  <p v-else-if="type === OBJECT_MODEL">{{ field?.value || field || '-' }}</p>

  <div
    v-else-if="type === EXTRA_INFO"
    class="flex items-center justify-between gap-3"
  >
    <p :class="isNegativeValue">
      {{
        field.length > 1
          ? `${hasDolar + field[0]}, ${hasDolar + field[1]} ...`
          : hasDolar + field[0]
      }}
    </p>

    <Button
      v-if="field.length > 1"
      outlined
      class="w-9 border-none"
      icon="pi pi-info-circle"
      severity="secondary"
      @click="toggle"
    />

    <OverlayPanel ref="extraInfoPanel">
      <div
        v-for="(item, index) in field"
        class="flex gap-2 border-t py-1 text-sm first:border-t-0 first:pt-0 last:pb-0"
        :key="index"
      >
        <p
          v-if="settings.type === 'quantity'"
          class="font-medium"
        >
          {{ '(' + field[index] + ') ' + rowData.sku[index] }}
        </p>

        <p
          v-else-if="settings.type === 'money'"
          class="font-medium"
        >
          {{ '(' + rowData.quantity[index] + ') ' + rowData.sku[index] + ' $' + field[index] }}
        </p>

        <p
          v-else
          class="font-medium"
        >
          {{ '(' + rowData.quantity[index] + ') ' + item }}
        </p>
      </div>
    </OverlayPanel>
  </div>

  <div
    v-else-if="type === MONEY && field"
    class="flex items-center justify-between gap-3"
  >
    <p :class="isNegativeValue">${{ formattedMoney }}</p>

    <Button
      v-if="settings.extraInfo && checkForInfoLength"
      outlined
      class="w-9 border-none"
      icon="pi pi-info-circle"
      severity="secondary"
      @click="toggle"
    />

    <OverlayPanel ref="extraInfoPanel">
      <div
        v-for="({ amount, description, price, product, quantity }, index) in extraInfos"
        class="flex gap-2 border-t py-1 text-sm first:border-t-0 first:pt-0 last:pb-0"
        :key="index"
      >
        <p class="font-medium">
          {{ isProductsInfo || isServicesInfo ? `(${quantity}) ` + product : description }}:
        </p>

        <p>${{ isProductsInfo || isServicesInfo ? price : formatMoney(amount.$numberDecimal) }}</p>
      </div>
    </OverlayPanel>
  </div>

  <div v-else-if="type === DATE_TO_ISO_STRING && field">{{ getDefaultDateFormat(field) }}</div>

  <div
    v-else-if="type === BIRTHDAY"
    class="flex flex-col"
  >
    <span>{{ field }}</span>

    <span>{{ field ? dateRelation(field) : '-' }}</span>
  </div>

  <p v-else-if="isDateFromId">{{ getDateFromOId(rowData._id.$oid) }}</p>

  <p v-else-if="type === DATE_STRING_WITH_TIME">
    {{ getDefaultDateFormat(dateStringToUtc(field), true) }}
  </p>

  <p v-else-if="type === DATE_TIME_TO_DATE">{{ formatDateToSimpleString(field) }}</p>

  <span
    v-else-if="type === MONEY && !field"
    class="flex flex-col items-start gap-0.5"
  >
    $ 0.00
  </span>

  <span
    v-else
    class="flex flex-col items-start gap-0.5"
  >
    -
  </span>
</template>
