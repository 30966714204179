<script setup lang="ts">
import { usePrimeVue } from 'primevue/config';

defineProps<{
  files: File[];
}>();

const emits = defineEmits<{
  onRemoveFile: [number];
}>();

const $primevue = usePrimeVue();

function formatSize(bytes: any) {
  const k = 1024;
  const dm = 3;
  const sizes = $primevue?.config?.locale?.fileSizeTypes;

  if (!sizes) return;

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
}
</script>

<template>
  <div class="grid grid-cols-2 items-center justify-center gap-1 p-0 sm:grid-cols-3 sm:gap-5">
    <div
      v-for="({ name, type, size }, index) of files"
      :key="name + type + size"
      class="relative m-0 flex max-h-14 flex-row items-center gap-2 rounded-lg border border-solid p-2 text-center"
    >
      <div
        class="h-8 w-12 overflow-hidden rounded bg-cover bg-center"
        :style="{ 'background-image': 'url(' + (files[index] as any).objectURL + ')' }"
      ></div>

      <div class="flex w-full flex-col gap-1 overflow-hidden text-ellipsis text-nowrap text-start">
        <span
          v-tooltip.top="name"
          class="overflow-hidden text-ellipsis text-xs font-semibold"
        >
          {{ name }}
        </span>

        <span class="text-[10px]">{{ formatSize(size) }}</span>
      </div>

      <Button
        class="pi pi-trash absolute -right-2.5 bottom-0.5 border-none bg-transparent text-xs text-red-600"
        rounded
        severity="danger"
        @click="emits('onRemoveFile', index)"
      />
    </div>
  </div>
</template>
