import { computed, ref, watch } from 'vue';
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { watchDeep } from '@vueuse/core';
import type { AdditionalFee, RtoFee } from '../../../../types';
import type { Field, FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import type { Moment } from 'moment';
import { useAToast } from '@Atoms';
import { useForm } from '@FormModule';
import {
  GetCartSessionService,
  UpdateAdditionalFeesService,
  UpdateRtoFeeService,
} from '../../../../services';
import useCartStore from '../../../../store';
import useFieldsOutcomeDate from './useFieldsOutcomeDate';

export default function () {
  const {
    animals,
    cartData,
    hasCart,
    isAdoption,
    isReturnToOwner,
    outcomeInfoActiveStep,
    rtoFees,
    selectedRtoFee,
  } = storeToRefs(useCartStore());

  const { fieldsSchema: outcomeDateFieldSchema } = useFieldsOutcomeDate();

  const { notify, TOAST_TYPES } = useAToast();
  const { getDetailsSchema } = useForm();

  const NOT_ALLOWED_FIELD = ['outcome_type'];
  const TOAST_ERROR = {
    message: 'cart.toast.outcomeInfoUpdate.error.message',
    title: 'cart.toast.outcomeInfoUpdate.error.title',
    type: TOAST_TYPES.ERROR,
  };

  const fieldsSettings: FieldInitialSettings = {
    additional_fees: {
      model: [],
      edit: {
        label: 'label',
        options: [],
      },
      settings: {
        maxSelectedLabels: 1,
      },
    },
    waivers: {
      model: [],
      edit: {
        label: 'title',
        options: [],
      },
      settings: {
        maxSelectedLabels: 1,
      },
    },
    file: {
      settings: {
        imageOnly: false,
      },
    },
    partner_name: {
      edit: {
        label: 'name',
        value: 'id',
        options: [],
      },
    },
    rto_fee_type: {
      edit: {
        label: 'label',
        value: 'value',
        options: [
          {
            label: 'Daily',
            value: 'daily',
          },
          {
            label: 'Flat',
            value: 'flat',
          },
        ],
      },
    },
    rto_fees: {
      model: '',
      edit: {
        label: 'label',
        options: [''],
      },
      settings: {
        placeholder: 'Select a Fee Type first',
      },
      state: {
        disabled: true,
        errors: new Set(),
      },
    },
  };

  const currentDataOnStepperId = ref('');
  const currentSchemaModels = ref<Set<GenericField>>(new Set());
  const fieldsSchema = ref<FieldSchema[]>([]);
  const formError = ref();
  const rawFields = ref<RawField[]>([]);
  const sourceData = ref<any | null>(null);
  const additionalFeesOptions = ref();
  const waivers = ref([]);

  const formData = computed(() =>
    animals.value.find((data: any) => data._id === currentDataOnStepperId.value)
  );
  const additionalFeesField = computed(
    () => fieldsSchema.value.find(({ name }) => name === 'additional_fees')?.model
  );
  const rtoFeeTypeField = computed(
    () => fieldsSchema.value.find(({ name }) => name === 'rto_fee_type')?.model
  );
  const rtoFeeField = computed(
    () => fieldsSchema.value.find(({ name }) => name === 'rto_fees')?.model
  );
  const outcomeDateModel = computed(
    () => outcomeDateFieldSchema.value.find(({ name }) => name === 'outcome_date')?.model
  );

  watch(formData, () => setAdditionalFeeField(additionalFeesOptions.value));
  watch(currentDataOnStepperId, () => onStepUpdate());
  watch(outcomeDateModel, () => onOutcomeDateUpdate());
  watch(rtoFeeTypeField, () => onRtoFeeTypeUpdate());
  watch(rtoFeeField, () => [onRtoFeesUpdate(), onOutcomeDateUpdate()]);
  watchDeep(additionalFeesField, (_, oldFees) => onAdditionalFeesUpdate(oldFees));

  // SETS
  function setForm() {
    if (!sourceData.value) return;

    rawFields.value = getRawFields(sourceData.value.rawFields);
    setFieldsSchema();
  }

  function setFieldsSchema() {
    fieldsSchema.value = getUpdatedSchemaModel();
  }

  function setWaverField() {
    const waverFields = [
      {
        label: 'Outcome Waivers',
        name: 'waivers',
        required: false,
        type: 'multiselect',
        value: '',
      },
    ];

    fieldsSettings.waivers.edit!.options = waivers?.value;

    rawFields.value.unshift(...waverFields);
  }

  function setAdditionalFeeField(options?: AdditionalFee[]) {
    if (!options) return;

    const optionsPerSpecies = options
      .filter((option) => option.species === formData.value.species)
      .sort((a, b) => a.description.localeCompare(b.description));
    if (!optionsPerSpecies.length) return;

    additionalFeesOptions.value = options;

    rawFields.value.push({
      label: 'Additional Fee(s)',
      name: 'additional_fees',
      required: false,
      type: 'multiselect',
      value: [],
    });

    const optionsWithCustomLabel = optionsPerSpecies.map((fee: AdditionalFee) => {
      return {
        ...fee,
        label: `${fee.description} - $${fee.amount}`,
      };
    });

    fieldsSettings.additional_fees.edit!.options = optionsWithCustomLabel;
  }

  function setRtoFeesField() {
    const rtoFields = [
      {
        label: 'RTO Fee Type',
        name: 'rto_fee_type',
        required: true,
        type: 'select',
        value: '',
      },
      {
        label: 'RTO Fee(s)',
        name: 'rto_fees',
        required: true,
        disabled: true,
        type: 'select',
        value: '',
      },
    ];

    rawFields.value.unshift(...rtoFields);
  }

  // GETTERS
  function getUpdatedSchemaModel() {
    const fieldSchema = getDetailsSchema(rawFields.value, fieldsSettings, formData.value);

    fieldSchema.forEach((field) => {
      //prettier-ignore
      switch (field.name) {
        case 'additional_fees': return setAdditionalFeeField(field);
        case 'waivers': return setWaiversField(field);
        case 'file': return setFileField(field);
        default: setMatch(field)
      }
    });

    function setAdditionalFeeField(field: Field) {
      const selectedFees = formData.value?.additional_fees;
      const feesOptions = sourceData.value?.additional_fees_options;
      const missingParameters =
        !selectedFees || !selectedFees.length || !feesOptions || !feesOptions.length;
      if (missingParameters) return;

      const additionalFees = feesOptions
        .filter((fee: AdditionalFee) =>
          selectedFees.some((selectedFee: any) => selectedFee._id === fee._id)
        )
        .map((fee: AdditionalFee) => {
          return {
            ...fee,
            label: `${fee.description} - $${fee.amount}`,
          };
        });

      field.model = additionalFees;
      field.value = additionalFees;
    }

    function setFileField(field: Field) {
      // TODO - AF-376 - We need to finish this implementation
      if (!formData.value?.outcome) return;

      // const FILE = sourceData.outcome.files.map((file: any) => {
      //   return new File([new Blob()], file.filename, { type: 'image/jpeg' });
      // });

      // field.model = FILE;
      // field.value = FILE;
    }

    function setWaiversField(field: Field) {
      if (!formData.value.outcome) return;
      field.value = formData.value.outcome[field.name];
      field.model = formData.value.outcome[field.name];
    }

    function setMatch(field: Field) {
      field.value = formData.value[field.name];
      field.model = formData.value[field.name];
    }

    return fieldSchema;
  }

  function getRawFields(dynamicFields: RawField[]) {
    const hasAdditionalFeeFields = sourceData.value?.additional_fees_options.length;
    if (hasAdditionalFeeFields && hasCart.value)
      setAdditionalFeeField(sourceData.value.additional_fees_options);

    const hasPartnerFields = sourceData.value?.partners.length;
    if (hasPartnerFields) fieldsSettings.partner_name.edit!.options = sourceData.value.partners;

    const hasRtoFees = sourceData.value?.rto_fees.length;
    if (hasRtoFees) setRtoFeesField();

    const hasWaivers = isAdoption.value || isReturnToOwner.value;
    if (hasWaivers && waivers?.value && waivers?.value?.length) setWaverField();

    const sanitizedFields = [...rawFields.value, ...dynamicFields].filter(
      ({ name }) => !NOT_ALLOWED_FIELD.includes(name)
    );

    return sanitizedFields;
  }

  function getRtoFeesUpdatePayload() {
    if (!rtoFeeTypeField.value) return;

    const animalId: undefined | string = formData.value?.animal_id;
    const intakeDate: undefined | string | Moment = formData.value?.intake_date;
    const missingParameters = !animalId || !intakeDate;
    if (missingParameters) return;

    // NOTE : If used somewhere else, this could be promoted to a helper
    const newLos: number = getLOS(moment(formData.value?.intake_date));

    function getLOS(intakeDate: Moment): number {
      const outcomeDateModel = outcomeDateFieldSchema.value[0].model;
      const outcomeDate: Moment = moment(outcomeDateModel || new Date()).startOf('day');
      const losDays: number = outcomeDate.diff(moment(intakeDate).startOf('day'), 'days', true);

      return Math.ceil(losDays + 1);
    }

    const newRtoFee = getRtoFee(newLos);
    function getRtoFee(los: number) {
      if (!rtoFeeField.value) return '';

      const rtoIsDaily = rtoFeeTypeField.value === 'daily';
      const amount = Math.round(parseFloat(rtoFeeField.value.amount) * 100) / 100;

      return rtoIsDaily ? amount * los : amount;
    }

    return {
      animals: {
        base_rto_fee: rtoFeeField.value?.amount ?? '',
        id: animalId,
        newLos,
        newRtoFee,
        rto_fee_type: rtoFeeTypeField.value,
      },
    };
  }

  // EVENTS
  function onStepUpdate() {
    fieldsSchema.value = [];
    setFieldsSchema();
    onRtoFeeTypeUpdate();
  }

  function onRtoFeeTypeUpdate() {
    if (!rtoFeeTypeField.value) return;

    const rtoFields = sourceData.value?.rto_fees;
    if (!rtoFields.length) return;

    updateRtoFeeOptions();

    function updateRtoFeeOptions() {
      const rtoFeesField = fieldsSchema.value.find(({ name }) => name === 'rto_fees');
      if (!rtoFeesField) return;

      if (!rtoFeesField?.edit) return;
      const options: RtoFee[] = rtoFields
        .filter((fee: any) => fee.type === rtoFeeTypeField.value.toLowerCase())
        .map((fee: RtoFee) => {
          return {
            ...fee,
            label: `${fee.description} - $${fee.amount}`,
          };
        });

      rtoFeesField.state.disabled = false;
      rtoFeesField.edit.options = options;
      rtoFeesField.settings.placeholder = 'Select a RTO Fee';

      const rtoFee = getSelectedRtoFee();
      function getSelectedRtoFee() {
        if (selectedRtoFee.value[outcomeInfoActiveStep.value]) {
          return selectedRtoFee.value[outcomeInfoActiveStep.value];
        }

        const rtoFee = formData.value?.base_rto_fee || parseFloat(formData.value.base_rto_fee);

        if (!rtoFee) return undefined;

        const selectedRtoFeeData = rtoFees.value
          .filter((fee: any) => fee.type === rtoFeeTypeField.value.toLowerCase())
          .find(({ amount }: RtoFee) => parseFloat(rtoFee) === parseFloat(String(amount)));

        if (!selectedRtoFeeData) return undefined;

        return {
          ...selectedRtoFeeData,
          label: `${selectedRtoFeeData.description} - $${selectedRtoFeeData.amount}`,
        };
      }

      // NOTE: This setTimeout is necessary so the cart summary hava time to update its values when
      // going back and forth in the outcome information page
      setTimeout(() => {
        rtoFeesField.model = rtoFee;
        rtoFeesField.value = rtoFee;
      }, 50);
    }

    // NOTE : If we start to store all fee information within the outcome this should be reviewed
    const noUpdate = formData.value.rto_fee_type === rtoFeeTypeField.value;
    if (noUpdate) return;

    const PAYLOAD = getRtoFeesUpdatePayload();
    if (!PAYLOAD) return;

    PAYLOAD.animals.base_rto_fee = '';

    fieldsSchema.value.forEach((field) => {
      if (field.name !== 'rto_fees') return;

      field.model = '';
      field.value = '';
      field.state.errors = new Set();
    });

    UpdateRtoFeeService(PAYLOAD)
      .then(() => useCartStore().fetchActiveCart())
      .catch(() => notify(TOAST_ERROR));
  }

  function onOutcomeDateUpdate() {
    const PAYLOAD = getRtoFeesUpdatePayload();
    if (!PAYLOAD) return;

    UpdateRtoFeeService(PAYLOAD)
      .then(() => useCartStore().fetchActiveCart())
      .catch(() => notify(TOAST_ERROR));
  }

  function onRtoFeesUpdate() {
    // NOTE : If we start to store all fee information within the outcome this should be reviewed
    const noUpdate =
      parseFloat(formData.value.base_rto_fee) === parseFloat(rtoFeeField.value?.amount);
    if (noUpdate) return;

    const PAYLOAD = getRtoFeesUpdatePayload();
    if (!PAYLOAD) return;

    // NOTE: We use this selectedRtoFee state to repopulate the select RTO Fees field after hard refresh
    if (rtoFeeField.value) {
      selectedRtoFee.value[outcomeInfoActiveStep.value] = {
        ...rtoFeeField.value,
        label: `${rtoFeeField.value.description} - $${rtoFeeField.value.amount}`,
      };
    }

    UpdateRtoFeeService(PAYLOAD)
      .then(() => useCartStore().fetchActiveCart())
      .catch(() => notify(TOAST_ERROR));
  }

  function onAdditionalFeesUpdate(oldFees?: AdditionalFee[]) {
    const isInitalFormLoad = typeof oldFees === 'undefined';
    if (isInitalFormLoad) return;

    const additionalFee: AdditionalFee[] = fieldsSchema.value.find(
      ({ name }) => name === 'additional_fees'
    )?.model;
    const animalId: undefined | string = formData.value?.animal_id;

    if (!animalId) return;

    const PAYLOAD = {
      animals: {
        additional_fees: additionalFee,
        animal_id: animalId,
        outcome_field: true,
      },
    };

    UpdateAdditionalFeesService(PAYLOAD)
      .then(() => onAdditionalFeesUpdate())
      .catch(() => notify(TOAST_ERROR));

    function onAdditionalFeesUpdate() {
      GetCartSessionService()
        .then((data: any) => {
          cartData.value.subtotal = data.subtotal;
          cartData.value.total = data.total;
          cartData.value.additional_fees = data.additional_fees;

          cartData.value.animals.forEach((animal: any, index: number) => {
            animal.subtotal = data.animals[index].subtotal;
            animal.total = data.animals[index].total;
            animal.total_additional_fees = data.animals[index].total_additional_fees;
            animal.additional_fees = data.animals[index].additional_fees;
          });
        })
        .catch(() => notify(TOAST_ERROR));
    }
  }

  return {
    currentDataOnStepperId,
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,
    sourceData,
    setForm,
    waivers,
  };
}
