import { defineStore } from 'pinia';
import { API } from '@Providers';
import { GetAnimalStatusReportService } from '@/modules/Entities/Shelter/modules/AnimalProfile/services';

interface State {
  animalHistoryKennelData: any[];
  animalStatusReport: any[];
  diagnosticTemplates: any[];
  examTemplates: any[];
  endpoint: string;
  followUp: any;
  fosterHistoryLoading: boolean;
  files: any;
  history: any;
  isEditing: boolean;
  loading: boolean;
  medicalData: any;
  medicalStaff: any;
  medicationName: any;
  medicationTemplates: any[];
  pageData: any;
  procedureName: any;
  proceduresTemplates: any[];
  rabiesTag: string;
  strayHoldRemaining: number;
  surgeryTemplates: any[];
  tabPageLoading: boolean;
  templates: any;
  vaccinationTemplates: any[];
  voucherTemplates: any[];
  weightId: string;
}
export default defineStore('animalProfile', {
  state: (): State => ({
    animalHistoryKennelData: [],
    animalStatusReport: [],
    diagnosticTemplates: [],
    examTemplates: [],
    endpoint: '',
    files: [],
    followUp: {},
    fosterHistoryLoading: false,
    history: [],
    isEditing: false,
    loading: false,
    medicalData: {},
    medicalStaff: [],
    medicationName: {},
    medicationTemplates: [],
    pageData: {},
    procedureName: '',
    proceduresTemplates: [],
    rabiesTag: '',
    strayHoldRemaining: 0,
    surgeryTemplates: [],
    tabPageLoading: false,
    templates: [],
    vaccinationTemplates: [],
    voucherTemplates: [],
    weightId: '',
  }),

  getters: {
    animal: (state) => state.pageData.animal,
    tempCart: (state) => (state.pageData.temp_cart?._id ? state.pageData.temp_cart : null),
    shelterId: (state) => state.pageData.animal.shelter_id.$oid,
  },

  actions: {
    fetchPageDataService() {
      if (!this.endpoint) return;

      const endpoint = `/api/shelter/animal/get-animal-profile/${this.endpoint}`;

      return API({ endpoint }).then((result: any) => {
        this.pageData = result;
      });
    },

    fetchMedicalDataService() {
      if (!this.endpoint) return;
      this.tabPageLoading = true;

      const endpoint = `/api/shelter/medical/get-medical-data/${this.endpoint}`;

      return API({ endpoint })
        .then((result: any) => {
          this.medicalData = result;
        })
        .finally(() => (this.tabPageLoading = false));
    },

    async fetchFilesDataService() {
      const endpoint = `/api/shelter/animal/get-animal-files/${this.endpoint}`;

      return API({ endpoint })
        .then((result: any) => {
          this.files = result.map(
            ({ _id, created_at, filename, path, type, spay_neuter, rabies, visibility }: any) => ({
              _id: this.shelterId,
              collection: undefined,
              created_at,
              destinationPath: undefined,
              fileTypeId: _id,
              filename,
              icon: undefined,
              path,
              rabies,
              spay_neuter,
              type,
              visibility,
            })
          );
        })
        .finally(() => (this.tabPageLoading = false));
    },

    async refreshMedicalData() {
      this.tabPageLoading = true;

      await this.fetchMedicalDataService()?.finally(() => (this.tabPageLoading = false));
    },

    async refreshData() {
      await this.fetchPageDataService();
    },

    async refreshFilesData() {
      this.tabPageLoading = true;

      await this.fetchFilesDataService().finally(() => (this.tabPageLoading = false));
    },

    fetchAnimalStatusReportData() {
      GetAnimalStatusReportService(this.animal._id.$oid).then((response) => {
        this.animalStatusReport = response?.data || [];
      });
    },

    getPersonLabel(item: any) {
      const formName = item?.intake?.form?.form_name || item?.form?.form_name;

      if (!formName) return 'Guardian';

      const isStray = formName === 'stray';
      if (isStray) return 'Dropped Off By:';

      // TODO AF 884 - After we create the tnvr add pet form we can filter here and display
      // the correct label/title
      // const is_tnvr = formName.toLowerCase() === 'add_pet';
      // if (is_tnvr) return 'tnvr.title.colonyManager';

      const isFound = formName.toLowerCase() === 'found';
      if (isFound) return 'Found By:';

      return 'Guardian';
    },
  },
});
