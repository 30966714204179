<script setup lang="ts">
// TODO - AF-539 This should be refactor to use FormTemplate
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import type { PaymentMethod } from '../../../types';
import { ADOPT_URL } from '@Constants';
import { useAToast } from '@Atoms';
import { emailValidation, phoneValidation } from '@FormModule';
import useCartStore from '../../../store';
import { CART_STEPS, PAYMENT_METHODS } from '../../../types';
import { SendCheckoutLinkService } from '../../../services';
import { useCartSteps } from '../../../composables';

const { cartData, cartId, loading, pendingOutcomeRoute } = storeToRefs(useCartStore());
const { outcomeCompletedCartReset } = useCartStore();

const { notify, TOAST_TYPES } = useAToast();
const { currentStep, previousStep } = useCartSteps();
const { push } = useRouter();

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const paymentOptions = computed((): PaymentMethod[] => [
  {
    icon: 'faCreditCard',
    key: PAYMENT_METHODS.CREDIT,
    label: 'cart.paymentOptions.creditCard',
    value: PAYMENT_METHODS.CREDIT,
    disabled: emptyTotal.value,
    disabledMessage: emptyTotal.value ? 'cart.title.noCreditCardPaymentAvailable' : '',
  },
  {
    icon: 'faCashRegister',
    key: PAYMENT_METHODS.OTHER,
    label: 'cart.paymentOptions.cashCheckOther',
    value: PAYMENT_METHODS.OTHER,
    disabled: false,
  },
]);

const checkoutMethods = [
  {
    icon: 'faMobile',
    label: 'cart.mobileCheckout.sendLink',
    value: 'adopter',
  },
  {
    icon: 'faHome',
    label: 'cart.mobileCheckout.shelterDevice',
    value: 'shelterDevice',
  },
];

const deviceCheckoutMethods = [
  {
    icon: 'faWindowMaximize',
    label: 'cart.mobileCheckout.openTab',
    value: 'newTab',
  },
  {
    icon: 'faQrcode',
    label: 'cart.mobileCheckout.getQrCode',
    value: 'qrCode',
  },
  {
    icon: 'faLink',
    label: 'cart.mobileCheckout.sendLink',
    value: 'link',
  },
];

const adopterAppLink = computed(() => ADOPT_URL + '/mobile-checkout/' + cartId.value);
const adopterEmail = ref('');
const adopterEmailError = ref('');
const adopterPhone = ref('');
const adopterPhoneError = ref('');
const checkoutMethod = ref();
const deviceCheckoutMethod = ref('');
const paymentMethod = ref<PAYMENT_METHODS | null>(null);
const showErrorMessage = ref(false);

const showCheckoutLinkError = computed(
  () =>
    (checkoutMethod.value === 'adopter' && !adopterEmail.value && !adopterPhone.value) ||
    !!adopterPhoneError.value ||
    !!adopterEmailError.value
);

const showShelterCheckoutMethod = computed(
  () =>
    (checkoutMethod.value === 'shelterDevice' && !deviceCheckoutMethod.value) ||
    shelterCheckoutByLinkHasError.value
);

const shelterCheckoutByLinkHasError = computed(
  () => deviceCheckoutMethod.value === 'link' && !adopterEmail.value
);

const emptyTotal = computed(() => !parseFloat(cartData.value?.total));

watch(adopterEmail, () => onEmailUpdate());
watch(adopterPhone, () => onPhoneUpdate());
watch([adopterEmailError, adopterPhone], () => onRequiredFieldsUpdate());

onMounted(() => (currentStep.value = CART_STEPS.MOBILE_CHECKOUT));

// EVENTS
function onRequiredFieldsUpdate() {
  if (adopterEmailError.value && adopterEmailError.value) {
    showErrorMessage.value = showCheckoutLinkError.value && showShelterCheckoutMethod.value;
  }
}

function onEmailUpdate() {
  if (!adopterEmail.value) return (adopterEmailError.value = '');

  const notValidEmail = !emailValidation(adopterEmail.value);
  if (notValidEmail) return (adopterEmailError.value = 'components.forms.errors.validEmail');

  adopterEmailError.value = '';
}

function onPhoneUpdate() {
  if (!adopterPhone.value) return (adopterPhoneError.value = '');

  const notValidPhone = !phoneValidation(adopterPhone.value);
  if (notValidPhone) return (adopterPhoneError.value = 'components.forms.errors.validPhone');

  adopterPhoneError.value = '';
}

function onNextStep() {
  const hasError =
    !paymentMethod.value ||
    !checkoutMethod.value ||
    showCheckoutLinkError.value ||
    showShelterCheckoutMethod.value;
  if (hasError) return (showErrorMessage.value = true);

  showErrorMessage.value = false;
  loading.value = true;

  // NOTE: This is a expected setting in the adopter app
  const paymentType =
    paymentMethod.value === PAYMENT_METHODS.CREDIT ? paymentMethod.value : PAYMENT_METHODS.CASH;

  const PAYLOAD = {
    _id: cartId.value,
    cart_id: cartId.value,
    checkoutFlow: checkoutMethod.value,
    email: adopterEmail.value,
    payment_type: paymentType,
    phone: adopterPhone.value,
    show_application: null, // NOTE: There is no feature using this for now
    show_contract: null, // NOTE: There is no feature using this for now
    status: 'pending',
  };

  const TOAST_ERROR = {
    message: 'errors.generics.unknownIssue.message',
    title: 'errors.generics.unknownIssue.title',
    type: TOAST_TYPES.ERROR,
  };

  SendCheckoutLinkService(PAYLOAD)
    .then(() => {
      if (deviceCheckoutMethod.value === 'newTab') window.open(adopterAppLink.value, '_blank');
      push(pendingOutcomeRoute.value).then(() => outcomeCompletedCartReset());
    })
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex h-fit flex-col gap-4">
    <main class="flex flex-1 flex-col gap-4">
      <!-- Payment Method -->
      <div class="flex flex-col gap-2">
        <AFieldLabel
          required
          :error="showErrorMessage && !paymentMethod"
          :label="$t('cart.mobileCheckout.paymentMethod')"
        />

        <div class="flex flex-wrap gap-1 sm:gap-4">
          <AFieldCheckboxButton
            v-model="paymentMethod"
            :options="paymentOptions"
          />
        </div>
      </div>

      <!-- Checkout Method -->
      <div class="flex flex-col gap-2">
        <AFieldLabel
          required
          :error="showErrorMessage && !checkoutMethod"
          :label="$t('cart.mobileCheckout.checkoutOption')"
        />

        <div class="flex flex-wrap gap-1 sm:gap-4">
          <AFieldCheckboxButton
            v-model="checkoutMethod"
            :options="checkoutMethods"
          />
        </div>
      </div>

      <!-- Send Link Options -->
      <div
        v-if="checkoutMethod === 'adopter'"
        class="flex flex-col gap-2"
      >
        <AFieldLabel
          required
          :error="showErrorMessage && showCheckoutLinkError"
          :label="$t('cart.mobileCheckout.deliveryMethod')"
        />

        <div class="flex w-96 max-w-full flex-col justify-center gap-1">
          <div class="flex flex-col items-start">
            <AFieldPhone
              v-model="adopterPhone"
              :error="
                (showErrorMessage && showCheckoutLinkError) || (adopterPhoneError && !adopterEmail)
              "
              :placeholder="$t('cart.mobileCheckout.enterPhone')"
            />

            <AFieldError
              v-if="adopterPhoneError && !adopterEmail"
              :error="adopterPhoneError"
            />
          </div>

          <div class="flex flex-col items-center">
            <span class="text-sm font-semibold">
              {{ $t('common.or') }}
            </span>
          </div>

          <form class="flex flex-col items-start">
            <AFieldEmail
              v-model="adopterEmail"
              :error="
                (showErrorMessage && showCheckoutLinkError) || (adopterEmailError && !adopterPhone)
              "
              :placeholder="$t('cart.mobileCheckout.enterEmail')"
            />

            <AFieldError
              v-if="adopterEmailError && !adopterPhone"
              :error="adopterEmailError"
            />
          </form>
        </div>
      </div>

      <!-- Shelter Device Options -->
      <div
        v-if="checkoutMethod === 'shelterDevice'"
        class="flex flex-col gap-2"
      >
        <AFieldLabel
          required
          :error="showErrorMessage && showShelterCheckoutMethod"
          :label="$t('cart.mobileCheckout.deviceCheckoutMethod')"
        />

        <div class="flex flex-wrap gap-4">
          <AFieldCheckboxButton
            v-model="deviceCheckoutMethod"
            class="text-nowrap"
            :options="deviceCheckoutMethods"
          />
        </div>

        <form class="max-w-[360px]">
          <AFieldEmail
            v-if="deviceCheckoutMethod === 'link'"
            v-model="adopterEmail"
            :error="(showErrorMessage && showShelterCheckoutMethod) || adopterEmailError"
            :placeholder="$t('cart.mobileCheckout.enterEmail')"
          />

          <AFieldError :error="adopterEmailError" />
        </form>
      </div>
    </main>

    <footer class="flex-shrink">
      <Message
        v-if="showErrorMessage"
        pt:text="text-sm"
        severity="error"
        :closable="false"
      >
        {{ $t('cart.mobileCheckout.formError') }}
      </Message>

      <div class="flex flex-wrap gap-1 sm:justify-between">
        <Button
          outlined
          class="af-button af-button-success-outlined w-fit"
          icon="pi pi-arrow-left"
          :loading
          :label="$t('common.back')"
          @click="emits('onStepUpdate', previousStep)"
        />

        <Button
          class="af-button af-button-success sm:ml-auto"
          iconPos="right"
          :loading
          :label="$t('cart.label.submitAndComplete')"
          @click="onNextStep"
        >
          <template #icon>
            <FaIcon
              class="mr-2"
              :icon="$icons.faPaw"
            />
          </template>
        </Button>
      </div>
    </footer>
  </div>
</template>
