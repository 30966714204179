import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import 'moment-timezone';

export default function() {
  const { t } = useI18n();

  const DEFAULT_DATA_RANGE = [
    new Date(moment().startOf('year').format()),
    new Date(moment().endOf('year').format()),
  ];

  const presetDates = computed(() =>[
    { 
      label: t('common.yesterday'),
      selectionModes: ['single'],
      value: moment().subtract(1, 'days').toDate(),
    },
    { 
      label: t('common.today'),
      selectionModes: ['single'],
      value: moment().toDate(),
    },
    { 
      label: t('common.tomorrow'),
      selectionModes: ['single'],
      value: moment().add(1, 'days').toDate(),
    },
    {
      label: t('common.thisMonth'),
      selectionModes: ['range'],
      value: [
        new Date(moment().startOf('month').format()),
        new Date(moment().endOf('month').format()),
      ],
    },
    {
      label: t('common.lastMonth'),
      selectionModes: ['range'],
      value: [
        new Date(moment().startOf('month').subtract(1, 'month').format()),
        new Date(moment().endOf('month').subtract(1, 'month').format()),
      ],
    },
    {
      label: t('common.thisYear'),
      selectionModes: ['range'],
      value: DEFAULT_DATA_RANGE,
    },
    {
      label: t('common.lastYear'),
      selectionModes: ['range'],
      value: [
        new Date(moment().startOf('year').subtract(1, 'year').format()),
        new Date(moment().endOf('year').subtract(1, 'year').format()),
      ],
    },
  ]);
  
  return {
    DEFAULT_DATA_RANGE,
    presetDates,
  }
};