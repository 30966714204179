<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { CartItemType } from '../../../types';
import { useCartStore } from '@CartModule';

const { hasCart } = storeToRefs(useCartStore());

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    hasAddition?: boolean;
    hasDelete?: boolean;
    outcomeCompleted?: boolean;
    cartItemType?: CartItemType;
    cartItem: any; // TODO - AF-532 CartItem should have a interface
  }>(),
  {
    hasAddition: true,
    hasDelete: true,
  }
);

const emits = defineEmits<{
  onRemoveCartItem: [];
  onRemoveCitation: [];
  onRemoveProduct: [];
  onUpdateQuantity: Number[];
}>();

const isProduct = computed(() => props.cartItemType === 'product');
const isAnimal = computed(() => props.cartItemType === 'animal');
const isReturnToOwner = computed(() => props.cartItem.outcome_type === 'return_to_owner');

const name = computed(() => {
  // prettier-ignore
  switch (props.cartItemType) {
    case 'animal': return props.cartItem?.animal_name ?? '';
    case 'product': return props.cartItem?.product ?? '';
    case 'service': return props.cartItem?.service ?? '';
    default: return `${t('fieldServices.title.case')} ${props.cartItem?.call_seq_id ?? ''}`; // citation
  }
});

const target = computed(() => {
  // prettier-ignore
  switch (props.cartItemType) {
    case 'animal': return `/shelter/animal-profile/${props.cartItem._id}`;
    case 'product': return '/shelter/products-and-services';
    case 'service': return '/shelter/products-and-services';
    default: return `/shelter/field-services/case/${props.cartItem.call_id}`; // citation
  }
});

function onRemove() {
  // prettier-ignore
  switch (props.cartItemType) {
    case 'animal': return emits('onRemoveCartItem');
    case 'product': return emits('onRemoveProduct');
    case 'service': return emits('onRemoveProduct');
    default: return emits('onRemoveCitation'); // citation
  }
}

function onUpdateQuantity(quantity: Number) {
  if (props.cartItemType === 'animal' || props.cartItemType === 'citation') return;
  emits('onUpdateQuantity', quantity);
}
</script>

<template>
  <div class="flex flex-col items-center gap-2">
    <RouterLink :to="target">
      <h2 class="text-sm font-semibold text-brand-400">{{ name }}</h2>
    </RouterLink>

    <div class="relative">
      <AAvatar
        v-if="isAnimal"
        class="h-14 w-14"
        :gender="cartItem?.gender"
        :src="cartItem?.photo"
      />

      <div
        v-else-if="isProduct"
        class="grid size-14 place-content-center rounded-full border-2 border-brand-200"
      >
        <span class="pi pi-box text-xl text-brand-400"></span>
      </div>

      <div
        v-else
        class="grid size-14 place-content-center rounded-full border-2 border-brand-200"
      >
        <span class="pi pi-money-bill text-xl text-brand-400"></span>
      </div>

      <Button
        v-if="hasDelete"
        outlined
        class="absolute -top-2 left-[-5px] h-5 w-5 border-none bg-red-50 p-2"
        icon="pi pi-trash text-sm"
        severity="danger"
        @click="onRemove"
      />

      <div v-if="hasAddition && props.cartItemType === 'product' && hasCart">
        <Button
          v-if="props.cartItem.quantity > 1"
          class="absolute -bottom-3 left-[-5px] z-40 h-5 w-5 p-1"
          icon="pi pi-minus text-xs"
          severity="secondary"
          @click="onUpdateQuantity(-1)"
        />

        <p
          class="absolute -bottom-3 right-3 z-30 h-5 w-8 bg-slate-50 text-center text-sm text-slate-500"
        >
          {{ cartItem.quantity }}
        </p>

        <Button
          class="absolute -bottom-3 right-[-5px] h-5 w-5 p-1"
          icon="pi pi-plus text-xs"
          severity="secondary"
          @click="onUpdateQuantity(1)"
        />
      </div>
    </div>

    <MCartRtoAddCost
      v-if="isReturnToOwner && !outcomeCompleted"
      :cartItem
    />
  </div>
</template>
