<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

const props = withDefaults(
  defineProps<{
    error?: string;
    label?: string;
    dateModel?: boolean;
  }>(),
  {
    label: 'animals.birthdayField.resultLabel',
  }
);

const { t } = useI18n();

const model = defineModel<Date | string>();

const dateFields = ref({
  year: {
    label: t('common.year', 2),
    model: 0,
    min: undefined,
    max: undefined,
  },
  month: {
    label: t('common.month', 2),
    model: 0,
    min: 0,
    max: 12,
  },
  days: {
    label: t('common.day', 2),
    model: 0,
    min: 0,
    max: 31,
  },
});

const currentDate = computed(() => moment(moment().format('MM/DD/YY'), 'MM/DD/YY').toDate());
const composedDate = computed(() => {
  const { year, month, days } = dateFields.value;

  const newDate = moment(currentDate.value)
    .subtract({
      years: year.model,
      months: month.model,
      days: days.model,
    })
    .toDate();

  return moment(newDate).format('MM/DD/YY');
});
const noRecord = computed(() => {
  const { year, month, days } = dateFields.value;

  return !year.model && !month.model && !days.model;
});

watch(composedDate, (newDate) => updateModel(newDate));

onMounted(() => setTimeout(() => setInitialModel(), 50));

function setInitialModel() {
  if (!model.value) return;

  const date = moment(model.value, ['YYYY-MM-DD', 'MM-DD-YYYY'], true);

  if (!date.isValid()) return;

  const diffYears = moment(currentDate.value).diff(date, 'years');
  const diffMonths = moment(currentDate.value).subtract(diffYears, 'years').diff(date, 'months');
  const diffDays = moment(currentDate.value)
    .subtract(diffYears, 'years')
    .subtract(diffMonths, 'months')
    .diff(date, 'days');

  dateFields.value.year.model = diffYears;
  dateFields.value.month.model = diffMonths;
  dateFields.value.days.model = diffDays;
}

function updateModel(newDate: string) {
  let result = null;

  if (noRecord.value) return result;

  if (!props.dateModel) model.value = newDate;

  const date = moment(newDate, 'MM/DD/YY');
  model.value = date.toDate();
}
</script>

<template>
  <div class="flex flex-col gap-1">
    <div class="flex flex-wrap gap-2">
      <fieldset
        v-for="field in dateFields"
        class="flex flex-col"
        :key="field.label"
      >
        <AFieldLabel
          :error
          :label="field.label"
        />

        <InputNumber
          v-model="field.model"
          :max="field.max"
          :min="field.min"
          :pt:input:root="['text-sm w-12 p-1', { 'af-field-error': !!error }]"
          @input="(event) => (field.model = Number(event.value))"
        />
      </fieldset>
    </div>

    <div class="flex gap-1 text-xs">
      <span class="font-medium"> {{ $t(label) }}: </span>

      <span>
        {{ !noRecord ? composedDate : '-' }}
      </span>
    </div>
  </div>
</template>
