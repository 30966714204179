<script setup lang="ts">
import { computed, ref } from 'vue';
import PAW_FALLBACK from '@Assets/images/paw.png';
import PERSON_FALLBACK from '@Assets/images/default.webp';

const props = withDefaults(
  defineProps<{
    type?: 'Animal' | 'Person' | 'Product';
    gender?: 'Male' | 'Female' | 'Unknown' | [];
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    src?: string;
  }>(),
  {
    type: 'Animal',
    size: 'md',
  }
);

const SIZES = {
  xs: 'size-6 min-h-6 min-w-6',
  sm: 'size-8 min-h-8 min-w-8',
  md: 'size-10 min-h-10 min-w-10',
  lg: 'size-12 min-h-12 min-w-12',
  xl: 'size-14 min-h-14 min-w-14',
};

const image = ref<HTMLImageElement>();

const genderClass = computed(() => {
  // prettier-ignore
  switch (props.gender) {
    case 'Male': return 'border-2 border-sky-400'
    case 'Female': return 'border-2 border-pink-500'
    default: return 'border-2'
  }
});

function loadingError() {
  if (!image.value) return;
  image.value.src = props.type === 'Animal' ? PAW_FALLBACK : PERSON_FALLBACK;
}
</script>

<template>
  <div
    v-if="type === 'Product'"
    :class="[
      'grid aspect-square place-content-center rounded-full border-2 border-brand-200 bg-white bg-cover object-cover p-0.5',
      [SIZES[size], genderClass],
    ]"
  >
    <span class="pi pi-box text-brand-400"></span>
  </div>

  <img
    v-else
    loading="lazy"
    ref="image"
    :src="src || ''"
    :class="[
      'aspect-square rounded-full bg-white bg-cover object-cover p-0.5',
      [SIZES[size], genderClass],
    ]"
    @error="loadingError"
  />
</template>
