import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: 'animals',
    name: 'animals',
    redirect: '/shelter/animals/in-care',
    children: [
      {
        path: 'in-care-options',
        name: 'animals-in-care-options',
        component: () => import('./views/AnimalsInCareOptions.view.vue'),
      },
      {
        path: 'all-options',
        name: 'all-animals-options',
        component: () => import('./views/AllAnimalsOptions.view.vue'),
      },
      {
        path: 'in-care',
        name: 'animals-in-care',
        component: () => import('./views/AnimalsInCare.view.vue'),
      },
      {
        path: 'all',
        name: 'all-animals',
        component: () => import('./views/AllAnimals.view.vue'),
      },
      {
        path: 'admission',
        name: 'admission-form',
        component: () => import('./views/AnimalsAdmission.view.vue'),
      },
      {
        path: 'add-pet/:id',
        name: 'add-pet-form',
        component: () => import('./views/AnimalsAddPetForm.view.vue'),
      },
    ],
  },
];
