<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const address = computed(() => {
  if (!props.field) return null;

  return props.field[props.settings.render.targetKey];
});
</script>

<template>
  <p v-if="!address">-</p>

  <p
    v-else
    v-html="address"
  ></p>
</template>
