function emailValidation(target: string) {
  const validTestCase = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return validTestCase.test(target);
}

function microchipValidation(target: string) {
  const validLengths = [9, 10, 15];

  return validLengths.includes(target.length);
}

function phoneValidation(input: string | Event): boolean {
  const target = getPhoneTarget(input);
  const numericTarget = target.replace(/\D/g, '');

  if (numericTarget.length < 10) return false;

  const validPattern = /^(\d ?)?\(?\d{3}\)? \d{3}-\d{4}$/;
  return validPattern.test(target) || numericTarget.length >= 10;
}

function getPhoneTarget(target: string | Event): string {
  const isEventOrElement =
    target instanceof Event && target.target && target.target instanceof HTMLInputElement;

  return isEventOrElement ? target.target.value : String(target);
}

export { emailValidation, microchipValidation, phoneValidation };
