import { ref } from 'vue';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useForm, setEmptyModelForFieldsWithDateType } from '@FormModule';

const personType = ref();

export default function () {
  const { getDetailsSchema } = useForm();

  const currentSchemaModels = ref<Set<GenericField>>(new Set());
  const fieldsSchema = ref<FieldSchema[]>([]);
  const fieldsSettings = ref<FieldInitialSettings>({
    phone: {
      settings: {
        mode: 'link',
        type: 'contact',
      },
    },
    email: {
      settings: {
        mode: 'link',
        type: 'contact',
      },
    },
  });
  const formError = ref();
  const formId = ref('');
  const formName = ref('');
  const formType = ref('');
  const rawFields = ref<RawField[]>([]);

  function setForm() {
    setEmptyModelForFieldsWithDateType(rawFields, fieldsSettings);

    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);
  }

  function resetFormSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    formId,
    formName,
    formType,
    personType,
    rawFields,
    resetFormSchema,
    setForm,
  };
}
