import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import type { GenericField } from '@Types';
import { useForm } from '@FormModule';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema } = useForm();
  const { t } = useI18n();

  const fieldsSettings: FieldInitialSettings = {
    primary: {
      edit: {
        label: 'name',
        value: 'value',
        options: [
          { name: t('common.yes'), value: true },
          { name: t('common.no'), value: false },
        ],
      },
    },
  };
  const rawFields: RawField[] = [
    {
      label: 'Primary Address',
      name: 'primary',
      required: false,
      type: 'radio',
      value: '',
    },
    {
      label: 'Address 1',
      name: 'address1',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Address 2',
      name: 'address2',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Country',
      name: 'country',
      required: true,
      type: 'country',
      value: '',
    },
    {
      label: 'City',
      name: 'city',
      required: true,
      type: 'text',
      value: '',
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      type: 'text',
      value: '',
    },
  ];

  function setForm() {
    if (fieldsSchema.value.length) return;

    fieldsSchema.value = getDetailsSchema(rawFields, fieldsSettings);
  }

  function updateSchemaModels(sourceData: any) {
    setForm();
    if (!fieldsSchema.value.length) return;

    fieldsSchema.value.forEach((field) => {
      const match = sourceData[field.name];

      switch (field.name) {
        case 'country': {
          field.model = { country: sourceData.country, state: sourceData.state };
          field.value = { country: sourceData.country, state: sourceData.state };
          break;
        }
        default: {
          field.model = match;
          field.value = match;
        }
      }
    });
  }

  function resetFieldSchema() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
    formError.value = null;
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    rawFields,
    resetFieldSchema,
    setForm,
    updateSchemaModels,
  };
}
