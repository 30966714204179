<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@Stores';
import { API_URL } from '@Constants';

const props = defineProps<{
  cartData: any; // TODO AF-543: Create a interface for this
  cartId: string;
  cartItem: any; // TODO - AF-532 CartItem should have a interface
}>();

const { shelterProfile } = storeToRefs(useSessionStore());

const animalId = computed(() => props.cartItem.animal_id);
const isIntake = computed(() => props.cartData.cart_type === 'intake');
const shelterId = computed(() => shelterProfile.value._id.$oid);
const href = computed(() => {
  const link = isIntake.value
    ? `/shelter/files/get-file/${shelterId.value}?file=${shelterId.value}/${animalId.value}/intake/${props.cartItem.intake_id}/intake_waiver.pdf`
    : '';

  return `${API_URL}${link}`;
});
</script>

<template>
  <a
    target="_blank"
    :href
  >
    <Button
      class="af-button af-button-success w-fit"
      icon="pi pi-print"
      pt:label="text-xs"
      :label="$t('cart.label.printWaivers')"
    />
  </a>
</template>
