import { ref } from 'vue';
import { SYSTEM_MODALS } from './constants';

const activeModal = ref('');
const activeModalProps = ref();
const dialogVisibility = ref(false);
const styles = ref(['w-full sm:w-[600px]']);

export default function () {
  function isCurrentModalOpen(targetModal: SYSTEM_MODALS) {
    return targetModal === activeModal.value;
  }

  function reset() {
    activeModal.value = '';
    activeModalProps.value = '';
    dialogVisibility.value = false;
    styles.value = ['w-full sm:w-[600px]'];
  }

  return {
    activeModal,
    activeModalProps,
    dialogVisibility,
    styles,

    SYSTEM_MODALS,

    isCurrentModalOpen,
    reset,
  };
}
