import type { GenericField } from '@Types';

export default <GenericField[]>[
  {
    name: 'Cat',
    value: 'cat',
  },
  {
    name: 'Dog',
    value: 'dog',
  },
  {
    name: 'Rabbit',
    value: 'rabbit',
  },
  {
    name: 'Horse',
    value: 'horse',
  },
  {
    name: 'Wildlife',
    value: 'wildlife',
  },
  {
    name: 'Barn',
    value: 'barn',
  },
  {
    name: 'Bird',
    value: 'bird',
  },
  {
    name: 'Pocket',
    value: 'pocket',
  },
  {
    name: 'Reptile',
    value: 'reptile',
  },
  {
    name: 'Other',
    value: 'other',
  },
];
