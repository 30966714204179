import { computed, ref } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useForm } from '@FormModule';

const animalId = ref('');
const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();
const formId = ref('');
const formName = ref('');
const formTemplateId = ref('');
const formTemplateName = ref('');
const formTitle = ref('');
const formType = ref('');
const intakeFormType = ref('');
const kennels = ref();
const rawFields = ref<RawField[]>([]);

export default function () {
  const { getDetailsSchema } = useForm();

  const INITIAL_FORM_TEMPLATE = 'dog';

  const hasVisualKennel = computed(() => {
    if (!kennels.value) return;

    return kennels?.value?.find((kennel: any) => kennel?.name);
  });
  const fieldsSettings = computed(
    (): FieldInitialSettings => ({
      kennel: {
        edit: {
          label: hasVisualKennel.value ? 'name' : '',
          options: kennels.value,
        },
      },
      animal_name: {
        label: 'Animal Name',
        settings: {
          type: 'animal_name',
        },
      },
      animal_template: {
        model: INITIAL_FORM_TEMPLATE,
      },
      breed: {
        edit: {
          options: [],
        },
        settings: {
          type: 'breeds',
        },
      },
      photo: {
        settings: {
          type: 'file',
          multiple: true,
          fileLimit: 5,
        },
      },
      species: {
        model: [],
        edit: {
          options: [],
        },
        settings: {
          show: true,
        },
      },
    })
  );

  const selectedTemplate = computed(() =>
    fieldsSchema.value.find(({ name }) => name === 'animal_template')
  );
  const formTemplate = computed(() => selectedTemplate.value?.model);

  function setForm() {
    fieldsSchema.value = getDetailsSchema(rawFields.value, fieldsSettings.value);
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    animalId,
    currentSchemaModels,
    fieldsSchema,
    fieldsSettings,
    formError,
    formId,
    formName,
    formTemplate,
    formTemplateId,
    formTemplateName,
    formTitle,
    formType,
    intakeFormType,
    kennels,
    rawFields,
    resetForm,
    setForm,
    INITIAL_FORM_TEMPLATE,
  };
}
