<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import { CURRENCY } from '@Constants';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

const props = withDefaults(
  defineProps<{
    currency?: CURRENCY;
    error?: string;
    field?: FieldSchema;
    modelValue: number | string;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    currency: CURRENCY.USD,
    placeholder: 'components.forms.placeholder.common',
  }
);

const emits = defineEmits<{
  'update:modelValue': [number];
}>();

const _model = ref<number>(
  typeof props.modelValue === 'string' ? parseInt(props.modelValue) : props.modelValue
);

const input = useDebounce(_model, 200);

onMounted(() => {
  if (isNaN(_model.value)) _model.value = 0;
});

watch(input, () => emits('update:modelValue', _model.value));
</script>

<template>
  <InputNumber
    v-model="_model"
    mode="currency"
    :class="['h-9 w-full', { 'af-field-error': !!error }]"
    :currency="field?.settings.currency || currency"
    :invalid="!!error"
    :placeholder="$t(field?.settings.placeholder || placeholder)"
    :pt:input:root="['text-sm', { 'af-field-error': !!error }]"
    :required="field?.settings.required || required"
    @input="(event) => (_model = Number(event.value))"
  />
</template>
