<script setup lang="ts">
import { computed } from 'vue';
import { capitalizeStr, convertToTitleCase } from '@Helpers';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const sanitizedField = computed((): any =>
  props.settings?.render?.targetKey.length
    ? getTargetValue(props.settings.render.targetKey)
    : props.field
);
const sanitizedFieldsWithoutSnakeCase = computed(() => {
  return /^[a-z]+_[a-z]+$/.test(sanitizedField.value) ||
    /^[a-z]+_[a-z]+_[a-z]+$/.test(sanitizedField.value)
    ? convertToTitleCase(sanitizedField.value)
    : capitalizeStr(sanitizedField.value);
});

function getTargetValue(targetKeys: string[]) {
  let targetValue = props.rowData;

  targetKeys.forEach((key) => (targetValue = targetValue[key]));

  return targetValue;
}
</script>

<template>
  <p v-if="Array.isArray(sanitizedField)">
    {{ sanitizedField.length ? sanitizedField.join(', ') : '-' }}
  </p>

  <p
    v-else
    class="overflow-hidden text-ellipsis"
  >
    {{ sanitizedField ? sanitizedFieldsWithoutSnakeCase : '-' }}
  </p>
</template>
