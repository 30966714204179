import type { DataTableColumn } from '@DataTableModule';
import {
  AFDataTableColumn,
  DATA_TABLE_COMPONENTS,
  FORMAT_COMPONENTS,
  getCellComponentHelper,
} from '@DataTableModule';

export default function (field: any) {
  const { FORMAT, IMAGE, TAG } = DATA_TABLE_COMPONENTS;
  const { MONEY } = FORMAT_COMPONENTS;

  const hasSettings = ['photo', 'price', 'type'];

  const COLUMN: DataTableColumn = new AFDataTableColumn(field, false);

  if (!hasSettings.includes(field.name)) return getCellComponentHelper(field.type, COLUMN);

  switch (field.name) {
    case 'price': {
      return {
        ...COLUMN,
        settings: {
          component: FORMAT,
          render: {
            type: MONEY,
          },
        },
      };
    }
    case 'type': {
      return {
        ...COLUMN,
        settings: {
          component: TAG,
        },
      };
    }
    case 'photo': {
      return {
        ...COLUMN,
        settings: {
          component: IMAGE,
          type: 'Product',
        },
      };
    }
    default:
      return COLUMN;
  }
}
