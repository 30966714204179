import { computed, watch } from 'vue';
import { type FiltersProps, type OptionArray, SYSTEM_FILTERS } from '../types';
import { SPECIES_AND_BREEDS_GENERATED } from '@AnimalModule';
import { type AllSpeciesWithBreeds } from '@AnimalModule';
import useActiveFilters from './useActiveFilters';
import useFilters from './useFilters';

const { _activeFilters } = useActiveFilters();
const { systemFilters } = useFilters();

const speciesWithBreeds: AllSpeciesWithBreeds = SPECIES_AND_BREEDS_GENERATED;
const DEFAULT_SPECIES = ['Dog', 'Cat'];

export default function () {
  let initialBreedsMessage: string | undefined = '';

  const speciesFilterModel = computed(() => {
    const speciesFilter = _activeFilters.value.filter(
      ({ name }) => name === systemFilters()[SYSTEM_FILTERS.species].name
    );

    return speciesFilter.length ? speciesFilter[0].model : undefined;
  });

  watch(speciesFilterModel, (model) => onSpeciesFilterUpdate(model as OptionArray[]));

  function setSpecies(filterSpecies: string[], speciesFilter: FiltersProps) {
    const species = _setDefaultSpecies([...new Set(filterSpecies)]);

    const speciesOptions = species.map((specie) => {
      const match = speciesWithBreeds[specie];
      let result = { name: specie, value: specie };

      if (match) result = { name: match.name, value: match.value };

      return result;
    });

    speciesFilter.settings.options = speciesOptions;
  }

  function _setDefaultSpecies(species: string[]) {
    const orderedDefault = species
      .filter((specie) => DEFAULT_SPECIES.includes(specie))
      .sort((a, b) => DEFAULT_SPECIES.indexOf(a) - DEFAULT_SPECIES.indexOf(b));

    const otherSpecies = species.filter((item) => !DEFAULT_SPECIES.includes(item)).sort();

    return [...orderedDefault, ...otherSpecies];
  }

  function orderSpeciesAnBreed(filters: FiltersProps[]) {
    const speciesIndex = filters.findIndex((obj) => obj.name === SYSTEM_FILTERS.species);
    const breedsIndex = filters.findIndex((obj) => obj.name === SYSTEM_FILTERS.breed);

    if (speciesIndex !== -1 && breedsIndex !== -1) {
      const breedsFilter = filters.splice(breedsIndex, 1)[0];
      filters.splice(speciesIndex + 1, 0, breedsFilter);
    }

    return filters;
  }

  function onSpeciesFilterUpdate(model: OptionArray[]) {
    const noSpeciesSelected = !model || !model.length;

    const breedsFilter = _activeFilters.value.filter(
      ({ name }) => name === systemFilters()[SYSTEM_FILTERS.breed].name
    )[0];

    if (!breedsFilter) return;

    handleBreedsHint(breedsFilter, model.length);

    if (noSpeciesSelected) {
      clearBreeds();
      return;
    }

    const multipleSpeciesSelect = model.length > 1;
    if (multipleSpeciesSelect) {
      clearBreeds();
      return;
    }

    const breedMatch = speciesWithBreeds[model[0].value];
    const breeds = breedMatch ? breedMatch.breeds : [];

    const selectedSpecieHasNoBreeds = !breeds.length;
    if (selectedSpecieHasNoBreeds) return;

    // Populate breeds
    if (breedsFilter.state) breedsFilter.state.disabled = false;
    breedsFilter.settings.options = breeds;

    function clearBreeds() {
      if (breedsFilter.state) breedsFilter.state.disabled = true;
      breedsFilter.model = [];
      breedsFilter.settings.options = [];
    }

    function handleBreedsHint(breedsFilter: FiltersProps, selectedSpecies: number) {
      if (!initialBreedsMessage && breedsFilter.text) {
        initialBreedsMessage = breedsFilter.text.hint;
      }

      if (breedsFilter.text) {
        switch (selectedSpecies) {
          case 0:
            breedsFilter.text.hint = initialBreedsMessage;
            break;
          case 1:
            breedsFilter.text.hint = '';
            break;
          default:
            breedsFilter.text.hint = 'filters.hint.onlyOneSpecie';
        }
      }
    }
  }

  return {
    setSpecies,
    orderSpeciesAnBreed,
  };
}
