enum FIELDS_COMPONENTS {
  AMOUNT_UNIT = 'AMOUNT_UNIT',
  ANIMAL_BIRTHDAY = 'ANIMAL_BIRTHDAY',
  ANIMAL_NAME = 'ANIMAL_NAME',
  ANIMAL_TEMPLATE = 'ANIMAL_TEMPLATE',
  BOOSTER = 'BOOSTER',
  BUTTON_CHECKBOX = 'BUTTON_CHECKBOX',
  CHECKBOX = 'CHECKBOX',
  COLOR_SELECTOR = 'COLOR_SELECTOR',
  CONTACT = 'CONTACT',
  CONTACT_TYPE = 'CONTACT_TYPE',
  COUNTRY = 'COUNTRY',
  DATE = 'DATE',
  DATE_PICKER = 'DATE_PICKER',
  DROPDOWN = 'DROPDOWN',
  EDITOR = 'EDITOR',
  EMAIL = 'EMAIL',
  FILE = 'FILE',
  FREQUENCY = 'FREQUENCY',
  GMAP = 'GMAP',
  HEADER = 'HEADER',
  HOLD_STATUS = 'HOLD_STATUS',
  KENNEL_MANAGER = 'KENNEL_MANAGER',
  LINK = 'LINK',
  LOCATION = 'LOCATION',
  MICROCHIP = 'MICROCHIP',
  MICROCHIP_ISSUER = 'MICROCHIP_ISSUER',
  MONEY = 'MONEY',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  PARAGRAPH = 'PARAGRAPH',
  PASSWORD = 'PASSWORD',
  PHONE_CARD = 'PHONE_CARD',
  PROFILE_INFO = 'PROFILE_INFO',
  OPEN_MODAL = 'OPEN_MODAL',
  PRINT = 'PRINT',
  RADIO = 'RADIO',
  RESULT = 'RESULT',
  SEPARATOR = 'SEPARATOR',
  SPECIES = 'SPECIES',
  STAFF = 'STAFF',
  TAG = 'TAG',
  TEL = 'TEL',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  TIMEFRAME = 'TIMEFRAME',
  TOGGLE = 'TOGGLE',
  WEIGHT = 'WEIGHT',
}

enum FORM_VALIDATIONS {
  MAX_LENGTH = 'MAX_LENGHT',
  MIN_LENGTH = 'MIN_LENGHT',
}

export { FIELDS_COMPONENTS, FORM_VALIDATIONS };
