import * as Sentry from '@sentry/vue';
import { APP_URL } from '@Constants';
import { useStorage } from '@vueuse/core';

async function errorHandler(error: any) {
  const structuredError = {
    code: error.code,
    data: error.response?.data,
    message: error.message,
    method: error.config?.method,
    status: error.response?.status,
    url: error.config?.url,
  };

  console.error('API Error', structuredError);

  Sentry.captureException(error, {
    tags: {
      type: 'axios',
      section: 'API Error Handler',
    },
    extra: structuredError,
  });

  const status = error.response?.status;
  const isAuthError = [401, 403].includes(status);

  if (isAuthError) {
    // TODO :: AF-321 Update this after Session store review. Use UiStore as example
    const STORE_BASE_NAME = 'af-session-store';
    const getKey = (key: string) => `${STORE_BASE_NAME}__${key}`;
    const storeKeys = {
      isInvalidSession: getKey('isInvalidSession'),
    };

    const isInvalidSession = useStorage(storeKeys.isInvalidSession, true);

    isInvalidSession.value = true;

    const loginPath = `${APP_URL}/login`;
    const isLoginRoute = window.location.href === loginPath;
    if (isLoginRoute) return;

    const currentUrl = new URL(window.location.href);
    const currentPath = `${currentUrl.hostname}${currentUrl.pathname}`;
    const isNotLoginRoute = currentPath !== loginPath;

    if (isNotLoginRoute) window.location.href = '/login';
  }

  throw error;
}

export default errorHandler;
