import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    path: '/settings',
    redirect: '/settings/adoption/app',
    children: [
      {
        path: 'adoption',
        redirect: '/settings/adoption/app',
        children: [
          {
            path: 'app',
            name: 'settings-adoption-app',
            component: () => import('./views/Adoption/SettingsAdoptionApp.view.vue'),
          },
          {
            path: 'application',
            name: 'settings-adoption-application',
            component: () => import('./views/Adoption/SettingsAdoptionApplication.view.vue'),
          },
          {
            path: 'contract',
            name: 'settings-adoption-contract',
            component: () => import('./views/Adoption/SettingsAdoptionContract.view.vue'),
          },
          {
            path: 'email-templates',
            name: 'settings-adoption-email-templates',
            component: () => import('./views/Adoption/SettingsAdoptionEmailTemplates.view.vue'),
          },
        ],
      },
      {
        path: 'animal',
        redirect: '/settings/animal/fields',
        children: [
          {
            path: 'add-pet-form-fields',
            name: 'settings-animal-add-pet-form-fields',
            component: () => import('./views/Animal/SettingsAnimalAddPetFormFields.view.vue'),
          },
          {
            path: 'fields',
            name: 'settings-animal-fields',
            component: () => import('./views/Animal/SettingsAnimalFields.view.vue'),
          },
          {
            path: 'hold-limits',
            name: 'settings-animal-hold-limits',
            component: () => import('./views/Animal/SettingsAnimalHoldLimits.view.vue'),
          },
          {
            path: 'intake-form-fields',
            name: 'settings-animal-intake-form-fields',
            component: () => import('./views/Animal/SettingsAnimalIntakeFormFields.view.vue'),
          },
          {
            path: 'locations',
            name: 'settings-animal-locations',
            component: () => import('./views/Animal/SettingsAnimalLocations.view.vue'),
          },
          {
            path: 'statuses',
            name: 'settings-animal-statuses',
            component: () => import('./views/Animal/SettingsAnimalStatuses.view.vue'),
          },
          {
            path: 'tags',
            name: 'settings-animal-tags',
            component: () => import('./views/Animal/SettingsAnimalTags.view.vue'),
          },
        ],
      },
      {
        path: 'community-support',
        redirect: '/settings/community-support/edit-service-request-form',
        children: [
          {
            path: 'edit-service-request-form',
            name: 'settings-community-support-edit-service-request-form',
            component: () =>
              import('./views/CommunitySupport/SettingsCSEditServiceRequestForm.view.vue'),
          },
          {
            path: 'edit-vouchers',
            name: 'settings-community-support-edit-vouchers',
            component: () => import('./views/CommunitySupport/SettingsCSEditVouchers.view.vue'),
          },
          {
            path: 'note-types',
            name: 'settings-community-support-note-types',
            component: () => import('./views/CommunitySupport/SettingsCSNoteTypes.view.vue'),
          },
        ],
      },
      {
        path: 'donation',
        redirect: '/settings/donation/donation-page',
        children: [
          {
            path: 'categories',
            name: 'settings-donation-categories',
            component: () => import('./views/Donation/SettingsDonationCategories.view.vue'),
          },
          {
            path: 'donation-page',
            name: 'settings-donation-page',
            component: () => import('./views/Donation/SettingsDonationPage.view.vue'),
          },
          {
            path: 'receipt',
            name: 'settings-donation-receipt',
            component: () => import('./views/Donation/SettingsDonationReceipt.view.vue'),
          },
        ],
      },
      {
        path: 'field-service',
        redirect: '/settings/field-service/activities-and-contact',
        children: [
          {
            path: 'activities-and-contact',
            name: 'settings-field-service-activities-and-contact',
            component: () => import('./views/FieldService/SettingsFSActivitiesAndContact.view.vue'),
          },
          {
            path: 'add-animal-form',
            name: 'settings-field-service-add-animal-form',
            component: () => import('./views/FieldService/SettingsFSAddAnimalForm.view.vue'),
          },
          {
            path: 'edit-call-form',
            name: 'settings-field-service-edit-call-form',
            component: () => import('./views/FieldService/SettingsFSEditCallForm.view.vue'),
          },
          {
            path: 'edit-citations',
            name: 'settings-field-service-edit-citations',
            component: () => import('./views/FieldService/SettingsFSEditCitations.view.vue'),
          },
        ],
      },
      {
        path: 'integration',
        redirect: '/settings/integration/adoption-partners',
        children: [
          {
            path: 'adoption-partners',
            name: 'settings-integration-adoption-partners',
            component: () =>
              import('./views/Integration/SettingsIntegrationAdoptionPartners.view.vue'),
          },
          {
            path: 'microchips',
            name: 'settings-integration-microchips',
            component: () => import('./views/Integration/SettingsIntegrationMicrochips.view.vue'),
          },
        ],
      },
      {
        path: 'lost-and-found',
        redirect: '/settings/lost-and-found/add-animal-form',
        children: [
          {
            path: 'add-animal-form',
            name: 'settings-lost-and-found-add-animal-form',
            component: () => import('./views/LostAndFound/SettingsLFAddAnimalForm.view.vue'),
          },
          {
            path: 'outcome-forms',
            name: 'settings-lost-and-found-outcome-forms',
            component: () => import('./views/LostAndFound/SettingsLFOutcomeForms.view.vue'),
          },
          {
            path: 'status',
            name: 'settings-lost-and-found-status',
            component: () => import('./views/LostAndFound/SettingsLFStatus.view.vue'),
          },
        ],
      },
      {
        path: 'medical',
        redirect: '/settings/medical/diagnostic-tests',
        children: [
          {
            path: 'diagnostic-tests',
            name: 'settings-medical-diagnostic-tests',
            component: () => import('./views/Medical/SettingsMedicalDiagnosticTests.view.vue'),
          },
          {
            path: 'exam-forms',
            name: 'settings-medical-exam-forms',
            component: () => import('./views/Medical/SettingsMedicalExamForms.view.vue'),
          },
          {
            path: 'medications',
            name: 'settings-medical-medications',
            component: () => import('./views/Medical/SettingsMedicalMedications.view.vue'),
          },
          {
            path: 'surgeries',
            name: 'settings-medical-surgeries',
            component: () => import('./views/Medical/SettingsMedicalSurgeries.view.vue'),
          },
          {
            path: 'vaccinations',
            name: 'settings-medical-vaccinations',
            component: () => import('./views/Medical/SettingsMedicalVaccinations.view.vue'),
          },
        ],
      },
      {
        path: 'other-forms-and-applications',
        redirect: '/settings/other-forms-and-applications/other',
        children: [
          {
            path: 'other',
            name: 'settings-other-forms-and-applications-other',
            component: () => import('./views/OtherFormsAndApplications/SettingsOther.view.vue'),
          },
          {
            path: 'other-foster-application',
            name: 'settings-other-forms-and-applications-other-foster-application',
            component: () =>
              import('./views/OtherFormsAndApplications/SettingsOtherFosterApplication.view.vue'),
          },
          {
            path: 'volunteer-application',
            name: 'settings-other-forms-and-applications-volunteer-application',
            component: () =>
              import(
                './views/OtherFormsAndApplications/SettingsOtherVolunteerApplication.view.vue'
              ),
          },
        ],
      },
      {
        path: 'outcome',
        redirect: '/settings/outcome/adoption-contract',
        children: [
          {
            path: 'adoption-contract',
            name: 'settings-outcome-adoption-contract',
            component: () => import('./views/Outcome/SettingsOutcomeAdoptionContract.view.vue'),
          },
          {
            path: 'discounts',
            name: 'settings-outcome-discounts',
            component: () => import('./views/Outcome/SettingsOutcomeDiscounts.view.vue'),
          },
          {
            path: 'fees',
            name: 'settings-outcome-fees',
            component: () => import('./views/Outcome/SettingsOutcomeFees.view.vue'),
          },
          {
            path: 'form-fields',
            name: 'settings-outcome-form-fields',
            component: () => import('./views/Outcome/SettingsOutcomeFormFields.view.vue'),
          },
          {
            path: 'rto-contract',
            name: 'settings-outcome-rto-contract',
            component: () => import('./views/Outcome/SettingsOutcomeRtoContract.view.vue'),
          },
        ],
      },
      {
        path: 'partner',
        redirect: '/settings/partner/custom-fields',
        children: [
          {
            path: 'custom-fields',
            name: 'settings-partner-custom-fields',
            component: () => import('./views/Partner/SettingsPartnerCustomFields.view.vue'),
          },
          {
            path: 'form-fields',
            name: 'settings-partner-form-fields',
            component: () => import('./views/Partner/SettingsPartnerFormFields.view.vue'),
          },
          {
            path: 'tags',
            name: 'settings-partner-tags',
            component: () => import('./views/Partner/SettingsPartnerTags.view.vue'),
          },
        ],
      },
      {
        path: 'people',
        redirect: '/settings/people/custom-fields',
        children: [
          {
            path: 'custom-fields',
            name: 'settings-people-custom-fields',
            component: () => import('./views/People/SettingsPeopleCustomFields.view.vue'),
          },
          {
            path: 'form-fields',
            name: 'settings-people-form-fields',
            component: () => import('./views/People/SettingsPeopleFormFields.view.vue'),
          },
          {
            path: 'tags',
            name: 'settings-people-tags',
            component: () => import('./views/People/SettingsPeopleTags.view.vue'),
          },
        ],
      },
      {
        path: 'staff',
        redirect: '/settings/staff/profile',
        children: [
          {
            path: 'form-fields',
            name: 'settings-staff-form-fields',
            component: () => import('./views/Staff/StaffFormFields.view.vue'),
          },
          {
            path: 'roles-and-permissions',
            name: 'settings-staff-roles-and-permissions',
            component: () => import('./views/Staff/StaffRolesAndPermissions.view.vue'),
          },
        ],
      },
      {
        path: 'transport',
        redirect: '/settings/transport/add-animal-form',
        children: [
          {
            path: 'add-animal-form',
            name: 'settings-transport-add-animal-form',
            component: () => import('./views/Transport/SettingsTransportAddAnimalForm.view.vue'),
          },
          {
            path: 'fees',
            name: 'settings-transport-fees',
            component: () => import('./views/Transport/SettingsTransportFees.view.vue'),
          },
        ],
      },
    ],
  },
];
