<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { GenericField } from '@Types';
import type { FieldSchema } from '../../../types';

defineOptions({ inheritAttrs: false });

withDefaults(
  defineProps<{
    binary?: boolean;
    error?: string;
    field?: FieldSchema;
    link?: string;
    linkLabel?: string;
    options?: GenericField[];
  }>(),
  {
    binary: true,
  }
);

const { t } = useI18n();

const model = defineModel<string[] | string>();

function isSimpleOption(option: any): option is string {
  return typeof option === 'string' || option === null;
}

function sanitizedOptionValues(option: any, useValue: boolean = false) {
  // prettier-ignore
  return option === null ? t('common.empty') : 
         isSimpleOption(option) ? option : 
         useValue ? option.value : 
         option.name;
}
</script>

<template>
  <div class="flex flex-wrap gap-4">
    <div
      v-for="option in field?.edit?.options || options"
      :key="isSimpleOption(option) ? option : `${option.name}_${option.value}`"
      class="flex items-center"
    >
      <Checkbox
        v-model="model"
        name="checkbox"
        :inputId="isSimpleOption(option) ? option : `${option.name}_${option.value}`"
        :value="sanitizedOptionValues(option, true)"
      />

      <label
        v-if="!link"
        :class="[
          'ml-2 text-sm',
          { 'italic text-gray-500': option === null },
          { 'af-field-error': !!error },
        ]"
      >
        {{ sanitizedOptionValues(option) }}
      </label>

      <label
        v-if="link && linkLabel"
        :class="['ml-2 flex gap-1 text-sm', { 'af-field-error': !!error }]"
      >
        <span v-if="option">{{ $t(option) }}</span>

        <a
          class="af-link"
          target="_blank"
          :href="link"
        >
          {{ $t(linkLabel) }}
        </a>
      </label>
    </div>
  </div>
</template>
