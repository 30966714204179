<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  field: any;
  settings?: any;
}>();

const link = computed(() => {
  if (props.settings?.link) return props.settings?.link;

  return props.field?.[0].adopt_user_id
    ? '/shelter/adoptions/animal/requests/'
    : '/shelter/animal-profile/';
});
const animalData = computed(() => {
  if (!props.field || !props.field.length) return [];

  return props.field.map(({ animal_id, animal_name, photo, _id, id }: any) => ({
    ANIMAL_NAME: animal_name,
    ANIMAL_PHOTO: photo,
    ID: animal_id || _id,
    ID2: id,
  }));
});
</script>

<template>
  <div
    v-if="animalData.length && !settings.noPhoto"
    class="flex w-fit gap-1"
  >
    <RouterLink
      v-for="animal in animalData"
      :key="animal.ID"
      :to="link + animal.ID"
    >
      <AAvatar
        v-tooltip.top="animal.ANIMAL_NAME"
        class="min-w-10 border-2 border-solid p-1 transition-colors hover:border-brand-400"
        :src="animal.ANIMAL_PHOTO"
      />
    </RouterLink>
  </div>

  <div
    v-else-if="animalData.length && settings.noPhoto"
    class="flex w-52 max-w-fit overflow-hidden text-ellipsis whitespace-nowrap"
  >
    <RouterLink
      v-for="animal in animalData"
      class="af-link items-center overflow-hidden text-ellipsis whitespace-nowrap border-r px-2 text-center text-xs first:pl-0 last:border-none last:pr-0"
      :key="animal.ID"
      :to="link + animal.ID"
    >
      <p>{{ animal.ID2 }}</p>

      <p class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{ animal.ANIMAL_NAME }}
      </p>
    </RouterLink>
  </div>

  <span v-else> - </span>
</template>
