import { API } from '@Providers';
import type { PaymentType } from '../types';

interface UpdateCartPaymentMethodPayload {
  payment_type: PaymentType;
}

export default async function (payload: UpdateCartPaymentMethodPayload) {
  const endpoint = '/api/shelter/update-cart-payment-method';

  return API({ endpoint, method: 'post', payload });
}
