<script setup lang="ts">
import { computed } from 'vue';
import { capitalizeStr, getTagsColors } from '@Helpers';

const props = defineProps<{
  field: any;
  isAside?: boolean;
  rowData: any;
  settings: any;
}>();

const tags = computed(() => {
  const { field, settings } = props;

  if (!field) return [];
  const TAGS_IDS = field.map((tag: any) => tag.$oid);

  const VALID_TAGS = settings.specifics
    .map((field: any) => {
      if (!TAGS_IDS.includes(field?._id?.$oid)) return false;

      const { _id, color, value } = field;
      return {
        key: _id?.$oid,
        value: capitalizeStr(value),
        color: color,
      };
    })
    .filter((item: any) => !!item);

  return VALID_TAGS;
});
const concatenatedTags = computed(() => tags.value.map((tag: any) => tag.value).join(' | '));
const concatenatedGroups = computed(() =>
  props.field.map((group: any) => capitalizeStr(group)).join(' | ')
);
const backgroundColor = computed(() => {
  const { field, rowData, settings } = props;

  return settings?.render?.targetKey ? rowData[settings.render.targetKey] : getTagsColors(field);
});

function getUniqueTags(tags: string[]) {
  return [...new Set(tags)];
}
</script>

<template>
  <span
    v-if="!field || !field.length"
    class="flex flex-col items-start gap-0.5"
  >
    -
  </span>

  <div
    v-else-if="settings.specifics"
    v-tooltip.top="!isAside ? concatenatedTags : ''"
    :class="[
      isAside ? 'flex-wrap gap-1 ' : 'w-full max-w-80 gap-0.5 overflow-hidden',
      'flex items-start',
    ]"
  >
    <Tag
      v-for="{ key, value, color } in tags"
      :class="[
        color === '#F5F7F8' ? 'text-gray-700' : 'text-white',
        isAside
          ? 'w-fit text-[10px] font-semibold'
          : 'tags w-16 rounded-lg py-[3px] text-[12px] font-semibold',
      ]"
      :key="key"
      :value="value"
      :style="{
        'background-color': color || '#726EB1',
      }"
    />
  </div>

  <div
    v-else-if="Array.isArray(field) && field.length"
    v-tooltip.top="concatenatedGroups"
    class="flex w-full max-w-80 items-start gap-0.5 overflow-hidden"
  >
    <Tag
      v-for="tag in getUniqueTags(field)"
      :class="[
        'tags w-16 rounded-lg bg-slate-400 py-[3px] text-[12px] font-semibold',
        backgroundColor === '#F5F7F8' ? 'text-gray-700' : 'text-white',
      ]"
      :key="tag"
      :value="capitalizeStr(tag || '')"
    />
  </div>

  <div
    v-else
    v-tooltip.top="rowData[settings.message]"
    class="flex items-center justify-between"
  >
    <Tag
      :class="[
        'w-fit text-[10px] font-semibold',
        backgroundColor === '#F5F7F8' ? 'text-gray-700' : 'text-white',
      ]"
      :style="{
        'background-color': backgroundColor || '#8B949E',
      }"
      :value="capitalizeStr(field)"
    />

    <i
      v-if="rowData[settings.message]"
      class="pi pi-info-circle text-gray-600"
    ></i>
  </div>
</template>

<style>
.tags > span {
  @apply overflow-hidden text-ellipsis;
}
</style>
