import type { RouteRecordRaw } from 'vue-router';
import { FEATURE_FLAGS, ROLES } from '@AuthModule';

export default <RouteRecordRaw[]>[
  {
    path: 'field-services',
    name: 'field-services',
    redirect: '/shelter/field-services/cases',
    meta: {
      featureFlag: FEATURE_FLAGS.FIELD_SERVICES,
      permissions: [ROLES.FIELD_SERVICES],
    },
    children: [
      {
        path: 'cases',
        name: 'field-services-cases-table',
        component: () => import('./views/FieldServicesCases.view.vue'),
      },
      {
        path: 'map',
        name: 'field-services-cases-map',
        component: () => import('./views/FieldServicesMap.view.vue'),
      },
      {
        path: 'stats',
        name: 'field-services-cases-stats',
        component: () => import('./views/FieldServicesCaseStats.view.vue'),
      },
      {
        path: 'cases-archive',
        name: 'field-services-cases-archive',
        component: () => import('./views/FieldServicesCaseArchive.view.vue'),
      },
      {
        path: 'bite-report',
        name: 'field-services-bite-report',
        component: () => import('./views/FieldServicesBiteReport.view.vue'),
      },
      {
        path: 'citation-report',
        name: 'field-services-reporting-table',
        component: () => import('./views/FieldServicesCitationReports.view.vue'),
      },
      {
        path: 'add-case',
        name: 'field-services-add-case',
        component: () => import('./views/FieldServicesAddCase.view.vue'),
      },
      {
        path: 'case/:id',
        name: 'field-services-cases-case',
        component: () => import('./views/FieldServicesCase.view.vue'),
      },
    ],
  },
];
