<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@Stores';
import useCartStore from '../../../store';
import { CART_STEPS } from '../../../types';
import { useCartSteps } from '../../../composables';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const { isCitation, isIntake, isProduct, isReturnToOwner, outcomeCheckoutType, stepState } =
  storeToRefs(useCartStore());
const { shelterHasProducts } = storeToRefs(useSessionStore());

const {
  ADD_PEOPLE_STEP_HEADERS,
  OUTCOMES_CART_MANUAL_STEPS,
  OUTCOMES_CART_MANUAL_WITH_PRODUCTS_STEPS,
  OUTCOMES_CART_MOBILE_STEPS,
  OUTCOMES_CART_MOBILE_WITH_PRODUCTS_STEPS,
  PRODUCTS_CART_MANUAL_STEPS,
  PRODUCTS_CART_MOBILE_STEPS,
  INTAKE_CART_MANUAL_STEPS,
  INTAKE_CART_MOBILE_STEPS,
  nextStep,
  currentStep,
  previousStep,
  setOutcomeFlow,
} = useCartSteps();

const { ADD_PEOPLE, CHECKOUT_METHOD, MOBILE_CHECKOUT, PAYMENT_METHOD } = CART_STEPS;

const checkoutType = ref<CART_STEPS | null>(null);
const showErrorMessage = ref(false);

const isManualCheckout = computed(
  () => checkoutType.value && [ADD_PEOPLE, PAYMENT_METHOD].includes(checkoutType.value)
);
const nextStepIcon = computed(() => nextStep.value.step?.header.icon || 'faMobile');

onMounted(() => (currentStep.value = CHECKOUT_METHOD));

watch(checkoutType, () => onCheckoutTypeUpdate());

// HELPERS
function getPeopleStepType(): keyof typeof ADD_PEOPLE_STEP_HEADERS | undefined {
  // prettier-ignore
  return isCitation.value ? 'citation' :
         isReturnToOwner.value ? 'guardian' :
         isProduct.value ? 'product' :
         isIntake.value ? undefined :
         'adopter';
}

// EVENTS
function onCheckoutTypeUpdate() {
  showErrorMessage.value = false;

  // prettier-ignore
  const manualFlow = isProduct.value ? PRODUCTS_CART_MANUAL_STEPS :
        isIntake.value ? INTAKE_CART_MANUAL_STEPS :
        shelterHasProducts.value ? OUTCOMES_CART_MANUAL_WITH_PRODUCTS_STEPS :
        OUTCOMES_CART_MANUAL_STEPS;

  // prettier-ignore
  const mobileFlow = isProduct.value ? PRODUCTS_CART_MOBILE_STEPS :
        isIntake.value ? INTAKE_CART_MOBILE_STEPS :
        shelterHasProducts.value ? OUTCOMES_CART_MOBILE_WITH_PRODUCTS_STEPS : 
        OUTCOMES_CART_MOBILE_STEPS;

  const activeFlow = isManualCheckout.value ? manualFlow : mobileFlow;
  const peopleStepType = getPeopleStepType();

  setOutcomeFlow(activeFlow, peopleStepType);
}

function onNextStep() {
  if (!checkoutType.value) return (showErrorMessage.value = true);

  stepState.value![CART_STEPS.CHECKOUT_METHOD] = checkoutType.value;
  outcomeCheckoutType.value = checkoutType.value;
  emits('onStepUpdate', checkoutType.value);
}
</script>

<template>
  <div class="flex h-fit flex-col items-center justify-center gap-4">
    <div class="flex flex-1 flex-wrap justify-center gap-4 sm:justify-start">
      <div class="w-44">
        <Button
          :class="[
            'w-full !border !border-solid border-brand-500 transition-colors',
            checkoutType !== MOBILE_CHECKOUT ? 'af-button-success-outlined' : 'af-button-success',
          ]"
          :outlined="checkoutType !== MOBILE_CHECKOUT"
          @click="checkoutType = MOBILE_CHECKOUT"
        >
          <div class="flex flex-col gap-3 py-4">
            <FaIcon
              class="text-3xl"
              :icon="$icons.faMobile"
            />

            <p>
              {{ $t('cart.checkoutSelector.manual.title') }}
            </p>

            <p class="text-center text-xs">
              {{ $t('cart.checkoutSelector.manual.label') }}
            </p>
          </div>
        </Button>
      </div>

      <div class="w-44">
        <Button
          :class="[
            'w-full !border !border-solid border-brand-500 transition-colors',
            isManualCheckout ? 'af-button-success' : 'af-button-success-outlined',
          ]"
          :outlined="!isManualCheckout"
          @click="checkoutType = isIntake ? PAYMENT_METHOD : ADD_PEOPLE"
        >
          <div class="flex flex-col gap-3 py-4">
            <FaIcon
              class="text-3xl"
              :icon="$icons.faUser"
            />

            <p>
              {{ $t('cart.checkoutSelector.mobile.title') }}
            </p>

            <p class="text-center text-xs">
              {{ $t('cart.checkoutSelector.mobile.label') }}
            </p>
          </div>
        </Button>
      </div>
    </div>

    <footer class="w-full flex-shrink">
      <Message
        v-if="showErrorMessage"
        pt:text="text-sm"
        severity="error"
      >
        {{ $t('cart.checkoutSelector.error') }}
      </Message>

      <div class="justfiy-between flex">
        <Button
          v-if="previousStep"
          outlined
          class="af-button af-button-success-outlined w-fit"
          icon="pi pi-arrow-left"
          :label="$t('common.back')"
          @click="emits('onStepUpdate', previousStep)"
        />

        <Button
          class="af-button af-button-success ml-auto"
          iconPos="right"
          :disabled="showErrorMessage"
          :label="$t('common.nextStep')"
          @click="onNextStep"
        >
          <template #icon>
            <FaIcon
              class="mr-2"
              :icon="$icons[nextStepIcon]"
            />
          </template>
        </Button>
      </div>
    </footer>
  </div>
</template>
