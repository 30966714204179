<script setup lang="ts">
import { computed } from 'vue';
import type { FieldSchema } from '../../../types';
import OPTIONS from './species.constant';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  field?: FieldSchema;
}>();

const model = defineModel<string>();

const specieOptionClasses = computed(
  () =>
    'grid cursor-pointer place-content-center rounded-md h-9 border px-4 py-1.5 active:bg-brand-600 hover:bg-brand-500 transition-colors hover:text-white'
);
const SPECIES = computed(() =>
  props?.field?.edit?.options?.length ? props?.field?.edit?.options : OPTIONS
);
</script>

<template>
  <div class="flex flex-wrap gap-2">
    <div
      v-for="{ name, value } in SPECIES"
      :key="`${name}_${value}`"
      class="flex items-center"
    >
      <label
        :for="`${name}_${value}`"
        :class="[
          specieOptionClasses,
          {
            'af-button border bg-brand-500 font-semibold text-white': model === value,
          },
        ]"
      >
        <RadioButton
          v-model="model"
          class="hidden"
          name="specie"
          :inputId="`${name}_${value}`"
          :value="value"
        />

        <label
          class="cursor-pointer text-sm"
          :for="`${name}_${value}`"
        >
          {{ $t(name) }}
        </label>
      </label>
    </div>
  </div>
</template>
