//@ts-nocheck
import moment from 'moment';
import { API } from '@Providers';
import type {
  TAddAnimalWeightRequest,
  TAnimalsListType,
  TDeleteAnimalWeightRequest,
  TEditAnimalWeightRequest,
  TShelterStatus,
} from './types';

export const initCSRF = async () => API({endpoint: '/api/sanctum/csrf-cookie'});

export const login = async (email: string, password: string) => {
  await initCSRF();

  const payload = { email, password };

  return API({ endpoint: '/api/login', method: 'post', payload });
};

export const loginAs = async (id: string) => API({endpoint: `/api/loginas/${id}`});

export const backToAdminSession = async (id: string) => API({endpoint: `/api/backtoadmin/${id}`});

export const logout = async () => API({endpoint: '/api/logout', method: 'post'});

export const sendPasswordResetEmail = async (email: string) => API({endpoint: '/api/password/reset-email', method: 'post', payload: { email }});

export const getProfile = async () => API({endpoint: '/api/shelter/profile/get'});

export const getShelters = async (status: TShelterStatus = 'active') =>
  API({endpoint: `/api/admin/get-shelters/${status}`});

export const getFormattedShelters = async (status: TShelterStatus = 'active') =>
  getShelters(status).then((response) => {
    const data = response.shelters;
    const stats = response.stats;

    const transformed = {
      pagination: {
        total: data.length,
        per_page: 50,
        current_page: 1,
        first_page: '',
        last_page: '',
        next_page_url: '',
        prev_page_url: '',
        from: '',
        to: '',
      },
      shelters: [],
    };

    for (let i = 0; i < data.length; i++) {
      const obj = {};
      for (const key in data[i]) {
        // eslint-disable-next-line
        if (data[i].hasOwnProperty(key)) {
          obj['id'] = data[i]._id.$oid;
          obj['create_date'] = moment(
            new Date(parseInt(data[i]._id.$oid.substring(0, 8), 16) * 1000)
          ).format('YYYY-MM-DD');
          obj['shelter'] = data[i].shelter;
          obj['contact'] = data[i].contact;
          obj['email'] = data[i].email;
          obj['location'] = data[i].city + ', ' + data[i].state;

          obj['in_care'] = data[i].in_care ? data[i].in_care : 0;
          obj['in_foster'] = data[i].in_foster ? data[i].in_foster : 0;
          obj['not_in_care'] = data[i].not_in_care ? data[i].not_in_care : 0;
          obj['lost'] = data[i].lost ? data[i].lost : 0;
          obj['found'] = data[i].lost ? data[i].found : 0;
          obj['total'] = data[i].total ? data[i].total : 0;
          obj['people'] = data[i].total ? data[i].people : 0;
          obj['adopters'] = data[i].total ? data[i].adopters : 0;
          obj['fosters'] = data[i].total ? data[i].fosters : 0;
          obj['partners'] = data[i].total ? data[i].partners : 0;
        }
      }
      transformed.shelters.push(obj);
    }

    return {
      shelters: transformed.shelters,
      stats,
    };
  });

export const archiveShelter = (payload: any) => {
  return API({endpoint: '/api/admin/shelter/archive', method: 'post', payload });
};

export const getAnimalsTableFields = async () =>
  API({ endpoint: '/api/shelter/animals/get-animals-in-care-table-fields' });

export const updateTableSettings = async (payload: any) =>
  API({endpoint: '/api/shelter/profile/update-table-settings', method: 'post', payload });

export const getAnimalsTableDownloadData = async (listType: TAnimalsListType, queryStr: string) =>
  API({endpoint: `/api/shelter/animals/get-animals-in-care/${listType}/download/?${queryStr}`});

export const getAnimalProfile = async (animalId: string) =>
  API({endpoint: `/api/shelter/animal/get-animal-profile/${animalId}`});

export const getKennels = () => API({endpoint: '/api/shelter/kennels/get-all-open-kennels'});

export const moveAnimalToKennel = (payload: any) =>
  API({endpoint: '/api/shelter/animal/change-visual-kennel', method: 'post', payload });

export const getFieldServicesFields = () =>
  API({endpoint: '/api/shelter/field-service-v2/get-field-service-fields'});

export const getFieldServicesMapFields = () =>
  API({endpoint: '/api/shelter/field-service-v2/get-map-fields'});

export const getFieldServicesMapData = (start: string, end: string, query: string) =>
  API({endpoint: `/api/shelter/field-service-v2/call/map/${start}/${end}?${query}`});

export const removeAnimalFromKennel = (payload: any) =>
  API({endpoint: '/api/shelter/animal/remove-from-visual-kennel/', method: 'post', payload });

export const updateAnimalStatus = (payload: any) =>
  API({endpoint: '/api/shelter/animal/update-animal-status', method: 'post', payload });

export const updateAnimalLocation = (payload: any) =>
  API({endpoint: '/api/shelter/animal/update-animal-location', method: 'post', payload });

export const addAnimalWeight = (payload: TAddAnimalWeightRequest) =>
  API({endpoint: '/api/shelter/medical/add-weight', method: 'post', payload });

export const editAnimalWeight = (payload: TEditAnimalWeightRequest) =>
  API({endpoint: '/api/shelter/medical/edit-weight', method: 'post', payload });

export const deleteAnimalWeight = (payload: TDeleteAnimalWeightRequest) =>
  API({endpoint: '/api/shelter/medical/delete-weight', method: 'post', payload });

export const deleteAnimal = (animalId: string, payload: any) =>
  API({endpoint: '/api/shelter/animal/delete/${animalId}', method: 'post', payload });