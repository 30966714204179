<script setup lang="ts">
import { FIELDS_COMPONENTS } from '../../../constants';
import type { FieldSchema } from '../../../types';

import AFieldOpenModal from '../AFieldOpenModal/AFieldOpenModal.vue';
import AFieldRenderDefault from '../AFieldRenderDefault/AFieldRenderDefault.vue';
import AFieldRenderLink from '../AFieldRenderLink/AFieldRenderLink.vue';
import AFieldRenderProfileInfo from '../AFieldRenderProfileInfo/AFieldRenderProfileInfo.vue';
import AFieldRenderTag from '../AFieldRenderTag/AFieldRenderTag.vue';
import AFieldGMap from '../AFieldGMap/AFieldGMap.vue';
import AFieldPrintButton from '../AFieldPrintButton/AFieldPrintButton.vue';

defineProps<{
  field: FieldSchema;
  model: any;
}>();

const { GMAP, LINK, OPEN_MODAL, PROFILE_INFO, TAG, PRINT } = FIELDS_COMPONENTS;

function componentSwitcher(component?: any) {
  if (!component) return AFieldRenderDefault;

  //prettier-ignore
  switch (component) {
    case TAG: return AFieldRenderTag;
    case LINK: return AFieldRenderLink;
    case PROFILE_INFO: return AFieldRenderProfileInfo;
    case OPEN_MODAL: return AFieldOpenModal;
    case GMAP: return AFieldGMap;
    case PRINT: return AFieldPrintButton;
    default: return AFieldRenderDefault;
  }
}
</script>

<template>
  <component
    :field
    :model
    :is="componentSwitcher(field?.render?.component)"
  />
</template>
