<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { GenericField } from '@Types';
import type { FieldSchema } from '../../../types';

export interface HoldStatus {
  days: number;
  id: string;
  name: string;
  position: number;
  value: string;
}

const props = defineProps<{
  error?: string;
  field?: FieldSchema;
  label?: string;
  options?: HoldStatus[];
}>();

const { t } = useI18n();

const model = defineModel<any>();

const holdModel = ref();
const customModel = ref();
const minDate = ref(new Date());
const radioModel = ref<'holdStatus' | 'customDate'>('holdStatus');

const options = computed((): HoldStatus | any[] => {
  const option = props?.field?.edit?.options || props?.options || [];

  return option.slice().sort((a: any, b: any) => a.position - b.position);
});
const radioOptions = computed((): GenericField[] => [
  {
    name: t('components.holdStatus.holdStatus'),
    value: 'holdStatus',
  },
  {
    name: t('components.holdStatus.customDate'),
    value: 'customDate',
  },
]);

watch(customModel, () => setCustomDate(customModel.value));
watch(radioModel, () => resetModels());

onMounted(() => initModels());

function resetModels() {
  holdModel.value = undefined;
  model.value = undefined;
}

function initModels() {
  if (!model.value) return;
  if (model.value.radioModel !== 'customDate') return (holdModel.value = model.value);

  radioModel.value = model.value.radioModel;
  customModel.value = model.value.expirationDate;
}

function setCustomDate(date: any) {
  if (radioModel.value === 'holdStatus') return (model.value = date);

  model.value = new Date(date);

  const expirationDate = date.toISOString();
  const customDate = formatCustomHoldExpirationDate(date);

  return (model.value = { customDate, expirationDate });
}

// HELPERS
function formatCustomHoldExpirationDate(date: Date): string {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `Custom Hold Expiration Date - ${month}-${day}-${year}`;
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <AFieldRadio
      v-model="radioModel"
      :options="radioOptions"
      :error
    />

    <AFieldDropdown
      v-if="radioModel === 'holdStatus'"
      v-model="holdModel"
      :error
      :options
      optionLabel="label"
      @change="setCustomDate(holdModel)"
    />

    <MFieldDatePicker
      v-else
      v-model="customModel"
      :error
      :minDate="minDate"
    />
  </div>
</template>
