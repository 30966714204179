<script setup lang="ts">
const emits = defineEmits<{
  onEmptySectionClick: [];
}>();

withDefaults(
  defineProps<{
    buttonIcon?: string;
    buttonLabel?: string;
    icon?: string;
    label?: string;
    loading?: boolean;
  }>(),
  {
    buttonIcon: 'pi pi-plus',
    buttonLabel: 'common.add',
    icon: 'pi pi-inbox',
    label: 'components.dataTable.emptyMessages.emptyMessage',
  }
);
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-3 text-center text-slate-400">
    <FaIcon
      v-if="$icons[icon]"
      class="mb-2 text-center text-2xl"
      :icon="$icons[icon]"
    />

    <span :class="[icon, 'text-2xl']"></span>

    <p>{{ $t(label) }}</p>

    <Button
      v-if="!$slots.customAction"
      class="af-button af-button-success h-9"
      icon="pi pi-plus"
      :label="$t(buttonLabel)"
      @click="emits('onEmptySectionClick')"
    />

    <slot name="customAction" />
  </div>
</template>
