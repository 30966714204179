<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import type { AddProductToCartRequestPayload } from '../../../types';
import { useAToast } from '@Atoms';
import {
  AddProductToCartService,
  DeleteCartItem,
  DeleteCartSessionService,
  DeleteCitationFromCart,
  DeleteProductFromCartService,
  GetCartSessionService,
} from '../../../services';
import useCartStore from '../../../store';

const props = withDefaults(
  defineProps<{
    cartData: any;
    hasDeleteItem?: boolean;
    outcomeCompleted?: boolean;
  }>(),
  {
    hasDeleteItem: true,
  }
);

const cartStore = storeToRefs(useCartStore());
const { loading } = cartStore;
const { getAnimals, getCartLength, getCitations, getProducts, getServices, getType } =
  useCartStore();

const { notify, TOAST_TYPES } = useAToast();
const { t } = useI18n();

const TOAST_ERROR = {
  message: 'cart.toast.removeItem.error.message',
  title: 'cart.toast.removeItem.error.title',
  type: TOAST_TYPES.INFO,
};

const TOAST_SUCCESS = {
  title: 'cart.toast.removeItem.success.title',
  type: TOAST_TYPES.INFO,
};

const animals = computed(() =>
  props.cartData ? getAnimals(props.cartData) : cartStore.animals.value
);

const cartLength = computed(() =>
  props.cartData ? getCartLength(props.cartData) : cartStore.cartLength.value
);

const citations = computed(() =>
  props.cartData ? getCitations(props.cartData) : cartStore.citations.value
);

const products = computed(() =>
  props.cartData ? getProducts(props.cartData) : cartStore.products.value
);

const services = computed(() =>
  props.cartData ? getServices(props.cartData) : cartStore.services.value
);

const type = computed(() => (props.cartData ? getType(props.cartData) : cartStore.type.value));

const groupData = computed(() => {
  const data = [];

  if (animals.value.length) {
    data.push({
      header: 'cart.title.animals',
      length: animals.value.length,
      type: 'animal',
      items: animals.value,
    });
  }

  if (citations.value.length) {
    data.push({
      header: t('cart.title.citation', 2),
      length: citations.value.length,
      type: 'citation',
      items: citations.value,
    });
  }

  if (products.value.length) {
    data.push({
      header: 'cart.title.products',
      length: products.value.length,
      type: 'product',
      items: products.value,
    });
  }

  if (services.value.length) {
    data.push({
      header: 'cart.title.services',
      length: services.value.length,
      type: 'product',
      items: services.value,
    });
  }

  return data;
});

const cartItemClasses = computed(() => [
  'grid gap-4',
  'grid-cols-1 sm:grid-cols-[100px_auto]',
  props.outcomeCompleted ? '' : 'sm:grid-cols-[100px_auto]',
  type.value === 'citation' ? 'items-center' : 'items-baseline',
]);

function showDeleteButton(type: string) {
  if (!props.hasDeleteItem) return false;

  if (products.value.length && type === 'animal') {
    return products.value.length && animals.value.length !== 1;
  }

  return props.hasDeleteItem;
}

function onRemoveCartItem(index: number) {
  if (!props.hasDeleteItem) return;

  loading.value = true;

  DeleteCartItem(index)
    .then(() =>
      cartLength.value === 1 ? onDeleteSession() : notify(TOAST_SUCCESS) && onRefreshCartSession()
    )
    .catch(() => notify(TOAST_ERROR) && (loading.value = false));
}

// TODO - AF-431 Improve interface for a citation
function onRemoveCitation(citation: any) {
  if (!props.hasDeleteItem) return;

  loading.value = true;

  DeleteCitationFromCart(citation)
    .then(() =>
      cartLength.value === 1 ? onDeleteSession() : notify(TOAST_SUCCESS) && onRefreshCartSession()
    )
    .catch(() => notify(TOAST_ERROR) && (loading.value = false));
}

function onRemoveProduct(product: any) {
  if (!props.hasDeleteItem) return;

  loading.value = true;

  DeleteProductFromCartService(product)
    .then(() =>
      cartLength.value === 1 ? onDeleteSession() : notify(TOAST_SUCCESS) && onRefreshCartSession()
    )
    .catch(() => notify(TOAST_ERROR) && (loading.value = false));
}

function onUpdateQuantity(quantity: number, cartItem: any) {
  const TOAST_SUCCESS = {
    title: 'cart.toast.addItem.success.title',
    type: TOAST_TYPES.SUCCESS,
  };

  const PAYLOAD: AddProductToCartRequestPayload = {
    cart_type: 'product',
    products: [
      {
        ...cartItem,
        quantity,
      },
    ],
  };

  AddProductToCartService(PAYLOAD)
    .then(() => notify(TOAST_SUCCESS) && onRefreshCartSession())
    .catch(() => notify(TOAST_ERROR));
}

function onDeleteSession() {
  const TOAST_ERROR = {
    message: 'cart.toast.emptyCart.error.message',
    title: 'cart.toast.emptyCart.error.title',
    type: TOAST_TYPES.INFO,
  };

  DeleteCartSessionService()
    .then(() => useCartStore().resetCart())
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}

function onRefreshCartSession() {
  GetCartSessionService()
    .then((data) => (cartStore.cartData.value = data))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div
    v-for="{ header, length, items, type } in groupData"
    class="mt-4 first:mt-0"
    :key="header"
  >
    <!-- TODO AF-431 - Review this when we handle citations with other outomes types -->
    <div v-if="!citations.length">
      <div class="flex items-center gap-4">
        <h3 class="block min-w-28 font-semibold">{{ $t(header) }} ({{ length }})</h3>

        <Divider
          class="before:border-t-2"
          type="dashed"
        />
      </div>
    </div>

    <div
      v-for="(cartItem, index) in items"
      class="mt-4"
      :key="cartItem._id"
    >
      <div :class="cartItemClasses">
        <ACartItemDetails
          :cartItem
          :outcomeCompleted
          :cartItemType="type"
          :hasDelete="showDeleteButton(type)"
          @onRemoveCartItem="onRemoveCartItem(index)"
          @onRemoveCitation="onRemoveCitation(cartItem)"
          @onRemoveProduct="onRemoveProduct(cartItem)"
          @onUpdateQuantity="onUpdateQuantity($event, cartItem)"
        />

        <ACartItemCost
          :cartData
          :cartItemType="type"
          :item="cartItem"
        />
      </div>

      <div
        v-if="cartData?.intake_waiver?._id && cartData?.mobile_checkout"
        class="flex justify-end"
      >
        <ACartButtonWaivers
          :cartItem
          :cartData
          :cartId="props.cartData._id"
        />
      </div>

      <Divider
        v-if="index + 1 < items.length"
        class="mt-4"
      />
    </div>
  </div>
</template>
