<script setup lang="ts">
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@Stores';
import { useAToast } from '@Atoms';

const { isLogged } = storeToRefs(useSessionStore());
const { state, isOnHover } = useAToast();

const ROUTE = useRoute();
const ROUTER = useRouter();

watch(isLogged, () => checkUserSession());

function checkUserSession() {
  if (isLogged.value) return;
  if (ROUTE.path === '/login') return;

  ROUTER.push('/login');
}
</script>

<template>
  <RouterView />

  <AToast
    v-bind="state"
    @mouseenter="isOnHover = true"
    @mouseleave="isOnHover = false"
    @onDismiss="state.isOpen = false"
  />
</template>
