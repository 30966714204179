<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useAToast } from '@Atoms';
import { scrollToTop } from '@Helpers';
import { useForm } from '@FormModule';
import getSanitizedPayload from './getSanitizedPayload';
import useCartStore from '../../../../store';
import useFieldsOutcomeDate from './useFieldsOutcomeDate';
import {
  AddOutcomeToCart,
  CompleteOutcome,
  GetOutcomeFormByName,
  GetWaiversService,
} from '../../../../services';
import { CART_STEPS } from '../../../../types';
import { useCartSteps } from '../../../../composables';
import useFields from './useFields';

const emits = defineEmits<{
  onStepUpdate: [CART_STEPS];
}>();

const {
  animals,
  cartData,
  cartLength,
  completeOutcomeRoute,
  loading,
  outcomeForm,
  outcomeInfoActiveStep,
  productsLength,
  servicesLength,
  rtoFees,
  stepState,
} = storeToRefs(useCartStore());
const { outcomeCompletedCartReset } = useCartStore();

const { currentStep, nextStep } = useCartSteps();
const { fieldsValidation } = useForm();
const { notify, TOAST_TYPES } = useAToast();
const { push } = useRouter();
const {
  currentDataOnStepperId,
  currentSchemaModels,
  fieldsSchema,
  formError,
  sourceData,
  setForm,
  waivers,
} = useFields();
const {
  currentSchemaModels: currentSchemaModelsOutcomeDate,
  fieldsSchema: fieldsSchemaOutcomeDate,
  setForm: setFormOutcomeDate,
} = useFieldsOutcomeDate();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const outcomeSchemaModels = ref<any[]>([]);

const notProductsAndServicesCartLength = computed(
  () => cartLength.value - productsLength.value - servicesLength.value
);

const nextStepButton = computed(() => ({
  label: nextStep.value.nextIsFinalStep ? 'common.complete' : 'common.nextStep',
  icon: nextStep.value.step ? nextStep.value.step.header.icon : '',
}));

onMounted(async () => await onInitPage());

// TODO AF-431 We may need to create a switcher for other data sources
watch(outcomeInfoActiveStep, () => setCurrentDataStepperId());

// HELPERS
function setCurrentDataStepperId() {
  currentDataOnStepperId.value = animals.value[outcomeInfoActiveStep.value]._id;
}

async function setOutcomeForms() {
  if (!cartData.value.outcome_type) return;
  loading.value = true;

  setCurrentDataStepperId();

  await GetWaiversService('outcome').then((waiver: any) => (waivers.value = waiver));
  await GetOutcomeFormByName(cartData.value.outcome_type).then((data) => {
    outcomeForm.value = data;

    const outcomeFormNotReady = !outcomeForm.value?.form?.fields;
    if (outcomeFormNotReady) return setOutcomeForms();

    // NOTE: We use this rtoFees state to repopulate the select RTO Fees field after hard refresh
    const hasRtoFees = outcomeForm.value?.rto_fees.length;
    if (hasRtoFees) rtoFees.value = outcomeForm.value.rto_fees;

    sourceData.value = {
      rawFields: outcomeForm.value?.form.fields,
      additional_fees_options: outcomeForm.value?.additional_fees,
      partners: outcomeForm.value?.partners,
      rto_fees: outcomeForm.value?.rto_fees,
    };
  });

  // TODO :: AF-587 We need to have a outcome_date per animal and not per outcome
  const oldestIntakeDate = animals.value.reduce((oldest: any, animal: any) => {
    return !oldest || new Date(animal.intake_date) < new Date(oldest) ? animal.intake_date : oldest;
  }, null);

  setFormOutcomeDate(oldestIntakeDate);
  setForm();

  loading.value = false;
}

function validateForms() {
  const hasErrors = fieldsValidation(fieldsSchema.value, currentSchemaModels.value);
  const noOutcomeDate = fieldsValidation(
    fieldsSchemaOutcomeDate.value,
    currentSchemaModelsOutcomeDate.value
  );

  if (hasErrors || noOutcomeDate) return (formError.value = true);

  outcomeSchemaModels.value[outcomeInfoActiveStep.value] = currentSchemaModels.value;

  formError.value = false;
}

// EVENTS
async function onInitPage() {
  outcomeInfoActiveStep.value = 0;
  currentStep.value = CART_STEPS.OUTCOME_INFO;
  await setOutcomeForms();
}

function onNextAnimal(action: Function) {
  validateForms();

  if (formError.value) return;

  scrollToTop();
  action();
}

function onNextStep() {
  validateForms();
  if (formError.value) return;

  loading.value = true;

  const [outcomeDate] = currentSchemaModelsOutcomeDate.value;
  // NOTE: this is the format being used at adopt.animalsfirst
  const adoptAppOutcomeDate = moment(outcomeDate.value).format('YYYY-MM-DD');

  const payload = animals.value.map((animal: any, index: number) => {
    const outcome = getSanitizedPayload(outcomeSchemaModels.value[index]);

    // NOTE: Removing rto_fees. Not used in the backend.
    delete (outcome as Partial<typeof outcome>).rto_fees;
    const { waivers, ...outcomeData } = outcome;

    return {
      ...animal,
      outcome_date: adoptAppOutcomeDate,
      waivers,
      outcome: {
        ...outcomeData,
        animal_id: animal._id,
        animal_name: animal.animal_name,
        form_id: outcomeForm.value.form._id.$oid,
        form_title: outcomeForm.value.form.form_title,
        outcome_type: cartData.value.outcome_type,
        outcome_date: adoptAppOutcomeDate,
        partner_name: outcomeForm.value.partners.find(
          (field: any) => field.id === outcome.partner_name
        ),
      },
    };
  });

  AddOutcomeToCart(payload)
    .then((data) => {
      cartData.value = data;

      if (nextStep.value.nextIsFinalStep) return onCompleteOutcome(data);

      emits('onStepUpdate', nextStep.value.step!.name);
      stepState.value![CART_STEPS.OUTCOME_INFO] = payload;
      loading.value = false;
    })
    .catch(() => notify(TOAST_ERROR) && (loading.value = false));
}

function onCompleteOutcome(payload: any) {
  CompleteOutcome(payload)
    .then(() => push(completeOutcomeRoute.value).then(() => outcomeCompletedCartReset()))
    .catch(() => notify(TOAST_ERROR))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex h-full max-w-[700px] flex-col gap-4">
    <FormTemplate
      class="w-full"
      formError=""
      :schema="fieldsSchemaOutcomeDate"
    />

    <div class="flex items-center gap-4">
      <h3 class="block font-semibold">{{ $t('common.details') }}</h3>

      <Divider
        class="before:border-t-2"
        type="dashed"
      />
    </div>

    <Stepper
      v-model:activeStep="outcomeInfoActiveStep"
      linear
      pt:header="px-0 flex-[0]"
      pt:nav="justify-start"
      pt:panelContainer="px-0"
    >
      <StepperPanel
        v-for="animal in animals"
        :key="animal._id"
      >
        <template #header="{ index, clickCallback }">
          <div class="flex w-full items-center justify-between gap-2">
            <div class="flex items-center gap-2">
              <Button
                rounded
                class="grid !h-8 !w-5 place-content-center border-none bg-brand-500 text-sm"
                :label="(index + 1).toString()"
                @click="clickCallback"
              />

              <Tag
                severity="warning"
                :value="animal.animal_name"
                @click="clickCallback"
              />
            </div>
          </div>
        </template>

        <template #content="{ index, nextCallback, prevCallback }">
          <div class="scrollToTop flex flex-col gap-4">
            <div
              class="surface-border border-round surface-ground justify-content-center align-items-center flex flex-auto border-2 border-dashed p-4 font-medium"
            >
              <FormTemplate
                class="w-full"
                :formError
                :schema="fieldsSchema"
              />
            </div>

            <AFormError :formError />
          </div>

          <div class="flex py-4">
            <Button
              v-if="notProductsAndServicesCartLength > 1 && index > 0"
              outlined
              class="af-button af-button-success-outlined mr-auto"
              icon="pi pi-arrow-left"
              :label="$t('common.previous')"
              @click="prevCallback"
            />

            <Button
              v-if="index + 1 < notProductsAndServicesCartLength"
              outlined
              class="af-button af-button-success-outlined ml-auto"
              icon="pi pi-arrow-right"
              :label="$t('common.next')"
              @click="onNextAnimal(nextCallback)"
            />

            <Button
              v-else
              class="af-button af-button-success ml-auto"
              iconPos="right"
              :label="$t(nextStepButton.label)"
              @click="onNextStep"
            >
              <template #icon>
                <FaIcon
                  v-if="nextStepButton.icon"
                  class="mr-2"
                  :icon="$icons[nextStepButton.icon]"
                />
              </template>
            </Button>
          </div>
        </template>
      </StepperPanel>
    </Stepper>
  </div>
</template>
