<script setup lang="ts">
/* TODO - AF-541 :: Probably outdated now */
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import useCartStore from '../../../store';
import { CART_STEPS } from '../../../types';
import { OUTCOMES_WITH_CART } from '../../../constants';
import { useCartSteps } from '../../../composables';

const { animals, cartData, hasCart, isCitation, loading, outcomeCompleted, outcomeType, people } =
  storeToRefs(useCartStore());
const { fetchCompletedOutcome } = useCartStore();

const { currentStep } = useCartSteps();

onMounted(() => (currentStep.value = CART_STEPS.SUMMARY) && onRefreshCartSession());

async function onRefreshCartSession() {
  loading.value = false;

  await fetchCompletedOutcome(cartData.value.cart_id.$oid).finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex max-w-[400px] flex-col gap-4">
    <APeopleSummary v-if="people && OUTCOMES_WITH_CART.includes(outcomeType)" />

    <div v-if="isCitation">
      <RouterLink
        class="flex items-center justify-end gap-1"
        :to="`shelter/people/profile/${people.person_id}`"
      >
        <span class="text-sm font-semibold"> {{ $t('cart.label.payor') }}: </span>

        <span class="font-semibold text-brand-400">
          {{ people.first_name }} {{ people.last_name }}
        </span>
      </RouterLink>
    </div>

    <div
      v-if="animals.length"
      class="flex items-center gap-4"
    >
      <h3 class="block text-nowrap font-semibold">
        {{ $t('common.animal', 2) }} ({{ animals.length }})
      </h3>

      <Divider
        class="before:border-t-2"
        type="dashed"
      />
    </div>

    <MCartItemSummary
      :outcomeCompleted
      :hasDeleteItem="false"
    />

    <OCartItensTotal
      v-if="hasCart || isCitation"
      class="mt-4"
    />
  </div>
</template>
