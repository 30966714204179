<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue';
import FileUpload from 'primevue/fileupload';
import { useAToast } from '@Atoms';
import type { FieldSchema } from '../../../types';

const emits = defineEmits<{
  'update:modelValue': [File[]];
}>();

const props = withDefaults(
  defineProps<{
    accept?: string;
    error?: string;
    field?: FieldSchema;
    fileLimit?: number;
    maxFileSize?: number;
    imageOnly?: boolean;
    maxLength?: number;
    minLength?: number;
    modelValue: File[] | string[] | string;
    multiple?: boolean;
    required?: boolean;
  }>(),
  {
    accept: 'image/*',
    imageOnly: true,
    maxFileSize: 10000000,
    multiple: false,
  }
);

const { notify, TOAST_TYPES } = useAToast();

const fileUpload = useTemplateRef<FileUpload>('fileUpload');

const _model = ref<File[]>([]);
const files = ref([]);
const overloadFile = ref<boolean>(false);

const buttonClass = computed(() => [
  'af-button w-full bg-transparent text-sm outline-none',
  props.error
    ? 'border-red-500 text-red-500 hover:bg-red-100 active:bg-red-200'
    : 'border-slate-300 text-gray-500 hover:bg-gray-100 active:bg-gray-200',
]);
function checkFileSize(event: any) {
  if (event.files.length !== 0) return (overloadFile.value = false);

  overloadFile.value = true;
  setTimeout(() => (overloadFile.value = false), 5000);
}

const iconClass = computed(() => {
  return props.error ? 'pi pi-plus text-red-500' : 'pi pi-plus';
});

const isMultiple = computed(() => props?.field?.settings?.multiple ?? props.multiple);

const settings = computed(() => props?.field?.settings);
const hasImageOnlySettings = computed(() =>
  settings.value?.imageOnly !== undefined ? settings.value.imageOnly : props.imageOnly
);

watch(_model, (newSelection) => emits('update:modelValue', newSelection));
watch(
  () => props.modelValue,
  () => clearFiles()
);

function onSelectedFiles(event: any) {
  checkFileSize(event);
  files.value = event.files;

  const TOAST_INFO = {
    message: 'components.fileUploader.toast.message.error',
    title: 'components.fileUploader.toast.title.error',
    type: TOAST_TYPES.ERROR,
  };

  if (!files.value.length) return notify(TOAST_INFO);

  _model.value = files.value;
}

function onRemoveFile(id: number, removeFileCallback: Function) {
  removeFileCallback(id);
  _model.value = _model.value.filter((_, index) => index !== id);
}

function clearFiles() {
  if (props.modelValue.length) return;

  (fileUpload?.value as any)?.clear();
}
</script>

<template>
  <FileUpload
    ref="fileUpload"
    name="files[]"
    pt:content="overflow-y-auto max-h-64"
    pt:header="p-2"
    pt:buttonbar="pb-0"
    :required
    :accept="
      hasImageOnlySettings
        ? 'image/*'
        : '.gif,.jpg,.jpeg,.png,.webp,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.heic'
    "
    :fileLimit="settings?.fileLimit || fileLimit"
    :maxFileSize="settings?.maxFileSize || maxFileSize"
    :multiple="settings?.multiple || multiple"
    :pt:root="error ? 'border-red-500 border border-solid rounded-lg' : 'border-gray-300'"
    :showCancelButton="false"
    :showUploadButton="false"
    @select="onSelectedFiles"
  >
    <template #header="{ chooseCallback }">
      <Button
        rounded
        outlined
        :class="buttonClass"
        :icon="iconClass"
        :label="$t('components.fileUploader.choose')"
        :disabled="!isMultiple && files.length >= 1"
        @click="chooseCallback()"
      />
    </template>

    <template #content="{ files, removeFileCallback }">
      <!-- TODO :: We should be able to render the images that the user already sent to the api here
      Right now we are not able due limitation in the API
      We would need a centered file method so we have unique IDs while rendering and deleting -->

      <AFieldUpload
        v-if="files.length"
        :error
        :files
        @onRemoveFile="(id: number) => onRemoveFile(id, removeFileCallback)"
      />

      <Message
        v-else-if="overloadFile"
        class="text-wrap"
        icon="pi pi-times-circle"
        pt:text="text-sm"
        severity="error"
        :closable="false"
      >
        {{ $t('components.fileUploader.toast.message.error') }}
      </Message>

      <div
        v-else
        :class="['flex flex-col items-center', { 'text-red-500': error }]"
      >
        <p class="flex items-center gap-3 text-center text-sm">
          <span class="pi pi-file-import text-sm"></span>

          {{ $t('components.fileUploader.drop') }}
        </p>
      </div>
    </template>
  </FileUpload>
</template>
