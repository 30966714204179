enum SITES {
  'Interscapular',
  'Left Shoulder or Foreleg',
  'Left Rear Leg',
  'Mouth',
  'Nose',
  'Right Shoulder or Foreleg',
  'Right Rear Leg',
  'Left Side of Neck',
  'Tail',
}

export { SITES };
