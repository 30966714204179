import type { ApiParams } from './types';
import { API_URL } from '@Constants';
import { axios } from '@Libraries';

const DEFAULT_AXIOS_OPTIONS = {
  validateStatus: (status: number) => status >= 200 && status < 300,
};

export default async function (params: ApiParams) {
  const { endpoint, method, payload, isUpload } = params;
  const apiEndpoint = endpoint.replace('/api', API_URL);
  
  const axiosOptions = {
    ...DEFAULT_AXIOS_OPTIONS,
    method,
    data: payload,
    headers: { 'Content-Type': isUpload ? 'multipart/form-data' : 'application/json' },
  };

  return axios(apiEndpoint, { ...axiosOptions })
    .then(({ data }) => data);
}
