import {
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowsSpin,
  faArrowsUpDown,
  faBars,
  faBox,
  faCashRegister,
  faCat,
  faChartBar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faClipboardList,
  faClock,
  faCode,
  faCog,
  faCreditCard,
  faDollarSign,
  faDog,
  faDownload,
  faEye,
  faFile,
  faFileInvoiceDollar,
  faFileWaveform,
  faFilter,
  faGear,
  faGift,
  faGlobe,
  faGlobeAmericas,
  faHandHoldingMedical,
  faHandshake,
  faHeartCircleCheck,
  faHeartPulse,
  faHome,
  faInfoCircle,
  faLink,
  faListCheck,
  faMagnifyingGlass,
  faMobile,
  faMoneyCheckDollar,
  faMoneyBillTransfer,
  faPaw,
  faPen,
  faPenToSquare,
  faPills,
  faPlus,
  faPrint,
  faQrcode,
  faQuestionCircle,
  faReceipt,
  faScrewdriverWrench,
  faSdCard,
  faSignInAlt,
  faSignOutAlt,
  faSignature,
  faStar,
  faStethoscope,
  faSyringe,
  faTable,
  faTableColumns,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faTruck,
  faUpload,
  faUser,
  faUsers,
  faUsersLine,
  faWallet,
  faWarehouse,
  faWindowMaximize,
  faXmark,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

interface FaIcons {
  [key: string]: string | IconDefinition;
  faAddressCard: IconDefinition;
  faAngleDown: IconDefinition;
  faAngleRight: IconDefinition;
  faArchive: IconDefinition;
  faArrowsSpin: IconDefinition;
  faArrowsUpDown: IconDefinition;
  faBars: IconDefinition;
  faBox: IconDefinition;
  faCashRegister: IconDefinition;
  faCat: IconDefinition;
  faChartBar: IconDefinition;
  faCheck: IconDefinition;
  faChevronLeft: IconDefinition;
  faChevronRight: IconDefinition;
  faCircleCheck: IconDefinition;
  faCircleInfo: IconDefinition;
  faCircleXmark: IconDefinition;
  faClipboardList: IconDefinition;
  faClock: IconDefinition;
  faCode: IconDefinition;
  faCog: IconDefinition;
  faCreditCard: IconDefinition;
  faDollarSign: IconDefinition;
  faDog: IconDefinition;
  faDownload: IconDefinition;
  faEye: IconDefinition;
  faFile: IconDefinition;
  faFileInvoiceDollar: IconDefinition;
  faFileWaveform: IconDefinition;
  faFilter: IconDefinition;
  faGear: IconDefinition;
  faGift: IconDefinition;
  faGlobe: IconDefinition;
  faGlobeAmericas: IconDefinition;
  faHandHoldingMedical: IconDefinition;
  faHandshake: IconDefinition;
  faHeartCircleCheck: IconDefinition;
  faHeartPulse: IconDefinition;
  faHome: IconDefinition;
  faInfoCircle: IconDefinition;
  faLink: IconDefinition;
  faListCheck: IconDefinition;
  faMagnifyingGlass: IconDefinition;
  faMobile: IconDefinition;
  faMoneyCheckDollar: IconDefinition;
  faMoneyBillTransfer: IconDefinition;
  faPaw: IconDefinition;
  faPen: IconDefinition;
  faPenToSquare: IconDefinition;
  faPills: IconDefinition;
  faPlus: IconDefinition;
  faPrint: IconDefinition;
  faQrcode: IconDefinition;
  faQuestionCircle: IconDefinition;
  faReceipt: IconDefinition;
  faScrewdriverWrench: IconDefinition;
  faSdCard: IconDefinition;
  faSignInAlt: IconDefinition;
  faSignOutAlt: IconDefinition;
  faSignature: IconDefinition;
  faStar: IconDefinition;
  faStethoscope: IconDefinition;
  faSyringe: IconDefinition;
  faTable: IconDefinition;
  faTableColumns: IconDefinition;
  faTimes: IconDefinition;
  faTrash: IconDefinition;
  faTriangleExclamation: IconDefinition;
  faTruck: IconDefinition;
  faUpload: IconDefinition;
  faUser: IconDefinition;
  faUsers: IconDefinition;
  faUsersLine: IconDefinition;
  faWallet: IconDefinition;
  faWarehouse: IconDefinition;
  faWindowMaximize: IconDefinition;
  faXmark: IconDefinition;
}

const ICONS: FaIcons = {
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowsSpin,
  faArrowsUpDown,
  faBars,
  faBox,
  faCashRegister,
  faCat,
  faChartBar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faClipboardList,
  faClock,
  faCode,
  faCog,
  faCreditCard,
  faDollarSign,
  faDog,
  faDownload,
  faEye,
  faFile,
  faFileInvoiceDollar,
  faFileWaveform,
  faFilter,
  faGear,
  faGift,
  faGlobe,
  faGlobeAmericas,
  faHandHoldingMedical,
  faHandshake,
  faHeartCircleCheck,
  faHeartPulse,
  faHome,
  faInfoCircle,
  faLink,
  faListCheck,
  faMagnifyingGlass,
  faMobile,
  faMoneyCheckDollar,
  faMoneyBillTransfer,
  faPaw,
  faPen,
  faPenToSquare,
  faPills,
  faPlus,
  faPrint,
  faQrcode,
  faQuestionCircle,
  faReceipt,
  faScrewdriverWrench,
  faSdCard,
  faSignInAlt,
  faSignOutAlt,
  faSignature,
  faStar,
  faStethoscope,
  faSyringe,
  faTable,
  faTableColumns,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faTruck,
  faUpload,
  faUser,
  faUsers,
  faUsersLine,
  faWallet,
  faWarehouse,
  faWindowMaximize,
  faXmark,
};

export type { FaIcons };
export default ICONS;
