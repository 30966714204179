<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { formatDateToString } from '@Helpers';
import type { FieldSchema } from '../../../types';

const props = withDefaults(
  defineProps<{
    error?: string;
    field?: FieldSchema;
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    placeholder: 'components.forms.placeholder.common',
  }
);

const model = defineModel<string[]>({
  default: [new Date()],
});

const modelBooster = ref<string[]>([]);
const boosterInitialValues = computed(() => props.field?.settings.boosterValue);

onMounted(() => {
  initModel();
  setModelBoosterProp();
});

watch(model, (newModel) => updateModel(newModel), { deep: true });
watch(boosterInitialValues, () => setModelBoosterProp(), { deep: true });

function initModel() {
  if (model.value && model.value.length) return;

  model.value = [formatDateToString(new Date())];
}

function setModelBoosterProp() {
  const boosterValue = boosterInitialValues.value;

  if (!boosterValue.booster) return (model.value = []);

  const frequency = parseInt(boosterValue.booster_frequency, 10);
  const distance = parseInt(boosterValue.booster, 10);
  const unit = boosterValue.booster_unit;

  if (isNaN(frequency) || isNaN(distance) || !unit) return;

  const now = new Date();
  const initialDate = new Date(now);
  switch (unit) {
    case 'days':
      initialDate.setDate(now.getDate() + distance);
      break;
    case 'weeks':
      initialDate.setDate(now.getDate() + distance * 7);
      break;
    case 'months':
      initialDate.setMonth(now.getMonth() + distance);
      break;
    case 'years':
      initialDate.setFullYear(now.getFullYear() + distance);
      break;
  }

  const newDates = [];
  for (let i = 0; i < frequency; i++) {
    let date = new Date(initialDate);
    switch (unit) {
      case 'days':
        date.setDate(initialDate.getDate() + i * distance);
        break;
      case 'weeks':
        date.setDate(initialDate.getDate() + i * distance * 7);
        break;
      case 'months':
        date.setMonth(initialDate.getMonth() + i * distance);
        break;
      case 'years':
        date.setFullYear(initialDate.getFullYear() + i * distance);
        break;
    }
    newDates.push(formatDateToString(date));
  }

  model.value = newDates;
}

function updateModel(newModel: string[]) {
  const formattedModel = newModel.map((item) => formatDateToString(new Date(item)));

  if (formattedModel.join(',') === model.value.join(',')) return;

  model.value = formattedModel;
}

function onNewInput() {
  const newDate = formatDateToString(new Date());
  modelBooster.value = [...model.value, newDate];

  model.value = modelBooster.value;
}

function onRemoveInput(index: number) {
  if (model.value.length <= 1) return;

  model.value.splice(index, 1);
}
</script>

<template>
  <div
    v-for="(entry, index) in model"
    class="mt-2"
    :key="index"
  >
    <InputGroup>
      <InputGroupAddon class="h-9 p-0">
        <i class="pi pi-angle-double-up mr-1 text-sm"></i>

        <p class="text-sm">{{ index + 1 }}</p>
      </InputGroupAddon>

      <MFieldDatePicker
        v-model="model[index]"
        class="w-full"
        :error="error"
        :field="field"
        :placeholder="field?.settings?.placeholder || placeholder"
        :required="field?.settings?.required || required"
      />

      <InputGroupAddon class="h-9 p-0">
        <Button
          outlined
          class="h-fit w-fit border-none px-2 py-1"
          icon="pi pi-trash text-sm"
          severity="danger"
          :disabled="model.length === 1"
          @click="onRemoveInput(index)"
        />
      </InputGroupAddon>
    </InputGroup>
  </div>

  <Button
    outlined
    class="af-button mt-2 w-fit text-sm"
    icon="pi pi-plus text-sm"
    :label="$t('components.forms.button.booster')"
    :severity="error ? 'danger' : 'info'"
    @click="onNewInput"
  />
</template>
