interface DropdownOptions {
  name: string;
  value: string;
}

interface DropdownSettings {
  checkmark?: boolean;
  filter?: boolean;
  highlightOnSelect?: boolean;
  optionLabel?: string;
  options: DropdownOptions[];
  showClear?: boolean;
  styles?: string;
  hasComposedValues?: boolean;
}

interface MultiSelectSettings {
  checkmark: boolean;
  filter: boolean;
  highlightOnSelect: boolean;
  optionLabel: string;
  resetFilterOnHide: boolean;
  showClear: boolean;
}

interface SelectInputNumber {
  min?: number;
}

interface OptionArray {
  name: string;
  value: string;
}

interface FilterState {
  disabled?: boolean;
  invalid?: boolean;
  loading?: boolean;
}

interface FilterText {
  hint?: string;
  label?: string;
  placeholder?: string;
}

interface FiltersProps {
  model: string | null | OptionArray[] | OptionArray;
  name: string;
  prevModel?: string | null | OptionArray[] | OptionArray;
  settings: DropdownSettings;
  state?: FilterState;
  text?: FilterText;
  type: FILTER_TYPES;
}

enum FILTER_TYPES {
  DROPDOWN = 'dropdown',
  MULTISELECT = 'multiselect',
  SELECT_INPUT_NUMBER = 'selectInputNumber',
}

enum SYSTEM_FILTERS {
  intake_type = 'intake_type',
  adopter_status = 'adopter_status',
  age = 'age',
  altered = 'altered',
  altered_in_care = 'altered_in_care',
  animals_impacted = 'animals_impacted',
  animal_status = 'animal_status',
  application = 'application',
  breed = 'breed',
  call_type = 'call_type',
  categories = 'donation_category',
  cause_of_death = 'cause_of_death',
  color_pattern = 'color_pattern',
  contact_source = 'contact_source',
  days_lost = 'days_lost',
  days_of_support = 'days_support',
  declawed = 'declawed',
  diagnostic = 'diagnostic',
  euthanasia_reason = 'euthanasia_reason',
  foster_status = 'foster_status',
  foster_status_report = 'foster_status_report',
  frequency = 'frequency',
  gender = 'gender',
  good_with_cats = 'good_with_cats',
  good_with_dogs = 'good_with_dogs',
  groups = 'groups',
  hold_status = 'hold_status',
  house_trained = 'house_trained',
  jurisdiction = 'jurisdiction',
  kennel = 'kennel',
  loc = 'loc',
  location = 'location',
  location_status = 'location_status',
  lof = 'lof',
  los = 'los',
  manufacturer = 'manufacturer',
  medication = 'medication',
  nof = 'nof',
  nop = 'nop',
  nor = 'nor',
  officers = 'officers',
  outcome_asilomar = 'outcome_asilomar',
  outcome_subtype = 'outcome_subtype',
  outcome_type = 'outcome_type',
  payment_types = 'payment_types',
  payment_type_1 = 'payment_type_1',
  payment_type_2 = 'payment_type_2',
  primary_color_map = 'primary_color_map',
  priority = 'priority',
  product = 'product',
  registration_status = 'registration_status',
  registration_status_reports = 'registration_status_reports',
  reintake = 'reintake',
  resolution = 'resolution',
  return_reason = 'return_reason',
  route = 'route', // NOTE : 'AS'
  seizure_reason = 'seizure_reason',
  service_type = 'service',
  size_group = 'size_group',
  source = 'contact_source',
  species = 'species',
  staff = 'staff',
  state = 'state',
  status = 'status',
  surgery = 'surgery',
  surrender_reason = 'surrender_reason',
  tags = 'tags',
  vaccine = 'vaccine',
  vaccine_type = 'vaccine_type',
  vouchers = 'vouchers',
  voucher_status = 'voucher_status',
}

interface SystemFilter {
  [key: string]: FiltersProps;
}
interface ApiSystemFilter {
  type: string;
  name: SYSTEM_FILTERS;
  values: any;
  hasComposedValues?: boolean;
}

export {
  type ApiSystemFilter,
  type DropdownOptions,
  type DropdownSettings,
  type FilterText,
  type FiltersProps,
  type MultiSelectSettings,
  type OptionArray,
  type SelectInputNumber,
  type SystemFilter,
  FILTER_TYPES,
  SYSTEM_FILTERS,
};
