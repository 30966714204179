<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { API } from '@Providers';
import { useAToast } from '@Atoms';
import { FetchProfileService } from '@AuthModule';
import { useSessionStore } from '@Stores';

const props = defineProps<{
  rowData: any;
}>();

const { initProfile } = useSessionStore();

const ROUTER = useRouter();
const { TOAST_TYPES, notify } = useAToast();

const TOAST_ERROR = {
  message: 'errors.generics.unknownIssue.message',
  title: 'errors.generics.unknownIssue.title',
  type: TOAST_TYPES.ERROR,
};

const shelterId = computed(() => props.rowData?._id.$oid || null);
const shelterName = computed(() => props.rowData?.shelter || null);

function getShelterInfo() {
  FetchProfileService()
    .then((profileData: any) => initProfile(profileData))
    .catch(() => notify(TOAST_ERROR));
}
async function onLoginAs(id: string) {
  if (!id) return;

  await API({ endpoint: `/api/loginas/${id}` }).finally(() => {
    getShelterInfo();
    ROUTER.push({ name: 'animals-in-care' });
  });
}
</script>

<template>
  <Button
    v-if="shelterId && shelterName"
    v-tooltip.left="{ value: $t('shelters.tooltips.login', { shelterName }), showDelay: 1000 }"
    outlined
    class="af-button w-full text-xs"
    icon="pi pi-sign-in"
    iconPos="right"
    :label="shelterName"
    @click="onLoginAs(shelterId)"
  />
</template>
