<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import type { GenericField } from '@Types';
import type { FieldSchema } from '../../../types';
import { capitalizeStr } from '@Helpers';
import { emailValidation, phoneValidation } from '../../../helpers';

export interface ContactFieldType {
  contact: string | null;
  email?: string | null;
  error?: string;
  id: number;
  phone?: string | null;
  primary: boolean;
  type: 'work' | 'home' | 'other' | 'mobile';
}

defineOptions({ inheritAttrs: false });

const props = withDefaults(
  defineProps<{
    contactType?: 'email' | 'phone';
    error?: string;
    field?: FieldSchema;
    options?: any[];
    placeholder?: string;
    required?: boolean;
  }>(),
  {
    contactType: 'email',
    placeholder: 'components.forms.placeholder.common',
  }
);

const model = defineModel<ContactFieldType[]>({
  default: [{ id: 1, contact: null, type: 'home', primary: false }],
});

const contactIdIndex = ref(1);
const radioModel = ref<number>(1);

const contactType = computed(() => props?.field?.settings?.contactType || props.contactType);
const isEmailType = computed(() => contactType.value === 'email');
const options = computed(() => props?.field?.edit?.options || props?.options || []);

watch(radioModel, (newId, oldId) => toggleRadioModel(newId, oldId));

watch(
  () => model,
  () => onModelUpdate(),
  { deep: true }
);

onMounted(() => setIds());

// EVENTS
function onModelUpdate() {
  onPrimaryUpdate();

  model.value.forEach(({ contact, id }) => {
    if (contact !== null) validateEmailField(contact, id);
  });
}

function onNewContact() {
  model.value.push({
    id: contactIdIndex.value + 1,
    contact: null,
    type: 'home',
    primary: !model.value.length,
  });

  ++contactIdIndex.value;
}

function onRemoveContact(toBeDeletedId: number) {
  const isLastItem = model.value.length === 1;
  const emptyContact = model.value[0].contact === null;

  // NOTE: Last entry in the array of items
  const lastItemWithEmptyContent = isLastItem && emptyContact;
  if (lastItemWithEmptyContent) return;

  const lastItemWithContent = isLastItem && !emptyContact;
  if (lastItemWithContent) return resetState();

  // NOTE: Validation for primary item
  let newEntries: ContactFieldType[] = model.value.filter(({ id }) => id !== toBeDeletedId);
  const newEntriesHasPrimary = newEntries.some(({ primary }) => primary);

  if (!newEntriesHasPrimary) newEntries[0].primary = true;

  model.value = newEntries;
}

function onTypeUpdate(index: number, { value }: GenericField) {
  model.value.forEach((entry) => {
    const matchEntry = entry.id === index;

    if (matchEntry) entry.type = value;
  });
}

function onPrimaryUpdate() {
  const primaryContact = model.value.filter(({ primary }) => primary);
  const noActivePrimary = !primaryContact.length;
  if (noActivePrimary) return;

  radioModel.value = primaryContact[0].id;
}
// HELPERS
function setIds() {
  if (!model.value.length) return;

  model.value.forEach((entry, index) => (entry.id = index + 1));
  contactIdIndex.value = model.value.length;
}

function toggleRadioModel(newSelectionId: number, oldSelectionId: number) {
  const contactData = {
    newId: '',
    oldId: '',
  };

  model.value.forEach(({ contact, id }) => {
    if (id === newSelectionId) contactData.newId = contact ?? '';
    if (id === oldSelectionId) contactData.oldId = contact ?? '';
  });

  const emptyContactNewId = contactData.newId;
  if (!emptyContactNewId) {
    setError(newSelectionId, getEmptyError());

    const emptyContactOldId = contactData.oldId;
    if (emptyContactOldId) radioModel.value = oldSelectionId;

    return;
  }

  model.value.forEach((entry) => (entry.primary = radioModel.value === entry.id));
}

function resetState() {
  contactIdIndex.value = 1;
  radioModel.value = 1;

  model.value = [
    {
      id: 1,
      contact: null,
      type: 'home',
      primary: true,
      error: getEmptyError(),
    },
  ];
}

// VALIDATIONS
function validateEmailField(contact: string, id: number) {
  if (!contact) return setError(id, getEmptyError());

  const isInvalid = isEmailType.value ? !emailValidation(contact) : !phoneValidation(contact);
  if (isInvalid) return setError(id, getInvalidError());

  const contactsInTheModel = model.value
    .map(({ contact }) => contact)
    .filter((contactModel) => contactModel === contact);
  const isDuplicated = contactsInTheModel.length > 1;
  if (isDuplicated) return setError(id, getDuplicatedError());

  return setError(id, '');
}

function setError(id: number, error: string) {
  model.value.forEach((entry) => {
    const matchEntry = entry.id === id;

    if (matchEntry) entry.error = error;
  });
}

function getEmptyError() {
  const EMPTY_EMAIL = 'components.forms.errors.addEmail';
  const EMPTY_PHONE = 'components.forms.errors.addPhone';

  return isEmailType.value ? EMPTY_EMAIL : EMPTY_PHONE;
}

function getInvalidError() {
  const INVALID_EMAIL = 'components.forms.errors.validEmail';
  const INVALID_PHONE = 'components.forms.errors.validPhone';

  return isEmailType.value ? INVALID_EMAIL : INVALID_PHONE;
}

function getDuplicatedError() {
  const DUPLICATED_EMAIL = 'components.forms.errors.duplicatedEmail';
  const DUPLICATED_PHONE = 'components.forms.errors.duplicatedPhone';

  return isEmailType.value ? DUPLICATED_EMAIL : DUPLICATED_PHONE;
}
</script>

<template>
  <div
    v-for="entry in model"
    class="mb-2"
    :key="entry.id"
  >
    <AFieldError :error="entry?.error" />

    <InputGroup>
      <InputGroupAddon :class="['h-9 w-36 p-0', { 'af-field-error': !!entry?.error }]">
        <AFieldDropdown
          class="!h-full !w-min border-none"
          optionLabel="name"
          :options
          :error="entry?.error"
          :placeholder="capitalizeStr(entry.type) || $t('common.type')"
          @change="({ value }: GenericField) => onTypeUpdate(entry.id, value)"
        />
      </InputGroupAddon>

      <AFieldEmail
        v-if="isEmailType"
        v-model="entry.contact"
        class="rounded-none"
        :error="entry?.error"
        :required="true"
        @change="(contact: string) => validateEmailField(contact, entry.id)"
      />

      <AFieldPhone
        v-else
        v-model="entry.contact"
        class="rounded-none border-r-0"
        :error="entry?.error"
        :required="true"
        @change="(contact: string) => validateEmailField(contact, entry.id)"
      />

      <InputGroupAddon :class="['h-9 p-0', { 'af-field-error': !!entry?.error }]">
        <RadioButton
          v-model="radioModel"
          v-tooltip.top="$t('tooltip.primary')"
          name="radio"
          :inputId="`${entry.id}`"
          :invalid="!!entry?.error"
          :value="entry.id"
        />
      </InputGroupAddon>

      <InputGroupAddon :class="['h-9 p-0', { 'af-field-error': !!entry?.error }]">
        <Button
          outlined
          class="h-fit w-fit border-none px-2 py-1"
          icon="pi pi-trash text-sm"
          severity="danger"
          @click="onRemoveContact(entry.id)"
        />
      </InputGroupAddon>
    </InputGroup>
  </div>

  <Button
    outlined
    class="af-button w-fit"
    icon="pi pi-plus"
    :label="$t(`common.${contactType}`)"
    @click="onNewContact"
  />
</template>
