import { ref } from 'vue';
import type { GenericField } from '@Types';
import type { FieldInitialSettings, FieldSchema, RawField } from '@FormModule';
import { useForm } from '@FormModule';
import { formatNumberToDate } from '@Helpers';

const currentSchemaModels = ref<Set<GenericField>>(new Set());
const fieldsSchema = ref<FieldSchema[]>([]);
const formError = ref();

export default function () {
  const { getDetailsSchema } = useForm();

  const fieldsSettings: FieldInitialSettings = {};
  const rawFields: RawField[] = [
    {
      label: 'Diagnostic Date',
      name: 'date',
      required: true,
      type: 'date',
      value: '',
    },
    {
      label: 'Product',
      name: 'product',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      required: false,
      type: 'text',
      value: '',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'textarea',
      value: '',
    },
  ];

  function setForm(sourceData?: any) {
    const updatedFieldSchema = getDetailsSchema(rawFields, fieldsSettings);

    updatedFieldSchema.forEach((field) => {
      const match = sourceData[field.name];

      // prettier-ignore
      switch (field.name) {
        case 'date': return setMatchDateField(field, sourceData)
        default: setMatchField(field, match);
      }
    });

    fieldsSchema.value = updatedFieldSchema;
  }

  function setMatchDateField(field: any, sourceData: any) {
    const date = sourceData.date.$date.$numberLong;

    field.model = formatNumberToDate(date, false, false, true) || new Date();
    field.value = formatNumberToDate(date, false, false, true) || new Date();
  }

  function setMatchField(field: any, match: any) {
    field.model = match;
    field.value = match;
  }

  function resetForm() {
    currentSchemaModels.value = new Set();
    fieldsSchema.value = [];
  }

  return {
    currentSchemaModels,
    fieldsSchema,
    formError,
    rawFields,

    resetForm,
    setForm,
  };
}
