<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { FieldSchema } from '../../../types';
import type { GenericField } from '@Types';
import { capitalizeStr } from '@Helpers';

export interface Model {
  duration: number;
  duration_unit: string;
}

const props = defineProps<{
  disabled?: boolean;
  error?: string;
  field?: FieldSchema;
  placeholder?: string;
  required?: boolean;
}>();

const { t } = useI18n();

const model = defineModel<Model>();
const modelNumber = ref();
const modelUnit = ref();
const modelDatePicker = ref();
const modelRadio = ref<'calendar' | 'selector'>(
  props.field?.settings.hasCalendar ? 'calendar' : 'selector'
);

const options = computed(() => [
  `${t('common.day', 2)}`,
  `${t('common.week', 2)}`,
  `${t('common.month', 2)}`,
  `${t('common.year', 2)}`,
]);
const optionsRadio = computed((): GenericField[] => [
  {
    name: t('common.calendar'),
    value: 'calendar',
  },
  {
    name: t('common.selector'),
    value: 'selector',
  },
]);

const input = useDebounce(modelNumber, 400);

onMounted(() => initModel());

watch(model, initModel);
watch(input, updateModel);
watch(modelUnit, updateModel);
watch(modelRadio, updateModel);
watch(modelDatePicker, updateModel);

function initModel() {
  // NOTE: This first if needs to be changed for the modelRadio that the back sends when there is a
  // backend for this (AF-500). When it does, move this if to inside the selector if and check first for the
  // modelRadio to choose one of the two cases.
  if (!model.value || !model.value.duration || !model.value.duration_unit) return;

  if (model.value.duration_unit) {
    modelRadio.value = 'selector';
    modelNumber.value = model.value.duration;
    modelUnit.value = capitalizeStr(model.value.duration_unit);
    return;
  }

  modelRadio.value = 'calendar';
  modelDatePicker.value = model.value.duration;
}

function updateModel() {
  // TODO: [AF-500] - Reactivate this mode after backend implementation
  // if (modelRadio.value === 'calendar')
  //   return (model.value = { duration: modelDatePicker.value, duration_unit: '' });

  if (!modelNumber.value || !modelUnit.value) return;

  model.value = { duration: modelNumber.value, duration_unit: modelUnit.value.toLowerCase() };
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <!-- TODO: [AF-500] - Reactivate this mode after backend implementation -->
    <!-- <AFieldRadio
      v-if="field?.settings.hasCalendar"
      v-model="modelRadio"
      :options="optionsRadio"
    />

    <MFieldDatePicker
      v-if="modelRadio === 'calendar'"
      v-model="modelDatePicker"
      :minDate="new Date()"
    /> -->

    <!-- v-else -->
    <div class="flex flex-row gap-3">
      <div class="!w-1/2">
        <AFieldNumber
          v-model="modelNumber"
          :disabled="field?.state?.disabled || disabled"
          :error
          :placeholder
          :required
        />
      </div>

      <AFieldDropdown
        v-model="modelUnit"
        class="!w-1/2"
        :disabled="field?.state?.disabled || disabled"
        :error
        :options
        :placeholder
        :required
        @change="updateModel"
      />
    </div>
  </div>
</template>
