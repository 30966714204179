<script setup lang="ts">
import { computed } from 'vue';
import { useSessionStore } from '@Stores';
import { API_URL } from '@Constants';

const props = defineProps<{
  field: any;
  rowData: any;
  settings: any;
}>();

const { shelterProfile } = useSessionStore();

const alerts = computed(() => props.rowData.alert);
const rfc = computed(() => props.rowData.rfc);
const src = computed(() => {
  let src = 'photo';

  if (!props.settings.render) return props.field ? props.field : src;

  if (Array.isArray(props.field) && props.field.length) {
    src = props.field[0][props.settings.render.targetKey];
  }

  return src;
});
const personPath = computed(() => {
  return `${shelterProfile._id.$oid}/people/${props.rowData?._id?.$oid}/${props.rowData?.profile_photo?.filename}`;
});
const peoplePath = computed(() => {
  return `${shelterProfile._id.$oid + '/people/' + props.rowData._id + '/' + props?.field?.filename}`;
});
const staffPath = computed(() => {
  return `${shelterProfile._id.$oid + '/users/' + props.rowData._id + '/' + props?.field?.filename}`;
});

function getPath() {
  const isPersonType = props.settings.type === 'Person';
  const isPeopleType = props.settings.type === 'People';
  const isStaffType = props.settings.type === 'Staff';

  if (isPersonType)
    return `${API_URL}/shelter/files/get-file/${shelterProfile._id.$oid}?file=${personPath.value}`;

  if (!props.field?.filename) return;

  if (isPeopleType)
    return `${API_URL}/shelter/files/get-file/${shelterProfile._id.$oid}?file=${encodeURIComponent(peoplePath.value)}`;

  if (isStaffType)
    return `${API_URL}/shelter/files/get-file/${shelterProfile._id.$oid}?file=${encodeURIComponent(staffPath.value)}`;
}
</script>

<template>
  <AAvatar
    v-if="settings.type"
    :gender="rowData.gender"
    :src="getPath()"
    :type="settings.type"
  />

  <div v-else>
    <AAvatar
      class="h-fit"
      :src
      :gender="rowData.gender"
      :type="settings.type"
    />

    <i
      v-if="alerts"
      v-tooltip.top="alerts"
      class="pi pi-exclamation-triangle absolute left-0 top-0 text-lg text-warning-500"
    />

    <div
      v-if="rfc?.length"
      v-tooltip.top="$t('animals.tooltips.requestForCare', { request: rfc.length })"
      class="absolute left-[37px] top-0 text-[17px] text-red-500"
    >
      <FaIcon :icon="$icons.faHandHoldingMedical" />
    </div>
  </div>
</template>
